@charset "utf-8";

$baseColor: #36357e;//ロゴカラー
$baseColor-light: #8b92c7;//ロゴカラー薄
$baseColor-titbg: #f5f5f8;//タイトル背景
$baseColor-bg: #f7f7fa;//ロゴカラー背景色
$textColor: #333;
$titleColor: #555;
$textColor-light: #666;
$textColor-link: #1432c8;
$textColor-hover: #ff6600;//マウスオーバー
$textColor-visited: #990099;//既読カラー
$borderColor: #ccc;
$iconColor: #6d6564;
$formFocus: #11cab6;
$formBg: #d6ffff;

//出版をお考えの方へ
$publi-baseColor:#e62814;//タイトル、リンク等
$publi-iconColor: #ed5c39;//アイコン用＠ちょっと薄い
$publi-hoverColor: #fdeeeb;//hover色
$publi-drawrColor: #d23c28;//SPメニュー用

//書籍案内
$bkinfo-baseColor: #ff9e00;//タイトル、リンク等
$bkinfo-iconColor: #f5a900;//アイコン用＠ちょっと薄い
$bkinfo-hoverColor: #fcf3e6;//hover色
$bkinfo-drawrColor: #faac2f;//SPメニュー用

//会社概要
$about-baseColor: #36357e;//タイトル、リンク等
$about-iconColor: #6867b0;//アイコン用＠ちょっと薄い
$about-hoverColor: #ece6ef;//hover色
$about-drawrColor: #363588;//SPメニュー用

//NEWS ※一部適当
$news-baseColor: #555;//タイトル、リンク等
$news-iconColor: #6d6564;//アイコン用＠ちょっと薄い
$news-hoverColor: #ccc;//hover色
$news-drawrColor: #6d6564;//SPメニュー用

//お問い合わせ SPメニュー用
$contact-drawrColor: #973e41;//SPメニュー用

//安心サポート色＠ピンク
$pink-baseColor: #ff8282;//タイトル等
$pink-lightColor: #ffc1c1;//ちょっと薄い
$pink-thinColor: #ffdada;//薄い

//ホンダスカラー
$hondas-baseColor: #60a718;//タイトル等

$white: #fff;
$black: #000;
$red: #f00;
$orange: #e65014;//説明会お問い合わせカラー

//グレーいろいろ
$darkgray: #808080;//メガドロ背景
$gray: #a5a5a5;//pagetopボタン等
$gray-b: #b4b4b4;//お知らせ
$gray-e: #eaeaea;//背景色
$gray-fc: #fcfcfc;//footer背景色
$bg-alert: #fbded9;//注意書き背景色

$baseWidthPlus: 1160px;
$baseWidth: 1080px;
$base2Width: 960px;
$fullWidth: 100%;
$spWidth: 94%;
$halfWidth: 50%;

//borderColor1px
$borderSol: 1px solid $borderColor;
$borderDot: 1px dotted $borderColor;
$borderDash: 1px dashed $borderColor;

//borderColor2px
$borderSol2: 2px solid $borderColor;

//borderSol
$borderSol-dgray: 1px solid $darkgray;
$borderSol-white1: 1px solid $white;
$borderSol-white2: 2px solid $white;

//border -baseC
$borderSol-baseC1: 1px solid $baseColor;
$borderSol-baseC2: 2px solid $baseColor;
$borderSol-baseC3: 3px solid $baseColor;

//border -publi
$borderSol-publi1: 1px solid $publi-baseColor;
$borderSol-publi2: 2px solid $publi-baseColor;
$borderSol-publi3: 3px solid $publi-baseColor;

//border -support
$borderSol-pink1: 1px solid $pink-baseColor;
$borderSol-pink2: 2px solid $pink-baseColor;
$borderSol-pink3: 3px solid $pink-baseColor;

//border -bkinfo
$borderSol-bkinfo1: 1px solid $bkinfo-baseColor;
$borderSol-bkinfo2: 2px solid $bkinfo-baseColor;
$borderSol-bkinfo3: 3px solid $bkinfo-baseColor;

//border -hondas
$borderSol-hondas1: 1px solid $hondas-baseColor;
$borderSol-hondas2: 2px solid $hondas-baseColor;
$borderSol-hondas3: 3px solid $hondas-baseColor;


$fs_xs: 1.0rem;
$fs_s: 1.2rem;
$fs_m: 1.4rem;
$fs_l: 1.6rem;
$fs_xl: 1.8rem;
$fs_xxl: 2.0rem;

$lh_s: 1.2;
$lh_m: 1.4;
$lh_l: 1.5;
$lh_xl: 1.7;
$lh_xxl: 1.9;

$breakpoints: (
	xxsx: "only screen and (max-width: 320px)",
	axsx: "only screen and (max-width: 360px)",//Android
	xsx: "only screen and (max-width: 375px)",//iPhone
	sx: "only screen and (max-width: 479px)",
	xmx: "only screen and (max-width: 539px)",
	mx: "only screen and (max-width: 700px)",
	lx: "only screen and (max-width: 767px)",//iPad以下
	xlx: "only screen and (max-width: 1160px)",//baseWidth（1080px ＋ margin:0 40px）
	ln: "print, screen and (min-width: 768px)",//PCのみ
	mn: "print, screen and (min-width: 701px)",//701px以上
);

//z-index
$layer: (
	hamburger: 110,
	header: 100,
	nav-sp: 90,
	pagetop: 80,
	search-sp: 70,
	first: 2,
	default: 1
);

//icon
$icomoon-font-family: "bungeisha" !default;
$icomoon-font-path: "../icon" !default;

$icon-blank: "\e900";
$icon-arrow-left: "\e901";
$icon-arrow-right: "\e902";
$icon-arrow-up: "\e903";
$icon-arrow-down: "\e904";
$icon-arrow-pagetop: "\e905";
$icon-arrow-chart: "\e906";
$icon-home: "\e907";
$icon-publishing: "\e908";
$icon-bookinfo: "\e909";
$icon-about: "\e90a";
$icon-contest01: "\e90b";
$icon-contest02: "\e90c";
$icon-publi-work01: "\e90d";
$icon-publi-work02: "\e90e";
$icon-publi-self01: "\e90f";
$icon-publi-self02: "\e910";
$icon-publi-guide01: "\e911";
$icon-publi-guide02: "\e912";
$icon-contact01: "\e913";
$icon-contact02: "\e914";
$icon-calendar: "\e915";
$icon-tel: "\e916";
$icon-mail: "\e917";
$icon-post: "\e918";
$icon-pc: "\e919";
$icon-book: "\e91a";
$icon-news: "\e91b";
$icon-question: "\e91c";
$icon-talk: "\e91d";
$icon-plus: "\e91e";
$icon-minus: "\e91f";
$icon-minus_bold: "\e920";
$icon-download: "\e922";
$icon-map: "\e923";
$icon-hondas: "\e921";
$icon-freecall: "\e924";
$icon-check: "\e925";
$icon-heart: "\e926";
$icon-search: "\e927";
$icon-company01: "\e928";
$icon-company02: "\e929";
$icon-philosophy01: "\e92a";
$icon-philosophy02: "\e92b";
$icon-commitment: "\e92c";
$icon-hot01: "\e92d";
$icon-hot02: "\e92e";
$icon-neo: "\e92f";
$icon-picturebook01: "\e930";
$icon-picturebook02: "\e931";
$icon-ebook01: "\e932";
$icon-ebook02: "\e933";
$icon-library01: "\e934";
$icon-library02: "\e935";
$icon-promotion: "\e936";
$icon-read-trial: "\e937";
$icon-website: "\e938";
$icon-blog: "\e939";
$icon-movie: "\e93a";
$icon-drama: "\e93b";
$icon-arrow-open: "\e93c";
$icon-twitter: "\e93d";
$icon-x: "\e93e";
$icon-note: "\e93f";