@charset "utf-8";

/* ============================================================
	toppage-main
============================================================ */
.toppage-main {
	width: $fullWidth;
	height: 600px;
	overflow: hidden;
	position: relative;
	@include media(lx) {
		width: $fullWidth;
		height: auto;
	}

	.video-wrap {
		position: absolute;
		display: block;
		right: 0;
		bottom: 0;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
		background-size: cover;
		opacity: 1;

		> video {
			width: auto;
			height: auto;
			position: absolute;
			top: 20%;
			left: 50%;
			min-width: 100%;
			min-height: 100%;
			transform: translate(-50%, -20%);
		}//video
	}//video-wrap

	.copy {
		@include z-index(first);
		display: block;
		position: absolute;
		right: 0;
		bottom: 0;
		width: 44%;
		height: 165px;
		padding: 30px;
		text-align: left;
	}
	.copy img {
		width: auto;
		height: 100%;
		filter: drop-shadow(0 0 2px rgba(0,0,0,0.5));
	}

	.copy_25th {
		@include z-index(first);
		display: block;
		position: absolute;
		right: 0;
		bottom: 0;
		width: 43%;
		height: 220px;
		padding: 30px;
		text-align: left;
		> a {
			display: inline-block;
			height: 160px;
		}
	}
	.copy_25th img {
		width: auto;
		height: 100%;
	}

	@include media(lx) {
		>.container {
			position: relative;
			margin-left: auto;
			margin-right: auto;
			max-width: 767px;
			margin-bottom: 30px;
			overflow: hidden;
		}
		//swiper.js
		.swiper-container {
			width: 100%;
			height: 100%;
			overflow: visible;
			@include z-index(default);

			@keyframes zoomUp {
				0% {transform: scale(1);}
				100% {transform: scale(1.15);}
			}
			.swiper-slide-active .slide-img,
			.swiper-slide-duplicate-active .slide-img,
			.swiper-slide-prev .slide-img {
				animation: zoomUp 10s linear 0s 1 normal both;
			}
			.slide-img img {display: block;}
		}//swiper-container

		.copy {
			right: 2vw;
			bottom: 2vw;
			width: auto;
			height: 70px;
			padding: 0 0 1vw 0;
			text-align: right;
		}
		.copy_25th {
			right: 2vw;
			bottom: 2vw;
			width: auto;
			height: 123px;
			padding: 0 1vw 1vw 0;
			text-align: right;
			> a {
				display: block;
				width: auto;
				height: 100%;
			}
		}
		.copy_25th img {filter: drop-shadow(0 0 2px rgba(0,0,0,0.5));}
	}//media(lx)
	@include media(sx) {
		.copy {height: 50px;}
		.copy_25th {height: 103px;}
	}

}//toppage-main


/* ============================================================
	toppage-news
============================================================ */
.toppage-news {
	margin: 20px auto 80px;
	position: relative;
	width: $baseWidth;
	@include media(ln) {
		@include flex-between;
	}
	@include media(lx) {
		@include margin-sp(0, 50px);
	}

	> .ttl-box {
		color: $baseColor;
		@include media(ln) {
			padding-top: 70px;
			width: 200px;
			min-height: 212px;
		}
		> .ttl {
			margin-bottom: 5px;
			font-size: 3.6rem;
			@include eng;
			@include media(lx) {font-size: 2.8rem;}
		}
		> .jpn {
			font-size: $fs-m;
			@include media(lx) {font-size: $fs-s;}
		}
	}//ttl-box

	> .btn-box {
		position: absolute;
		@include media(ln) {
			bottom: 6px;
			left: 0;
		}
		@include media(lx) {
			top: 6px;
			right: 0;
		}
	}//btn-box
	.btnw {
		padding: 10px 40px 10px 20px;
		@include ie-hack(gteIE11) {padding-top: 17px;}
		font-size: $fs_l;
		@include media(lx) {
			padding: 5px 30px 5px 10px;
			@include ie-hack(gteIE11) {padding-top: 12px;}
			font-size: 1.3rem;
		}
	}//btnw

	> .list {
		width: 800px;
		@include media(lx) {
			margin-top: 20px;
			width: $fullWidth;
		}
		> li {
			margin: 0 !important;
			display: block;
			border-top: $borderSol;
			&:first-child {border-top: none;}


			&.info + li.news {
				position: relative;
				margin-top: 15px;
				padding-top: 15px;
				border-top: solid 1px $gray-b;
				@include media(lx) {
					margin-top: 10px;
					padding-top: 10px;
					border-top: solid 2px $gray-b;
				}

				&::after {
					position: absolute;
					content: "";
					display: block;
					border-top: solid 1px $baseColor;
					top: -1px;
					width: 20%;
					@include media(lx) {
						border-top: solid 2px $baseColor;
						top: -2px;
						width: 50%;
					}
				}
			}

			> a {
				@include flex-wrap;
				@include flex-center-tb;
				padding: 6px 5px;
				width: $fullWidth;
				@include media(lx) {
					padding: 10px 0;
				}
				> p {
					@include media(ln) {margin: 0 5px;}
					&.thumb {
						margin-right: 5px;
						width: 72px;
						border: 1px solid $gray-e;
					}
					&.date {
						width: 106px;
						color: $gray;
						white-space: nowrap;
						@include media(lx) {
							margin-right: 5px;
							width: auto;
							font-size: $fs-s;
						}
					}
					&.label {
						@include label;
						width: 90px;
						font-size: $fs_s;
						@include media(lx) {
							width: auto;
							font-size: $fs-s;
						}
					}//label
					&.txt {
						width: 482px;
						line-height: 1.5;
						@include media(lx) {
							margin-top: 5px;
							width: 100%;
						}
					}
				}//p
				@include hover {
					@include opacity;
					text-decoration: none;
				}
			}//a
			> a.link-none {
				pointer-events: none;
				color: $textColor;
				@include hover {
					opacity: 1;
					text-decoration: none;
				}
			}//a.link-none
		}//li
	}//list
}//toppage-news


/* ============================================================
	toppage-bkinfo
============================================================ */
.toppage-bkinfo {
	margin: 80px auto;
	position: relative;
	width: $baseWidth;
	@include media(lx) {
		@include margin-sp(0, 50px);
	}

	> .ttl-box {
		margin-bottom: 30px;
		@include flex-between;
		@include flex-center-tb;
		position: relative;
		color: $baseColor;
		> .ttl {
			@include flex;
			@include flex-center-tb;
			font-size: 3.6rem;
			@include eng;
			@include media(lx) {font-size: 2.8rem;}
			> span {
				display: block;
				padding-right: 20px;
				border-right: $borderSol-baseC1;
				@include media(lx) {padding-right: 15px;}
				&.jpn {
					@include ie-hack(gteIE11) {padding-top: 7px;}
					padding-left: 20px;
					border: none;
					font-size: 2rem;
					font-style: normal;
					@include media(lx) {
						padding-left: 15px;
						font-size: $fs_l;
					}
				}
			}
		}//ttl
		> .link-box {
			margin-top: 0;
			> .link-arrow {
				font-size: $fs_l;
				@include ie-hack(gteIE11) {padding-top: 7px;}
			}
		}
	}//ttl-box

	> .menu-area {
		margin-bottom: 30px;
		width: $fullWidth;
		@include flex-between;
		> li {
			position: relative;
			padding: 18px 0;
			@include ie-hack(gteIE11) {padding-top: 25px;}
			width: 260px;
			background-color: $gray-e;
			color: $textColor;
			@include radius(3);
			font-size: $fs-l;
			@include bold;
			text-align: center;
			cursor: pointer;
			@include media(lx) {
				padding: 12px 0;
				width: 20vw;
				font-size: $fs-m;
			}
			@include hover() {
				background-color: $baseColor;
				color: $white;
				text-decoration: none;
				@include transition;
			}
			&.current {
				background-color: $baseColor;
				color: $white;
				span {
					color: $white;
					@include transition;
				}
				&::before {
					content: "";
					position: absolute;
					top: 98%;
					left: 50%;
					margin-left: -15px;
					border-top: 20px solid $baseColor;
					border-right: 15px solid transparent;
					border-left: 15px solid transparent;
					@include media(lx) {
						margin-left: -10px;
						border-top: 15px solid $baseColor;
						border-right: 10px solid transparent;
						border-left: 10px solid transparent;
					}
				}
			}//current
		}//li
		@include media(lx) {
			> li:nth-of-type(1), > li:nth-of-type(4) {width: 25vw;}
		}
	}//menu-area

	> .book-area {
		display: none;
		width: $fullWidth;
		&.current {
			display: block;
		}

		> .list {
			@include flex-wrap;
			width: $fullWidth;

			> li {
				display: block;
				padding: 0 40px;
				width: 270px;
				@include media(lx) {
					margin-bottom: 30px;
					padding: 0 10px;
					width: 50%;
				}

				> a {
					display: block;

					> p {
						margin: 10px 0;
						&.thumb {
							@include flex-center;
							margin-bottom: 20px;
							height: 222px;
							@include media(lx) {
								margin: 0;
								height: auto;
								min-height: 222px;
							}
							img {
								width: auto;
								max-width: 192px;
								@include media(ln) {max-height: 222px;}
								border: 1px solid $borderColor;
								@include media(lx) {
									width: 100%;
									max-width: 142px;
								}
							}
						}
						&.ttl {
							font-size: $fs_xl;
							@include bold;
							line-height: $lh-s;
							text-align: left;
							word-break: break-all;
							@include media(lx) {
								font-size: $fs_l;
							}
						}
						&.name {
							padding-bottom: 10px;
							font-size: $fs_s;
							border-bottom: $borderSol;
						}
						&.txt {
							font-size: 1.3rem;
							line-height: $lh-m;
							text-align: justify;
						}
						&.data {
							@include flex;
							@include flex-center-tb;
							font-size: $fs_s;
							color: $gray;
							> .label {
								@include label;
								margin-right: 5px;
								border: 1px solid $gray;
								font-size: $fs_xs;
								color: $gray;
							}//label
						}//data
					}//p

					@include hover {
						@include opacity;
						text-decoration: none;
					}

				}//a
			}//li
		}//list

	}//book-area

	> .btn-box {
		display: none;
		@include media(lx) {display: block;}
	}

}//toppage-bkinfo


/* ============================================================
	toppage-pickup
============================================================ */
.toppage-pickup {
	position: relative;
	margin: 60px auto 0;
	padding: 40px;
	width: $fullWidth;
	background-color: $gray-e;
	overflow: hidden;
	@include media(lx) {
		margin: 0;
		padding: 30px 0;
	}

	> .ttl-box {
		margin: 0 auto 40px;
		width: $baseWidth;
		@include flex-between;
		@include flex-center-tb;
		position: relative;
		color: $baseColor;
		@include media(lx) {
			@include margin-sp(0, 30px);
		}

		> .ttl {
			@include flex-center;
			font-size: 3.6rem;
			@include eng;
			@include media(lx) {font-size: 2.8rem;}
			> span {
				display: block;
				padding-right: 20px;
				border-right: $borderSol-baseC1;
				@include media(lx) {padding-right: 15px;}
				&.jpn {
					@include ie-hack(gteIE11) {padding-top: 7px;}
					padding-left: 20px;
					border: none;
					font-size: 2rem;
					font-style: normal;
					@include media(lx) {
						padding-left: 15px;
						font-size: $fs_l;
					}
				}
			}
		}//ttl
		> .link-box {
			margin-top: 0;
			> .link-arrow {font-size: $fs_l;}
		}
	}//ttl-box

	>.container {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 50px;
		max-width: 370px;
		@include media(lx) {
			margin-bottom: 30px;
			max-width: 190px;
		}
	}

//swiper.js
	>.container > .swiper-container {
		width: 100%;
		height: 100%;
		overflow: visible;
		@include z-index(default);
	}
	>.container > .swiper-pagination {
		width: 100%;
		position: absolute;
		bottom: -40px !important;
		left: 50%;
		transform: translate(-50%, 0);
		@include media(lx) {
			bottom: -30px !important;
		}
	}
	.swiper-pagination-bullet {
		margin: 0 5px;
		width: 12px;
		height: 12px;
		@include transition;
		@include hover() {
			opacity: .2;
		}
	}
	.swiper-pagination-bullet-active {
		background: $baseColor;
	}
//swiper.js

	.swiper-slide a {
		display: inline-block;
		margin: 0 10px;
		width: 360px;
		height: 340px;
		background-color: $white !important;
		@include media(lx) {
			width: 180px;
			height: 220px;
		}

		> p {
			&.data {
				padding: 15px;
				@include flex-between;
				@include flex-center-tb;
				font-size: $fs_m;
				color: $gray;
				@include media(lx) {
					padding: 10px;
					font-size: $fs_s;
				}
				> .label {
					@include label;
					font-size: $fs_xs;
				}//label
			}//data

			&.ttl {
				padding: 0 15px;
				font-size: $fs_l;
				@include bold;
				line-height: $lh-l;
				text-align: justify;
				overflow-wrap: break-word;
				@include ie-hack(gteIE11) {word-wrap: break-word;}
				@include media(lx) {
					padding: 0 10px;
					font-size: $fs_m;
					line-height: $lh-m;
				}
			}
		}//p

		@include hover {
			@include opacity;
			text-decoration: none;
		}
	}//a
}//toppage-pickup


/* ============================================================
	toppage-calendar
============================================================ */
.toppage-calendar {
	position: relative;
	margin: 0 auto 60px;
	padding: 40px;
	width: $fullWidth;
	text-align: center;
	&.-spring {
		background: url(/images/top/bg_calendar_spring.jpg) center no-repeat;
		background-size: cover;
	}
	&.-summer {
		background: url(/images/top/bg_calendar_summer.jpg) center 60% no-repeat;
		background-size: cover;
	}
	&.-autumn {
		background: url(/images/top/bg_calendar_autumn.jpg) center no-repeat;
		background-size: cover;
	}
	&.-winter {
		background: url(/images/top/bg_calendar_winter.jpg) center no-repeat;
		background-size: cover;
	}
	@include media(lx) {
		margin: 0;
		padding: 30px 0;
	}

	> .ttl-box {
		margin: 0 auto 40px;
		width: $baseWidth;
		@include flex-between;
		@include flex-center-tb;
		position: relative;
		color: $white;
		@include media(lx) {
			@include margin-sp(0, 30px);
		}

		> .ttl {
			@include flex-center;
			font-size: 3.6rem;
			@include eng;
			text-shadow: 0 0 3px #555,
									0 0 4px #555,
									0 0 5px #555;
			@include media(lx) {font-size: 2.8rem;}
			> span {
				display: block;
				padding-right: 20px;
				border-right: $borderSol-white1;
				@include media(lx) {padding-right: 15px;}
				&.jpn {
					@include ie-hack(gteIE11) {padding-top: 7px;}
					padding-left: 20px;
					border: none;
					font-size: 2rem;
					font-style: normal;
					@include media(lx) {
						padding-left: 15px;
						font-size: $fs_l;
					}
				}
			}
		}//ttl
		> .link-box {
			margin-top: 0;
			> .link-arrow {font-size: $fs_l;}
		}
	}//ttl-box

	> .list {
		margin: 40px auto;
		@include flex-wrap;
		width: $baseWidth;
		@include media(lx) {
			@include margin-sp(0, 2px);
			height: 300px;
			overflow-x: hidden;
			overflow-y: scroll;
			&::-webkit-scrollbar {width: 10px;}
			&::-webkit-scrollbar-track {
				background: $gray-e;
				border: 2px solid $gray-e;
				border-radius: 5px;
				padding: 5px 0;
			}
			&::-webkit-scrollbar-thumb {
				background: $darkgray;
				border: 2px solid $gray-e;
				border-radius: 5px;
				box-shadow: none;
			}
		}//media(lx)
		> li {
			position: relative;
			margin: 2px 20px;
			width: 500px;
			text-align: left;
			background: rgba(0,0,0,0.5);
			@include media(lx) {
				margin: 2px 0;
				width: $fullWidth;
			}

			@include media(ln) {
				&.item01 {order: 1;}
				&.item02 {order: 3;}
				&.item03 {order: 5;}
				&.item04 {order: 7;}
				&.item05 {order: 9;}
				&.item06 {order: 2;}
				&.item07 {order: 4;}
				&.item08 {order: 6;}
				&.item09 {order: 8;}
				&.item10 {order: 10;}
			}

			> a {
				@include media(ln) {
					@include flex;
					@include flex-center-tb;
				}
				padding: 15px;
				width: $fullWidth;
				height: 100%;
				font-size: $fs-l;
				color: $white;
				@include media(lx) {
					display: block;
					padding: 10px;
				}

				> p {
					&.data {
						@include flex;
						@include flex-center-tb;
						white-space: nowrap;
						> .label {
							@include label;
							margin-left: 5px;
							border: $borderSol-white1;
							color: $white;
							font-size: $fs_s;
						}//label
					}//data
					&.ttl {
						margin-left: 10px;
						line-height: $lh-s;
						@include bold;
						@include media(lx) {
							margin: 5px 0 0;
						}
					}
				}//p

				@include hover {
					@include opacity;
					text-decoration: none;
				}
			}//a
		}//li
	}//list

	> .lead {
		margin: 0 auto;
		display: inline-block;
		color: $white;
		text-align: left;
		text-shadow: 0 0 3px #333,
								0 0 4px #333,
								0 0 5px #333;
		> span {
			display: block;
			margin-bottom: 10px;
			font-size: $fs-xl;
			@include bold;
		}
		@include media(lx) {
			@include margin-sp(20px, 0);
			line-height: $lh-l;
			> span {line-height: $lh-s;}
		}
	}//lead

}//toppage-calendar

.toppage-calendar.-spring > .list > li {border-left: #00e3d9 2px solid;}
.toppage-calendar.-summer > .list > li {border-left: #6eeaff 2px solid;}
.toppage-calendar.-autumn > .list > li {border-left: #ffb45a 2px solid;}
.toppage-calendar.-winter > .list > li {border-left: #a49ede 2px solid;}

.toppage-calendar.-spring > .lead > span {color: #00e3d9;text-shadow: 0 0 3px #555,0 0 4px #555,0 0 5px #555;}
.toppage-calendar.-summer > .lead > span {color: #6eeaff;text-shadow: 0 0 3px #555,0 0 4px #555,0 0 5px #555;}
.toppage-calendar.-autumn > .lead > span {color: #ffb45a;}
.toppage-calendar.-winter > .lead > span {color: #3930a5;text-shadow: 0 0 3px $gray-e,0 0 4px $gray-e,0 0 5px $gray-e;}


/* ============================================================
	toppage-banner
============================================================ */
.toppage-banner {
	position: relative;
	margin: 60px auto;
	padding: 40px;
	width: $fullWidth;
	background-color: $gray-e;
	overflow: hidden;
	@include media(lx) {
		margin: 30px auto;
		padding: 30px 0;
	}

	>.container {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		max-width: 240px;
		margin-bottom: 50px;
		@include media(lx) {
			margin-bottom: 30px;
		}
	}

//swiper.js
	>.container >.swiper-container {
		width: 100%;
		height: 100%;
		overflow: visible;
		@include z-index(default);
	}
	>.container > .swiper-pagination {
		width: 100%;
		position: absolute;
		bottom: -50px !important;
		left: 50%;
		transform: translate(-50%, 0);
		@include media(lx) {
			bottom: -35px !important;
		}
	}
	.swiper-pagination-bullet {
		margin: 0 5px;
		width: 12px;
		height: 12px;
		@include transition;
		@include hover() {
			opacity: .2;
		}
	}
	.swiper-pagination-bullet-active {
		background: $baseColor;
	}
//swiper.js

	.swiper-slide a {
		display: inline-block;
		margin: 0 5px;
		width: 230px;
		height: 76px;
		background-color: $white;
	}//a
}//toppage-banner
