@charset "utf-8";

/* ============================================================
	publishing-top
============================================================ */
.publitop-main {
	width: $fullWidth;
	overflow-x: hidden;
	text-align: center;
	@include media(lx) {
		width: $fullWidth;
		height: auto;
	}

	a {
		display: inline-block;
		width: 1080px;
		height: 512px;
		@include media(lx) {
			width: $fullWidth;
			height: auto;
		}
	}//a
}//publi-main

.publittl-box {
	> .ttl {
		display: inline-block;
		padding-left: 55px;
		@include ie-hack(gteIE11) {padding-top: 7px;}
		&::after {top: -6px;font-size: 50px;}
		&::before {top: -6px;font-size: 50px;color: $publi-iconColor;}
		@include media(lx) {
			padding-left: 45px;
			&::after,
			&::before {top: -8px;font-size: 40px;}
		}
		span {
			font-size: 3.4rem;
			@include jpn;
			@include bold;
			@include media(lx) {
				font-size: 2.6rem;
			}
		}
	}//ttl
	> .eng {
		margin-top: 10px;
		@include ie-hack(gteIE11) {margin-top: 0;}
		color: $titleColor;
		font-size: $fs_m;
		@include eng;
		font-style: italic;
		@include media(lx) {
			margin-top: 8px;
		}
	}
	+ .lead {
		margin-top: 20px;
		font-size: $fs_l;
		line-height: $lh_m;
		@include media(lx) {
			text-align: left;
			line-height: $lh_l;
		}
	}
}//publittl-box


/* ============================================================
	publitop-contest
============================================================ */
.publitop-contest {
	margin: 100px auto;
	width: $baseWidth;
	text-align: center;
	@include media(lx) {
		@include margin-sp(60px, 70px);
	}

	> .list {
		@include flex-between;
		margin: 40px auto;
		width: $baseWidth;
		@include media(lx) {
			@include flex-wrap;
			margin: 10px 0;
			width: $fullWidth;
		}

		> .item {
			width: 340px;
			text-align: center;
			line-height: $lh_s;
			@include media(lx) {
				padding: 5px 0;
				width: 49%;
			}
			@include media(xsx) {
				padding: 10px 0;
				width: $fullWidth;
			}//media(xsx)
			&.-column2 {
				@include media(ln) {
					margin: 0 auto;
				}
			}
			&.-column1 {
				margin: 0 auto;
			}
		}

	}//list
}//publitop-contest


/* ============================================================
	publitop-work
============================================================ */
.publitop-work {
	margin: 100px auto;
	width: $baseWidth;
	text-align: center;
	@include media(lx) {
		@include margin-sp(50px, 30px);
	}
	> .list {
		@include flex-between;
		margin: 60px auto 0;
		width: 970px;
		@include media(lx) {
			@include flex-wrap;
			margin: 30px auto 0;
			width: $fullWidth;
			> li {
				@include flex-between;
				width: $fullWidth;
				margin-bottom: 30px;
				}
		}
	}
}//publitop-work

.publitop-linkarea {
	display: block;
	position: relative;
	padding: 165px 75px 0;
	width: 450px;
	height: 430px;
	@include link-black;
	cursor: pointer;
	@include media(lx) {
		@include margin;
		padding: 25px 20px 35px;
		width: $fullWidth;
		height: auto;
		&.-form {background: url(/publishing/images/top/bg_form.jpg) center 50% no-repeat;background-size: cover;}
		&.-webform {background: url(/publishing/images/top/bg_webform.jpg) center bottom no-repeat;background-size: cover;}
		&.-download {background: url(/publishing/images/top/bg_download.jpg) center bottom no-repeat;background-size: cover;}
	}

	> .bg {
		position: absolute;
		left: 0;
		top: 0;
		@include media(lx) {
			display: none;
		}
	}

	> .ttl {
		@include publitop-h3ttl;
		margin-bottom: 10px;
		text-shadow: 0 0 3px $white,
								0 0 4px $white,
								0 0 5px $white;
		@include media(lx) {
			margin-bottom: 15px;
		}
	}
	> .lead {
		position: relative;
		width: $fullWidth;
		line-height: $lh_xl;
		text-align: left;
		@include media(lx) {
			line-height: $lh_l;
		}
	}
	> .btn-box {
		position: absolute;
		right: -25px;
		bottom: -25px;
		@include media(lx) {
			right: -5px;
			bottom: -10px;
		}

		> .btn-top {
			position: relative;
			@include flex-center;
			@include transition;
			width: 240px;
			height: 68px;
			background-color: $white;
			@include box-shadow;
			color: $publi-baseColor;
			font-size: $fs_l;
			text-align: center;
			@include radius(3);
			@include ie-hack(gteIE11) {padding-top: 7px;}
			@include media(lx) {
				padding: 10px 30px 10px 15px;
				@include ie-hack(gteIE11) {padding-top: 17px;}
				width: auto;
				height: auto;
				font-size: $fs_m;
			}

			&::before {
				font-family: '#{$icomoon-font-family}' !important;
				content: $icon-arrow-right;
				position: absolute;
				top: 50%;
				right: 35px;
				font-size: $fs_s;
				transform: translate(0,-50%);
				display: inline-block;
				transition: right .1s;
				@include media(lx) {
					right: 12px;
					font-size: $fs_xs;
				}
			}

		}//btn-top
	}//btn-box

	@include hover() {
		@include opacity(1);
		> .bg, > .ttl, > .lead {@include opacity;}
		> .btn-box {
			> .btn-top {
				background-color: $publi-baseColor;
				@include box-shadow-hov;
				color: $white;
				&::before {right: 30px;}
				@include media(lx) {
					&::before {right: 8px;}
				}
			}
		}
	}//hover
}//publitop-linkarea

.publitop-linkarea.-webform,
.publitop-linkarea.-download {
	padding: 50px 0 0;
	height: 160px;
	@include media(lx) {
		@include flex-center;
		margin: 0;
		padding: 30px 0 40px;
		width: 48%;
		height: auto;
	}
}//publitop-linkarea -webform -download

@include media(ln) {
	.publitop-linkarea.-webform {margin-top: 40px;}
	.publitop-linkarea.-download {margin-top: 70px;}
}


/* ============================================================
	publitop-self, publitop-guide
============================================================ */
.publitop-self {
	margin: 100px auto;
	padding: 70px 0;
	width: $fullWidth;
	background: $gray-e;
	text-align: center;
	@include media(lx) {
		margin: 30px auto;
		@include padding-sp(50px);
	}
	> .list {
		@include flex-wrap;
		margin: 60px auto 0;
		width: $baseWidth;
		text-align: left;
		@include media(lx) {
			margin: 30px 0 0;
			width: $fullWidth;
		}
		> li {
			position: relative;
			@include media(lx) {
				width: $halfWidth;
				&:first-of-type {display: none;}
				&:last-of-type {width: $fullWidth;}
				&:nth-child(2) {padding-right: 2px;}
				&:nth-child(3) {padding-left: 2px;}
			}
		}
	}//list
}//publitop-self

.publitop-guide {
	margin: 100px auto;
	width: $baseWidth;
	text-align: center;
	@include media(lx) {
		@include margin-sp(50px, 50px);
	}
	> .list {
		@include flex-wrap;
		margin: 60px auto 0;
		width: $baseWidth;
		text-align: left;
		@include media(lx) {
			margin: 30px 0 0;
			width: $fullWidth;
		}
		> li {
			position: relative;
			@include media(lx) {
				width: $halfWidth;
				&:first-of-type {display: none;}
				&:nth-child(2) {padding-right: 2px;}
				&:nth-child(3) {padding-left: 2px;}
			}
		}
	}//list
}//publitop-guide

.publitop-linkbox {
	display: block;
	margin-left: 4px;
	width: 268px;
	height: 440px;
	background-color: $white;
	@include box-shadow;
	@include link-black;
	cursor: pointer;
	@include media(lx) {
		margin: 0;
		width: $fullWidth;
		height: auto;
	}
}//publitop-linkbox

.publitop-linkbox.-soudankai,
.publitop-linkbox.-setsumeikai {
	margin-left: 0;
	width: 536px;
	height: 440px;
}
.publitop-linkbox.-soudankai + .schedule-list,
.publitop-linkbox.-setsumeikai + .schedule-list {
	position: absolute;
	top: 21px;
	right: 15px;
	padding: 15px;
	width: 250px;
	min-height: 284px;
	background-color: $white;
	text-align: left;
	@include radius(3);
}
.schedule-list {
	> .ttl {
		@include flex-center;
		margin: 8px 0 16px;
		color: $titleColor;
		font-size: $fs_l;
		@include bold;
		text-align: center;
		> .icon-calendar {
			margin-right: 2px;
			font-size: 2.4rem;
		}
	}
	> .data {
		padding: 6px 0;
		border-bottom: $borderDot;
		line-height: $lh_m;
		a {
			@include link-text;
		}
	}//data
	> .txt {
		padding-top: 6px;
		font-size: 1.3rem;
		text-align: justify;
	}
}//schedule-list

.publitop-linkbox .txt-area {
	padding: 28px 20px 20px;
	@include media(lx) {
		padding: 15px 0;
	}

	> .ttl {
		@include publitop-h3ttl;

		&::before {
			content: '';
			position: absolute;
			left: 50%;
			bottom: -34px;
			display: inline-block;
			width: 70px;/*線の長さ*/
			height: 1px;/*線の太さ*/
			transform: translateX(-50%);/*位置調整*/
			background-color: $borderColor;/*線の色*/
			@include radius(2);
			@include media(lx) {
				display: none;
			}
		}
	}//ttl

	.lead {
		margin-top: 70px;
		line-height: $lh_l;
		@include media(lx) {
			display: none;
		}
	}
	.link {
		position: relative;
		margin: 20px 5px 0 0;
		padding-right: 15px;
		color: $publi-baseColor;
		text-align: right;

		&::before {
			font-family: '#{$icomoon-font-family}' !important;
			content: $icon-arrow-right;
			position: absolute;
			top: 50%;
			right: 0;
			font-size: $fs_s;
			transform: translate(0,-50%);
			display: inline-block;
			transition: right .1s;
			@include media(lx) {
				font-size: $fs_xs;
			}
		}
	}
}//publitop-linkbox .txt-area
.publitop-linkbox {
	@include hover() {
		.txt-area .link {
			&::before {right: -5px;}
		}
	}
}//publitop-linkbox


.publitop-linkbox.-soudankai .txt-area {
	@include flex-wrap;
	padding: 20px;
	> .ttl {
		width: 200px;
		&::before {display: none;}
	}
	> .lead {
		margin-top: 0;
		padding-left: 26px;
		width: 296px;
		border-left: $borderSol;
	}
	> .link {
		margin-top: -8px;
		width: $fullWidth;
	}
}

.publitop-linkbox.-setsumeikai .txt-area {
	@include flex-wrap;
	padding: 20px;
	> .ttl {
		width: 210px;
		&::before {display: none;}
	}
	> .lead {
		margin-top: 0;
		padding-left: 16px;
		width: 286px;
		border-left: $borderSol;
	}
	> .link {
		margin-top: -8px;
		width: $fullWidth;
	}
}

.publitop-linkbox.-syuppantaiken {
	@include media(ln) {
		@include flex-between;
	}
	margin: 4px 0 0;
	width: 1080px;
	height: 214px;
	@include media(lx) {
		width: $fullWidth;
		height: auto;
	}

	> .bg {
		width: 356px;
		height: 214px;
		@include media(lx) {
			width: $fullWidth;
			height: 30vw;
			overflow: hidden;
		}
	}
	@include media(ln) {
		> .txt-area {
			width: 690px;
			> .ttl::before {
				bottom: -24px;
			}
			> .lead {
				margin-top: 45px;
			}
			> .link {
				margin-top: 10px;
			}
		}
	}
	@include media(lx) {
		> .txt-area {
			> .ttl {
			&::before {display: inline;bottom: -20px;}
			}
			> .lead {
				display: inline-block;
				margin-top: 35px;
				padding: 0 10px;
			}
		}
	}

}//publitop-linkbox.-syuppantaiken

.publitop-linkbox.-kakitai .txt-area,
.publitop-linkbox.-hondas .txt-area {
	> .ttl {
		line-height: $lh_s;
		&::before {bottom: -14px;}
	}
	> .lead {
		margin-top: 40px;
	}
}


/* ============================================================
	publitop-bnrarea
============================================================ */
.publitop-bnrarea {
	@include media(ln) {
		@include flex-center;
		margin: 100px auto;
	}
	width: $baseWidth;
	text-align: center;
	@include media(lx) {
		@include margin-sp(50px, 50px);
	}
	> .item {
		display: block;
		width: 502px;
		height: 112px;
		@include media(ln) {margin: 0 10px;}
		@include media(lx) {
			margin-top: 10px;
			width: $fullWidth;
			height: auto;
		}
	}
}//publitop-bnrarea


/* ============================================================
	publitop-contact
============================================================ */
.publitop-contact {
	padding: 70px 0;
	height: 470px;
	background: $gray-e url(/assets/images/bg_contact.jpg) top center no-repeat;
	text-align: center;
	@include media(lx) {
		background-size: cover;
		@include padding-sp(30px);
		height: auto;
	}

	> .ttl {
		display: inline-block;
		margin: 0 auto 20px;
		padding: 0 30px 15px;
		border-bottom: $borderSol;
		color: $textColor-light;
		font-size: 2.6rem;
		line-height: $lh_s;
		@include bold;
		@include media(lx) {
			padding: 0 0 15px;
			font-size: 2.2rem;
		}
	}
	> .lead {
		font-size: $fs_l;
		line-height: $lh_m;
		@include media(lx) {
			text-align: left;
			line-height: $lh_l;
		}
	}
	> .list {
		@include media(ln) {
			@include flex-between;
		}
		margin: 60px auto 0;
		width: $baseWidth;
		@include media(lx) {
			margin: 30px auto 0;
			width: 90%;
			max-width: 320px;
		}

		> .linkbox {
			display: block;
			margin: 10px auto;
			padding: 40px;
			width: 332px;
			background: rgba(255,255,255,0.5);
			border: $borderSol;
			text-align: center;
			@include link-black;
			@include media(lx) {
				padding: 20px;
				width: auto;
			}

			> .ttl {
				position: relative;
				display: inline-block;
				margin: 0 auto 30px;
				padding-right: 20px;
				color: $baseColor;
				font-size: $fs_l;
				@include bold;
				text-align: center;
				&::before {
					font-family: '#{$icomoon-font-family}' !important;
					content: $icon-arrow-right;
					position: absolute;
					top: 50%;
					right: 0;
					font-size: $fs_s;
					transform: translate(0,-50%);
					display: inline-block;
					transition: right .1s;
				}
			}
			> .lead {
				line-height: $lh_l;
				text-align: left;
			}

			@include hover() {
				background: rgba(255,255,255,0.5);
				.ttl::before {right: -5px;}
			}

		}//linkbox
	}//list

}//publitop-contact

/* ============================================================
	publitop-sponly
============================================================ */
.publitop-sponly {
	display: none;
	@include media(lx) {
		display: block;
		@include margin-sp(40px, 60px);

		> .list {
			margin: 0;
			width: $fullWidth;
			> li {
				margin-bottom: 40px;

				> .schedule-list {
					@include flex-wrap;
					margin: 0;
					> .ttl {margin: 10px 0 8px;width: $fullWidth;}
					> .data {
						margin-right: 2%;
						padding: 6px 0;
						width: 32%;
						&:nth-child(4), &:nth-child(7) {margin-right: 0;}
					}
				}

			}//li
		}//list

		.publitop-linkbox.-soudankaisp,
		.publitop-linkbox.-setsumeikaisp {
			width: $fullWidth;
			height: auto;
			box-shadow: none;

			> .bg {
				position: relative;
				width: $fullWidth;
				height: 30vw;
				overflow: hidden;
				img {
					position: absolute;
					top: -50%;
					left: 0;
					width: 150%;
				}
			}

			> .txt-area {
				@include flex-wrap;
				@include flex-center-tb;
				padding: 15px 0 5px;

				> .ttl {
					width: 35%;
					border-right: $borderSol;
					line-height: $lh_s;
					.small {font-size: $fs_s;}
				}
				> .lead {
					display: inline-block;
					margin-top: 0;
					padding-left: 10px;
					width: 65%;
				}
				> .link {
					margin: 8px 5px 0 0;
					width: $fullWidth;
				}
			}//txt-area
		}//publitop-linkbox.-syuppantaiken
		.publitop-linkbox.-setsumeikaisp > .bg img {top: -120%;}

	}//media(lx)

	@include media(mx) {
		> .list > li > .schedule-list > .data {
			margin-right: 0;
			width: 48%;
			&:nth-child(even) {margin-right: 4%;}
		}
	}//media(mx)

	@include media(sx) {
		> .list > li > .schedule-list.-soudankai > .data {
			padding: 6px 6px 6px 40px;
			width: $fullWidth;
			&:nth-child(even) {margin-right: 0;}
		}
	}//media(sx)
	@include media(xsx) {
		> .list > li > .schedule-list.-setsumeikai > .data {
			padding: 6px 6px 6px 40px;
			width: $fullWidth;
			&:nth-child(even) {margin-right: 0;}
		}
	}//media(xxsx)

}//publitop-sponly