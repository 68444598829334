@charset "utf-8";

/* ============================================================
	Helper - font-size
============================================================ */
._fs-10 {font-size: 1.0rem !important;}
._fs-11 {font-size: 1.1rem !important;}
._fs-12 {font-size: 1.2rem !important;}
._fs-13 {font-size: 1.3rem !important;}
._fs-14 {font-size: 1.4rem !important;}
._fs-15 {font-size: 1.5rem !important;}
._fs-16 {font-size: 1.6rem !important;}
._fs-17 {font-size: 1.7rem !important;}
._fs-18 {font-size: 1.8rem !important;}
._fs-19 {font-size: 1.9rem !important;}
._fs-20 {font-size: 2.0rem !important;}
._fs-22 {font-size: 2.2rem !important;}
._fs-24 {font-size: 2.4rem !important;}
._fs-26 {font-size: 2.6rem !important;}
._fs-28 {font-size: 2.8rem !important;}
._fs-30 {font-size: 3rem !important;}

@include media(lx) {
._spfs-10 {font-size: 1.0rem !important;}
._spfs-11 {font-size: 1.1rem !important;}
._spfs-12 {font-size: 1.2rem !important;}
._spfs-13 {font-size: 1.3rem !important;}
._spfs-14 {font-size: 1.4rem !important;}
._spfs-15 {font-size: 1.5rem !important;}
._spfs-16 {font-size: 1.6rem !important;}
._spfs-17 {font-size: 1.7rem !important;}
._spfs-18 {font-size: 1.8rem !important;}
._spfs-19 {font-size: 1.9rem !important;}
._spfs-20 {font-size: 2.0rem !important;}
._spfs-22 {font-size: 2.2rem !important;}
._spfs-24 {font-size: 2.4rem !important;}
._spfs-26 {font-size: 2.6rem !important;}
._spfs-28 {font-size: 2.8rem !important;}
._spfs-30 {font-size: 3rem !important;}
}

/* ============================================================
	Helper - line-height
============================================================ */
._lh-10 {line-height: 1.0 !important;}
._lh-11 {line-height: 1.1 !important;}
._lh-12 {line-height: 1.2 !important;}
._lh-13 {line-height: 1.3 !important;}
._lh-14 {line-height: 1.4 !important;}
._lh-15 {line-height: 1.5 !important;}
._lh-16 {line-height: 1.6 !important;}
._lh-17 {line-height: 1.7 !important;}
._lh-18 {line-height: 1.8 !important;}
._lh-19 {line-height: 1.9 !important;}
._lh-20 {line-height: 2.0 !important;}

@include media(lx) {
._splh-10 {line-height: 1.0 !important;}
._splh-11 {line-height: 1.1 !important;}
._splh-12 {line-height: 1.2 !important;}
._splh-13 {line-height: 1.3 !important;}
._splh-14 {line-height: 1.4 !important;}
._splh-15 {line-height: 1.5 !important;}
._splh-16 {line-height: 1.6 !important;}
._splh-17 {line-height: 1.7 !important;}
._splh-18 {line-height: 1.8 !important;}
._splh-19 {line-height: 1.9 !important;}
._splh-20 {line-height: 2.0 !important;}
}


/* ============================================================
	Helper - font-weight
============================================================ */
._bold, strong, b {
	font-weight: 700 !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
._normal {
	font-weight: 500 !important;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: unset;
}

/* ============================================================
	Helper - font-color
============================================================ */
._red {color: $red !important;}
._pink {color: $pink-baseColor !important;}
._white {color: $white !important;}
._black {color: $black !important;}

/* ============================================================
	Helper - text
============================================================ */
._shadow {text-shadow: 0 0 3px $black;}

._ta-c {text-align: center !important;}
._ta-l {text-align: left !important;}
._ta-r {text-align: right !important;}

@include media(lx) {
._spta-c {text-align: center !important;}
._spta-l {text-align: left !important;}
._spta-r {text-align: right !important;}
}

/* ============================================================
	Helper - display
============================================================ */
._block {display: block !important;}
._inbl {display: inline-block !important;}
._none {display: none !important;}
._flex {display: flex !important;}
._table {display: table !important;}
._tbcl {display: table-cell !important;}

@include media(lx) {
._sp-block {display: block !important;}
._sp-none {display: none !important;}
._sp-flex {display: flex !important;}
._sp-table {display: table !important;}
._sp-tbcl {display: table-cell !important;}
}


/* ============================================================
	Helper - other
============================================================ */
._bd {border: $borderSol !important;}
._bd-none {border: none !important;}
._bd-top {border-top: $borderSol !important;}

._wd-a {width: auto !important;}
._hg-a {height: auto !important;}

._palt {font-feature-settings: "palt" !important;}


/* ============================================================
	Helper - margin: 0 auto
============================================================ */
._mg-0a {margin: 0 auto !important;}
/* PCのみ */
@include media(ln) {
	._pcmg-0a {margin: 0 auto !important;}
}
/* SPのみ */
@include media(lx) {
	._spmg-0a {margin: 0 auto !important;}
}


/* ============================================================
	Helper - margin
============================================================ */
._ml-1em {margin-left: 1em !important;}

._mg-0 {margin: 0 !important;}

._mt-0 {margin-top: 0 !important;}
._mt-5 {margin-top: 5px !important;}
._mt-10 {margin-top: 10px !important;}
._mt-15 {margin-top: 15px !important;}
._mt-20 {margin-top: 20px !important;}
._mt-25 {margin-top: 25px !important;}
._mt-30 {margin-top: 30px !important;}
._mt-35 {margin-top: 35px !important;}
._mt-40 {margin-top: 40px !important;}
._mt-45 {margin-top: 45px !important;}
._mt-50 {margin-top: 50px !important;}
._mt-55 {margin-top: 55px !important;}
._mt-60 {margin-top: 60px !important;}

._mb-0 {margin-bottom: 0 !important;}
._mb-5 {margin-bottom: 5px !important;}
._mb-10 {margin-bottom: 10px !important;}
._mb-15 {margin-bottom: 15px !important;}
._mb-20 {margin-bottom: 20px !important;}
._mb-25 {margin-bottom: 25px !important;}
._mb-30 {margin-bottom: 30px !important;}
._mb-35 {margin-bottom: 35px !important;}
._mb-40 {margin-bottom: 40px !important;}
._mb-45 {margin-bottom: 45px !important;}
._mb-50 {margin-bottom: 50px !important;}
._mb-55 {margin-bottom: 55px !important;}
._mb-60 {margin-bottom: 60px !important;}

._ml-0 {margin-left: 0 !important;}
._ml-5 {margin-left: 5px !important;}
._ml-10 {margin-left: 10px !important;}
._ml-15 {margin-left: 15px !important;}
._ml-20 {margin-left: 20px !important;}
._ml-25 {margin-left: 25px !important;}
._ml-30 {margin-left: 30px !important;}
._ml-35 {margin-left: 35px !important;}
._ml-40 {margin-left: 40px !important;}
._ml-45 {margin-left: 45px !important;}
._ml-50 {margin-left: 50px !important;}
._ml-55 {margin-left: 55px !important;}
._ml-60 {margin-left: 60px !important;}

._mr-0 {margin-right: 0 !important;}
._mr-5 {margin-right: 5px !important;}
._mr-10 {margin-right: 10px !important;}
._mr-15 {margin-right: 15px !important;}
._mr-20 {margin-right: 20px !important;}
._mr-25 {margin-right: 25px !important;}
._mr-30 {margin-right: 30px !important;}
._mr-35 {margin-right: 35px !important;}
._mr-40 {margin-right: 40px !important;}
._mr-45 {margin-right: 45px !important;}
._mr-50 {margin-right: 50px !important;}
._mr-55 {margin-right: 55px !important;}
._mr-60 {margin-right: 60px !important;}


/* ============================================================
	Helper - margin-sp
============================================================ */
@include media(lx) {
._spmg-0 {margin: 0 !important;}

._spmt-0 {margin-top: 0 !important;}
._spmt-5 {margin-top: 5px !important;}
._spmt-10 {margin-top: 10px !important;}
._spmt-15 {margin-top: 15px !important;}
._spmt-20 {margin-top: 20px !important;}
._spmt-25 {margin-top: 25px !important;}
._spmt-30 {margin-top: 30px !important;}
._spmt-35 {margin-top: 35px !important;}
._spmt-40 {margin-top: 40px !important;}
._spmt-45 {margin-top: 45px !important;}
._spmt-50 {margin-top: 50px !important;}
._spmt-55 {margin-top: 55px !important;}
._spmt-60 {margin-top: 60px !important;}

._spmb-0 {margin-bottom: 0 !important;}
._spmb-5 {margin-bottom: 5px !important;}
._spmb-10 {margin-bottom: 10px !important;}
._spmb-15 {margin-bottom: 15px !important;}
._spmb-20 {margin-bottom: 20px !important;}
._spmb-25 {margin-bottom: 25px !important;}
._spmb-30 {margin-bottom: 30px !important;}
._spmb-35 {margin-bottom: 35px !important;}
._spmb-40 {margin-bottom: 40px !important;}
._spmb-45 {margin-bottom: 45px !important;}
._spmb-50 {margin-bottom: 50px !important;}
._spmb-55 {margin-bottom: 55px !important;}
._spmb-60 {margin-bottom: 60px !important;}

._spml-0 {margin-left: 0 !important;}
._spml-5 {margin-left: 5px !important;}
._spml-10 {margin-left: 10px !important;}
._spml-15 {margin-left: 15px !important;}
._spml-20 {margin-left: 20px !important;}
._spml-25 {margin-left: 25px !important;}
._spml-30 {margin-left: 30px !important;}
._spml-35 {margin-left: 35px !important;}
._spml-40 {margin-left: 40px !important;}
._spml-45 {margin-left: 45px !important;}
._spml-50 {margin-left: 50px !important;}
._spml-55 {margin-left: 55px !important;}
._spml-60 {margin-left: 60px !important;}

._spmr-0 {margin-right: 0 !important;}
._spmr-5 {margin-right: 5px !important;}
._spmr-10 {margin-right: 10px !important;}
._spmr-15 {margin-right: 15px !important;}
._spmr-20 {margin-right: 20px !important;}
._spmr-25 {margin-right: 25px !important;}
._spmr-30 {margin-right: 30px !important;}
._spmr-35 {margin-right: 35px !important;}
._spmr-40 {margin-right: 40px !important;}
._spmr-45 {margin-right: 45px !important;}
._spmr-50 {margin-right: 50px !important;}
._spmr-55 {margin-right: 55px !important;}
._spmr-60 {margin-right: 60px !important;}
}


/* ============================================================
	Helper - padding
============================================================ */
._pg-0 {padding: 0 !important;}

._pt-0 {padding-top: 0 !important;}
._pt-5 {padding-top: 5px !important;}
._pt-10 {padding-top: 10px !important;}
._pt-15 {padding-top: 15px !important;}
._pt-20 {padding-top: 20px !important;}
._pt-25 {padding-top: 25px !important;}
._pt-30 {padding-top: 30px !important;}
._pt-35 {padding-top: 35px !important;}
._pt-40 {padding-top: 40px !important;}
._pt-45 {padding-top: 45px !important;}
._pt-50 {padding-top: 50px !important;}
._pt-55 {padding-top: 55px !important;}
._pt-60 {padding-top: 60px !important;}

._pb-0 {padding-bottom: 0 !important;}
._pb-5 {padding-bottom: 5px !important;}
._pb-10 {padding-bottom: 10px !important;}
._pb-15 {padding-bottom: 15px !important;}
._pb-20 {padding-bottom: 20px !important;}
._pb-25 {padding-bottom: 25px !important;}
._pb-30 {padding-bottom: 30px !important;}
._pb-35 {padding-bottom: 35px !important;}
._pb-40 {padding-bottom: 40px !important;}
._pb-45 {padding-bottom: 45px !important;}
._pb-50 {padding-bottom: 50px !important;}
._pb-55 {padding-bottom: 55px !important;}
._pb-60 {padding-bottom: 60px !important;}

._pl-0 {padding-left: 0 !important;}
._pl-5 {padding-left: 5px !important;}
._pl-10 {padding-left: 10px !important;}
._pl-15 {padding-left: 15px !important;}
._pl-20 {padding-left: 20px !important;}
._pl-25 {padding-left: 25px !important;}
._pl-30 {padding-left: 30px !important;}
._pl-35 {padding-left: 35px !important;}
._pl-40 {padding-left: 40px !important;}
._pl-45 {padding-left: 45px !important;}
._pl-50 {padding-left: 50px !important;}
._pl-55 {padding-left: 55px !important;}
._pl-60 {padding-left: 60px !important;}

._pr-0 {padding-right: 0 !important;}
._pr-5 {padding-right: 5px !important;}
._pr-10 {padding-right: 10px !important;}
._pr-15 {padding-right: 15px !important;}
._pr-20 {padding-right: 20px !important;}
._pr-25 {padding-right: 25px !important;}
._pr-30 {padding-right: 30px !important;}
._pr-35 {padding-right: 35px !important;}
._pr-40 {padding-right: 40px !important;}
._pr-45 {padding-right: 45px !important;}
._pr-50 {padding-right: 50px !important;}
._pr-55 {padding-right: 55px !important;}
._pr-60 {padding-right: 60px !important;}

/* ============================================================
	Helper - padding-sp
============================================================ */
@include media(lx) {
._sppg-0 {padding: 0 !important;}

._sppt-0 {padding-top: 0 !important;}
._sppt-5 {padding-top: 5px !important;}
._sppt-10 {padding-top: 10px !important;}
._sppt-15 {padding-top: 15px !important;}
._sppt-20 {padding-top: 20px !important;}
._sppt-25 {padding-top: 25px !important;}
._sppt-30 {padding-top: 30px !important;}
._sppt-35 {padding-top: 35px !important;}
._sppt-40 {padding-top: 40px !important;}
._sppt-45 {padding-top: 45px !important;}
._sppt-50 {padding-top: 50px !important;}
._sppt-55 {padding-top: 55px !important;}
._sppt-60 {padding-top: 60px !important;}

._sppb-0 {padding-bottom: 0 !important;}
._sppb-5 {padding-bottom: 5px !important;}
._sppb-10 {padding-bottom: 10px !important;}
._sppb-15 {padding-bottom: 15px !important;}
._sppb-20 {padding-bottom: 20px !important;}
._sppb-25 {padding-bottom: 25px !important;}
._sppb-30 {padding-bottom: 30px !important;}
._sppb-35 {padding-bottom: 35px !important;}
._sppb-40 {padding-bottom: 40px !important;}
._sppb-45 {padding-bottom: 45px !important;}
._sppb-50 {padding-bottom: 50px !important;}
._sppb-55 {padding-bottom: 55px !important;}
._sppb-60 {padding-bottom: 60px !important;}

._sppl-0 {padding-left: 0 !important;}
._sppl-5 {padding-left: 5px !important;}
._sppl-10 {padding-left: 10px !important;}
._sppl-15 {padding-left: 15px !important;}
._sppl-20 {padding-left: 20px !important;}
._sppl-25 {padding-left: 25px !important;}
._sppl-30 {padding-left: 30px !important;}
._sppl-35 {padding-left: 35px !important;}
._sppl-40 {padding-left: 40px !important;}
._sppl-45 {padding-left: 45px !important;}
._sppl-50 {padding-left: 50px !important;}
._sppl-55 {padding-left: 55px !important;}
._sppl-60 {padding-left: 60px !important;}

._sppr-0 {padding-right: 0 !important;}
._sppr-5 {padding-right: 5px !important;}
._sppr-10 {padding-right: 10px !important;}
._sppr-15 {padding-right: 15px !important;}
._sppr-20 {padding-right: 20px !important;}
._sppr-25 {padding-right: 25px !important;}
._sppr-30 {padding-right: 30px !important;}
._sppr-35 {padding-right: 35px !important;}
._sppr-40 {padding-right: 40px !important;}
._sppr-45 {padding-right: 45px !important;}
._sppr-50 {padding-right: 50px !important;}
._sppr-55 {padding-right: 55px !important;}
._sppr-60 {padding-right: 60px !important;}
}