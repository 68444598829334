@charset "utf-8";

/* ============================================================
	print
============================================================ */
@media print {

//all
	header {
		position: relative !important;
		height: 74px !important;
	}
	header.smaller {
		padding: 4px 0 0 !important;
		border-bottom: 0 !important;
		background: #fff !important;
	}
	header.smaller .global-header > .logo {
		top: 22px !important;
	}
	header.smaller .global-header > .lead {
		top: 0 !important;
	}
	header.smaller .global-header > .tel-box {
		top: 22px !important;
	}

	.global-nav, .header-nav, footer, .pagetop, .bc-area,
	.base-wrap.-faq-area {display: none !important;}
	.content-area {margin-top: 10px;}
	.base-wrap:last-of-type,
	.old-wrap:last-of-type {margin-bottom: 0;}
	.no-print {display: none !important;}
	.for-print {display: block !important;}

	.qa-list > dd {display: block;}

//contest
	.social-box, .template-area, .btn-entry,
	.contest-entrybox .btn-area,
	.contest-entrybox.-web, .ehon_awardlist,
	.fancybox-wrap {display: none !important;}
	.contest-inquiry {align-items: flex-start;}

//kakitai
	.contents_social, .contents_paging {display: none !important;}

}//media print