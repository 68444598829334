@charset "utf-8";

/* ============================================================
	icon
============================================================ */
@font-face {
	font-family: '#{$icomoon-font-family}';
	src:url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?8iv790');
	src:url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?8iv790#iefix') format('embedded-opentype'),
			url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?8iv790') format('truetype'),
			url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?8iv790') format('woff'),
			url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?8iv790##{$icomoon-font-family}') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: '#{$icomoon-font-family}' !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon2-"], [class*=" icon2-"] {
	position: relative;
	font-family: '#{$icomoon-font-family}' !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon2-"]::before, [class*=" icon2-"]::before {
	position: absolute;top: 0;left: 0;
}
[class^="icon2-"]::after, [class*=" icon2-"]::after {
	position: absolute;top: 0;left: 0;
	color: $iconColor;
}

.icon-blank {
	&::before {
		content: $icon-blank;
	}
}
.icon-arrow-left {
	&::before {
		content: $icon-arrow-left;
	}
}
.icon-arrow-right {
	&::before {
		content: $icon-arrow-right;
	}
}
.icon-arrow-up {
	&::before {
		content: $icon-arrow-up;
	}
}
.icon-arrow-down {
	&::before {
		content: $icon-arrow-down;
	}
}
.icon-arrow-pagetop {
	&::before {
		content: $icon-arrow-pagetop;
	}
}
.icon-arrow-chart {
	&::before {
		content: $icon-arrow-chart;
	}
}
.icon-home {
	&::before {
		content: $icon-home;
		color: $news-iconColor;
	}
}
.icon-publishing {
	&::before {
		content: $icon-publishing;
		color: $publi-iconColor;
	}
}
.icon-bookinfo {
	&::before {
		content: $icon-bookinfo;
		color: $bkinfo-iconColor;
	}
}
.icon-about {
	&::before {
		content: $icon-about;
		color: $about-iconColor;
	}
}

.icon2-contest {
	&::before {
		content: $icon-contest01;
	}
}
.icon2-contest {
	&::after {
		content: $icon-contest02;
	}
}
.icon2-publi-work {
	&::before {
		content: $icon-publi-work01;
	}
}
.icon2-publi-work {
	&::after {
		content: $icon-publi-work02;
	}
}
.icon2-publi-self {
	&::before {
		content: $icon-publi-self01;
	}
}
.icon2-publi-self {
	&::after {
		content: $icon-publi-self02;
	}
}
.icon2-publi-guide {
	&::before {
		content: $icon-publi-guide01;
	}
}
.icon2-publi-guide {
	&::after {
		content: $icon-publi-guide02;
	}
}
.icon2-contact {
	&::before {
		content: $icon-contact01;
	}
}
.icon2-contact {
	&::after {
		content: $icon-contact02;
	}
}
.icon-calendar {
	&::before {
		content: $icon-calendar;
	}
}
.icon-tel {
	&::before {
		content: $icon-tel; 
	}
}
.icon-mail {
	&::before {
		content: $icon-mail; 
	}
}
.icon-post {
	&::before {
		content: $icon-post; 
	}
}
.icon-pc {
	&::before {
		content: $icon-pc; 
	}
}
.icon-book {
	&::before {
		content: $icon-book; 
	}
}
.icon-news {
	&::before {
		content: $icon-news; 
	}
}
.icon-question {
	&::before {
		content: $icon-question; 
	}
}
.icon-talk {
	&::before {
		content: $icon-talk; 
	}
}
.icon-plus {
	&::before {
		content: $icon-plus;
	}
}
.icon-minus {
	&::before {
		content: $icon-minus;
	}
}
.icon-minus_bold {
	&::before {
		content: $icon-minus_bold;
	}
}
.icon-download {
	&::before {
		content: $icon-download;
	}
}
.icon-map {
	&::before {
		content: $icon-map;
	}
}
.icon-hondas {
	&::before {
		content: $icon-hondas;
	}
}
.icon-freecall {
	&::before {
		content: $icon-freecall;
		color: #0e0d6a;
	}
}
.icon-check {
	&::before {
		content: $icon-check; 
	}
}
.icon-heart {
	&::before {
		content: $icon-heart;
	}
}
.icon-search {
	&::before {
		content: $icon-search;
	}
}
.icon2-company {
	&::before {
		content: $icon-company01;
	}
}
.icon2-company {
	&::after {
		content: $icon-company02;
	}
}
.icon2-philosophy {
	&::before {
		content: $icon-philosophy01;
	}
}
.icon2-philosophy {
	&::after {
		content: $icon-philosophy02;
	}
}
.icon2-commitment {
	&::before {
		content: $icon-commitment;
	}
}
.icon2-hot {
	&::before {
		content: $icon-hot01;
	}
}
.icon2-hot {
	&::after {
		content: $icon-hot02;
	}
}
.icon2-neo {
	&::before {
		content: $icon-neo;
	}
}
.icon2-picturebook {
	&::before {
		content: $icon-picturebook01;
	}
}
.icon2-picturebook {
	&::after {
		content: $icon-picturebook02;
	}
}
.icon2-ebook {
	&::before {
		content: $icon-ebook01;
	}
}
.icon2-ebook {
	&::after {
		content: $icon-ebook02;
	}
}
.icon2-library {
	&::before {
		content: $icon-library01;
	}
}
.icon2-library {
	&::after {
		content: $icon-library02;
	}
}
.icon-promotion {
	&::before {
		content: $icon-promotion;
	}
}
.icon-read-trial {
	&::before {
		content: $icon-read-trial;
	}
}
.icon-website {
	&::before {
		content: $icon-website;
	}
}
.icon-blog {
	&::before {
		content: $icon-blog;
	}
}
.icon-movie {
	&::before {
		content: $icon-movie;
	}
}
.icon-drama {
	&::before {
		content: $icon-drama;
	}
}
.icon-arrow-open {
	&::before {
		content: $icon-arrow-open;
	}
}
.icon-twitter {
	&::before {
		content: $icon-twitter;
	}
}
.icon-x {
	&::before {
		content: $icon-x;
	}
}
.icon-note {
	&::before {
		content: $icon-note;
	}
}