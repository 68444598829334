@charset "utf-8";

/* ============================================================
	form-nav
============================================================ */
.form-nav {
	margin-bottom: 40px;
	display: table;
	table-layout: fixed;
	width: 100%;
	box-sizing: border-box;
	height: 48px;
	border-left: $borderSol;
	border-right: $borderSol;
	@include media(lx) {
		margin-bottom: 20px;
	}

	> li {
		position: relative;
		display: table-cell;
		padding-left: 25px;
		text-align: center;
		vertical-align: middle;
		background-color: $gray-e;
		color: $baseColor;
		font-size: $fs_l;
		@include bold;
		border-top: $borderSol;
		border-bottom: $borderSol;
		@include media(lx) {
			font-size: $fs_m;
		}
	}
	> li:first-child {padding-left: 0;}
	> li.current {
		background-color: $baseColor;
		color: $white;
		border-top: $borderSol-baseC1;
		border-bottom: $borderSol-baseC1;
	}

	> li::before {
		content: "";
		position: absolute;
		top: -1px;
		right: -25px;
		bottom: 0;
		width: 0;
		height: 0;
		border-top: 24px solid transparent;
		border-bottom: 24px solid transparent;
		border-left: 24px solid $borderColor;
		z-index: 10;
	}
	> li:last-of-type:before {display: none;}

	> li::after {
		content: "";
		position: absolute;
		top: -1px;
		right: -24px;
		bottom: 0;
		width: 0;
		height: 0;
		border-top: 24px solid transparent;
		border-bottom: 24px solid transparent;
		border-left: 24px solid $gray-e;
		z-index: 10;
	}
	> li:last-child::after {display: none;}
	> li.current::after {border-left: 24px solid $baseColor;}

}//form-nav
.form-nav.-publi {
	> li {
		color: $publi-baseColor;
	}
	> li.current {
		background-color: $publi-baseColor;
		color: $white;
		border-top: $borderSol-publi1;
		border-bottom: $borderSol-publi1;
	}
	> li.current::after {border-left: 24px solid $publi-baseColor;}
}//form-nav.-publi
.form-nav.-bkinfo {
	> li {
		color: $bkinfo-baseColor;
	}
	> li.current {
		background-color: $bkinfo-baseColor;
		color: $white;
		border-top: $borderSol-bkinfo1;
		border-bottom: $borderSol-bkinfo1;
	}
	> li.current::after {border-left: 24px solid $bkinfo-baseColor;}
}//form-nav.-bkinfo

.form-errmsg {
	font-size: $fs_l;
	@include bold;
	color: $red;
	text-align: center;
	@include media(lx) {
		margin-top: 30px;
	}
	> span {
		font-size: $fs_l !important;
	}
}//form-errmsg


/* ============================================================
	formparts
============================================================ */
.ssl-box {
	margin-bottom: 60px;
	@include media(ln) {
		@include flex-between;
	}
	@include media(lx) {
		display: block;
		margin-bottom: 30px;
	}
	> .seal {
		width: 120px;
		height: 59px;
	}
	> .wording {
		width: 930px;
		line-height: $lh_l;
		@include media(lx) {
			margin-top: 5px;
			width: auto;
		}
	}
}//ssl-box

.form-lead {
	margin-bottom: 30px;
	font-size: 1.6rem;
	line-height: 1.7;
	@include media(lx) {
		margin-bottom: 20px;
		font-size: 1.4rem;
	}
}//form-lead

/* ============================================================
	file-box
============================================================ */
.file-box {
	@include flex;
	margin: 5px 0 15px;
	padding: 8px 12px;
	border: 1px solid #ccc;
	border-radius: 3px;
	transition: all .3s;

	#file-btn.btn,
	#file-btn.btn-publi {
		display: inline-block;
		padding: 5px 15px;
		font-size: 1.6rem;
		white-space: nowrap;
		&::before {
			display: none;
		}
	}
	#file-select {
		display: none;
		width: 0 !important;
		height: 0;
		margin: 0;
		padding: 0;
		border: none;
	}
	input[type="text"] {
		background: none;
		box-shadow: none;
		border: none;
		margin: 0 0 0 10px;
		padding: 0;
		width: 100% !important;
		height: auto !important;
		color: #333;
	}
}//file-box


/* ============================================================
	form-box
============================================================ */
.alert-block {
	@include margin(30px 0);
	padding: 8px;
	width: $fullWidth;
	background-color: $bg-alert;
	@include fs-16l;
	text-align: center;
	> p span {color: $red;}
}
.att-ttl {
	@include margin(30px 0 0);
	padding: 8px;
	width: $fullWidth;
	background-color: $bg-alert;
	@include fs-16l;
	text-align: center;
	> p span {color: $red;}
}

.errMsg {
	@include fs-12s;
	color: $red;
}

.form-box {
	label {display: block;}
	> li {
		display: table;
		padding: 10px 0;
		border-bottom: $borderDot;
		@include fs-14l;
		@include media(lx) {
			display: block;
			border-bottom: none;
		}
	}//li

	> li > .ttl-box {
		display: table-cell;
		vertical-align: middle;
		width: 365px;
		padding: 10px 20px;
		background-color: $gray-e;
		> .ttl {
			span {
				margin-left: 5px;
				color: $red;
			}
		}
		@include media(lx) {
			display: block;
			width: $fullWidth;
			padding: 8px 10px;
		}
	}//ttl-box

	> li > .item {
		position: relative;
		display: table-cell;
		vertical-align: middle;
		width: 715px;
		padding-left: 20px;
		word-break: break-all;
		> input[name="inputPostal"] {
			ime-mode: disabled;
		}
		@include media(lx) {
			display: block;
			width: $fullWidth;
			padding: 5px 0 0 0;
		}
	}//item

	> li > .item .flex-wrap {
		@include flex-wrap;
		@include flex-center-tb;
		> label {
			display: inline-block;
		}

	}//item.-flex

	.icon-check {
		position: relative;
		margin-left: 20px;
		padding: 5px;
		border-radius: 50%;
		background-color: $formFocus;
		font-size: $fs_l;
		color: $white;
		@include media(lx) {margin-left: 10px;}
	}//icon-check
	.icon-check.-multi {
		position: absolute;
		@include position-center-tb;
		right: 10px;
		@include media(lx) {
			top: 10%;
		}
	}
}//form-box

.checkmark {
	display: none;
	vertical-align: middle;
}
.checkmark.is-show {
	display: inline-block;
}

.form-footer {
	margin: 40px 0;
	text-align: center;
	> a {width: 400px;}
	> a.disabled {
		pointer-events: none;
		padding: 20px 40px;
		background-color: $borderColor;
		border: $borderSol;
		color: $publi-baseColor;
			&::before {display: none;}
	}
	@include media(lx) {
		margin: 30px 0;
		> a {width: $fullWidth;}
		> a.disabled {padding: 20px 10px;}
	}
}//form-footer

.form-footer.-two {
	> a {width: 200px;}
	@include media(lx) {
		> a {width: 40%;}
	}
}//form-footer


/* ============================================================
	form-item
============================================================ */
form label.validate-err {
	display: block;
	font-size: $fs_m;
	color: $red;
}
input:invalid {box-shadow: none;}

input, textarea, select {
	margin: 5px 0;
	padding: 8px 12px;
	border: $borderSol;
	border-radius: 3px;
	background-color: $white;
	@include transition;
}

input:focus,
textarea:focus,
select:focus {
	box-shadow: 0 0 8px $formFocus;
	border: 1px solid $formFocus;
	background-color: $formBg;
}

input[type="file"] {
	width: $fullWidth !important;
	font-size: $fs_l;
	@include media(lx) {
		font-size: $fs_s;
	}
}
input[type="text"] {
	width : 350px !important;
	height: 38px;
	@include media(lx) {width: 82% !important;}
}
@include media(lx) {
	input[type="text"].size-full {width: $fullWidth !important;}
}
input[type="number"] {
	width : 55px !important;
	height: 38px;
}
input[type="tel"] {
	width : 200px !important;
	height: 38px;
}
select {
	width: auto !important;
	min-width: 200px !important;
	height: 38px;
	cursor: pointer;
	background-image: url(/images/svg/arrow_down.svg);
	background-repeat: no-repeat;
	background-size: 12px 10px;
	background-position: right 10px center;

	@include media(lx) {
		max-width: 82% !important;
	}
}

textarea {
	width: 100% !important;
	height: 150px;
	min-height: 150px;
}
.hiding_form textarea,
.hiding_form .hiding-wrap {
	display: none;
}
.hiding_form.-active textarea {
	display: block;
}
.hiding_form.-active .hiding-wrap {
	display: flex;
	@include media(lx) {
		display: block;
	}
	> p {
		&:nth-of-type(1) {margin-right: 10px;width: 200px;}
		&:nth-of-type(2) {
			margin-right: 10px;
			width: 200px;
			> input[type="text"] {width: 200px !important;}
		}
		&:nth-of-type(3) {width: 100px;}
	}//p
}//hiding-wrap

.label-radio {
	margin: 10px 0;
	> input[type="radio"] {display: none;}
	> .txt {
		margin-right: 20px;
		padding-left: 20px;
		position: relative;
		line-height: 1;
		cursor: pointer;
		@include transition;
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: -2px;
			left: 0;
			width: 15px !important;
			height: 15px !important;
			border: $borderSol;
			border-radius: 50%;
			background-color: $white;
		}
		@include hover {
			color: $formFocus;
		}
	}//txt

	> input[type="radio"]:checked + .txt::after {
		content: "";
		display: block;
		position: absolute;
		top: 1px;
		left: 3px;
		width: 11px !important;
		height: 11px !important;
		background: $formFocus;
		border-radius: 50%;
	}
}//label-radio


.ui-autocomplete {
	width: 400px;
	list-style: none;
	background-color: $white;
	border: $borderSol;
}
.ui-menu-item-wrapper {
	padding-left: 5px;
}

//リスト hover時のカラー
.ui-state-active {
	padding-left: 5px;
	border: 1px solid $formFocus !important;
	background: $gray-e !important;
	font-weight: bold !important;
	color: $black;
	cursor: pointer;
}
.ui-helper-hidden-accessible > * {
	display: none;
}


/* ============================================================
	datepicker
============================================================ */
.appendDatepicker {position: relative;}
.ui-datepicker {
	top: 0 !important;
	left: 0 !important;
}
.ui-datepicker-prev .ui-datepicker-next {transition: none;}
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {top: 2px !important;}
.ui-datepicker .ui-datepicker-prev-hover {left: 2px !important;}
.ui-datepicker .ui-datepicker-next-hover {right: 2px !important;}

input[type="text"].date {
	width : 238px !important;
}

/* 日曜日 */
.day-sunday .ui-state-default, .day-sunday .ui-state-active {
	background-image: none !important;
	background-color: #FFC0CB !important;
}
/* 土曜日 */
.day-saturday .ui-state-default, .day-saturday .ui-state-active {
	background-image: none !important;
	background-color: #AFEEEE !important;
}
/* 祝祭日 */
.day-holiday .ui-state-default, .day-holiday .ui-state-active {
	background-image: none !important;
	background-color: #FFC0CB !important;
}
/* 年月のリストメニュー */
.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
	width: auto;
}
.ui-datepicker-calendar .ui-state-active {
	border: 1px solid #003eff !important;
	background: #007fff !important;
	font-weight: normal !important;
}


/* ============================================================
	select-box
============================================================ */
.select-box {
	display: flex;
}
.select-box li select {
	min-width: 77px !important;
	margin-right: 2px;
}
.select-box li:first-of-type select {
	min-width: 86px !important;
}
.form-box .select-box .icon-check {
	margin-left: 0;
	margin-right: 6px;
	font-size: 1rem;
}