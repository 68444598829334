@charset "utf-8";

/* ============================================================
	publishing/form/choice-area
============================================================ */
.choice-area {
	@include flex-between;
	@include margin;
	width: 840px;
	@include media(lx) {width: $fullWidth;}
	> .btn-publi {
		width: 400px;
		@include media(lx) {width: 48%;}
	}
}//choice-area


/* ============================================================
	publishing/form/web-flow
============================================================ */
.-web-flow {
	margin-bottom: 0 !important;
	background: url(/publishing/images/common/bg_web.jpg) top center no-repeat;
	background-size: cover;
	@include media(lx) {
		> .h2ttl-icon {font-size: $fs_xxl;}
	}
	> .flow-area {
		@include margin(30px auto 0);
		padding: 50px 80px;
		width: $base2Width;
		background-color: rgba(255,255,255,0.7);
		@include media(lx) {
			@include margin(25px auto 0);
			padding: 20px 15px;
			width: $fullWidth;
		}
		> .ttl {
			margin-bottom: 15px;
			font-size: $fs_xxl;
			@include bold;
			color: $publi-baseColor;
			@include media(lx) {
				font-size: $fs_xl;
			}
		}//ttl
		> .txt {
			margin-bottom: 15px;
			@include media(lx) {
				margin-bottom: 15px;
			}
		}//ttl
		> .btn-box {
			margin-bottom: 15px;
			text-align: left;
			> .btn-publi {
				width: 400px;
				@include media(lx) {width: $fullWidth;}
			}
		}//btn-box
		> .indent + .ttl {
			margin-top: 40px;
			@include media(lx) {margin-top: 30px;}
		}
		.txt-box {
			margin-bottom: 15px;
			padding: 15px 30px;
			@include fs-16xl;
			@include bold;
			border: $borderSol;
			@include media(lx) {padding: 10px 15px;}
		}//txt-box
	}//flow-area
}//web-flow


/* ============================================================
	publishing/form/post-flow
============================================================ */
.-post-flow {
	margin-top: 0 !important;
	background: url(/publishing/images/common/bg_post.jpg) top center no-repeat;
	background-size: cover;
	@include media(lx) {
		> .h2ttl-icon {font-size: $fs_xxl;}
	}
	> .flow-area {
		@include margin(30px auto 0);
		@include flex-wrap;
		width: $base2Width;
		@include media(lx) {
				@include margin(25px auto 0);
			width: $fullWidth;
		}
	}//flow-area

	.step-area {
		background-color: rgba(255,255,255,0.6);
		> li {
			@include flex;
			@include media(lx) {
				display: block;
			}

			> .ttl-box {
				position: relative;
				@include flex-center;
				padding: 35px 10px;
				width: 200px;
				background: $publi-baseColor;
				@include media(ln) {border-bottom: $borderSol-white2;}
				text-align: center;
				color: $white;
				&:last-child {border-bottom: none;}
				span.eng {
					display: block;
					font-size: $fs_l;
					color: $gray-e;
					@include eng;
					@include media(lx) {
						display: inline-block;
						font-size: $fs_m;
					}
				}
				span.ttl {
					display: block;
					margin-top: 5px;
					@include fs-20s;
					color: $white;
					@include media(lx) {
						display: inline-block;
						margin-top: 0;
						margin-left: 5px;
						font-size: $fs_xl;
					}
				}
				@include media(ln) {
					&::before {
						display: block;
						font-family: 'bungeisha' !important;
						content: $icon-arrow-chart;
						@include position-center-lr;
						bottom: -24px;
						font-size: 3.6rem;
						@include z-index(default);
					}
				}
				@include media(lx) {
					display: block;
					padding: 5px 10px;
					width: $fullWidth;
					text-align: left;
				}
			}//ttl-box

			> .txt-box {
				@include margin(0 50px);
				padding: 30px 0;
				width: 660px;
				height: auto;
				@include media(ln) {border-bottom: $borderDot;}
				@include media(lx) {
					@include margin(0);
					padding: 15px 10px 20px 10px;
					width: $fullWidth;
					font-size: $fs_m;
				}

				> .ttl {
					margin-bottom: 10px;
					font-size: $fs_xxl;
					@include bold;
					color: $publi-baseColor;
					@include media(lx) {
						font-size: $fs_xl;
					}
				}//ttl
				> .txt {
					margin-bottom: 10px;
					@include media(lx) {
						margin-bottom: 15px;
					}
				}//ttl
				> .btn-box {
					margin-bottom: 15px;
					text-align: left;
					> .btn-publi {
						width: 400px;
						@include media(lx) {width: $fullWidth;}
					}
				}//btn-box
				> .btn-box + .ttl {
					margin-top: 40px;
					@include media(lx) {margin-top: 30px;}
				}

				> .pcflexbw-wrap > .item {
					margin-top: 0;
					@include bold;
				}

				> .add-box {
					display: inline-block;
					margin-bottom: 10px;
					padding: 10px 20px;
					@include fs-18l;
					@include bold;
					border: $borderSol-publi2;
					> span {
						font-size: 2.2rem;
					}
					@include media(lx) {
						display: block;
						padding: 10px;
						font-size: $fs_l;
						> span {font-size: $fs_xxl;}
					}
				}//txt-box

			}//txt-box

		}//li
		> li:last-child {
			> .ttl-box {
				border-bottom: none;
				&::before {display: none;}
			}
			> .txt-box{border-bottom: none;}
		}
	}//step-area

}//post-flow


/* ============================================================
	publishing/form/zeroen-box
============================================================ */
.zeroen-box {
	@include flex-between;
	margin-top: 30px;
	padding: 10px;
	border: $borderSol;
	> .logo {width: 300px;height: 222px;}
	> .detail {
		width: 740px;
		> .lead {
			margin-bottom: 5px;
			font-size: $fs_l;
			@include bold;
		}
		> .txt {margin-bottom: 20px;}
	}
	@include media(lx) {
		display: block;
		margin-top: 20px;
		> .logo {
			margin-bottom: 10px;
			width: $fullWidth;
			height: auto;
			text-align: center;
			img {max-width: 300px;}
		}
		> .detail {
			width: $fullWidth;
			> .lead {font-size: $fs_m;}
		}
	}//media(lx)
}//zeroen-box


/* ============================================================
	publishing/download-area
============================================================ */
.download-area {
	@include media(ln) {
		@include flex-between;

		&.-pdf {
			border-top: 1px dotted $borderColor;
			margin-top: 20px;
			padding-top: 20px;
		}
		>.item-set > a {padding: 10px 40px 10px 30px;}
	}//ln

	> .ttl {
		padding: 20px;
		width: 170px;
		background-color: #c6ecf1;
		font-size: $fs_xxl;
		@include bold;
		text-align: center;
		@include media(lx) {
			padding: 10px;
			width: $fullWidth;
			font-size: $fs_xl;
			text-align: left;
		}
	}//ttl
	> .item-set {
		@include flex;
		@include flex-center-tb;
		@include media(lx) {
			@include flex-between;
			margin: 10px 0 40px;
		}

		> .txt {
			margin-right: 5px;
			width: 256px;
			font-size: $fs_xl;
			line-height: $lh_s;
			@include bold;
			@include media(lx) {
				margin-right: 5px;
				width: auto;
				font-size: $fs_l;
			}
			> span {
				font-size: $fs_m;
				@include media(lx) {font-size: $fs_s;}
				@media only screen and (max-width: 400px) {
					display: block;
					margin-left: 1em;
				}
			}//span
		}//txt
	}//item-set
}//download-area


/* ============================================================
	publishing/flow
============================================================ */
.flow-area {
	> .step-box {
		@include media(ln) {
			@include flex-wrap;
			align-items: flex-start;
			margin-bottom: 40px;
		}
		@include media(lx) {
			display: block;
			margin-bottom: 30px;
		}

		> .ttl {
			margin-right: 20px;
			padding: 15px 10px;
			width: 180px;
			background-color: $publi-baseColor;
			font-size: $fs_xxl;
			color: $white;
			@include media(lx) {
				margin: 0 0 10px;
				padding: 10px 5px;
				width: $fullWidth;
				font-size: $fs_xl;
			}

			> span.eng {
				display: block;
				margin-bottom: 5px;
				font-size: $fs_l;
				color: $gray-e;
				font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
				font-weight: 500;
				@include media(lx) {
					display: inline-block;
					margin: 0 5px 0;
				}
			}//eng
		}//ttl

		> .lead-box {
			width: 880px;
			@include media(lx) {width: $fullWidth;}
			> .part {
				display: inline-block;
				margin-bottom: 6px;
				padding: 4px 6px;
				border: 1px solid $baseColor-light;
			}
			> .lead {
				font-size: $fs_l;
				line-height: $lh_m;
				@include bold;
			}
		}//lead-box

		> .txt-box {
			margin: 15px 20px 0 0;
			width: 810px;
			@include media(lx) {
				margin: 10px 0 0 0;
				width: $fullWidth;
			}
			p {
				margin-bottom: 10px;
				line-height: 1.8;
			}
		}//txt-box

		> .photo {
			margin-top: 15px;
			width: 250px;
			@include media(lx) {width: $fullWidth;}
		}//photo

	}//step-box
}//flow-area


/* ============================================================
	publishing/support
============================================================ */
.support-person {
	position: relative;
	@include media(ln) {
		padding-left: 220px;
		min-height: 250px;
	}

	> .photo {
		@include media(ln) {
			position: absolute;
			top: 0;
			left:0;
			width: 180px;
		}
		@include media(lx) {
			margin-bottom: 10px;
			width: $fullWidth;
		}
	}//photo

	> .txt-area {
		margin-bottom: 20px;
		width: 860px;
		padding: 30px;
		background-color: $pink-thinColor;
		@include radius;
		@include media(lx) {
			margin-bottom: 10px;
			width: $fullWidth;
			padding: 15px 10px;
		}

		> .lead {
			display: block;
			margin-bottom: 20px;
			font-size: $fs_xl;
			color: $pink-baseColor;
			@include bold;
			> span {font-size: 2.6rem;}
			@include media(lx) {
				margin-bottom: 10px;
				@include fs-16m;
				> span {font-size: 2.4rem;}
			}
		}
		> .part {
			display: block;
			font-size: $fs_xl;
			color: $pink-baseColor;
			@include bold;
			text-align: right;
			@include media(lx) {
				font-size: $fs_l;
			}
		}
	}//txt-area
}//support-person

.support-box {
	margin-top: 30px;
	@include media(lx) {margin-top: 25px;}
	> .list {
		@include flex-wrap;
		> li {
			margin-top: 5px;
			width: 25%;
			@include media(lx) {
				width: 50%;
			}
			@include media(sx) {
				width: $fullWidth;
			}
		}
	}//list
}//support-box


/* ============================================================
	publishing/support/partner
============================================================ */
.partner-pc {
	border: $borderSol-publi1;
	border-radius: 10px;
	text-align: center;
	@include media(lx) {display: none;}
}//partner-pc

.partner-sp {
	display: none;
	@include media(lx) {
		display: block;
		margin-top: 0 !important;
		> .txt {margin-bottom: 10px;}
		> .partner-nav {
			border: 1px solid $gray;
			> li {
				&.ttl {
					padding: 5px;
					background-color: $borderColor;
					font-size: $fs_l;
				}//ttl
				&.item-box {
					margin: 10px 0 0 10px;
					> a {
						&.item {
							display: inline-block;
							margin: 0 30px 10px 0;
						}
						&.item2 {
							display: inline-block;
							margin: 0 16px 10px 0;
						}
						> .icon-arrow-right {
							font-size: $fs_xs;
						}
					}//a
				}//item-box
			}//li
		}//partner-nav
	}
}//partner-sp

.partner-list {
	border: 1px solid $gray;
	width: $fullWidth;
	tr, td {
		border: 1px solid $gray;
		padding: 5px 8px;
	}
	.pub-partner1 {
		margin-bottom: 12px;
		padding-left:7px;
		border-width: 0px 0px 1px 6px;
		border-color: $publi-baseColor;
		border-style: solid;
		font-size: $fs_l;
		@include bold;
	}
	.pub-partner2 {
		background: #e5d1b8;
	}
	.pub-partner3 {
		background: $white;
		width: 50%;
		vertical-align: text-top;
		line-height: 24px;
	}
}//partner-list

.partner-data {
	margin-top: 10px;
	text-align: right;
}


/* ============================================================
	publishing/support/storage
============================================================ */
.storage-box {
	margin-bottom: 60px;
	@include media(lx) {margin-bottom: 30px;}
	.item-list {
		@include flex;
		@include media(lx) {display: block;}
		> .item {
			margin-right: 16px;
			width: 258px;
			@include media(lx) {
				margin: 0 0 20px;
				width: $fullWidth;
			}
			&:last-of-type {margin-right: 0;}
			> .txt {margin-top: 5px;}
		}//item
	}//item-list
}//storage-box


/* ============================================================
	publishing/syuppantaiken-list
============================================================ */
.syuppantaiken-list {
	margin: 0;
	padding: 0;
	@include media(ln) {
		@include flex-wrap;
	}
	@include media(lx) {
		display: block;
	}

	&.-under {
		margin-bottom: 30px;
	}

	> li {
		margin: 0 20px 20px 0;
		width: 530px;
		&:nth-of-type(even) {margin-right: 0;}
		@include media(lx) {
			margin: 0 0 20px 0;
			width: $fullWidth;
		}

		> .item {
			display: block;
			box-sizing: border-box;
			position: relative;
			overflow: hidden;
			padding: 15px 12px;
			width: 528px;
			height: 114px;
			background: $white;
			border: $borderSol-publi2;
			color: $textColor;
			text-decoration: none;
			transition: all .3s;
			box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.3);
			@include hover {
				opacity: 0.7;
			}
			@include media(lx) {
				padding: 8px 0 8px 5px;
				width: 99.6%;
				height: auto;
				min-height: 82px;
			}

			> .name {
				margin: 0;
				padding: 0 0 10px;
				width: 354px;
				border-bottom: 1px solid $publi-baseColor;
				font-size: $fs_xxl;
				@include bold;
				@include media(lx) {
					box-sizing: border-box;
					padding: 0 109px 5px 0;
					width: $fullWidth;
					font-size: $fs_l;
				}
			}//name
			> .title {
				@include flex;
				@include flex-center-tb;
				margin: 0;
				padding: 0;
				width: 354px;
				height: 66px;
				font-size: $fs_l;
				line-height: $lh_m;
				@include media(lx) {
					padding: 5px 109px 0 0;
					width: $fullWidth;
					height: auto;
					font-size: $fs_m;
				}
			}//title
		}//item
	}//li

	img {
		position: absolute;
		top: 0;
		right: 0;
		width: 140px;
		height: 110px;
		@include media(lx) {
			width: 109px;
			height: 82px;
			border-left: 5px solid $white;
		}
	}//img
}//syuppantaiken-list


/* ============================================================
	publishing/syuppantaiken-article
============================================================ */
.syuppantaiken-article {
	position: relative;
	overflow: hidden;
	line-height: 2.0;
	@include media(lx) {
		line-height: $lh_xl;
	}

	> .tit {
		padding-bottom: 5px;
		margin-bottom: 10px;
		font-size: 2.4rem;
		color: $publi-baseColor;
		border-bottom: 1px solid $publi-baseColor;
		@include bold;
		position: relative;
		z-index: 1;
		@include media(lx) {
			font-size: $fs_xl;
		}
	}//tit

	> .name {
		margin:0 0 25px 0;
		font-size: $fs_xxl;
		@include bold;
		position: relative;
		z-index: 1;
		@include media(lx) {
			font-size: $fs_l;
		}
	}//name

	> .article-bg {
		margin-bottom: 0;
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 0;
		@include media(lx) {
			bottom: 20px;
			text-align: right;
			img {
				width: $fullWidth;
				max-width: 270px;
				height: auto;
			}
		}
	}//article-bg

	> .lead {
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px $darkgray dotted;
		font-size: $fs_l;
		p {
			margin: 15px 0;
			&:last-of-type {margin: 15px 0 0;}
		}
	}//lead

	> .txt {
		width: 780px;
		position: relative;
		font-size: $fs_l;
		z-index: 1;
		@include media(lx) {
			padding-bottom: 200px;
			width: $fullWidth;
		}
		p {
			margin: 15px 0;
			&.comment {
				margin-top: 20px;
				padding-top: 20px;
					border-top: 1px $darkgray dotted;
			}
		}//p
		@include media(ln) {
			&.-short {padding-bottom: 100px;}
		}
	}//txt

	> .pict {
		margin: 0 auto;
		text-align: center;
		position: relative;
		@include media(lx) {
			width: $fullWidth;
			padding-bottom: 0;
			z-index: 1;
		}
		img {
			max-width: 360px;
			@include media(lx) {
				width: $fullWidth;
				padding-bottom: 0;
				z-index: 1;
			}
		}//img
	}//pict
}//syuppantaiken-article


/* ============================================================
	publishing/syuppantaiken-book
============================================================ */
.syuppantaiken-book {
	@include flex-wrap;

	> .bookdata {
		margin: 0 9px 10px;
		width: 102px;
		min-height: 200px;
		font-size: $fs_s;
		line-height: $lh_s;
		text-align: center;
		display: block;
		@include media(lx) {
			margin: 0 2% 2%;
			min-height: auto;
		}
		@include media(mx) {
			width: 21%;
		}
		@include media(xsx) {
			width: 29%;
		}

		img {
			border: 1px solid $textColor-light;
			margin-bottom: 5px;
		}
	}//bookdata
}//syuppantaiken-book


/* ============================================================
	publishing/kakitai-list
============================================================ */
.kakitai-list {
	margin: 20px 0;
	&.-under {
		margin-bottom: 50px;
		@include media(lx) {margin-bottom: 30px;}
	}

	> li {
		overflow: hidden;
		margin: 0 0 10px 0;
		padding: 0 0 10px 0;
		border-bottom: 1px dotted $borderColor;
		@include media(lx) {
			margin: 0 0 20px 0;
		}

		> .thumbnail {
			float: left;
			width: 90px;
			height: 90px;
			margin-right: 20px;
			@include media(lx) {
				margin: 0 5px 5px 0;
			}
		}//thumbnail

		> .detail {
			@include media(ln) {
				float: left;
				margin-top: 10px;
				width: 970px;
			}
			> .date {
				display: inline-block;
				margin: 0;
				font-size: $fs_m;
				color: $textColor-light;
				@include media(lx) {
					margin: 0 5px 5px 0;
				}
			}//date
			> .category {
				display: inline-block;
				margin: 0;
				font-size: $fs_m;
				color: $textColor-light;
				@include media(lx) {
					margin: 0 5px 5px 0;
				}
			}//category
			> .title {
				display: block;
				margin: 10px 0 5px;
				font-size: $fs_l;
				@include bold;
				@include media(lx) {
					margin: 5px 0 5px 0;
					line-height: $lh_m;
				}
			}//title
			> .text {
				display: inline-block;
				margin: 5px 0 0;
				font-size: $fs_m;
				@include media(lx) {
					display: inline;
					margin: 10px 0;
					font-size: $fs_l;
					line-height: $lh_m;
				}
			}//text
		}//detail

	}//li
}//kakitai-list


/* ============================================================
	publishing/kakitai-article
============================================================ */
.kakitai-article {
	> .contents_head {
		padding: 0 0 15px 0;
		font-size: $fs_l;
		> h2 {
			font-size: 2.4rem;
			line-height: $lh_l;
			padding: 0;
			margin:0 0 10px;
			@include bold;
			@include media(lx) {font-size: $fs_xxl;}
		}//h2
		> .date {
			padding-right: 15px;
			@include media(lx) {padding-right: 0;}
		}//date
	}//contents_head

	> .contents_social {
		@include flex-wrap;
		margin: 0 auto 10px;
		padding: 5px 2px 10px 10px;
		background: #ddd;
		border: $borderSol;
		@include media(axsx) {padding: 5px 2px 10px 8px;}
		> div {
			display: inline-block;
			margin-top: 5px;
			padding: 0 5px 0 0;
			height: 20px;
			line-height: 30px;
			&.facebook {
				margin-top: -5px;
				padding: 0;
				width: 91px;
				height: auto;
			}
//&.facebook_like {padding: 0;width: 103px;}
		}//div
	}//contents_social

	> .contents_paging {
		margin-top: 20px;
	}

	> .contents-main {
		padding: 15px 0 20px;
		font-size: $fs_l;

		h2, h3 {
			position: relative;
			font-size: $fs_xl;
			line-height: $lh_s;
			margin-top: 40px;
			padding: 10px 0 10px 0.5em;
			background-color: $pink-thinColor;
			border-left: 10px solid $pink-lightColor;
			@include bold;
			&:first-of-type {margin-top: 0;}
			@include media(lx) {margin-top: 30px;font-size: $fs_l;}
		}
		h4 {
			margin: 15px 0;
			font-size: $fs_m;
			@include bold;
		}
		p {
			margin: 16px 0;
			line-height: $lh_xl;
			@include media(lx) {line-height: $lh_l;}
		}
		a {
			text-decoration: underline;
			text-indent: 0;
			@include hover {
				text-decoration: none;
			}
		}//a

		> .blockquote {
			margin: 16px 0;
			border-left: none;
			background-color: #ffedff;
			border-radius: 10px;
			padding: 3em 1em;
			position: relative;
			&::before {
				content: "“";
				font-size: 400%;
				line-height: 1em;
				color: $pink-lightColor;
				position: absolute;
				left: 0.1em;
				top: 0.1em;
			}
			&::after {
				content: "”";
				font-size: 400%;
				line-height: 0em;
				color: $pink-lightColor;
				position: absolute;
				right: 0.1em;
				bottom: -0.1em;
			}
			p:first-of-type {margin-top: 0;}
			p:last-of-type {margin-bottom: 0;}
			.source {
				font-size: $fs_s;
				display: block;
				text-align :right;
			}
		}//blockquote

		> .affiliate-area {
			margin: 50px 0 0;
			+ .affiliate-area {margin: 20px 0 0;}
			.memo {margin: 0;font-size: $fs_s;}

			> .tit {
				margin: 0;
				font-size: $fs_m;
				line-height: $lh_m;
				@include bold;
				> span {
					font-size: $fs_s;
					@include normal;
				}
				+ .note {margin: 0;}
			}
			> .list {
				li {
					display: inline-block;
					margin: 10px 10px 0 0;
					font-size: $fs_s;
					vertical-align: top;
					&:last-of-type {margin-right: 0;}
					img {width: auto;border: $borderSol;}
					> span {
						display: block;
						margin-top: 5px;
						font-size: $fs_s;
						line-height: $lh_m;
					}
				}
			}//list
			> iframe {margin-top: 5px;}
		}//affiliate-area

	}//contents-main
}//kakitai-article


/* ============================================================
	publishing/hondas
============================================================ */
.hondas-illust {
	padding-right: 100px;
	font-size: 6rem;
	text-align: center;
	color: $hondas-baseColor;
	> span {display: block;}
	> .name {
		margin-top: 5px;
		font-size: $fs_m;
		color: $textColor;
	}
	@include media(lx) {
		padding-right: 0;
			> .name {font-size: $fs_s;}
	}
}//hondas-illust

.hondas-for {
	@include flex-wrap;
	@include flex-center-tb;
	> .ttl {
		@include flex;
		@include flex-center-tb;
		margin-bottom: 10px;
		padding: 20px;
		width: 350px;
		background-color: #ccff99;
		font-size: $fs_xl;
		@include bold;
		text-align: center;
		img {width: auto;margin-right: 10px;}
		@include media(ln) {
			&:last-of-type {margin-bottom: 0;}
		}
		@include media(lx) {
			padding: 10px;
			width: $fullWidth;
			font-size: $fs_l;
		}
	}
	> .txt {
		margin: 0 0 10px 30px;
		width: 700px;
		&:last-of-type {margin-bottom: 0;}
		@include media(lx) {margin: 0 0 20px 0;width: $fullWidth;}
	}
}//hondas-for

.hondas-sbttl {
	font-size: $fs_xl;
	@include bold;
}

.hondas-point {
	@include flex-wrap;
	@include flex-center-tb;
	margin-top: 20px;
	> .ttl {
		@include flex;
		@include flex-center-tb;
		padding: 12px;
		width: 350px;
		color: $white;
		font-size: $fs_xl;
		line-height: $lh_m;
		@include bold;
		text-align: left;
		img {width: auto;margin-right: 10px;}
		@include media(ln) {
		}
		@include media(lx) {
			margin-bottom: 10px;
			padding: 10px;
			width: $fullWidth;
			font-size: $fs_l;
		}
		&.-first {background-color: #d3764e;}
		&.-second {background-color: #135dad;}
		.icon-hondas {font-size: 3rem;}
		> .number{
			margin: 0 10px 0 8px;
			font-size: 2.2rem;
		}
	}//ttl
	> .txt {
		margin-left: 30px;
		width: 700px;
		@include media(lx) {margin-left: 0;width: $fullWidth;}
	}
}//hondas-point

.hondas-ttlbox {
	@include flex;
	@include flex-center-tb;
	margin: 20px 0;
	padding: 12px;
	background-color: #5eb226;
	color: $white;
	font-size: $fs_xl;
	line-height: $lh_m;
	@include bold;
	text-align: left;
	img {width: auto;margin-right: 10px;}
	@include media(ln) {
	}
	@include media(lx) {
		margin-bottom: 10px;
		padding: 10px;
		width: $fullWidth;
		font-size: $fs_l;
	}
	.icon-hondas {font-size: 3rem;}
	> .number{
		margin: 0 10px 0 8px;
		font-size: 2.2rem;
	}
}//hondas-ttlbox

.hondas-kit {
	@include media(ln) {
		@include flex-between;
		@include flex-row;
	}
	> .txt-box {
		width: 650px;
		@include media(lx) {width: $fullWidth;}

		> .kit-list {
			@include media(ln) {@include flex;}
			margin-top: 15px;
			@include media(lx) {margin-top: 10px;}
			> li {
				width: 50%;
				line-height: 1.8;
				@include media(lx) {
					width: $fullWidth;
					line-height: $lh_l;
					&:last-child {margin-top: 5px;}
				}
			}
		}//kit-list

	}//txt-box
	> .img {
		width: 400px;
		@include media(lx) {margin-top: 10px;width: $fullWidth;}
	}
}//hondas-kit

.hondas-sample {
	@include media(ln) {
		@include flex-between;
		@include flex-center-tb;
	}
	> .txt-box {
		width: 580px;
		@include media(lx) {width: $fullWidth;}
		.ttl {
			margin-bottom: 5px;
			font-size: $fs_xl;
			@include bold;
			color: $red;
		}
		.sbttl {
			margin: 20px 0 5px;
			font-size: $fs_l;
			@include bold;
			color: $hondas-baseColor;
		}
	}
	> .img {
		width: 500px;
		text-align: center;
		img {width: auto;}
		@include media(lx) {margin-top: 10px;width: $fullWidth;}
	}
}//hondas-sample


/* ============================================================
	publishing/faq
============================================================ */
.qa-list {
	@include margin(0 auto 20px);
	width: $fullWidth;
	border-bottom: $borderDash;
	@include media(lx) {
		width: $fullWidth;
	}

	> dt {border-top: $borderDash;}
	> dt:first-of-type {border-top: none;}
	> dt > .item {
		display: block;
		position: relative;
		padding: 10px 0 10px 22px;
		cursor: pointer;
		@include fs-16l;
		.q {
			position: absolute;
			top: 10px;
			left:0;
		}
	}//item

	> dd {
		display: none;
		margin: 0 0 15px 22px;
		padding: 10px 15px 15px 15px;
		border: $borderSol-publi1;
		border-radius: 5px;

		.txt-box{position: relative;}
		.answer {
			position: absolute;
			top: 6px;
			left: 0;
			color: $publi-baseColor;
			width: 40px;
			font-size: $fs_xl;
			@include bold;
		}
		.txt-box .wrap {
			padding: 5px 0 0 30px;
			> p,
			> ul {
				margin-bottom: 10px;
				@include fs-14xl;
				&:last-child {margin-bottom: 0;}
			}
			> p,
			> ul li {
				> a {@include link-text;}
			}
		}
	}//dd
}//faq-qlist

	.qa-menu {
		@include margin(40px auto 80px);
		@include media(ln) {
			@include flex-between;
		}
		padding: 0 80px;
		border-bottom: $borderSol-publi1;
		font-size: 0;
		@include media(lx) {
			@include margin(0 auto 40px);
			padding: 0;
			border-bottom: none;
			> li {
				margin-top: 5px;
				display: inline-block;
				width: $fullWidth;
				border-bottom: $borderSol-publi1;
				text-align: center;
			}
		}

		.btn-anker {
			display: block;
			position: relative;
			padding: 10px 30px 10px 10px;
			@include ie-hack(gteIE11) {padding-top: 17px;}
			color: $publi-baseColor;
			font-size: $fs_xl;
			&::before {
				@include arrow-down;
				transition: top .3s;
			}
			@include hover() {
				text-decoration: none;
				&::before {
					top: 68%;
					transform: translate(0,-68%);
				}
			}

			@include media(lx) {
				font-size: $fs_l;
			}
		}//btn-anker
	}//qa-menu


/* ============================================================
	publishing/cashico
============================================================ */
.cashico-area {
	@include media(ln) {
		@include flex-between;
		@include flex-row;
	}
	margin: 20px auto -18px;
	padding-top: 24px;
	border-top: $borderSol;
	@include media(lx) {
		margin: 16px auto -12px;
		padding-top: 20px;
	}

	> .img {
		width: 500px;
		@include media(lx) {
			margin-bottom: 10px;
			width: $fullWidth;
		}
	}//img
	> .txt {
		width: 540px;
		line-height: $lh_l;
		@include media(lx) {
			width: $fullWidth;
			line-height: $lh_m;
		}
		> p {margin-bottom: 10px;}
	}//txt
}//cashico-area