@charset "utf-8";

/* ============================================================
	news-box
============================================================ */
.news-box {
	margin: 0 auto 80px;
	position: relative;
	width: $baseWidth;
	@include media(lx) {
		margin: 0 auto 30px;
		width: $fullWidth;
	}

	> .ttl {
		position: relative;
		padding: 0 0 10px 5px;
		border-bottom: solid 1px $gray-b;
		font-size: $fs-l;
		color: $baseColor;

		&::after {
			position: absolute;
			content: " ";
			display: block;
			border-bottom: solid 1px $baseColor;
			bottom: -1px;
			left: -0;
			width: 20%;
			@include media(lx) {
				width: 40%;
			}
		}
	}//ttl

	> .list {
		width: 1080px;
		@include media(lx) {
			width: $fullWidth;
		}
		> li {
			margin: 0 !important;
			display: block;
			border-top: $borderSol;
			&:first-child {border-top: none;}

			> a {
				@include flex-wrap;
				@include flex-center-tb;
				padding: 10px 5px;
				width: $fullWidth;
				@include media(lx) {
					padding: 10px 0;
				}
				> p {
					@include media(ln) {margin: 0 5px;}
					&.thumb {
						margin-right: 5px;
						width: 72px;
						border: 1px solid $gray-e;
					}
					&.date {
						width: 106px;
						color: $gray;
						white-space: nowrap;
						@include media(lx) {
							margin-right: 5px;
							width: auto;
							font-size: $fs-s;
						}
					}
					&.label {
						@include label;
						width: 90px;
						font-size: $fs_s;
						@include media(lx) {
							width: auto;
							font-size: $fs-s;
						}
					}//label
					&.txt {
						width: 762px;
						line-height: 1.5;
						@include media(lx) {
							margin-top: 5px;
							width: 100%;
						}
					}
				}//p
				@include hover {
					@include opacity;
					text-decoration: none;
				}
			}//a
			> a.link-none {
				pointer-events: none;
				color: $textColor;
				@include hover {
					opacity: 1;
					text-decoration: none;
				}
			}//a.link-none
		}//li
	}//list

}//news-box


/* ============================================================
	pagination
============================================================ */
.pagination {
	@include flex-center;

	p {
		a, strong {
			padding: 0 5px;
			display: inline-block;
			line-height: 2;
		}
	}//p
}//pagination


/* ============================================================
	news-article
============================================================ */
.news-ttl {
	padding: 40px 0 60px 0;
	font-size: 3.4rem;
	line-height: $lh_m;
	color: $baseColor;
	@include media(lx) {
		padding: 5px 0;
		font-size: 2rem;
	}
}
.news-data {
	padding: 15px 0;
	@include flex;
	@include flex-center-tb;
	font-size: $fs_m;
	color: $gray;
	@include media(lx) {
		padding: 10px 0;
		font-size: $fs_s;
	}
	> .label {
		@include label;
		margin-left: 10px;
		font-size: $fs_xs;
	}//label
}//news-data

.news-article {
	border-top: $borderSol;
	padding-top: 20px;
	font-size:  $fs_l;
	@include media(lx) {
		padding-top: 0;
	}

	[class^="img-area"] {
		text-align: center;

		img {
			margin: 30px 0 20px;
			width: auto;
			max-width: 1060px;
			height: auto;
			@include media(lx) {
				margin: 20px 0 10px;
				width: auto !important;
				max-width: 90%;
				max-height: 500px;
			}
		}//img
	}//img-area

	h2 {
		margin-top: 15px;
		@include fs-20xl;
		@include bold;
		@include media(lx) {
			@include fs-18l;
		}
	}//h2
	p {
		margin-top: 15px;
		line-height: $lh_xl;
		@include media(lx) {
			line-height: $lh_l;
		}
	}//p
	a {
		text-decoration: underline;
		text-indent: 0;
		@include hover {
			text-decoration: none;
		}
	}//a

	.book-area {
		margin-top: 50px;
		@include media(lx) {margin: 30px auto 0;}
		.h4ttl-line {margin-bottom: 0;}
		.list {
			@include flex-wrap;
			@include media(lx) {
				display: block;
			}

			li {
				@include flex;
				margin: 20px 0 0;
				padding: 0 5px;
				width: 50%;
				&:only-of-type {width: $fullWidth;}
				@include media(lx) {
					padding: 0;
					width: $fullWidth;
				}

				> .thumb {
					margin-right: 10px;
					img {
						width: auto;
						max-width: 192px;
						@include media(ln) {max-height: 222px;}
						border: 1px solid $borderColor;
						@include media(lx) {
							max-width: 125px;
						}
					}
				}//thumb
				> .detail {
					.ttl {
						@include fs-18m;
						@include bold;
						@include media(lx) {@include fs-16s;}
					}
					.name {
						margin-top: 5px;
						font-size: $fs-l;
						@include bold;
						@include media(lx) {font-size: $fs-m;}
					}
					.data {
						margin-top: 20px;
						font-size: $fs-l;
						@include media(lx) {margin-top: 15px;font-size: $fs-m;}
					}
					.other {
						margin-top: 10px;
						font-size: $fs-m;
						@include media(lx) {margin-top: 5px;}
					}
				}

			}//li
		}//list
	}//book-area

	.contact-box {
		margin: 60px auto 0;
		@include media(lx) {
			margin: 40px auto 0;
			width: $fullWidth;
		}

		> .box {
			p {
				margin-top: 0;
				@include fs-14m;
			}//p
		}//box
	}//contact-box

}//news-article

/* ============================================================
	news-other
============================================================ */
.ttldeco-bgpubli {
	display: block;
	padding: 10px;
	width: 100%;
	background-color: $publi-iconColor;
	@include bold;
	font-size: 2rem !important;
	line-height: $lh_l;
	text-align: center;
	width: 100%;
	color: $white;
}