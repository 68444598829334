@charset "UTF-8";
/* ============================================================
	reset
============================================================ */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, a, ins, kbd, q, samp,
small, strong, sub, sup, var, b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  vertical-align: baseline;
}

h1, h2, h3, h4, h5, h6, b {
  font-weight: 500;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

img {
  vertical-align: bottom;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote::before, blockquote::after,
q::before, q::after {
  content: '';
  content: none;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  vertical-align: middle;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 100% !important;
  color: #333;
}

a:focus {
  outline: none;
}

textarea {
  resize: vertical;
}

input::-ms-clear {
  visibility: hidden;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

body {
  line-height: 1;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-print-color-adjust: exact;
}

/* ============================================================
	icon
============================================================ */
@font-face {
  font-family: "bungeisha";
  src: url("../icon/bungeisha.eot?8iv790");
  src: url("../icon/bungeisha.eot?8iv790#iefix") format("embedded-opentype"), url("../icon/bungeisha.ttf?8iv790") format("truetype"), url("../icon/bungeisha.woff?8iv790") format("woff"), url("../icon/bungeisha.svg?8iv790#bungeisha") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "bungeisha" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon2-"], [class*=" icon2-"] {
  position: relative;
  font-family: "bungeisha" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon2-"]::before, [class*=" icon2-"]::before {
  position: absolute;
  top: 0;
  left: 0;
}

[class^="icon2-"]::after, [class*=" icon2-"]::after {
  position: absolute;
  top: 0;
  left: 0;
  color: #6d6564;
}

.icon-blank::before {
  content: "";
}

.icon-arrow-left::before {
  content: "";
}

.icon-arrow-right::before {
  content: "";
}

.icon-arrow-up::before {
  content: "";
}

.icon-arrow-down::before {
  content: "";
}

.icon-arrow-pagetop::before {
  content: "";
}

.icon-arrow-chart::before {
  content: "";
}

.icon-home::before {
  content: "";
  color: #6d6564;
}

.icon-publishing::before {
  content: "";
  color: #ed5c39;
}

.icon-bookinfo::before {
  content: "";
  color: #f5a900;
}

.icon-about::before {
  content: "";
  color: #6867b0;
}

.icon2-contest::before {
  content: "";
}

.icon2-contest::after {
  content: "";
}

.icon2-publi-work::before {
  content: "";
}

.icon2-publi-work::after {
  content: "";
}

.icon2-publi-self::before {
  content: "";
}

.icon2-publi-self::after {
  content: "";
}

.icon2-publi-guide::before {
  content: "";
}

.icon2-publi-guide::after {
  content: "";
}

.icon2-contact::before {
  content: "";
}

.icon2-contact::after {
  content: "";
}

.icon-calendar::before {
  content: "";
}

.icon-tel::before {
  content: "";
}

.icon-mail::before {
  content: "";
}

.icon-post::before {
  content: "";
}

.icon-pc::before {
  content: "";
}

.icon-book::before {
  content: "";
}

.icon-news::before {
  content: "";
}

.icon-question::before {
  content: "";
}

.icon-talk::before {
  content: "";
}

.icon-plus::before {
  content: "";
}

.icon-minus::before {
  content: "";
}

.icon-minus_bold::before {
  content: "";
}

.icon-download::before {
  content: "";
}

.icon-map::before {
  content: "";
}

.icon-hondas::before {
  content: "";
}

.icon-freecall::before {
  content: "";
  color: #0e0d6a;
}

.icon-check::before {
  content: "";
}

.icon-heart::before {
  content: "";
}

.icon-search::before {
  content: "";
}

.icon2-company::before {
  content: "";
}

.icon2-company::after {
  content: "";
}

.icon2-philosophy::before {
  content: "";
}

.icon2-philosophy::after {
  content: "";
}

.icon2-commitment::before {
  content: "";
}

.icon2-hot::before {
  content: "";
}

.icon2-hot::after {
  content: "";
}

.icon2-neo::before {
  content: "";
}

.icon2-picturebook::before {
  content: "";
}

.icon2-picturebook::after {
  content: "";
}

.icon2-ebook::before {
  content: "";
}

.icon2-ebook::after {
  content: "";
}

.icon2-library::before {
  content: "";
}

.icon2-library::after {
  content: "";
}

.icon-promotion::before {
  content: "";
}

.icon-read-trial::before {
  content: "";
}

.icon-website::before {
  content: "";
}

.icon-blog::before {
  content: "";
}

.icon-movie::before {
  content: "";
}

.icon-drama::before {
  content: "";
}

.icon-arrow-open::before {
  content: "";
}

.icon-twitter::before {
  content: "";
}

.icon-x::before {
  content: "";
}

.icon-note::before {
  content: "";
}

/* ============================================================
	base
============================================================ */
html {
  font-size: 62.5%;
  min-width: 320px;
}

body {
  position: relative;
  font-family: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  color: #333;
}

body.is-fixed {
  overflow: hidden;
}

a {
  color: #1432c8;
  text-decoration: none;
  transition: all .3s;
}

a:visited {
  color: #990099;
}

a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 0.7;
  color: #ff6600;
  text-decoration: underline;
}

@media print, screen and (min-width: 768px) {
  a:hover {
    opacity: 0.7;
    color: #ff6600;
    text-decoration: underline;
  }
}

img {
  width: 100%;
  height: auto;
}

img.no-resize {
  width: auto !important;
  height: auto;
}

.txt {
  font-size: 1.4rem;
  line-height: 1.7;
}

@media only screen and (max-width: 767px) {
  .txt {
    font-size: 1.4rem;
    line-height: 1.5;
  }
}

.txt-p {
  margin-top: 15px;
  font-size: 1.4rem;
  line-height: 1.7;
}

@media only screen and (max-width: 767px) {
  .txt-p {
    font-size: 1.4rem;
    line-height: 1.5;
  }
}

.for-print {
  display: none !important;
}

.txt-l {
  font-size: 1.6rem;
  line-height: 1.7;
}

/* ============================================================
	content
============================================================ */
.content-area {
  margin-top: 204px;
  width: 100%;
}

@media only screen and (max-width: 1160px) {
  .content-area {
    width: 1160px;
  }
}

@media only screen and (max-width: 767px) {
  .content-area {
    margin-top: 56px;
    width: 100%;
  }
}

@media print, screen and (min-width: 768px) {
  .content-area.mgdr-none {
    margin-top: 105px;
  }
}

.title-area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 265px;
  background-color: #ff8282;
}

@media only screen and (max-width: 767px) {
  .title-area {
    height: 132px;
  }
}

.title-area > .title {
  width: 100%;
  color: #fff;
  font-size: 3.4rem;
  text-align: center;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0 0 5px #555, 0 0 6px #555, 0 0 7px #555;
}

.title-area > .title .small {
  font-size: 2.4rem;
}

@media only screen and (max-width: 767px) {
  .title-area > .title {
    font-size: 2.4rem;
  }
  .title-area > .title .small {
    font-size: 1.4rem;
  }
}

.title-area > .title > .eng {
  width: 100%;
  margin-top: 16px;
  color: #36357e;
  font-size: 1.6rem;
  text-align: center;
  text-shadow: 0 0 5px #ccc, 0 0 6px #ccc, 0 0 7px #ccc;
  font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
  font-weight: 400;
  font-style: italic;
}

@media only screen and (max-width: 767px) {
  .title-area > .title > .eng {
    margin-top: 8px;
  }
}

.title-area.-news {
  background: url(/images/ttlbg/news.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-contest {
  background: url(/publishing/images/ttlbg/contest.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-form {
  background: url(/publishing/images/ttlbg/form.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-webform {
  background: url(/publishing/images/ttlbg/webform.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-download {
  background: url(/publishing/images/ttlbg/download.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-soudankai {
  background: url(/publishing/images/ttlbg/soudankai.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-flow {
  background: url(/publishing/images/ttlbg/flow.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-support {
  background: url(/publishing/images/ttlbg/support.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-partner {
  background: url(/publishing/images/ttlbg/partner.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-storage {
  background: url(/publishing/images/ttlbg/storage.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-syuppantaiken {
  background: url(/publishing/images/ttlbg/syuppantaiken.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-setsumeikai {
  background: url(/publishing/images/ttlbg/setsumeikai.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-kakitai {
  background: url(/publishing/images/ttlbg/kakitai.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-hondas {
  background: url(/publishing/images/ttlbg/hondas.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-qa {
  background: url(/publishing/images/ttlbg/qa.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-pamphlet {
  background: url(/publishing/images/ttlbg/pamphlet.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-cashico {
  background: url(/publishing/images/ttlbg/cashico.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-webmeeting {
  background: url(/publishing/images/ttlbg/webmeeting.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-telephonemeeting {
  background: url(/publishing/images/ttlbg/telephonemeeting.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-pickup {
  background: url(/bookinfo/images/ttlbg/pickup.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-reprint {
  background: url(/bookinfo/images/ttlbg/reprint.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-drama {
  background: url(/bookinfo/images/ttlbg/drama.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-profile {
  background: url(/about/images/ttlbg/profile.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-map {
  background: url(/about/images/ttlbg/map.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-history {
  background: url(/about/images/ttlbg/history.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-recruit {
  background: url(/about/images/ttlbg/recruit.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-group {
  background: url(/about/images/ttlbg/group.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-message {
  background: url(/about/images/ttlbg/message.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-philosophy {
  background: url(/about/images/ttlbg/philosophy.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-anniversary {
  background: url(/about/images/ttlbg/20th.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-protection {
  background: url(/about/images/ttlbg/protection.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-principles {
  background: url(/about/images/ttlbg/principles.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-contact {
  background: url(/about/images/ttlbg/contact.jpg) center no-repeat;
  background-size: cover;
}

.title-area.-contest > .title > .eng,
.title-area.-form > .title > .eng,
.title-area.-webform > .title > .eng,
.title-area.-download > .title > .eng,
.title-area.-soudankai > .title > .eng,
.title-area.-flow > .title > .eng,
.title-area.-support > .title > .eng,
.title-area.-partner > .title > .eng,
.title-area.-storage > .title > .eng,
.title-area.-syuppantaiken > .title > .eng,
.title-area.-setsumeikai > .title > .eng,
.title-area.-kakitai > .title > .eng,
.title-area.-hondas > .title > .eng,
.title-area.-qa > .title > .eng,
.title-area.-pamphlet > .title > .eng,
.title-area.-cashico > .title > .eng,
.title-area.-webmeeting > .title > .eng,
.title-area.-telephonemeeting > .title > .eng {
  color: #e62814;
}

.title-area.-pickup > .title > .eng,
.title-area.-reprint > .title > .eng,
.title-area.-drama > .title > .eng {
  color: #ff9e00;
}

.title-area.-pickup > .title > .eng,
.title-area.-reprint > .title > .eng {
  text-shadow: 0 0 5px #eee, 0 0 6px #eee, 0 0 7px #eee;
}

.title-area.-simple {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: #ff8282;
}

@media only screen and (max-width: 767px) {
  .title-area.-simple {
    height: 60px;
  }
}

.title-area.-simple > .title {
  text-shadow: none;
}

.title-area.-bkinfo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90px;
  background-color: #fff;
}

@media only screen and (max-width: 767px) {
  .title-area.-bkinfo {
    height: 100px;
  }
}

.title-area.-bkinfo > .title {
  color: #36357e;
  text-shadow: none;
}

.title-area.-bkinfo > .title > .eng {
  color: #ff9e00;
  text-shadow: none;
}

.title-area.-small {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: #fff;
}

@media only screen and (max-width: 767px) {
  .title-area.-small {
    margin-top: 10px;
    height: auto;
  }
}

.title-area.-small > .title {
  color: #555;
  font-size: 3rem;
  text-shadow: none;
}

.title-area.-small > .title .small {
  font-size: 2rem;
}

@media only screen and (max-width: 767px) {
  .title-area.-small > .title {
    font-size: 2.4rem;
  }
  .title-area.-small > .title .small {
    font-size: 1.6rem;
  }
}

.title-area.-about {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 140px;
  background-color: #36357e;
}

@media only screen and (max-width: 767px) {
  .title-area.-about {
    height: 120px;
    line-height: 1.2;
  }
}

.title-area.-about > .title {
  text-shadow: none;
}

.title-area.-about > .title > .eng {
  margin-top: 16px;
  color: #fff;
  text-align: center;
  text-shadow: none;
}

@media only screen and (max-width: 767px) {
  .title-area.-about > .title > .eng {
    margin-top: 8px;
  }
}

/* ============================================================
	lead-area
============================================================ */
.lead-area {
  margin: 0 auto 80px;
  padding: 40px 0;
  width: 1080px;
  border-bottom: 1px solid #ccc;
}

.lead-area .lead {
  font-size: 1.6rem;
  line-height: 1.7;
}

@media only screen and (max-width: 767px) {
  .lead-area {
    margin: 0 3% 40px;
    width: 94%;
    padding: 20px 0;
    line-height: 1.5;
  }
  .lead-area .lead {
    line-height: 1.5;
  }
}

@media print, screen and (min-width: 768px) {
  .lead-area > .column {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .lead-area > .column > .txt-area {
    width: 800px;
  }
  .lead-area > .column > .photo {
    width: 260px;
    height: 100%;
  }
  .lead-area.-col {
    display: flex;
    justify-content: space-between;
  }
  .lead-area.-col > .wrap {
    width: 860px;
  }
  .lead-area.-col > img {
    width: 190px;
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .lead-area > .column > .photo {
    margin-top: 15px;
    text-align: center;
  }
  .lead-area > .column > .photo img {
    max-width: 300px;
  }
  .lead-area.-spcol {
    display: flex;
    justify-content: space-between;
  }
  .lead-area.-spcol > .wrap {
    width: 79%;
  }
  .lead-area.-spcol > img {
    width: 20%;
  }
}

.lead-ttl {
  margin-bottom: 20px;
  font-size: 2.4rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .lead-ttl {
    margin-bottom: 15px;
    font-size: 2rem;
    line-height: 1.2;
  }
}

/* ============================================================
	wrap
============================================================ */
.base-wrap {
  margin: 140px auto;
  width: 1080px;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .base-wrap {
    margin: 60px 3% 60px;
    width: 94%;
  }
}

.full-wrap {
  margin: 140px auto;
  padding: 60px 0;
  width: 100%;
  background-color: #eaeaea;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .full-wrap {
    margin: 60px 0;
    padding: 30px 3%;
    width: 100%;
  }
}

.old-wrap {
  margin: 0 auto 140px;
  width: 580px;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .old-wrap {
    margin: 0 3% 60px;
    width: 94%;
  }
}

.bc-area + .base-wrap,
.lead-area + .base-wrap,
.lead-area + .full-wrap {
  margin-top: 0;
}

.title-area + .base-wrap {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .title-area + .base-wrap {
    margin-top: 20px;
  }
}

.old-wrap + .base-wrap {
  margin-top: -100px;
}

@media only screen and (max-width: 767px) {
  .old-wrap + .base-wrap {
    margin-top: -30px;
  }
}

.base-wrap.-bkinfo {
  margin-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .base-wrap.-bkinfo {
    margin: 0 3% 20px;
    width: 94%;
  }
}

.search-box + .base-wrap,
.search-word + .base-wrap {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .search-box + .base-wrap,
  .search-word + .base-wrap {
    margin-top: 20px;
  }
}

.social-box {
  display: flex;
  flex-wrap: wrap;
  margin: 15px auto;
  padding: 5px 2px 10px 10px;
  border: 1px solid #ccc;
}

.social-box > div {
  display: inline-block;
  margin-top: 5px;
  padding: 0 5px 0 0;
  height: 20px;
  line-height: 30px;
}

.social-box > div.facebook {
  margin-top: -5px;
  padding: 0;
  width: 91px;
  height: auto;
}

/* ============================================================
	.content-area.-single
============================================================ */
.content-area.-single {
  margin: 10px 0 30px;
}

@media only screen and (max-width: 767px) {
  .content-area.-single {
    margin: 0 0 20px;
  }
}

.header-simple + .content-area.-single {
  margin: 90px 0 30px;
}

@media only screen and (max-width: 767px) {
  .header-simple + .content-area.-single {
    margin: 56px 0 20px;
  }
}

.content-area.-single > .title-area {
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1080px;
  height: 100px;
  background-color: #ff8282;
}

@media only screen and (max-width: 767px) {
  .content-area.-single > .title-area {
    width: 100%;
    height: 80px;
  }
}

.content-area.-single > .title-area > .title {
  width: 100%;
  color: #fff;
  font-size: 3.4rem;
  text-align: center;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: none;
}

.content-area.-single > .title-area > .title .small {
  font-size: 2.4rem;
}

@media only screen and (max-width: 767px) {
  .content-area.-single > .title-area > .title {
    font-size: 2.4rem;
  }
  .content-area.-single > .title-area > .title .small {
    font-size: 1.4rem;
  }
}

.content-area.-single > .title-area + .base-wrap {
  margin: 0 auto;
}

/* ============================================================
	section-box
============================================================ */
.sec-box {
  margin: 100px auto;
}

@media only screen and (max-width: 767px) {
  .sec-box {
    margin: 60px auto;
  }
}

.sec-sbox {
  margin: 60px auto;
}

@media only screen and (max-width: 767px) {
  .sec-sbox {
    margin: 30px auto;
  }
}

.sec-box:first-child,
.sec-sbox:first-child {
  margin-top: 0;
}

.sec-box:last-child,
.sec-sbox:last-child {
  margin-bottom: 0;
}

/* ============================================================
	title-icon
============================================================ */
.h2ttl-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555;
  font-size: 2.6rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .h2ttl-icon {
    font-size: 2.2rem;
  }
}

.h2ttl-icon > [class^="icon-"] {
  margin-top: -4px;
  margin-right: 6px;
  font-size: 3.4rem;
}

_:-ms-fullscreen, :root .h2ttl-icon > [class^="icon-"] {
  margin-top: -11px;
}

@media only screen and (max-width: 767px) {
  .h2ttl-icon > [class^="icon-"] {
    font-size: 3rem;
  }
}

.h2ttl-icon > .icon-talk {
  margin-top: -12px;
}

@media only screen and (max-width: 767px) {
  .h2ttl-icon > .icon-talk {
    margin-top: -8px;
  }
}

.h3ttl-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555;
  font-size: 2rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .h3ttl-icon {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 320px) {
  .h3ttl-icon {
    font-size: 1.6rem;
  }
}

.h3ttl-icon > [class^="icon-"] {
  margin-right: 6px;
  font-size: 2.8rem;
}

@media only screen and (max-width: 767px) {
  .h3ttl-icon > [class^="icon-"] {
    font-size: 2.6rem;
  }
}

/* ============================================================
	h2ttl-linebox
============================================================ */
.h2ttl-linebox {
  display: block;
  margin-bottom: 20px;
  padding: 10px 12px;
  border: 2px solid #36357e;
  color: #36357e;
  font-size: 2.6rem;
  line-height: 1.2;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .h2ttl-linebox {
    margin-bottom: 15px;
    padding: 6px 8px;
    font-size: 2.2rem;
  }
}

.h2ttl-linebox.-publi {
  border: 2px solid #e62814;
  color: #e62814;
}

.h2ttl-linebox.-support {
  border: 2px solid #ff8282;
  color: #ff8282;
}

.h2ttl-linebox.-bkinfo {
  border: 2px solid #ff9e00;
  color: #ff9e00;
}

.h2ttl-linebox > [class^="icon-"] {
  margin-right: 6px;
  font-size: 2.6rem;
}

@media only screen and (max-width: 767px) {
  .h2ttl-linebox > [class^="icon-"] {
    font-size: 2.2rem;
  }
}

/* ============================================================
	h2ttl-bgbox
============================================================ */
.h2ttl-bgbox {
  display: block;
  margin-bottom: 20px;
  padding: 10px 12px;
  background-color: #36357e;
  color: #fff;
  font-size: 2.6rem;
  line-height: 1.2;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .h2ttl-bgbox {
    margin-bottom: 15px;
    padding: 6px 8px;
    font-size: 2.2rem;
  }
}

.h2ttl-bgbox.-publi {
  background-color: #e62814;
}

.h2ttl-bgbox.-support {
  background-color: #ff8282;
}

.h2ttl-bgbox.-bkinfo {
  background-color: #ff9e00;
}

.h2ttl-bgbox > [class^="icon-"] {
  margin-right: 6px;
  font-size: 2.6rem;
}

@media only screen and (max-width: 767px) {
  .h2ttl-bgbox > [class^="icon-"] {
    font-size: 2.2rem;
  }
}

/* ============================================================
	h2ttl-line
============================================================ */
.h2ttl-line {
  display: block;
  margin-bottom: 20px;
  padding: 6px 10px;
  border-bottom: 3px solid #36357e;
  color: #36357e;
  font-size: 2.6rem;
  line-height: 1.4;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .h2ttl-line {
    margin-bottom: 15px;
    padding: 4px 8px;
    font-size: 2.2rem;
  }
}

.h2ttl-line.-publi {
  border-bottom: 2px solid #e62814;
  color: #e62814;
}

.h2ttl-line.-support {
  border-bottom: 2px solid #ff8282;
  color: #ff8282;
}

.h2ttl-line.-bkinfo {
  border-bottom: 2px solid #ff9e00;
  color: #ff9e00;
}

/* ============================================================
	h3ttl-line
============================================================ */
.h3ttl-line {
  display: block;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 3px solid #36357e;
  color: #555;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .h3ttl-line {
    padding: 10px 0;
    margin-bottom: 15px;
    font-size: 1.8rem;
    text-align: center;
  }
}

.h3ttl-line.-publi {
  border-bottom: 3px solid #e62814;
}

.h3ttl-line.-support {
  border-bottom: 3px solid #ff8282;
}

@media only screen and (max-width: 767px) {
  .h3ttl-line.-support {
    text-align: left;
  }
}

.h3ttl-line.-hondas {
  border-bottom: 3px solid #60a718;
}

.h3ttl-line.-bkinfo {
  border-bottom: 3px solid #ff9e00;
}

/* ============================================================
	h3ttl
============================================================ */
.h3ttl {
  display: block;
  margin-bottom: 15px;
  color: #555;
  font-size: 2rem;
  line-height: 1.4;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .h3ttl {
    font-size: 1.8rem;
  }
}

/* ============================================================
	h4ttl-line
============================================================ */
.h4ttl-line {
  display: block;
  margin-bottom: 20px;
  padding: 4px 8px;
  border-bottom: 2px solid #36357e;
  color: #555;
  font-size: 1.8rem;
  line-height: 1.4;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .h4ttl-line {
    margin-bottom: 15px;
    font-size: 1.6rem;
  }
}

.h4ttl-line.-publi {
  border-bottom: 2px solid #e62814;
}

.h4ttl-line.-support {
  border-bottom: 2px solid #ff8282;
}

.h4ttl-line.-bkinfo {
  border-bottom: 2px solid #ff9e00;
}

/* ============================================================
	h4ttl
============================================================ */
.h4ttl {
  display: block;
  margin-bottom: 15px;
  color: #555;
  font-size: 1.8rem;
  line-height: 1.4;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .h4ttl {
    margin-bottom: 10px;
    font-size: 1.6rem;
  }
}

/* ============================================================
	h5ttl-line
============================================================ */
.h5ttl-line {
  display: block;
  margin-bottom: 15px;
  padding: 3px 8px 5px 10px;
  border-left: 5px solid #36357e;
  border-bottom: 1px solid #36357e;
  color: #555;
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .h5ttl-line {
    margin-bottom: 10px;
  }
}

.h5ttl-line.-publi {
  border-left: 5px solid #e62814;
  border-bottom: 1px solid #e62814;
}

.h5ttl-line.-support {
  border-left: 5px solid #ff8282;
  border-bottom: 1px solid #ff8282;
}

.h5ttl-line.-bkinfo {
  border-left: 5px solid #ff9e00;
  border-bottom: 1px solid #ff9e00;
}

/* ============================================================
	h5ttl
============================================================ */
.h5ttl {
  display: block;
  margin-bottom: 10px;
  color: #555;
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

/* ============================================================
	lead
============================================================ */
.lead-lcc {
  margin: 20px 0 30px;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .lead-lcc {
    margin: 10px 0 20px;
  }
}

.lead-mcc {
  margin: 20px 0;
  font-size: 1.4rem;
  line-height: 1.7;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .lead-mcc {
    margin: 10px 0 15px;
    line-height: 1.5;
  }
}

.lead-lcl {
  margin: 20px 0 30px;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .lead-lcl {
    margin: 10px 0 20px;
    text-align: left;
  }
}

.lead-mcl {
  margin: 20px 0;
  font-size: 1.4rem;
  line-height: 1.7;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .lead-mcl {
    margin: 10px 0 15px;
    text-align: left;
    line-height: 1.5;
  }
}

.lead-lll {
  margin: 20px 0 30px;
  font-size: 1.6rem;
  line-height: 1.7;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .lead-lll {
    margin: 15px 0 20px;
    line-height: 1.5;
  }
}

.lead-mll {
  margin: 20px 0;
  font-size: 1.4rem;
  line-height: 1.7;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .lead-mll {
    margin: 10px 0 15px;
    line-height: 1.5;
  }
}

/* ============================================================
	bc_area
============================================================ */
.bc-area {
  margin: 0 auto;
  padding: 20px 0;
  overflow: auto;
  white-space: nowrap;
  width: 1080px;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .bc-area {
    padding: 0;
    width: 100%;
  }
}

.bc-area::-webkit-scrollbar {
  height: 1px;
}

.bc-area::-webkit-scrollbar-track {
  background: #fff;
}

.bc-area::-webkit-scrollbar-track {
  background: #fff;
}

.bc-list {
  display: table;
  list-style: none;
  margin: 0;
  padding: 12px 5px;
  font-size: 0;
}

.bc-list > li {
  display: table-cell;
  font-size: 1.2rem;
  white-space: nowrap;
}

.bc-list > li a {
  color: #333;
}

.bc-list > li:not(:last-child)::after {
  content: ">";
  padding: 0 10px;
}

@media only screen and (max-width: 767px) {
  .bc-list > li {
    font-size: 1rem;
  }
}

/* ============================================================
	btn
============================================================ */
.btn-box {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.btn-back {
  display: inline-block;
  position: relative;
  margin-right: 25px;
  padding: 20px 40px 20px 50px;
  color: #fff;
  font-size: 1.8rem;
  line-height: 1.4;
  text-align: center;
  border-radius: 8px;
  transition: all .3s;
  cursor: pointer;
  background-color: #a5a5a5;
  border: 1px solid #a5a5a5;
}

_:-ms-fullscreen, :root .btn-back {
  padding-top: 27px;
}

@media only screen and (max-width: 767px) {
  .btn-back {
    margin-right: 15px;
    padding: 10px 20px 10px 30px;
    font-size: 1.6rem;
  }
  _:-ms-fullscreen, :root .btn-back {
    padding-top: 17px;
  }
}

.btn-back:visited {
  color: #fff;
}

.btn-back::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: auto;
  left: 20px;
  font-size: 1.2rem;
  content: "";
  transition: left .1s;
}

@media only screen and (max-width: 767px) {
  .btn-back::before {
    left: 10px;
  }
}

.btn-back.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-color: #fff;
  color: #808080;
  text-decoration: none;
}

.btn-back.hover::before {
  left: 15px;
}

@media only screen and (max-width: 767px) {
  .btn-back.hover::before {
    left: 8px;
  }
}

@media print, screen and (min-width: 768px) {
  .btn-back:hover {
    opacity: 1;
    background-color: #fff;
    color: #808080;
    text-decoration: none;
  }
  .btn-back:hover::before {
    left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btn-back:hover::before {
    left: 8px;
  }
}

.btn {
  display: inline-block;
  position: relative;
  padding: 20px 50px 20px 40px;
  color: #fff;
  font-size: 1.8rem;
  line-height: 1.4;
  text-align: center;
  border-radius: 8px;
  transition: all .3s;
  cursor: pointer;
  text-decoration: none !important;
  background-color: #36357e;
  border: 1px solid #36357e;
}

_:-ms-fullscreen, :root .btn {
  padding-top: 27px;
}

@media only screen and (max-width: 767px) {
  .btn {
    padding: 10px 30px 10px 20px;
    font-size: 1.6rem;
  }
  _:-ms-fullscreen, :root .btn {
    padding-top: 17px;
  }
}

.btn:visited {
  color: #fff;
}

.btn::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.2rem;
  content: "";
  transition: right .1s;
}

@media only screen and (max-width: 767px) {
  .btn::before {
    right: 10px;
  }
}

.btn.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-color: #fff;
  color: #36357e;
  text-decoration: none !important;
}

.btn.hover::before {
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btn.hover::before {
    right: 8px;
  }
}

@media print, screen and (min-width: 768px) {
  .btn:hover {
    opacity: 1;
    background-color: #fff;
    color: #36357e;
    text-decoration: none !important;
  }
  .btn:hover::before {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btn:hover::before {
    right: 8px;
  }
}

.btn.-narrow {
  padding-top: 10px;
  padding-bottom: 10px;
}

.btn.-anker::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.2rem;
  content: "";
  right: 15px;
  transition: top .3s;
}

@media only screen and (max-width: 767px) {
  .btn.-anker::before {
    right: 12px;
  }
}

.btn.-anker.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;
  background-color: #fff;
}

.btn.-anker.hover::before {
  right: 15px;
  top: 58%;
  transform: translate(0, -58%);
}

@media only screen and (max-width: 767px) {
  .btn.-anker.hover::before {
    right: 12px;
  }
}

@media print, screen and (min-width: 768px) {
  .btn.-anker:hover {
    text-decoration: none;
    background-color: #fff;
  }
  .btn.-anker:hover::before {
    right: 15px;
    top: 58%;
    transform: translate(0, -58%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btn.-anker:hover::before {
    right: 12px;
  }
}

.btn.-blank::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.6rem;
  content: "";
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btn.-blank::before {
    right: 12px;
  }
}

.btn.-blank.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
}

.btn.-blank.hover::before {
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btn.-blank.hover::before {
    right: 12px;
  }
}

@media print, screen and (min-width: 768px) {
  .btn.-blank:hover {
    background-color: #fff;
  }
  .btn.-blank:hover::before {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btn.-blank:hover::before {
    right: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .btn.-dl {
    padding-right: 40px;
  }
}

.btn.-dl::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.6rem;
  content: "";
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btn.-dl::before {
    right: 12px;
  }
}

.btn.-dl.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
}

.btn.-dl.hover::before {
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btn.-dl.hover::before {
    right: 12px;
  }
}

@media print, screen and (min-width: 768px) {
  .btn.-dl:hover {
    background-color: #fff;
  }
  .btn.-dl:hover::before {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btn.-dl:hover::before {
    right: 12px;
  }
}

.btn-publi {
  display: inline-block;
  position: relative;
  padding: 20px 50px 20px 40px;
  color: #fff;
  font-size: 1.8rem;
  line-height: 1.4;
  text-align: center;
  border-radius: 8px;
  transition: all .3s;
  cursor: pointer;
  text-decoration: none !important;
  background-color: #36357e;
  border: 1px solid #36357e;
  background-color: #e62814;
  border: 1px solid #e62814;
}

_:-ms-fullscreen, :root .btn-publi {
  padding-top: 27px;
}

@media only screen and (max-width: 767px) {
  .btn-publi {
    padding: 10px 30px 10px 20px;
    font-size: 1.6rem;
  }
  _:-ms-fullscreen, :root .btn-publi {
    padding-top: 17px;
  }
}

.btn-publi:visited {
  color: #fff;
}

.btn-publi::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.2rem;
  content: "";
  transition: right .1s;
}

@media only screen and (max-width: 767px) {
  .btn-publi::before {
    right: 10px;
  }
}

.btn-publi.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-color: #fff;
  color: #36357e;
  text-decoration: none !important;
}

.btn-publi.hover::before {
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btn-publi.hover::before {
    right: 8px;
  }
}

@media print, screen and (min-width: 768px) {
  .btn-publi:hover {
    opacity: 1;
    background-color: #fff;
    color: #36357e;
    text-decoration: none !important;
  }
  .btn-publi:hover::before {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btn-publi:hover::before {
    right: 8px;
  }
}

.btn-publi.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  color: #e62814;
}

@media print, screen and (min-width: 768px) {
  .btn-publi:hover {
    background-color: #fff;
    color: #e62814;
  }
}

.btn-publi.-narrow {
  padding-top: 10px;
  padding-bottom: 10px;
}

.btn-publi.-anker::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.2rem;
  content: "";
  right: 15px;
  transition: top .3s;
}

@media only screen and (max-width: 767px) {
  .btn-publi.-anker::before {
    right: 12px;
  }
}

.btn-publi.-anker.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;
  background-color: #fff;
}

.btn-publi.-anker.hover::before {
  right: 15px;
  top: 58%;
  transform: translate(0, -58%);
}

@media only screen and (max-width: 767px) {
  .btn-publi.-anker.hover::before {
    right: 12px;
  }
}

@media print, screen and (min-width: 768px) {
  .btn-publi.-anker:hover {
    text-decoration: none;
    background-color: #fff;
  }
  .btn-publi.-anker:hover::before {
    right: 15px;
    top: 58%;
    transform: translate(0, -58%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btn-publi.-anker:hover::before {
    right: 12px;
  }
}

.btn-publi.-blank::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.6rem;
  content: "";
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btn-publi.-blank::before {
    right: 12px;
  }
}

.btn-publi.-blank.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
}

.btn-publi.-blank.hover::before {
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btn-publi.-blank.hover::before {
    right: 12px;
  }
}

@media print, screen and (min-width: 768px) {
  .btn-publi.-blank:hover {
    background-color: #fff;
  }
  .btn-publi.-blank:hover::before {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btn-publi.-blank:hover::before {
    right: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-publi.-dl {
    padding-right: 40px;
  }
}

.btn-publi.-dl::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.6rem;
  content: "";
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btn-publi.-dl::before {
    right: 12px;
  }
}

.btn-publi.-dl.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
}

.btn-publi.-dl.hover::before {
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btn-publi.-dl.hover::before {
    right: 12px;
  }
}

@media print, screen and (min-width: 768px) {
  .btn-publi.-dl:hover {
    background-color: #fff;
  }
  .btn-publi.-dl:hover::before {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btn-publi.-dl:hover::before {
    right: 12px;
  }
}

.btn-bkinfo {
  display: inline-block;
  position: relative;
  padding: 20px 50px 20px 40px;
  color: #fff;
  font-size: 1.8rem;
  line-height: 1.4;
  text-align: center;
  border-radius: 8px;
  transition: all .3s;
  cursor: pointer;
  text-decoration: none !important;
  background-color: #36357e;
  border: 1px solid #36357e;
  background-color: #ff9e00;
  border: 1px solid #ff9e00;
}

_:-ms-fullscreen, :root .btn-bkinfo {
  padding-top: 27px;
}

@media only screen and (max-width: 767px) {
  .btn-bkinfo {
    padding: 10px 30px 10px 20px;
    font-size: 1.6rem;
  }
  _:-ms-fullscreen, :root .btn-bkinfo {
    padding-top: 17px;
  }
}

.btn-bkinfo:visited {
  color: #fff;
}

.btn-bkinfo::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.2rem;
  content: "";
  transition: right .1s;
}

@media only screen and (max-width: 767px) {
  .btn-bkinfo::before {
    right: 10px;
  }
}

.btn-bkinfo.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-color: #fff;
  color: #36357e;
  text-decoration: none !important;
}

.btn-bkinfo.hover::before {
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btn-bkinfo.hover::before {
    right: 8px;
  }
}

@media print, screen and (min-width: 768px) {
  .btn-bkinfo:hover {
    opacity: 1;
    background-color: #fff;
    color: #36357e;
    text-decoration: none !important;
  }
  .btn-bkinfo:hover::before {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btn-bkinfo:hover::before {
    right: 8px;
  }
}

.btn-bkinfo.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  color: #ff9e00;
}

@media print, screen and (min-width: 768px) {
  .btn-bkinfo:hover {
    background-color: #fff;
    color: #ff9e00;
  }
}

.btn-bkinfo.-narrow {
  padding-top: 10px;
  padding-bottom: 10px;
}

.btn-bkinfo.-anker::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.2rem;
  content: "";
  right: 15px;
  transition: top .3s;
}

@media only screen and (max-width: 767px) {
  .btn-bkinfo.-anker::before {
    right: 12px;
  }
}

.btn-bkinfo.-anker.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;
  background-color: #fff;
}

.btn-bkinfo.-anker.hover::before {
  right: 15px;
  top: 58%;
  transform: translate(0, -58%);
}

@media only screen and (max-width: 767px) {
  .btn-bkinfo.-anker.hover::before {
    right: 12px;
  }
}

@media print, screen and (min-width: 768px) {
  .btn-bkinfo.-anker:hover {
    text-decoration: none;
    background-color: #fff;
  }
  .btn-bkinfo.-anker:hover::before {
    right: 15px;
    top: 58%;
    transform: translate(0, -58%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btn-bkinfo.-anker:hover::before {
    right: 12px;
  }
}

.btn-bkinfo.-blank::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.6rem;
  content: "";
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btn-bkinfo.-blank::before {
    right: 12px;
  }
}

.btn-bkinfo.-blank.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
}

.btn-bkinfo.-blank.hover::before {
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btn-bkinfo.-blank.hover::before {
    right: 12px;
  }
}

@media print, screen and (min-width: 768px) {
  .btn-bkinfo.-blank:hover {
    background-color: #fff;
  }
  .btn-bkinfo.-blank:hover::before {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btn-bkinfo.-blank:hover::before {
    right: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-bkinfo.-dl {
    padding-right: 40px;
  }
}

.btn-bkinfo.-dl::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.6rem;
  content: "";
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btn-bkinfo.-dl::before {
    right: 12px;
  }
}

.btn-bkinfo.-dl.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
}

.btn-bkinfo.-dl.hover::before {
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btn-bkinfo.-dl.hover::before {
    right: 12px;
  }
}

@media print, screen and (min-width: 768px) {
  .btn-bkinfo.-dl:hover {
    background-color: #fff;
  }
  .btn-bkinfo.-dl:hover::before {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btn-bkinfo.-dl:hover::before {
    right: 12px;
  }
}

/* ============================================================
	btnw
============================================================ */
.btnw {
  display: inline-block;
  position: relative;
  padding: 20px 50px 20px 40px;
  color: #36357e;
  font-size: 1.8rem;
  line-height: 1.4;
  text-align: center;
  border-radius: 8px;
  transition: all .3s;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #36357e;
}

_:-ms-fullscreen, :root .btnw {
  padding-top: 27px;
}

@media only screen and (max-width: 767px) {
  .btnw {
    padding: 10px 35px 10px 15px;
    font-size: 1.6rem;
  }
  _:-ms-fullscreen, :root .btnw {
    padding-top: 17px;
  }
}

.btnw:visited {
  color: #36357e;
}

.btnw::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.2rem;
  content: "";
  transition: right .1s;
}

@media only screen and (max-width: 767px) {
  .btnw::before {
    right: 10px;
  }
}

.btnw.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-color: #36357e;
  color: #fff;
  text-decoration: none;
}

.btnw.hover::before {
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btnw.hover::before {
    right: 8px;
  }
}

@media print, screen and (min-width: 768px) {
  .btnw:hover {
    opacity: 1;
    background-color: #36357e;
    color: #fff;
    text-decoration: none;
  }
  .btnw:hover::before {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btnw:hover::before {
    right: 8px;
  }
}

.btnw.-narrow {
  padding-top: 10px;
  padding-bottom: 10px;
}

.btnw.-anker::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.2rem;
  content: "";
  right: 15px;
  transition: top .3s;
}

@media only screen and (max-width: 767px) {
  .btnw.-anker::before {
    right: 12px;
  }
}

.btnw.-anker.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.btnw.-anker.hover::before {
  right: 15px;
  top: 58%;
  transform: translate(0, -58%);
}

@media only screen and (max-width: 767px) {
  .btnw.-anker.hover::before {
    right: 12px;
  }
}

@media print, screen and (min-width: 768px) {
  .btnw.-anker:hover::before {
    right: 15px;
    top: 58%;
    transform: translate(0, -58%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btnw.-anker:hover::before {
    right: 12px;
  }
}

.btnw.-blank::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.6rem;
  content: "";
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btnw.-blank::before {
    right: 12px;
  }
}

.btnw.-blank.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.btnw.-blank.hover::before {
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btnw.-blank.hover::before {
    right: 12px;
  }
}

@media print, screen and (min-width: 768px) {
  .btnw.-blank:hover::before {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btnw.-blank:hover::before {
    right: 12px;
  }
}

.btnw-publi {
  display: inline-block;
  position: relative;
  padding: 20px 50px 20px 40px;
  color: #36357e;
  font-size: 1.8rem;
  line-height: 1.4;
  text-align: center;
  border-radius: 8px;
  transition: all .3s;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #36357e;
  color: #e62814;
  border: 1px solid #e62814;
}

_:-ms-fullscreen, :root .btnw-publi {
  padding-top: 27px;
}

@media only screen and (max-width: 767px) {
  .btnw-publi {
    padding: 10px 35px 10px 15px;
    font-size: 1.6rem;
  }
  _:-ms-fullscreen, :root .btnw-publi {
    padding-top: 17px;
  }
}

.btnw-publi:visited {
  color: #36357e;
}

.btnw-publi::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.2rem;
  content: "";
  transition: right .1s;
}

@media only screen and (max-width: 767px) {
  .btnw-publi::before {
    right: 10px;
  }
}

.btnw-publi.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-color: #36357e;
  color: #fff;
  text-decoration: none;
}

.btnw-publi.hover::before {
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btnw-publi.hover::before {
    right: 8px;
  }
}

@media print, screen and (min-width: 768px) {
  .btnw-publi:hover {
    opacity: 1;
    background-color: #36357e;
    color: #fff;
    text-decoration: none;
  }
  .btnw-publi:hover::before {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btnw-publi:hover::before {
    right: 8px;
  }
}

.btnw-publi:visited {
  color: #e62814;
}

.btnw-publi.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #e62814;
  color: #fff;
}

@media print, screen and (min-width: 768px) {
  .btnw-publi:hover {
    background-color: #e62814;
    color: #fff;
  }
}

.btnw-publi.-anker::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.2rem;
  content: "";
  right: 15px;
  transition: top .3s;
}

@media only screen and (max-width: 767px) {
  .btnw-publi.-anker::before {
    right: 12px;
  }
}

.btnw-publi.-anker.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;
  background-color: #e62814;
}

.btnw-publi.-anker.hover::before {
  right: 15px;
  top: 58%;
  transform: translate(0, -58%);
}

@media only screen and (max-width: 767px) {
  .btnw-publi.-anker.hover::before {
    right: 12px;
  }
}

@media print, screen and (min-width: 768px) {
  .btnw-publi.-anker:hover {
    text-decoration: none;
    background-color: #e62814;
  }
  .btnw-publi.-anker:hover::before {
    right: 15px;
    top: 58%;
    transform: translate(0, -58%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btnw-publi.-anker:hover::before {
    right: 12px;
  }
}

.btnw-publi.-blank::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.6rem;
  content: "";
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btnw-publi.-blank::before {
    right: 12px;
  }
}

.btnw-publi.-blank.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #e62814;
}

.btnw-publi.-blank.hover::before {
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btnw-publi.-blank.hover::before {
    right: 12px;
  }
}

@media print, screen and (min-width: 768px) {
  .btnw-publi.-blank:hover {
    background-color: #e62814;
  }
  .btnw-publi.-blank:hover::before {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btnw-publi.-blank:hover::before {
    right: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .btnw-publi.-dl {
    padding-right: 40px;
  }
}

.btnw-publi.-dl::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.6rem;
  content: "";
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btnw-publi.-dl::before {
    right: 12px;
  }
}

.btnw-publi.-dl.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #e62814;
}

.btnw-publi.-dl.hover::before {
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btnw-publi.-dl.hover::before {
    right: 12px;
  }
}

@media print, screen and (min-width: 768px) {
  .btnw-publi.-dl:hover {
    background-color: #e62814;
  }
  .btnw-publi.-dl:hover::before {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btnw-publi.-dl:hover::before {
    right: 12px;
  }
}

.btnw-bkinfo {
  display: inline-block;
  position: relative;
  padding: 20px 50px 20px 40px;
  color: #36357e;
  font-size: 1.8rem;
  line-height: 1.4;
  text-align: center;
  border-radius: 8px;
  transition: all .3s;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #36357e;
  color: #ff9e00;
  border: 1px solid #ff9e00;
}

_:-ms-fullscreen, :root .btnw-bkinfo {
  padding-top: 27px;
}

@media only screen and (max-width: 767px) {
  .btnw-bkinfo {
    padding: 10px 35px 10px 15px;
    font-size: 1.6rem;
  }
  _:-ms-fullscreen, :root .btnw-bkinfo {
    padding-top: 17px;
  }
}

.btnw-bkinfo:visited {
  color: #36357e;
}

.btnw-bkinfo::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.2rem;
  content: "";
  transition: right .1s;
}

@media only screen and (max-width: 767px) {
  .btnw-bkinfo::before {
    right: 10px;
  }
}

.btnw-bkinfo.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-color: #36357e;
  color: #fff;
  text-decoration: none;
}

.btnw-bkinfo.hover::before {
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .btnw-bkinfo.hover::before {
    right: 8px;
  }
}

@media print, screen and (min-width: 768px) {
  .btnw-bkinfo:hover {
    opacity: 1;
    background-color: #36357e;
    color: #fff;
    text-decoration: none;
  }
  .btnw-bkinfo:hover::before {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .btnw-bkinfo:hover::before {
    right: 8px;
  }
}

.btnw-bkinfo:visited {
  color: #ff9e00;
}

.btnw-bkinfo.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #ff9e00;
  color: #fff;
}

@media print, screen and (min-width: 768px) {
  .btnw-bkinfo:hover {
    background-color: #ff9e00;
    color: #fff;
  }
}

/* ============================================================
	link
============================================================ */
.link-box {
  display: block;
  margin-top: 20px;
}

.link-box + .link-box {
  margin-top: 15px;
}

.txtlink {
  text-decoration: underline;
  text-indent: 0;
}

.txtlink.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .txtlink:hover {
    text-decoration: none;
  }
}

.link-arrow {
  display: inline-block;
  position: relative;
  margin-right: 5px;
  padding-right: 15px;
  line-height: 1.2;
  text-decoration: none !important;
}

.link-arrow::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.2rem;
  content: "";
  right: 0;
  font-size: 1rem;
  transition: right .1s;
}

.link-arrow.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: underline !important;
}

.link-arrow.hover::before {
  right: -5px;
}

@media print, screen and (min-width: 768px) {
  .link-arrow:hover {
    text-decoration: underline !important;
  }
  .link-arrow:hover::before {
    right: -5px;
  }
}

.link-arrow.-publi {
  color: #e62814;
}

.link-arrow.-bkinfo {
  color: #ff9e00;
}

/* ============================================================
	indent
============================================================ */
.indent {
  padding-left: 1em;
  text-indent: -1em;
  text-align: left;
  line-height: 1.5;
}

.indent + .indent {
  margin-top: 5px;
}

.indent-list > li {
  padding-left: 1em;
  text-indent: -1em;
  text-align: left;
  margin-top: 5px;
  line-height: 1.5;
}

.indent-list > li:first-of-type {
  margin-top: 0;
}

.indent-multilist > li {
  position: relative;
  margin: 5px 0 0 0;
  display: table;
  line-height: 1.5;
  text-align: left;
}

.indent-multilist > li > span {
  display: table-cell;
}

.indent-multilist > li > span:first-child {
  white-space: nowrap;
}

.indent-multilist > li > div {
  display: table-cell;
}

.indent-multilist > li > div:first-child {
  white-space: nowrap;
}

.indent-multilist.-decimal > li > span:first-child {
  width: 2.3rem;
  text-align: right;
  padding-right: 0.4rem;
}

.note {
  padding-left: 1em;
  text-indent: -1em;
  text-align: left;
  margin-top: 15px;
  font-size: 1.2rem !important;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .note {
    font-size: 1rem !important;
    line-height: 1.4;
  }
}

.note + .note {
  margin-top: 5px;
}

.note-list {
  margin-top: 15px;
}

.note-list > li {
  position: relative;
  margin: 5px 0 0 0;
  display: table;
  font-size: 1.2rem;
  line-height: 1.4;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .note-list > li {
    font-size: 1rem;
  }
}

.note-list > li > span {
  display: table-cell;
}

.note-list > li > span:first-child {
  white-space: nowrap;
}

/* ============================================================
	list
============================================================ */
.decimal-list {
  list-style-type: decimal;
  padding-left: 40px;
}

.decimal-list > li {
  margin-top: 5px;
  line-height: 1.5;
}

.decimal-list > li:first-of-type {
  margin-top: 0;
}

/* ============================================================
	column
============================================================ */
.column2-wrap {
  display: flex;
  justify-content: space-between;
}

.column2-wrap > .item {
  width: 48%;
}

@media only screen and (max-width: 767px) {
  .column2-wrap {
    display: block;
  }
  .column2-wrap > .item {
    width: 100%;
  }
  .column2-wrap > .item:first-of-type {
    margin-bottom: 20px;
  }
}

.pcflexbw-wrap {
  display: flex;
  justify-content: space-between;
}

.pcflexbw-wrap > .item {
  width: auto;
}

@media only screen and (max-width: 767px) {
  .pcflexbw-wrap {
    display: block;
  }
  .pcflexbw-wrap > .item {
    width: 100%;
    margin-top: 20px;
  }
}

/* ============================================================
	pc-sp change
============================================================ */
/* PC時表示 */
.size-pc {
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .size-pc {
    display: none !important;
  }
}

/* 480px以上表示 */
.size-pcsx {
  display: inline-block;
}

@media only screen and (max-width: 479px) {
  .size-pcsx {
    display: none !important;
  }
}

/* SP時表示 */
.size-sp {
  display: none;
}

@media only screen and (max-width: 767px) {
  .size-sp {
    display: inline-block !important;
  }
}

/* 700px以下表示 */
.size-spmx {
  display: none;
}

@media only screen and (max-width: 700px) {
  .size-spmx {
    display: inline-block !important;
  }
}

/* 479px以下表示 */
.size-spsx {
  display: none;
}

@media only screen and (max-width: 479px) {
  .size-spsx {
    display: inline-block !important;
  }
}

/* 375px以下表示 */
.size-spxsx {
  display: none;
}

@media only screen and (max-width: 375px) {
  .size-spxsx {
    display: inline-block !important;
  }
}

/* 360px以下表示 */
.size-spaxsx {
  display: none;
}

@media only screen and (max-width: 360px) {
  .size-spaxsx {
    display: inline-block !important;
  }
}

/* 320px以下表示 */
.size-spxxsx {
  display: none;
}

@media only screen and (max-width: 320px) {
  .size-spxxsx {
    display: inline-block !important;
  }
}

/* ============================================================
	marker
============================================================ */
.marker-red {
  background: linear-gradient(transparent 90%, #ff7f7f 90%);
}

.marker-blue {
  background: linear-gradient(transparent 90%, #7fbfff 90%);
}

.marker-yellow {
  background: linear-gradient(transparent 90%, #ffdc00 90%);
}

.marker-purple {
  background: linear-gradient(transparent 90%, #ce9eff 90%);
}

/* ============================================================
	bgbox
============================================================ */
.box-base {
  margin: 30px 0;
  padding: 16px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .box-base {
    padding: 8px;
  }
}

.box-base.-bgred {
  background-color: #fbded9;
}

/* ============================================================
	icon addition
============================================================ */
[class^="icon-"]::before, [class*=" icon-"]::before {
  display: inline-block;
}

[class^="icon-"]::before.hover, [class*=" icon-"]::before.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none !important;
}

@media print, screen and (min-width: 768px) {
  [class^="icon-"]::before:hover, [class*=" icon-"]::before:hover {
    text-decoration: none !important;
  }
}

.icon-blank {
  margin: 0 3px;
}

.icon-blank::before {
  font-size: 1.2rem;
}

/* ============================================================
	Helper - font-size
============================================================ */
._fs-10 {
  font-size: 1.0rem !important;
}

._fs-11 {
  font-size: 1.1rem !important;
}

._fs-12 {
  font-size: 1.2rem !important;
}

._fs-13 {
  font-size: 1.3rem !important;
}

._fs-14 {
  font-size: 1.4rem !important;
}

._fs-15 {
  font-size: 1.5rem !important;
}

._fs-16 {
  font-size: 1.6rem !important;
}

._fs-17 {
  font-size: 1.7rem !important;
}

._fs-18 {
  font-size: 1.8rem !important;
}

._fs-19 {
  font-size: 1.9rem !important;
}

._fs-20 {
  font-size: 2.0rem !important;
}

._fs-22 {
  font-size: 2.2rem !important;
}

._fs-24 {
  font-size: 2.4rem !important;
}

._fs-26 {
  font-size: 2.6rem !important;
}

._fs-28 {
  font-size: 2.8rem !important;
}

._fs-30 {
  font-size: 3rem !important;
}

@media only screen and (max-width: 767px) {
  ._spfs-10 {
    font-size: 1.0rem !important;
  }
  ._spfs-11 {
    font-size: 1.1rem !important;
  }
  ._spfs-12 {
    font-size: 1.2rem !important;
  }
  ._spfs-13 {
    font-size: 1.3rem !important;
  }
  ._spfs-14 {
    font-size: 1.4rem !important;
  }
  ._spfs-15 {
    font-size: 1.5rem !important;
  }
  ._spfs-16 {
    font-size: 1.6rem !important;
  }
  ._spfs-17 {
    font-size: 1.7rem !important;
  }
  ._spfs-18 {
    font-size: 1.8rem !important;
  }
  ._spfs-19 {
    font-size: 1.9rem !important;
  }
  ._spfs-20 {
    font-size: 2.0rem !important;
  }
  ._spfs-22 {
    font-size: 2.2rem !important;
  }
  ._spfs-24 {
    font-size: 2.4rem !important;
  }
  ._spfs-26 {
    font-size: 2.6rem !important;
  }
  ._spfs-28 {
    font-size: 2.8rem !important;
  }
  ._spfs-30 {
    font-size: 3rem !important;
  }
}

/* ============================================================
	Helper - line-height
============================================================ */
._lh-10 {
  line-height: 1.0 !important;
}

._lh-11 {
  line-height: 1.1 !important;
}

._lh-12 {
  line-height: 1.2 !important;
}

._lh-13 {
  line-height: 1.3 !important;
}

._lh-14 {
  line-height: 1.4 !important;
}

._lh-15 {
  line-height: 1.5 !important;
}

._lh-16 {
  line-height: 1.6 !important;
}

._lh-17 {
  line-height: 1.7 !important;
}

._lh-18 {
  line-height: 1.8 !important;
}

._lh-19 {
  line-height: 1.9 !important;
}

._lh-20 {
  line-height: 2.0 !important;
}

@media only screen and (max-width: 767px) {
  ._splh-10 {
    line-height: 1.0 !important;
  }
  ._splh-11 {
    line-height: 1.1 !important;
  }
  ._splh-12 {
    line-height: 1.2 !important;
  }
  ._splh-13 {
    line-height: 1.3 !important;
  }
  ._splh-14 {
    line-height: 1.4 !important;
  }
  ._splh-15 {
    line-height: 1.5 !important;
  }
  ._splh-16 {
    line-height: 1.6 !important;
  }
  ._splh-17 {
    line-height: 1.7 !important;
  }
  ._splh-18 {
    line-height: 1.8 !important;
  }
  ._splh-19 {
    line-height: 1.9 !important;
  }
  ._splh-20 {
    line-height: 2.0 !important;
  }
}

/* ============================================================
	Helper - font-weight
============================================================ */
._bold, strong, b {
  font-weight: 700 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._normal {
  font-weight: 500 !important;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
}

/* ============================================================
	Helper - font-color
============================================================ */
._red {
  color: #f00 !important;
}

._pink {
  color: #ff8282 !important;
}

._white {
  color: #fff !important;
}

._black {
  color: #000 !important;
}

/* ============================================================
	Helper - text
============================================================ */
._shadow {
  text-shadow: 0 0 3px #000;
}

._ta-c {
  text-align: center !important;
}

._ta-l {
  text-align: left !important;
}

._ta-r {
  text-align: right !important;
}

@media only screen and (max-width: 767px) {
  ._spta-c {
    text-align: center !important;
  }
  ._spta-l {
    text-align: left !important;
  }
  ._spta-r {
    text-align: right !important;
  }
}

/* ============================================================
	Helper - display
============================================================ */
._block {
  display: block !important;
}

._inbl {
  display: inline-block !important;
}

._none {
  display: none !important;
}

._flex {
  display: flex !important;
}

._table {
  display: table !important;
}

._tbcl {
  display: table-cell !important;
}

@media only screen and (max-width: 767px) {
  ._sp-block {
    display: block !important;
  }
  ._sp-none {
    display: none !important;
  }
  ._sp-flex {
    display: flex !important;
  }
  ._sp-table {
    display: table !important;
  }
  ._sp-tbcl {
    display: table-cell !important;
  }
}

/* ============================================================
	Helper - other
============================================================ */
._bd {
  border: 1px solid #ccc !important;
}

._bd-none {
  border: none !important;
}

._bd-top {
  border-top: 1px solid #ccc !important;
}

._wd-a {
  width: auto !important;
}

._hg-a {
  height: auto !important;
}

._palt {
  font-feature-settings: "palt" !important;
}

/* ============================================================
	Helper - margin: 0 auto
============================================================ */
._mg-0a {
  margin: 0 auto !important;
}

/* PCのみ */
@media print, screen and (min-width: 768px) {
  ._pcmg-0a {
    margin: 0 auto !important;
  }
}

/* SPのみ */
@media only screen and (max-width: 767px) {
  ._spmg-0a {
    margin: 0 auto !important;
  }
}

/* ============================================================
	Helper - margin
============================================================ */
._ml-1em {
  margin-left: 1em !important;
}

._mg-0 {
  margin: 0 !important;
}

._mt-0 {
  margin-top: 0 !important;
}

._mt-5 {
  margin-top: 5px !important;
}

._mt-10 {
  margin-top: 10px !important;
}

._mt-15 {
  margin-top: 15px !important;
}

._mt-20 {
  margin-top: 20px !important;
}

._mt-25 {
  margin-top: 25px !important;
}

._mt-30 {
  margin-top: 30px !important;
}

._mt-35 {
  margin-top: 35px !important;
}

._mt-40 {
  margin-top: 40px !important;
}

._mt-45 {
  margin-top: 45px !important;
}

._mt-50 {
  margin-top: 50px !important;
}

._mt-55 {
  margin-top: 55px !important;
}

._mt-60 {
  margin-top: 60px !important;
}

._mb-0 {
  margin-bottom: 0 !important;
}

._mb-5 {
  margin-bottom: 5px !important;
}

._mb-10 {
  margin-bottom: 10px !important;
}

._mb-15 {
  margin-bottom: 15px !important;
}

._mb-20 {
  margin-bottom: 20px !important;
}

._mb-25 {
  margin-bottom: 25px !important;
}

._mb-30 {
  margin-bottom: 30px !important;
}

._mb-35 {
  margin-bottom: 35px !important;
}

._mb-40 {
  margin-bottom: 40px !important;
}

._mb-45 {
  margin-bottom: 45px !important;
}

._mb-50 {
  margin-bottom: 50px !important;
}

._mb-55 {
  margin-bottom: 55px !important;
}

._mb-60 {
  margin-bottom: 60px !important;
}

._ml-0 {
  margin-left: 0 !important;
}

._ml-5 {
  margin-left: 5px !important;
}

._ml-10 {
  margin-left: 10px !important;
}

._ml-15 {
  margin-left: 15px !important;
}

._ml-20 {
  margin-left: 20px !important;
}

._ml-25 {
  margin-left: 25px !important;
}

._ml-30 {
  margin-left: 30px !important;
}

._ml-35 {
  margin-left: 35px !important;
}

._ml-40 {
  margin-left: 40px !important;
}

._ml-45 {
  margin-left: 45px !important;
}

._ml-50 {
  margin-left: 50px !important;
}

._ml-55 {
  margin-left: 55px !important;
}

._ml-60 {
  margin-left: 60px !important;
}

._mr-0 {
  margin-right: 0 !important;
}

._mr-5 {
  margin-right: 5px !important;
}

._mr-10 {
  margin-right: 10px !important;
}

._mr-15 {
  margin-right: 15px !important;
}

._mr-20 {
  margin-right: 20px !important;
}

._mr-25 {
  margin-right: 25px !important;
}

._mr-30 {
  margin-right: 30px !important;
}

._mr-35 {
  margin-right: 35px !important;
}

._mr-40 {
  margin-right: 40px !important;
}

._mr-45 {
  margin-right: 45px !important;
}

._mr-50 {
  margin-right: 50px !important;
}

._mr-55 {
  margin-right: 55px !important;
}

._mr-60 {
  margin-right: 60px !important;
}

/* ============================================================
	Helper - margin-sp
============================================================ */
@media only screen and (max-width: 767px) {
  ._spmg-0 {
    margin: 0 !important;
  }
  ._spmt-0 {
    margin-top: 0 !important;
  }
  ._spmt-5 {
    margin-top: 5px !important;
  }
  ._spmt-10 {
    margin-top: 10px !important;
  }
  ._spmt-15 {
    margin-top: 15px !important;
  }
  ._spmt-20 {
    margin-top: 20px !important;
  }
  ._spmt-25 {
    margin-top: 25px !important;
  }
  ._spmt-30 {
    margin-top: 30px !important;
  }
  ._spmt-35 {
    margin-top: 35px !important;
  }
  ._spmt-40 {
    margin-top: 40px !important;
  }
  ._spmt-45 {
    margin-top: 45px !important;
  }
  ._spmt-50 {
    margin-top: 50px !important;
  }
  ._spmt-55 {
    margin-top: 55px !important;
  }
  ._spmt-60 {
    margin-top: 60px !important;
  }
  ._spmb-0 {
    margin-bottom: 0 !important;
  }
  ._spmb-5 {
    margin-bottom: 5px !important;
  }
  ._spmb-10 {
    margin-bottom: 10px !important;
  }
  ._spmb-15 {
    margin-bottom: 15px !important;
  }
  ._spmb-20 {
    margin-bottom: 20px !important;
  }
  ._spmb-25 {
    margin-bottom: 25px !important;
  }
  ._spmb-30 {
    margin-bottom: 30px !important;
  }
  ._spmb-35 {
    margin-bottom: 35px !important;
  }
  ._spmb-40 {
    margin-bottom: 40px !important;
  }
  ._spmb-45 {
    margin-bottom: 45px !important;
  }
  ._spmb-50 {
    margin-bottom: 50px !important;
  }
  ._spmb-55 {
    margin-bottom: 55px !important;
  }
  ._spmb-60 {
    margin-bottom: 60px !important;
  }
  ._spml-0 {
    margin-left: 0 !important;
  }
  ._spml-5 {
    margin-left: 5px !important;
  }
  ._spml-10 {
    margin-left: 10px !important;
  }
  ._spml-15 {
    margin-left: 15px !important;
  }
  ._spml-20 {
    margin-left: 20px !important;
  }
  ._spml-25 {
    margin-left: 25px !important;
  }
  ._spml-30 {
    margin-left: 30px !important;
  }
  ._spml-35 {
    margin-left: 35px !important;
  }
  ._spml-40 {
    margin-left: 40px !important;
  }
  ._spml-45 {
    margin-left: 45px !important;
  }
  ._spml-50 {
    margin-left: 50px !important;
  }
  ._spml-55 {
    margin-left: 55px !important;
  }
  ._spml-60 {
    margin-left: 60px !important;
  }
  ._spmr-0 {
    margin-right: 0 !important;
  }
  ._spmr-5 {
    margin-right: 5px !important;
  }
  ._spmr-10 {
    margin-right: 10px !important;
  }
  ._spmr-15 {
    margin-right: 15px !important;
  }
  ._spmr-20 {
    margin-right: 20px !important;
  }
  ._spmr-25 {
    margin-right: 25px !important;
  }
  ._spmr-30 {
    margin-right: 30px !important;
  }
  ._spmr-35 {
    margin-right: 35px !important;
  }
  ._spmr-40 {
    margin-right: 40px !important;
  }
  ._spmr-45 {
    margin-right: 45px !important;
  }
  ._spmr-50 {
    margin-right: 50px !important;
  }
  ._spmr-55 {
    margin-right: 55px !important;
  }
  ._spmr-60 {
    margin-right: 60px !important;
  }
}

/* ============================================================
	Helper - padding
============================================================ */
._pg-0 {
  padding: 0 !important;
}

._pt-0 {
  padding-top: 0 !important;
}

._pt-5 {
  padding-top: 5px !important;
}

._pt-10 {
  padding-top: 10px !important;
}

._pt-15 {
  padding-top: 15px !important;
}

._pt-20 {
  padding-top: 20px !important;
}

._pt-25 {
  padding-top: 25px !important;
}

._pt-30 {
  padding-top: 30px !important;
}

._pt-35 {
  padding-top: 35px !important;
}

._pt-40 {
  padding-top: 40px !important;
}

._pt-45 {
  padding-top: 45px !important;
}

._pt-50 {
  padding-top: 50px !important;
}

._pt-55 {
  padding-top: 55px !important;
}

._pt-60 {
  padding-top: 60px !important;
}

._pb-0 {
  padding-bottom: 0 !important;
}

._pb-5 {
  padding-bottom: 5px !important;
}

._pb-10 {
  padding-bottom: 10px !important;
}

._pb-15 {
  padding-bottom: 15px !important;
}

._pb-20 {
  padding-bottom: 20px !important;
}

._pb-25 {
  padding-bottom: 25px !important;
}

._pb-30 {
  padding-bottom: 30px !important;
}

._pb-35 {
  padding-bottom: 35px !important;
}

._pb-40 {
  padding-bottom: 40px !important;
}

._pb-45 {
  padding-bottom: 45px !important;
}

._pb-50 {
  padding-bottom: 50px !important;
}

._pb-55 {
  padding-bottom: 55px !important;
}

._pb-60 {
  padding-bottom: 60px !important;
}

._pl-0 {
  padding-left: 0 !important;
}

._pl-5 {
  padding-left: 5px !important;
}

._pl-10 {
  padding-left: 10px !important;
}

._pl-15 {
  padding-left: 15px !important;
}

._pl-20 {
  padding-left: 20px !important;
}

._pl-25 {
  padding-left: 25px !important;
}

._pl-30 {
  padding-left: 30px !important;
}

._pl-35 {
  padding-left: 35px !important;
}

._pl-40 {
  padding-left: 40px !important;
}

._pl-45 {
  padding-left: 45px !important;
}

._pl-50 {
  padding-left: 50px !important;
}

._pl-55 {
  padding-left: 55px !important;
}

._pl-60 {
  padding-left: 60px !important;
}

._pr-0 {
  padding-right: 0 !important;
}

._pr-5 {
  padding-right: 5px !important;
}

._pr-10 {
  padding-right: 10px !important;
}

._pr-15 {
  padding-right: 15px !important;
}

._pr-20 {
  padding-right: 20px !important;
}

._pr-25 {
  padding-right: 25px !important;
}

._pr-30 {
  padding-right: 30px !important;
}

._pr-35 {
  padding-right: 35px !important;
}

._pr-40 {
  padding-right: 40px !important;
}

._pr-45 {
  padding-right: 45px !important;
}

._pr-50 {
  padding-right: 50px !important;
}

._pr-55 {
  padding-right: 55px !important;
}

._pr-60 {
  padding-right: 60px !important;
}

/* ============================================================
	Helper - padding-sp
============================================================ */
@media only screen and (max-width: 767px) {
  ._sppg-0 {
    padding: 0 !important;
  }
  ._sppt-0 {
    padding-top: 0 !important;
  }
  ._sppt-5 {
    padding-top: 5px !important;
  }
  ._sppt-10 {
    padding-top: 10px !important;
  }
  ._sppt-15 {
    padding-top: 15px !important;
  }
  ._sppt-20 {
    padding-top: 20px !important;
  }
  ._sppt-25 {
    padding-top: 25px !important;
  }
  ._sppt-30 {
    padding-top: 30px !important;
  }
  ._sppt-35 {
    padding-top: 35px !important;
  }
  ._sppt-40 {
    padding-top: 40px !important;
  }
  ._sppt-45 {
    padding-top: 45px !important;
  }
  ._sppt-50 {
    padding-top: 50px !important;
  }
  ._sppt-55 {
    padding-top: 55px !important;
  }
  ._sppt-60 {
    padding-top: 60px !important;
  }
  ._sppb-0 {
    padding-bottom: 0 !important;
  }
  ._sppb-5 {
    padding-bottom: 5px !important;
  }
  ._sppb-10 {
    padding-bottom: 10px !important;
  }
  ._sppb-15 {
    padding-bottom: 15px !important;
  }
  ._sppb-20 {
    padding-bottom: 20px !important;
  }
  ._sppb-25 {
    padding-bottom: 25px !important;
  }
  ._sppb-30 {
    padding-bottom: 30px !important;
  }
  ._sppb-35 {
    padding-bottom: 35px !important;
  }
  ._sppb-40 {
    padding-bottom: 40px !important;
  }
  ._sppb-45 {
    padding-bottom: 45px !important;
  }
  ._sppb-50 {
    padding-bottom: 50px !important;
  }
  ._sppb-55 {
    padding-bottom: 55px !important;
  }
  ._sppb-60 {
    padding-bottom: 60px !important;
  }
  ._sppl-0 {
    padding-left: 0 !important;
  }
  ._sppl-5 {
    padding-left: 5px !important;
  }
  ._sppl-10 {
    padding-left: 10px !important;
  }
  ._sppl-15 {
    padding-left: 15px !important;
  }
  ._sppl-20 {
    padding-left: 20px !important;
  }
  ._sppl-25 {
    padding-left: 25px !important;
  }
  ._sppl-30 {
    padding-left: 30px !important;
  }
  ._sppl-35 {
    padding-left: 35px !important;
  }
  ._sppl-40 {
    padding-left: 40px !important;
  }
  ._sppl-45 {
    padding-left: 45px !important;
  }
  ._sppl-50 {
    padding-left: 50px !important;
  }
  ._sppl-55 {
    padding-left: 55px !important;
  }
  ._sppl-60 {
    padding-left: 60px !important;
  }
  ._sppr-0 {
    padding-right: 0 !important;
  }
  ._sppr-5 {
    padding-right: 5px !important;
  }
  ._sppr-10 {
    padding-right: 10px !important;
  }
  ._sppr-15 {
    padding-right: 15px !important;
  }
  ._sppr-20 {
    padding-right: 20px !important;
  }
  ._sppr-25 {
    padding-right: 25px !important;
  }
  ._sppr-30 {
    padding-right: 30px !important;
  }
  ._sppr-35 {
    padding-right: 35px !important;
  }
  ._sppr-40 {
    padding-right: 40px !important;
  }
  ._sppr-45 {
    padding-right: 45px !important;
  }
  ._sppr-50 {
    padding-right: 50px !important;
  }
  ._sppr-55 {
    padding-right: 55px !important;
  }
  ._sppr-60 {
    padding-right: 60px !important;
  }
}

/* ============================================================
	header
============================================================ */
header {
  position: fixed;
  top: 0;
  padding: 4px 0 0;
  width: 100%;
  height: 105px;
  background: #fff;
  border-bottom: 1px solid #ccc;
  transition: all .3s;
  z-index: 100;
}

@media only screen and (max-width: 1160px) {
  header {
    width: 1160px;
  }
}

@media only screen and (max-width: 767px) {
  header {
    padding: 0;
    width: 100%;
    height: 56px;
  }
}

@media print, screen and (min-width: 768px) {
  header.mgdr-publi, header.mgdr-bkinfo, header.mgdr-about {
    height: 204px;
    border-bottom: none;
  }
}

.header-inner {
  position: relative;
  margin: 0 auto;
  width: 100%;
}

@media print, screen and (min-width: 768px) {
  /* ============================================================
	global-header, global-search, global-nav
============================================================ */
  .global-header {
    position: relative;
    margin: 0 auto;
    width: 1080px;
    height: 100px;
  }
  .global-header > .logo {
    position: absolute;
    top: 22px;
    left: 0;
    width: 162px;
    height: 38px;
  }
  .global-header > .lead {
    position: absolute;
    top: 2px;
    left: 0;
    color: #333;
    font-size: 1.2rem;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all .3s;
  }
  .global-header > .tel-box {
    position: absolute;
    top: 22px;
    left: 180px;
    color: #333;
    transition: all .3s;
  }
  .global-header > .tel-box .time {
    font-size: 1.2rem;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .global-header > .tel-box .time > .label {
    margin-left: 3px;
    padding: 2px 6px 3px;
    color: #fff;
    background-color: #8b92c7;
    border-radius: 2px;
  }
  _:-ms-fullscreen, :root .global-header > .tel-box .time > .label {
    padding: 2px 6px 0;
    font-size: 1rem;
  }
  .global-header > .tel-box .tel {
    margin-top: 6px;
    font-size: 2rem;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
  }
  @supports (-ms-ime-align: auto) {
    .global-header > .tel-box .time .label {
      padding: 1px 6px;
      font-size: 1rem;
    }
  }
  .global-search {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 0;
    transition: all .3s;
  }
  .global-search > li {
    white-space: nowrap;
  }
  .global-search input[type="text"] {
    margin: 0;
    width: 180px !important;
    height: 26px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .global-search input[type="text"] {
    width: 82% !important;
  }
}

@media print, screen and (min-width: 768px) {
  .global-search .btn-search {
    display: inline-block;
    position: relative;
    margin: 0 0 0 5px;
    padding: 5px 10px;
    color: #fff;
    font-size: 1.4rem;
    text-align: center;
    line-height: 1;
    border-radius: 4px;
    transition: all .3s;
    cursor: pointer;
    background-color: #36357e;
    border: 1px solid #36357e;
  }
  _:-ms-fullscreen, :root .global-search .btn-search {
    padding-top: 12px;
  }
  .global-search .btn-search:visited {
    color: #fff;
  }
  .global-search .btn-search.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    opacity: 1;
    background-color: #fff;
    color: #36357e;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) and (min-width: 768px) {
  .global-search .btn-search:hover {
    opacity: 1;
    background-color: #fff;
    color: #36357e;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) {
  .global-searchbtn, .global-searchsp, .global-searchsp.-open {
    display: none !important;
  }
  .global-nav {
    display: flex;
    position: absolute;
    top: 50px;
    right: 0;
    transition: all .3s;
  }
  .global-nav > li {
    position: relative;
    margin-left: 25px;
  }
  .global-nav > li > .ctg {
    display: block;
    text-align: center;
    color: #333;
  }
  .global-nav > li > .ctg:visited {
    color: #333;
  }
  .global-nav > li > .ctg.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #333;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) and (min-width: 768px) {
  .global-nav > li > .ctg:hover {
    color: #333;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) {
  .global-nav > li > .ctg [class^="icon-"] {
    margin-right: 2px;
  }
  .global-nav > li > .ctg .icon-publishing {
    margin-right: 0;
  }
  .global-nav > li > .ctg > .ttl {
    margin-bottom: 5px;
    font-size: 1.6rem;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .global-nav > li > .ctg > .eng {
    color: #555;
    font-size: 1rem;
    font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
    font-weight: 400;
  }
  .global-nav > li > .ctg::after, .global-nav > li > .ctg::before {
    content: '';
    position: absolute;
    top: 115%;
    width: 0;
    height: 2px;
    background: #36357e;
    transition: all .3s;
  }
  .global-nav > li > .ctg::after {
    right: 50%;
  }
  .global-nav > li > .ctg::before {
    left: 50%;
  }
  .global-nav > li > .ctg.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .global-nav > li > .ctg.hover > .ttl {
    color: #36357e;
  }
  .global-nav > li > .ctg.hover::after, .global-nav > li > .ctg.hover::before {
    width: 50%;
  }
}

@media print, screen and (min-width: 768px) and (min-width: 768px) {
  .global-nav > li > .ctg:hover > .ttl {
    color: #36357e;
  }
  .global-nav > li > .ctg:hover::after, .global-nav > li > .ctg:hover::before {
    width: 50%;
  }
}

@media print, screen and (min-width: 768px) {
  .global-nav > li > .ctg.-current > .ttl {
    color: #36357e;
  }
  .global-nav > li > .ctg.-current::after, .global-nav > li > .ctg.-current::before {
    width: 50%;
  }
  /* ============================================================
	header-nav, menu-mgdr
============================================================ */
  .header-nav {
    display: none;
  }
  header[class^="mgdr-"] .header-nav {
    display: block;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    box-shadow: 0 0 8px #ccc;
    transition: all .3s;
  }
  .menu-mgdr {
    display: table;
    table-layout: fixed;
    position: relative;
    margin: 0 auto;
    width: 1080px;
  }
  .menu-mgdr .first {
    display: table-cell;
    padding: 10px;
    height: 100px;
    z-index: 2;
  }
  .menu-mgdr .first > .ctg {
    position: relative;
    display: block;
    height: 80px;
    text-align: center;
    color: #555;
  }
  .menu-mgdr .first > .ctg:visited {
    color: #555;
  }
  .menu-mgdr .first > .ctg.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #555;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) and (min-width: 768px) {
  .menu-mgdr .first > .ctg:hover {
    color: #555;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) {
  .menu-mgdr .first > .ctg.-toplink {
    display: none;
  }
  .menu-mgdr .first > .ctg.-current {
    background: #ece6ef;
  }
  .menu-mgdr .first > .ctg.-current > .ttl {
    color: #6867b0;
  }
  .menu-mgdr .first > .ctg::before {
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    bottom: 0;
    margin: auto 0;
    height: 80px;
    border-right: 1px dotted #ccc;
  }
  .menu-mgdr .first > .ctg > .ttl {
    margin-bottom: 3px;
    font-size: 1.6rem;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .menu-mgdr .first > .ctg > .eng {
    color: #6867b0;
    font-size: 1rem;
    font-style: italic;
    font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
    font-weight: 400;
  }
  .menu-mgdr .first.-bkinfo#bkinfo05 > .ctg::after {
    content: '';
    position: absolute;
    top: 0;
    right: -10px;
    bottom: 0;
    margin: auto 0;
    height: 80px;
    border-right: 1px dotted #ccc;
  }
  .menu-mgdr .first:last-child > .ctg::after {
    content: '';
    position: absolute;
    top: 0;
    right: -10px;
    bottom: 0;
    margin: auto 0;
    height: 80px;
    border-right: 1px dotted #ccc;
  }
  .menu-mgdr .first > .second {
    display: flex;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    padding: 20px 10px;
    width: 1080px;
    background: rgba(70, 70, 70, 0.9);
    text-align: left;
    z-index: 1;
    cursor: default !important;
  }
  .menu-mgdr .first.-bkinfo > .second {
    background: rgba(252, 252, 247, 0.98);
  }
  .menu-mgdr .first > .second > a {
    margin: 0 10px;
    width: 248px;
    text-align: center;
    color: #fff;
  }
  .menu-mgdr .first > .second > a:visited {
    color: #fff;
  }
  .menu-mgdr .first > .second > a.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #fff;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) and (min-width: 768px) {
  .menu-mgdr .first > .second > a:hover {
    color: #fff;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) {
  .menu-mgdr .first > .second > a > .ttl {
    margin-top: 5px;
    font-size: 1.6rem;
    line-height: 1.2;
  }
  .menu-mgdr .first.-bkinfo > .second > a {
    color: #333;
  }
  .menu-mgdr .first.-bkinfo > .second > a:visited {
    color: #333;
  }
  .menu-mgdr .first.-bkinfo > .second > a.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #333;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) and (min-width: 768px) {
  .menu-mgdr .first.-bkinfo > .second > a:hover {
    color: #333;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) {
  .menu-mgdr .first.-bkinfo > .second.-hotitems > a {
    width: 198px;
  }
  .menu-mgdr .first > .second > .list {
    margin-left: 40px;
  }
  .menu-mgdr .first > .second > .list > a {
    position: relative;
    display: table;
    margin: 20px 0;
    width: auto;
    text-align: left;
    color: #fff;
  }
  .menu-mgdr .first > .second > .list > a:visited {
    color: #fff;
  }
  .menu-mgdr .first > .second > .list > a.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #fff;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) and (min-width: 768px) {
  .menu-mgdr .first > .second > .list > a:hover {
    color: #fff;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) {
  .menu-mgdr .first > .second > .list > a > .ttl {
    margin-top: 0;
    padding-right: 15px;
    font-size: 1.6rem;
    line-height: 1;
  }
  .menu-mgdr .first > .second > .list > a::before {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-family: "bungeisha" !important;
    display: inline-block;
    right: 20px;
    font-size: 1.2rem;
    content: "";
    right: 0;
    transition: right .1s;
    font-size: 1rem;
  }
  .menu-mgdr .first > .second > .list > a.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .menu-mgdr .first > .second > .list > a.hover::before {
    right: -5px;
  }
}

@media print, screen and (min-width: 768px) and (min-width: 768px) {
  .menu-mgdr .first > .second > .list > a:hover::before {
    right: -5px;
  }
}

@media print, screen and (min-width: 768px) {
  .menu-mgdr .first > .second.-ebook > .list,
  .menu-mgdr .first > .second.-library > .list {
    margin-left: 60px;
  }
  .menu-mgdr .first > .second.-ebook > .list.-line,
  .menu-mgdr .first > .second.-library > .list.-line {
    margin-left: 10px;
    padding-right: 40px;
    border-right: 1px solid #ccc;
  }
  .menu-mgdr .first > .second.-ebook > .list.-genre:nth-of-type(3), .menu-mgdr .first > .second.-ebook > .list.-genre:nth-of-type(4),
  .menu-mgdr .first > .second.-library > .list.-genre:nth-of-type(3),
  .menu-mgdr .first > .second.-library > .list.-genre:nth-of-type(4) {
    padding-top: 27px;
  }
  .menu-mgdr .first > .second.-ebook > .list.-genre > .title,
  .menu-mgdr .first > .second.-library > .list.-genre > .title {
    margin-bottom: 15px;
    font-size: 1.2rem;
    color: #555;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .menu-mgdr .first > .second.-ebook > .list.-genre > a > .ttl,
  .menu-mgdr .first > .second.-library > .list.-genre > a > .ttl {
    font-size: 1.4rem;
  }
  .menu-mgdr .first > .second.-ebook > .list > a,
  .menu-mgdr .first > .second.-library > .list > a {
    margin: 0 0 15px;
    color: #333;
  }
  .menu-mgdr .first > .second.-ebook > .list > a:visited,
  .menu-mgdr .first > .second.-library > .list > a:visited {
    color: #333;
  }
  .menu-mgdr .first > .second.-ebook > .list > a.hover,
  .menu-mgdr .first > .second.-library > .list > a.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #333;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) and (min-width: 768px) {
  .menu-mgdr .first > .second.-ebook > .list > a:hover,
  .menu-mgdr .first > .second.-library > .list > a:hover {
    color: #333;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) {
  .menu-mgdr .first > .second.-ebook > .list > a:last-child,
  .menu-mgdr .first > .second.-library > .list > a:last-child {
    margin: 0;
  }
  .menu-mgdr .first > .second.-ebook > .list > a > .ttl,
  .menu-mgdr .first > .second.-library > .list > a > .ttl {
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .menu-mgdr .first > .second.-library > .list.-main {
    margin-left: 10px;
    padding-right: 27px;
  }
  .menu-mgdr .first > .second.-library > .list.-main > .box {
    margin-top: 20px;
    padding-top: 4px;
    border-top: 1px solid #ccc;
  }
  .menu-mgdr .first > .second.-library > .list.-main > .box > a {
    position: relative;
    display: table;
    margin: 15px 0 0;
    width: auto;
    text-align: left;
    color: #333;
  }
  .menu-mgdr .first > .second.-library > .list.-main > .box > a:visited {
    color: #333;
  }
  .menu-mgdr .first > .second.-library > .list.-main > .box > a.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #333;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) and (min-width: 768px) {
  .menu-mgdr .first > .second.-library > .list.-main > .box > a:hover {
    color: #333;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) {
  .menu-mgdr .first > .second.-library > .list.-main > .box > a > .ttl {
    margin-top: 0;
    padding-right: 15px;
    font-size: 1.4rem;
    line-height: 1;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .menu-mgdr .first > .second.-library > .list.-main > .box > a::before {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-family: "bungeisha" !important;
    display: inline-block;
    right: 20px;
    font-size: 1.2rem;
    content: "";
    right: 0;
    transition: right .1s;
    font-size: 1rem;
  }
  .menu-mgdr .first > .second.-library > .list.-main > .box > a.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .menu-mgdr .first > .second.-library > .list.-main > .box > a.hover::before {
    right: -5px;
  }
}

@media print, screen and (min-width: 768px) and (min-width: 768px) {
  .menu-mgdr .first > .second.-library > .list.-main > .box > a:hover::before {
    right: -5px;
  }
}

@media print, screen and (min-width: 768px) {
  .menu-mgdr .first > .second.-neo > .neo-list {
    margin: 0 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .menu-mgdr .first > .second.-neo > .neo-list > li {
    width: 240px;
  }
  .menu-mgdr .first > .second.-neo > .neo-list > li:last-child {
    width: 210px;
  }
  .menu-mgdr .first > .second.-neo > .neo-list > li > .title {
    margin-bottom: 10px;
    color: #555;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .menu-mgdr .first > .second.-neo > .neo-list > li > a {
    display: flex;
    align-items: center;
    color: #333;
  }
  .menu-mgdr .first > .second.-neo > .neo-list > li > a:visited {
    color: #333;
  }
  .menu-mgdr .first > .second.-neo > .neo-list > li > a.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #333;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) and (min-width: 768px) {
  .menu-mgdr .first > .second.-neo > .neo-list > li > a:hover {
    color: #333;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) {
  .menu-mgdr .first > .second.-neo > .neo-list > li > a > img {
    margin-right: 10px;
    width: auto;
    height: 108px;
  }
  .menu-mgdr .first > .second.-neo > .neo-list > li > a > .ttl {
    line-height: 1.4;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .menu-mgdr .first > .second.-neo > .neo-list > li > a > .ttl > span {
    display: block;
    margin-top: 5px;
    font-size: 1.2rem;
    color: #555;
    font-weight: 500;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: unset;
  }
  .menu-mgdr .first.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
  }
  .menu-mgdr .first.hover .ctg {
    background: #ece6ef;
    opacity: 1;
  }
  .menu-mgdr .first.hover .ctg > .ttl {
    color: #6867b0;
  }
}

@media print, screen and (min-width: 768px) and (min-width: 768px) {
  .menu-mgdr .first:hover {
    cursor: pointer;
  }
  .menu-mgdr .first:hover .ctg {
    background: #ece6ef;
    opacity: 1;
  }
  .menu-mgdr .first:hover .ctg > .ttl {
    color: #6867b0;
  }
}

@media print, screen and (min-width: 768px) {
  .menu-mgdr [class^="icon2-"] {
    display: inline-block;
  }
  .menu-mgdr [class^="icon2-"]::before {
    color: #6867b0;
  }
  .menu-mgdr .icon2-contest {
    width: 48px;
    height: 46px;
  }
  .menu-mgdr .icon2-contest::after {
    font-size: 48px;
  }
  .menu-mgdr .icon2-contest::before {
    font-size: 48px;
  }
  .menu-mgdr .icon2-publi-work {
    top: 6px;
    left: 2px;
    width: 34px;
    height: 46px;
  }
  .menu-mgdr .icon2-publi-work::after {
    font-size: 34px;
  }
  .menu-mgdr .icon2-publi-work::before {
    font-size: 34px;
  }
  .menu-mgdr .icon2-publi-self {
    top: 3px;
    width: 44px;
    height: 46px;
  }
  .menu-mgdr .icon2-publi-self::after {
    font-size: 44px;
  }
  .menu-mgdr .icon2-publi-self::before {
    font-size: 44px;
  }
  .menu-mgdr .icon2-publi-guide {
    top: 4px;
    width: 40px;
    height: 46px;
  }
  .menu-mgdr .icon2-publi-guide::after {
    font-size: 40px;
  }
  .menu-mgdr .icon2-publi-guide::before {
    font-size: 40px;
  }
  .menu-mgdr .icon2-contact {
    width: 46px;
    height: 46px;
  }
  .menu-mgdr .icon2-contact::after {
    font-size: 46px;
  }
  .menu-mgdr .icon2-contact::before {
    font-size: 46px;
  }
  .menu-mgdr .icon2-hot {
    top: 1px;
    width: 45px;
    height: 46px;
  }
  .menu-mgdr .icon2-hot::after {
    font-size: 45px;
  }
  .menu-mgdr .icon2-hot::before {
    font-size: 45px;
  }
  .menu-mgdr .icon2-neo {
    top: 5px;
    width: 37px;
    height: 46px;
  }
  .menu-mgdr .icon2-neo::before {
    font-size: 37px;
  }
  .menu-mgdr .icon2-picturebook {
    top: 4px;
    width: 38px;
    height: 46px;
  }
  .menu-mgdr .icon2-picturebook::after {
    font-size: 38px;
  }
  .menu-mgdr .icon2-picturebook::before {
    font-size: 38px;
  }
  .menu-mgdr .icon2-ebook {
    top: 4px;
    width: 38px;
    height: 46px;
  }
  .menu-mgdr .icon2-ebook::after {
    font-size: 38px;
  }
  .menu-mgdr .icon2-ebook::before {
    font-size: 38px;
  }
  .menu-mgdr .icon2-library {
    top: 4px;
    width: 38px;
    height: 46px;
  }
  .menu-mgdr .icon2-library::after {
    font-size: 38px;
  }
  .menu-mgdr .icon2-library::before {
    font-size: 38px;
  }
  .menu-mgdr .icon2-company {
    top: 4px;
    width: 38px;
    height: 46px;
  }
  .menu-mgdr .icon2-company::after {
    font-size: 38px;
  }
  .menu-mgdr .icon2-company::before {
    font-size: 38px;
  }
  .menu-mgdr .icon2-philosophy {
    top: 4px;
    width: 40px;
    height: 46px;
  }
  .menu-mgdr .icon2-philosophy::after {
    font-size: 40px;
  }
  .menu-mgdr .icon2-philosophy::before {
    font-size: 40px;
  }
  .menu-mgdr .icon2-commitment {
    top: 5px;
    width: 36px;
    height: 46px;
  }
  .menu-mgdr .icon2-commitment::before {
    font-size: 36px;
  }
  .menu-mgdr .sp-drawer {
    display: none;
  }
  /* ===================================================================
	header.smaller
=================================================================== */
  header.smaller {
    padding: 0;
    height: 69px;
    border-bottom: 1px solid #ccc;
    background: rgba(255, 255, 255, 0.9);
  }
  header.smaller .header-inner {
    width: 1080px;
  }
  header.smaller .global-header {
    position: relative;
    margin: 0;
    height: 68px;
  }
  header.smaller .global-header > .logo {
    top: 15px;
  }
  header.smaller .global-header > .lead {
    top: -120px;
  }
  header.smaller .global-header > .tel-box {
    top: 15px;
  }
  header.smaller .global-header .global-search {
    top: -100px;
  }
  header.smaller .global-header > .global-nav {
    top: 18px;
  }
  header.smaller .header-nav {
    position: absolute;
    top: 0;
    right: 0;
    width: 890px;
    height: 100%;
    box-shadow: none;
  }
  header.smaller .menu-mgdr {
    width: 100%;
  }
  header.smaller .menu-mgdr .first {
    margin: 0;
    padding: 5px 10px;
    height: 68px;
  }
  header.smaller .menu-mgdr .first > .ctg {
    height: 58px;
  }
  header.smaller .menu-mgdr .first > .ctg::before {
    height: 58px;
  }
  header.smaller .menu-mgdr .first > .ctg.-current {
    background: #ece6ef;
  }
  header.smaller .menu-mgdr .first > .ctg > .ttl {
    margin: -5px 0 0;
    font-size: 1.2rem;
  }
  header.smaller .menu-mgdr .first > .ctg > .eng {
    display: none;
  }
  header.smaller .menu-mgdr .first.-bkinfo#bkinfo05 > .ctg::after,
  header.smaller .menu-mgdr .first:last-child > .ctg::after {
    height: 58px;
  }
  header.smaller .menu-mgdr .first > .second {
    top: 68px;
    left: -190px;
  }
  header.smaller .menu-mgdr .first.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  header.smaller .menu-mgdr .first.hover .ctg {
    background: #ece6ef;
    opacity: 1;
  }
}

@media print, screen and (min-width: 768px) and (min-width: 768px) {
  header.smaller .menu-mgdr .first:hover .ctg {
    background: #ece6ef;
    opacity: 1;
  }
}

@media print, screen and (min-width: 768px) {
  header.smaller .menu-mgdr .icon2-contest {
    top: -2px;
  }
  header.smaller .menu-mgdr .icon2-publi-work {
    top: 3px;
  }
  header.smaller .menu-mgdr .icon2-publi-self {
    top: 0;
  }
  header.smaller .menu-mgdr .icon2-publi-guide {
    top: 1px;
  }
  header.smaller .menu-mgdr .icon2-hot {
    top: -3px;
  }
  header.smaller .menu-mgdr .icon2-neo {
    top: 3px;
  }
  header.smaller .menu-mgdr .icon2-picturebook {
    top: 1px;
  }
  header.smaller .menu-mgdr .icon2-ebook {
    top: 1px;
  }
  header.smaller .menu-mgdr .icon2-library {
    top: 1px;
  }
  header.smaller .menu-mgdr .icon2-company {
    top: 2px;
  }
  header.smaller .menu-mgdr .icon2-philosophy {
    top: 1px;
  }
  header.smaller .menu-mgdr .icon2-commitment {
    top: 3px;
  }
  header.mgdr-publi.smaller .global-header > .tel-box,
  header.mgdr-bkinfo.smaller .global-header > .tel-box,
  header.mgdr-about.smaller .global-header > .tel-box {
    top: -100px;
  }
  header.mgdr-publi.smaller .global-header > .global-nav,
  header.mgdr-bkinfo.smaller .global-header > .global-nav,
  header.mgdr-about.smaller .global-header > .global-nav {
    top: -100px;
  }
  header.mgdr-publi.smaller .header-nav {
    display: block;
  }
  header.mgdr-bkinfo.smaller .header-nav {
    display: block;
  }
  header.mgdr-about.smaller .header-nav {
    display: block;
  }
  /* ===================================================================
	header-navsp
=================================================================== */
  .header-navsp {
    display: none;
  }
  /* ============================================================
	drawer
.first.-bkinfo {display: none;}
============================================================ */
  /* ============================================================
	header.mgdr-publi
============================================================ */
  header.mgdr-publi .menu-mgdr [class^="icon2-"]::before {
    color: #ed5c39;
  }
  header.mgdr-publi .menu-mgdr .first > .ctg.-current {
    background: #fdeeeb;
  }
  header.mgdr-publi .menu-mgdr .first > .ctg.-current > .ttl {
    color: #ed5c39;
  }
  header.mgdr-publi .menu-mgdr .first > .ctg > .eng {
    color: #ed5c39;
  }
  header.mgdr-publi .menu-mgdr .first.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  header.mgdr-publi .menu-mgdr .first.hover .ctg {
    background: #fdeeeb;
  }
  header.mgdr-publi .menu-mgdr .first.hover .ctg > .ttl {
    color: #ed5c39;
  }
}

@media print, screen and (min-width: 768px) and (min-width: 768px) {
  header.mgdr-publi .menu-mgdr .first:hover .ctg {
    background: #fdeeeb;
  }
  header.mgdr-publi .menu-mgdr .first:hover .ctg > .ttl {
    color: #ed5c39;
  }
}

@media print, screen and (min-width: 768px) {
  header.mgdr-publi .first.-bkinfo,
  header.mgdr-publi .first.-about {
    display: none;
  }
  /* ============================================================
	header.mgdr-bkinfo
============================================================ */
  header.mgdr-bkinfo .menu-mgdr [class^="icon2-"]::before {
    color: #f5a900;
  }
  header.mgdr-bkinfo .menu-mgdr .first > .ctg.-current {
    background: #fcf3e6;
  }
  header.mgdr-bkinfo .menu-mgdr .first > .ctg.-current > .ttl {
    color: #f5a900;
  }
  header.mgdr-bkinfo .menu-mgdr .first > .ctg > .eng {
    color: #f5a900;
  }
  header.mgdr-bkinfo .menu-mgdr .first.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  header.mgdr-bkinfo .menu-mgdr .first.hover .ctg {
    background: #fcf3e6;
  }
  header.mgdr-bkinfo .menu-mgdr .first.hover .ctg > .ttl {
    color: #f5a900;
  }
}

@media print, screen and (min-width: 768px) and (min-width: 768px) {
  header.mgdr-bkinfo .menu-mgdr .first:hover .ctg {
    background: #fcf3e6;
  }
  header.mgdr-bkinfo .menu-mgdr .first:hover .ctg > .ttl {
    color: #f5a900;
  }
}

@media print, screen and (min-width: 768px) {
  header.mgdr-bkinfo .first.-publi,
  header.mgdr-bkinfo .first.-about,
  header.mgdr-bkinfo .first.-contact {
    display: none;
  }
  /* ============================================================
	header.mgdr-about
============================================================ */
  header.mgdr-about .first.-publi,
  header.mgdr-about .first.-bkinfo {
    display: none;
  }
  /* ========================================================= */
}

/* ============================================================
	header-simple
============================================================ */
.header-simple {
  position: fixed;
  top: 0;
  padding: 4px 0 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid #ccc;
  z-index: 100;
}

@media only screen and (max-width: 1160px) {
  .header-simple {
    width: 1160px;
  }
}

@media only screen and (max-width: 767px) {
  .header-simple {
    margin-bottom: 0;
    padding: 0;
    width: 100%;
    height: 56px;
  }
}

.header-simple > .global-header {
  position: relative;
  margin: 0 auto;
  width: 1080px;
  height: 70px;
}

@media only screen and (max-width: 767px) {
  .header-simple > .global-header {
    background: none;
    width: 100%;
    height: 56px;
  }
  .header-simple > .global-header > .tel-box {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  /* ============================================================
	global-header
============================================================ */
  .global-header {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 56px;
    background: #fff;
    border-bottom: 1px solid #ccc;
    z-index: 110;
  }
  .global-header > .logo {
    position: absolute;
    top: 12px;
    left: 5px;
    width: 124px;
    height: auto;
  }
  .global-header > .lead {
    display: none;
  }
  .global-header > .tel-box {
    position: absolute;
    top: 0;
    right: 120px;
    color: #333;
    font-size: 1.2rem;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all .3s;
  }
  .global-header > .tel-box .time, .global-header > .tel-box .time .label {
    display: none;
  }
  .global-header > .tel-box .tel > a.size-sp {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 55px;
    border-left: 1px solid #ccc;
    font-size: 2.4rem;
    color: #36357e;
    text-align: center;
    color: #36357e;
  }
  .global-header > .tel-box .tel > a.size-sp:visited {
    color: #36357e;
  }
  .global-header > .tel-box .tel > a.size-sp.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #36357e;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) and (min-width: 768px) {
  .global-header > .tel-box .tel > a.size-sp:hover {
    color: #36357e;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .global-header > .tel-box .tel > a.size-sp .jpn {
    display: block;
    margin-top: 5px;
    color: #333;
    font-size: 1rem;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .global-header .global-searchbtn {
    display: flex;
    position: absolute;
    top: 0;
    right: 60px;
    transition: all .3s;
  }
  .global-header .global-searchbtn > .trigger {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 55px;
    border-left: 1px solid #ccc;
    text-align: center;
    color: #333;
    font-size: 2.4rem;
  }
  .global-header .global-searchbtn > .trigger:visited {
    color: #333;
  }
  .global-header .global-searchbtn > .trigger.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #333;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) and (min-width: 768px) {
  .global-header .global-searchbtn > .trigger:hover {
    color: #333;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .global-header .global-searchbtn > .trigger > .icon-search::before {
    color: #36357e;
  }
  .global-header .global-searchbtn > .trigger .jpn {
    display: block;
    margin-top: 5px;
    color: #333;
    font-size: 1rem;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .global-header .global-searchsp {
    z-index: 70;
    position: fixed;
    top: 54px;
    right: 0;
    margin: 0;
    padding: 0 12px;
    width: 100%;
    display: none;
  }
  .global-header .global-searchsp::before {
    position: absolute;
    top: -8px;
    right: 75px;
    content: "";
    border-bottom: 10px solid #ff9e00;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }
  .global-header .global-searchsp::after {
    position: absolute;
    top: -7px;
    right: 76px;
    content: "";
    border-bottom: 9px solid #fcf3e6;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    text-decoration: none;
  }
  .global-header .global-searchsp.-open {
    transform: translate(0);
  }
  .global-header .global-searchsp > .wrap {
    width: 100%;
    background-color: #fcf3e6;
    padding: 12px;
    border-radius: 10px;
    border: 1px solid #ff9e00;
    font-size: 1.4rem;
    position: relative;
  }
  .global-header .global-searchsp > .wrap > .ttl {
    margin-bottom: 10px;
    padding: 6px;
    background: #ff9e00;
    border-radius: 5px;
    font-size: 1.6rem;
    color: #fff;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .global-header .global-searchsp > .wrap > .sbttl {
    margin: 10px 0 5px;
    font-size: 1.6rem;
    color: #ff9e00;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .global-header .global-searchsp > .wrap .searchsp-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .global-header .global-searchsp > .wrap .searchsp-box li:first-child {
    width: 100% !important;
  }
  .global-header .global-searchsp > .wrap .searchsp-box input[type="text"] {
    margin: 0;
    width: 100% !important;
    height: 36px;
  }
  .global-header .global-searchsp > .wrap .searchsp-box .btn-search {
    display: inline-block;
    position: relative;
    margin: 0 0 0 5px;
    padding: 9px 10px;
    color: #fff;
    text-align: center;
    line-height: 1;
    border-radius: 4px;
    transition: all .3s;
    cursor: pointer;
    background-color: #ff9e00;
    border: 1px solid #ff9e00;
    white-space: nowrap;
  }
  _:-ms-fullscreen, :root .global-header .global-searchsp > .wrap .searchsp-box .btn-search {
    padding-top: 17px;
  }
  .global-header .global-searchsp > .wrap .searchsp-box .btn-search:visited {
    color: #fff;
  }
  .global-header .global-searchsp > .wrap .searchsp-box .btn-search.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    opacity: 1;
    background-color: #fff;
    color: #ff9e00;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) and (min-width: 768px) {
  .global-header .global-searchsp > .wrap .searchsp-box .btn-search:hover {
    opacity: 1;
    background-color: #fff;
    color: #ff9e00;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .global-header .global-searchsp > .wrap .category-list {
    height: 230px;
    border: 1px solid #dcbf3c;
    background-color: #fdf3c7;
    overflow-y: scroll;
  }
  .global-header .global-searchsp > .wrap .category-list > .switch {
    border-bottom: 1px solid #EEE0A1;
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    text-align: left;
    text-decoration: none;
  }
  .global-header .global-searchsp > .wrap .category-list > .switch:last-of-type {
    border-bottom: none;
  }
  .global-header .global-searchsp > .wrap .category-list > .switch::after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-family: "bungeisha" !important;
    display: inline-block;
    right: 20px;
    font-size: 1.6rem;
    content: "";
    color: #ff9e00;
  }
  .global-header .global-searchsp > .wrap .category-list > .switch.-active::after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-family: "bungeisha" !important;
    display: inline-block;
    right: 20px;
    font-size: 1.6rem;
    content: "";
  }
  .global-header .global-searchsp > .wrap .category-list > .genre {
    display: none;
    border-bottom: 1px solid #EEE0A1;
  }
  .global-header .global-searchsp > .wrap .category-list > .genre:last-of-type {
    border-top: 1px solid #EEE0A1;
    border-bottom: none;
  }
  .global-header .global-searchsp > .wrap .category-list > .genre > a {
    background-color: #fefae8;
    border-bottom: 1px solid #EEE0A1;
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    text-align: left;
    text-decoration: none;
  }
  .global-header .global-searchsp > .wrap .category-list > .genre > a:last-child {
    border-bottom: none;
  }
  .global-header .global-searchsp > .wrap .category-list > .genre > a::after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-family: "bungeisha" !important;
    display: inline-block;
    right: 20px;
    font-size: 1.2rem;
    content: "";
    color: #ff9e00;
  }
  /* ============================================================
	global-nav changeold

.global-nav {
	@include flex;
	position: absolute;
	top: 0;
	right: 60px;
	@include transition;

	> li {position: relative;}
	> li > .ctg {
		@include flex-center;
		width: 60px;
		height: 55px;
		border-left: $borderSol;
		text-align: center;
		@include link-black;

		.icon-publishing {margin-right: 0;}

		> .ttl {
			@include bold;
			font-size: 2.4rem;
			> .icon-home::before {color: $baseColor;}
			> .jpn {
				display: block;
				margin-top: 4px;
				font-size: $fs_xs;
			}//jpn
		}
		> .eng {display: none;}

	}//ctg
}//global-nav old
============================================================ */
  /* ============================================================
	global-nav changenew
============================================================ */
  .global-nav {
    display: none;
  }
  /* ============================================================
	header-nav
============================================================ */
  .header-nav {
    position: fixed;
    top: 0;
    right: 0;
    margin: 0;
    padding-top: 55px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    background-color: rgba(255, 255, 255, 0.95);
    transition: all .3s;
    transform: translate(100%);
  }
  .header-nav.-open {
    transform: translate(0);
  }
  /* ============================================================
	menu-mgdr
============================================================ */
  .menu-mgdr {
    display: block;
    position: relative;
    margin: 0;
    width: 100%;
  }
  .menu-mgdr .first {
    display: none;
    padding: 0;
    width: 100%;
    z-index: 2;
  }
  .menu-mgdr .first > .ctg {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 20px;
    text-align: left;
    color: #333;
    border-bottom: 1px dotted #ccc;
  }
  .menu-mgdr .first > .ctg:visited {
    color: #333;
  }
  .menu-mgdr .first > .ctg.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #333;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) and (min-width: 768px) {
  .menu-mgdr .first > .ctg:hover {
    color: #333;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .menu-mgdr .first > .ctg::after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-family: "bungeisha" !important;
    display: inline-block;
    right: 20px;
    font-size: 1.2rem;
    content: "";
  }
  .menu-mgdr .first > .ctg[target="_blank"]::after {
    content: "";
  }
  .menu-mgdr .first > .ctg > .ttl {
    margin: 0 0 0 5px;
    font-size: 1.4rem;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .menu-mgdr .first > .ctg > .eng {
    display: none;
  }
  .menu-mgdr .sp-drawer + .first.-contact > .ctg {
    display: none;
  }
  .menu-mgdr .first > .second {
    display: block;
    position: relative;
    padding: 0;
    width: 100%;
    text-align: left;
    transition: all .2s ease;
    z-index: 1;
    cursor: default !important;
  }
  .menu-mgdr .first > .second.-neo,
  .menu-mgdr .first > .second.-ebook,
  .menu-mgdr .first > .second.-library {
    display: none !important;
  }
  .menu-mgdr .first > .second a {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 12px 20px;
    width: 100%;
    border-bottom: 1px dotted #ccc;
    text-align: left;
    color: #333;
  }
  .menu-mgdr .first > .second a:visited {
    color: #333;
  }
  .menu-mgdr .first > .second a.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #333;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) and (min-width: 768px) {
  .menu-mgdr .first > .second a:hover {
    color: #333;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .menu-mgdr .first > .second a img {
    display: none;
  }
  .menu-mgdr .first > .second a > .ttl {
    margin: 0;
    font-size: 1.2rem;
  }
  .menu-mgdr .first > .second a::after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-family: "bungeisha" !important;
    display: inline-block;
    right: 20px;
    font-size: 1.2rem;
    content: "";
  }
  .menu-mgdr .first > .second a[target="_blank"]::after {
    content: "";
  }
  .menu-mgdr .first.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
  }
  .menu-mgdr .first.hover .ctg {
    background-color: #fdeeeb;
  }
  .menu-mgdr .first.hover .ctg > .ttl {
    color: #ed5c39;
  }
  .menu-mgdr .first.hover > .second {
    display: flex;
  }
}

@media only screen and (max-width: 767px) and (min-width: 768px) {
  .menu-mgdr .first:hover {
    cursor: pointer;
  }
  .menu-mgdr .first:hover .ctg {
    background-color: #fdeeeb;
  }
  .menu-mgdr .first:hover .ctg > .ttl {
    color: #ed5c39;
  }
  .menu-mgdr .first:hover > .second {
    display: flex;
  }
}

@media only screen and (max-width: 767px) {
  .menu-mgdr [class^="icon2-"] {
    display: inline-block;
  }
  .menu-mgdr .icon2-contest {
    top: 4px;
    width: 28px;
    height: 36px;
  }
  .menu-mgdr .icon2-contest::after {
    font-size: 28px;
  }
  .menu-mgdr .icon2-contest::before {
    font-size: 28px;
    color: #ed5c39;
  }
  .menu-mgdr .icon2-publi-work {
    top: 8px;
    left: 4px;
    width: 28px;
    height: 36px;
  }
  .menu-mgdr .icon2-publi-work::after {
    font-size: 20px;
  }
  .menu-mgdr .icon2-publi-work::before {
    font-size: 20px;
    color: #ed5c39;
  }
  .menu-mgdr .icon2-publi-self {
    top: 5px;
    width: 28px;
    height: 36px;
  }
  .menu-mgdr .icon2-publi-self::after {
    font-size: 28px;
  }
  .menu-mgdr .icon2-publi-self::before {
    font-size: 28px;
    color: #ed5c39;
  }
  .menu-mgdr .icon2-publi-guide {
    top: 4px;
    width: 28px;
    height: 36px;
  }
  .menu-mgdr .icon2-publi-guide::after {
    font-size: 28px;
  }
  .menu-mgdr .icon2-publi-guide::before {
    font-size: 28px;
    color: #ed5c39;
  }
  .menu-mgdr .icon2-contact {
    width: 29px;
    height: 29px;
  }
  .menu-mgdr .icon2-contact::after {
    font-size: 29px;
    color: #fff;
  }
  .menu-mgdr .icon2-contact::before {
    font-size: 29px;
    color: #fff;
  }
  .menu-mgdr .icon2-hot {
    top: 3px;
    width: 28px;
    height: 36px;
  }
  .menu-mgdr .icon2-hot::after {
    font-size: 28px;
  }
  .menu-mgdr .icon2-hot::before {
    font-size: 28px;
    color: #f5a900;
  }
  .menu-mgdr .icon2-neo {
    top: 7px;
    left: 2px;
    width: 22px;
    height: 36px;
  }
  .menu-mgdr .icon2-neo::before {
    font-size: 22px;
    color: #f5a900;
  }
  .menu-mgdr .icon2-picturebook {
    top: 7px;
    width: 24px;
    height: 36px;
  }
  .menu-mgdr .icon2-picturebook::after {
    font-size: 24px;
  }
  .menu-mgdr .icon2-picturebook::before {
    font-size: 24px;
    color: #f5a900;
  }
  .menu-mgdr .icon2-ebook {
    top: 7px;
    width: 24px;
    height: 36px;
  }
  .menu-mgdr .icon2-ebook::after {
    font-size: 24px;
  }
  .menu-mgdr .icon2-ebook::before {
    font-size: 24px;
    color: #f5a900;
  }
  .menu-mgdr .icon2-library {
    top: 6px;
    width: 24px;
    height: 36px;
  }
  .menu-mgdr .icon2-library::after {
    font-size: 24px;
  }
  .menu-mgdr .icon2-library::before {
    font-size: 24px;
    color: #f5a900;
  }
  .menu-mgdr .icon2-company {
    top: 6px;
    width: 24px;
    height: 36px;
  }
  .menu-mgdr .icon2-company::after {
    font-size: 24px;
  }
  .menu-mgdr .icon2-company::before {
    font-size: 24px;
    color: #6867b0;
  }
  .menu-mgdr .icon2-philosophy {
    top: 7px;
    width: 24px;
    height: 36px;
  }
  .menu-mgdr .icon2-philosophy::after {
    font-size: 24px;
  }
  .menu-mgdr .icon2-philosophy::before {
    font-size: 24px;
    color: #6867b0;
  }
  .menu-mgdr .icon2-commitment {
    top: 7px;
    width: 22px;
    height: 36px;
  }
  .menu-mgdr .icon2-commitment::before {
    font-size: 22px;
    color: #6867b0;
  }
  .menu-mgdr .sp-drawer {
    display: block;
  }
  .menu-mgdr .sp-drawer > .gnav {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    text-align: left;
    color: #fff;
  }
  .menu-mgdr .sp-drawer > .gnav:visited {
    color: #fff;
  }
  .menu-mgdr .sp-drawer > .gnav.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #fff;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) and (min-width: 768px) {
  .menu-mgdr .sp-drawer > .gnav:hover {
    color: #fff;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .menu-mgdr .sp-drawer > .gnav::after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-family: "bungeisha" !important;
    display: inline-block;
    right: 20px;
    font-size: 1.6rem;
    content: "";
    color: #fff;
  }
  .menu-mgdr .sp-drawer > .gnav.-publi {
    background: #d23c28;
  }
  .menu-mgdr .sp-drawer > .gnav.-bkinfo {
    background: #faac2f;
  }
  .menu-mgdr .sp-drawer > .gnav.-about {
    background: #363588;
  }
  .menu-mgdr .sp-drawer > .gnav.-news {
    background: #6d6564;
  }
  .menu-mgdr .sp-drawer > .gnav.-news::after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-family: "bungeisha" !important;
    display: inline-block;
    right: 20px;
    font-size: 1.2rem;
    content: "";
  }
  .menu-mgdr .sp-drawer > .gnav.-contact {
    background: #973e41;
  }
  .menu-mgdr .sp-drawer > .gnav [class^="icon-"] {
    display: inline-block;
    padding-top: 1px;
    width: 30px;
    height: 30px;
  }
  .menu-mgdr .sp-drawer > .gnav [class^="icon-"]::before {
    font-size: 28px;
    color: #fff;
  }
  .menu-mgdr .sp-drawer > .gnav .icon-publishing {
    padding-top: 0;
  }
  .menu-mgdr .sp-drawer > .gnav .icon-publishing::before {
    font-size: 30px;
  }
  .menu-mgdr .sp-drawer > .gnav > .ttl {
    margin: 0 0 0 5px;
    font-size: 1.6rem;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .menu-mgdr .sp-drawer > .gnav > .eng {
    display: none;
  }
  .menu-mgdr .sp-drawer > .gnav.-active::after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-family: "bungeisha" !important;
    display: inline-block;
    right: 20px;
    font-size: 1.6rem;
    content: "";
  }
  .menu-mgdr .first > .ctg.-toplink {
    display: inline-block;
    margin: 0;
    padding: 12px 20px;
    width: 100%;
    border-bottom: 1px dotted #ccc;
    text-align: left;
    color: #333;
  }
  .menu-mgdr .first > .ctg.-toplink:visited {
    color: #333;
  }
  .menu-mgdr .first > .ctg.-toplink.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #333;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) and (min-width: 768px) {
  .menu-mgdr .first > .ctg.-toplink:hover {
    color: #333;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .menu-mgdr .first > .ctg.-toplink > .ttl {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .menu-mgdr .first:nth-child(6) > .second a:last-of-type {
    border-bottom: none;
  }
  .menu-mgdr .first.-bkinfo#bkinfo05 > .ctg {
    border-bottom: none;
  }
  /* ===================================================================
	header-navsp
=================================================================== */
  .header-navsp {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 55px;
  }
  .header-navsp > .trigger {
    border-left: 1px solid #ccc;
    position: absolute;
    width: 100%;
    background-color: transparent;
    cursor: pointer;
    height: 55px;
    line-height: 0;
    right: 0;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
  .header-navsp > .trigger .hamburger {
    display: inline-block;
    position: absolute;
    top: 50%;
    bottom: auto;
    right: 16px;
    left: auto;
    transform: translateX(0) translateY(-50%);
    width: 25px;
    height: 2px;
    background-color: #333;
    transition: background-color 0.3s;
  }
  .header-navsp > .trigger .hamburger::before, .header-navsp > .trigger .hamburger::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #333;
    right: 0;
    transition: transform .3s, top .3s, background-color 0s;
  }
  .header-navsp > .trigger .hamburger::before {
    top: -8px;
  }
  .header-navsp > .trigger .hamburger::after {
    top: 8px;
  }
  .header-navsp > .trigger .hamburger.-clicked {
    background-color: rgba(255, 255, 255, 0);
  }
  .header-navsp > .trigger .hamburger.-clicked::before, .header-navsp > .trigger .hamburger.-clicked::after {
    background-color: #666;
  }
  .header-navsp > .trigger .hamburger.-clicked::before {
    top: 0;
    transform: rotate(135deg);
  }
  .header-navsp > .trigger .hamburger.-clicked::after {
    top: 0;
    transform: rotate(225deg);
  }
  /* ========================================================= */
}

/* ============================================================
	footer
============================================================ */
footer {
  padding-top: 50px;
  width: 100%;
  background: #fcfcfc;
}

@media only screen and (max-width: 1160px) {
  footer {
    width: 1160px;
  }
}

@media only screen and (max-width: 767px) {
  footer {
    padding: 0;
    width: 100%;
  }
}

.global-footer {
  margin: 0 auto;
  width: 1080px;
}

@media print, screen and (min-width: 768px) {
  .global-footer {
    display: flex;
  }
}

@media only screen and (max-width: 767px) {
  .global-footer {
    width: 100%;
  }
}

.footer-item {
  margin-right: 15px;
  width: 140px;
}

.footer-item > .logo {
  margin-bottom: 35px;
}

.footer-item > .sns-ttl {
  margin-bottom: 10px;
  font-size: 1.4rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.footer-item > .sns-list {
  display: flex;
}

.footer-item > .sns-list > li {
  margin-right: 10px;
  width: 26px;
  height: 26px;
}

.footer-item > .sns-list > li:last-of-type {
  margin-right: 0;
}

@media only screen and (max-width: 767px) {
  .footer-item {
    padding: 30px 0;
    width: 100%;
    text-align: center;
  }
  .footer-item > .logo, .footer-item > .sns-ttl {
    display: none;
  }
  .footer-item > .sns-list {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.footer-nav {
  margin-left: 25px;
}

@media only screen and (max-width: 767px) {
  .footer-nav {
    margin: 0;
    width: 100%;
  }
}

.footer-nav > .ctg,
.footer-nav > .acc-area .ctg {
  position: relative;
  margin-bottom: 5px;
  line-height: 1.7;
}

@media only screen and (max-width: 767px) {
  .footer-nav > .ctg,
  .footer-nav > .acc-area .ctg {
    line-height: 1;
    margin-bottom: 0;
  }
}

.footer-nav > .ctg a, .footer-nav > .ctg label,
.footer-nav > .acc-area .ctg a,
.footer-nav > .acc-area .ctg label {
  display: inline-block;
}

.footer-nav > .ctg.-lrg a,
.footer-nav > .ctg.-lrg label,
.footer-nav > .acc-area .ctg.-lrg a,
.footer-nav > .acc-area .ctg.-lrg label {
  padding-left: 20px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

.footer-nav > .ctg.-mdl,
.footer-nav > .acc-area .ctg.-mdl {
  padding-left: 20px;
  color: #333;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.footer-nav > .ctg.-sml.-sp,
.footer-nav > .acc-area .ctg.-sml.-sp {
  display: none;
}

.footer-nav > .ctg.-sml,
.footer-nav > .acc-area .ctg.-sml {
  padding-left: 20px;
  font-size: 1.2rem;
}

.footer-nav > .ctg.-sml a,
.footer-nav > .acc-area .ctg.-sml a {
  color: #666;
}

.footer-nav > .ctg.-lrg a::before,
.footer-nav > .ctg.-lrg label::before,
.footer-nav > .acc-area .ctg.-lrg a::before,
.footer-nav > .acc-area .ctg.-lrg label::before {
  font-family: "bungeisha" !important;
  content: "";
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translate(0, -50%);
  font-size: 1rem;
}

.footer-nav > .ctg.-lrg.-publi a::before,
.footer-nav > .acc-area .ctg.-lrg.-publi a::before {
  color: #e62814;
}

.footer-nav > .ctg.-lrg.-bkinfo a::before,
.footer-nav > .acc-area .ctg.-lrg.-bkinfo a::before {
  color: #ff9e00;
}

.footer-nav > .ctg.-lrg.-about a::before,
.footer-nav > .acc-area .ctg.-lrg.-about a::before {
  color: #36357e;
}

.footer-nav > .ctg.-lrg.-news a::before,
.footer-nav > .acc-area .ctg.-lrg.-news a::before {
  color: #555;
}

.footer-nav > .ctg.-lrg.-publi label::before,
.footer-nav > .acc-area .ctg.-lrg.-publi label::before {
  color: #e62814;
}

.footer-nav > .ctg.-lrg.-bkinfo label::before,
.footer-nav > .acc-area .ctg.-lrg.-bkinfo label::before {
  color: #ff9e00;
}

.footer-nav > .ctg.-lrg.-about label::before,
.footer-nav > .acc-area .ctg.-lrg.-about label::before {
  color: #36357e;
}

@media only screen and (max-width: 767px) {
  .footer-nav > .ctg,
  .footer-nav > .acc-area .ctg {
    margin: 0;
  }
  .footer-nav > .ctg a, .footer-nav > .ctg label,
  .footer-nav > .acc-area .ctg a,
  .footer-nav > .acc-area .ctg label {
    display: block;
    padding: 10px 20px 10px 30px !important;
    width: 100%;
    border-top: 1px solid #ccc;
  }
  .footer-nav > .ctg.-lrg a::before,
  .footer-nav > .ctg.-lrg label::before,
  .footer-nav > .acc-area .ctg.-lrg a::before,
  .footer-nav > .acc-area .ctg.-lrg label::before {
    left: 16px;
  }
  .footer-nav > .ctg.-lrg a::after,
  .footer-nav > .ctg.-lrg label::after,
  .footer-nav > .acc-area .ctg.-lrg a::after,
  .footer-nav > .acc-area .ctg.-lrg label::after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-family: "bungeisha" !important;
    display: inline-block;
    right: 20px;
    font-size: 1.6rem;
    content: "";
    color: #36357e;
  }
  .footer-nav > .ctg.-mdl,
  .footer-nav > .acc-area .ctg.-mdl {
    border-top: 1px solid #ccc;
    padding: 10px 30px;
    font-size: 1.2rem;
  }
  .footer-nav > .ctg.-sml,
  .footer-nav > .acc-area .ctg.-sml {
    padding-left: 0;
  }
  .footer-nav > .ctg.-sml.-sp,
  .footer-nav > .acc-area .ctg.-sml.-sp {
    display: list-item;
  }
  .footer-nav > .ctg.-sml a::after,
  .footer-nav > .acc-area .ctg.-sml a::after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-family: "bungeisha" !important;
    display: inline-block;
    right: 20px;
    font-size: 1.2rem;
    content: "";
    color: #36357e;
  }
  .footer-nav > .ctg.-sml.-blank a::after,
  .footer-nav > .acc-area .ctg.-sml.-blank a::after {
    content: "";
  }
  .footer-nav > .ctg.size-sp,
  .footer-nav > .acc-area .ctg.size-sp {
    display: block !important;
  }
}

.footer-nav > .acc-area {
  display: block;
}

@media only screen and (max-width: 767px) {
  .footer-nav > .acc-area .ctg {
    height: 0;
    padding: 0;
    overflow: hidden;
    opacity: 0;
    transition: all .3s;
  }
  .footer-nav > .acc-area .ctg.-mdl {
    border-top: 0;
    padding: 0 30px;
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 767px) {
  .footer-nav > .acc-check:checked + .ctg + .acc-area .ctg {
    height: 33px;
    opacity: 1;
  }
  .footer-nav > .acc-check:checked + .ctg + .acc-area .ctg.-mdl {
    border-top: 1px solid #ccc;
    padding: 10px 30px;
    font-size: 1.2rem;
  }
  .footer-nav > .acc-check:checked + .ctg label::after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-family: "bungeisha" !important;
    display: inline-block;
    right: 20px;
    font-size: 1.6rem;
    content: "";
  }
}

.footer-nav .acc-check {
  display: none;
}

.footer-nav.-other > .ctg.-lrg a::after {
  display: none;
}

.footer-btm {
  margin-top: 50px;
  padding: 20px 0;
  width: 100%;
  background: #36357e;
  text-align: center;
}

.footer-btm > .item {
  margin: 0 auto;
  width: 1080px;
  font-size: 1.2rem;
  color: #fff;
}

@media print, screen and (min-width: 768px) {
  .footer-btm > .item {
    display: flex;
    justify-content: space-between;
  }
}

.footer-btm > .item > .copy {
  font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
  font-weight: 400;
}

.footer-btm a {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .footer-btm {
    margin: 0;
    padding: 0;
  }
  .footer-btm > .item {
    width: 100%;
  }
  .footer-btm > .item > .nav {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .footer-btm > .item > .nav span {
    display: none;
  }
  .footer-btm > .item > .nav a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    width: 50%;
    line-height: 1.4;
    text-align: center;
    border-bottom: 1px solid #fff;
  }
  .footer-btm > .item > .nav a.hover {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) and (min-width: 768px) {
  .footer-btm > .item > .nav a:hover {
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) {
  .footer-btm > .item > .nav a:nth-child(1), .footer-btm > .item > .nav a:nth-child(5) {
    border-right: 1px solid #fff;
  }
  .footer-btm > .item > .copy {
    padding: 20px 0;
    width: 100%;
    text-align: center;
  }
}

/* ============================================================
	footer-simple
============================================================ */
.footer-simple {
  padding: 20px;
  width: 100%;
  background: #36357e;
  text-align: center;
}

.footer-simple > .copy {
  margin: 0 auto;
  font-size: 1.2rem;
  color: #fff;
  font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
  font-weight: 400;
  width: 1080px;
}

@media only screen and (max-width: 767px) {
  .footer-simple > .copy {
    width: 100%;
  }
}

/* ============================================================
	pagetop
============================================================ */
.pagetop {
  z-index: 80;
  position: fixed;
  bottom: 120px;
  right: 30px;
}

@media only screen and (max-width: 767px) {
  .pagetop {
    bottom: 10px;
    right: 10px;
  }
}

.pagetop a {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #a5a5a5;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  padding-top: 30px;
  font-size: 1rem;
  font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
}

.pagetop a:visited {
  color: #fff;
}

.pagetop a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #fff;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .pagetop a:hover {
    color: #fff;
    text-decoration: none;
  }
}

.pagetop a::before {
  font-family: 'bungeisha' !important;
  content: "";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 18%;
  font-size: 3rem;
}

.pagetop a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #a5a5a5;
  opacity: 0.3;
}

@media print, screen and (min-width: 768px) {
  .pagetop a:hover {
    background-color: #a5a5a5;
    opacity: 0.3;
  }
}

@media only screen and (max-width: 767px) {
  .pagetop a {
    padding-top: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #a5a5a5;
    color: #fff;
  }
  .pagetop a::before {
    font-size: 2rem;
  }
}

/* ============================================================
	recommend
============================================================ */
.recommend-area {
  margin: 20px auto 0;
  display: flex;
  justify-content: space-between;
  width: 804px;
}

@media only screen and (max-width: 767px) {
  .recommend-area {
    width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .recommend-area {
    margin: 10px auto 0;
    display: block;
  }
}

.recommend-area > .item {
  display: block;
  width: 248px;
}

.recommend-area > .item > .ttl {
  margin-top: 5px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .recommend-area > .item {
    width: 32%;
  }
  .recommend-area > .item > .ttl {
    line-height: 1.4;
  }
}

@media only screen and (max-width: 479px) {
  .recommend-area > .item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    width: 100%;
  }
  .recommend-area > .item img {
    width: 124px;
  }
  .recommend-area > .item > .ttl {
    margin: 0 0 0 15px;
    width: 100%;
    text-align: left;
  }
}

/* ============================================================
	faq-qlist
============================================================ */
.faq-qlist {
  margin: 0 auto 20px;
  width: 960px;
}

@media only screen and (max-width: 767px) {
  .faq-qlist {
    width: 100%;
  }
}

.faq-qlist > li {
  border-bottom: 1px dashed #ccc;
}

.faq-qlist > li > .item {
  display: block;
  position: relative;
  padding: 10px 0 10px 22px;
  font-size: 1.6rem;
  line-height: 1.5;
}

.faq-qlist > li > .item .q {
  position: absolute;
  top: 10px;
  left: 0;
}

/* ============================================================
	contact-box
============================================================ */
.contact-box {
  position: relative;
  margin: 140px auto;
  width: 960px;
}

@media only screen and (max-width: 767px) {
  .contact-box {
    margin: 60px auto;
    width: 94%;
  }
}

@media only screen and (max-width: 700px) {
  .contact-box {
    max-width: 352px;
  }
}

@media only screen and (max-width: 375px) {
  .contact-box {
    margin: 60px 3% 60px;
    width: 94%;
  }
}

.contact-box > .ttl {
  margin: 0;
  padding: 14px 20px;
  background-color: #36357e;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 2rem;
  line-height: 1;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  text-align: center;
}

_:-ms-fullscreen, :root .contact-box > .ttl {
  padding-top: 21px;
}

@media only screen and (max-width: 767px) {
  .contact-box > .ttl {
    padding: 10px;
    font-size: 1.8rem;
    line-height: 1.2;
  }
}

.contact-box > .box {
  border: 1px solid #808080;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 20px;
}

@media print, screen and (min-width: 701px) {
  .contact-box > .box {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 767px) {
  .contact-box > .box {
    padding: 20px 25px 10px;
  }
}

.contact-box > .box > .division {
  width: 100%;
  margin-bottom: 15px;
  font-size: 2.8rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #36357e;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .contact-box > .box > .division {
    font-size: 2rem;
    line-height: 1.2;
  }
}

.contact-box > .box > .item {
  padding: 0 30px;
}

@media print, screen and (min-width: 701px) {
  .contact-box > .box > .item {
    width: 50%;
  }
  .contact-box > .box > .item:first-of-type {
    border-right: 1px solid #808080;
  }
}

@media only screen and (max-width: 767px) {
  .contact-box > .box > .item {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 700px) {
  .contact-box > .box > .item {
    padding: 0;
  }
}

.contact-box > .box > .item > .tel-box, .contact-box > .box > .item .fax-box, .contact-box > .box > .item .post-box, .contact-box > .box > .item .mail-box {
  position: relative;
}

.contact-box > .box > .item > .tel-box .memo, .contact-box > .box > .item .fax-box .memo, .contact-box > .box > .item .post-box .memo, .contact-box > .box > .item .mail-box .memo {
  display: inline-block;
  line-height: 1.4;
  font-weight: 500;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
}

@media only screen and (max-width: 767px) {
  .contact-box > .box > .item > .tel-box .memo, .contact-box > .box > .item .fax-box .memo, .contact-box > .box > .item .post-box .memo, .contact-box > .box > .item .mail-box .memo {
    margin-top: 2px;
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 320px) {
  .contact-box > .box > .item > .tel-box .memo, .contact-box > .box > .item .fax-box .memo, .contact-box > .box > .item .post-box .memo, .contact-box > .box > .item .mail-box .memo {
    font-size: 1.2rem;
  }
}

.contact-box > .box > .item > .tel-box > .sbttl, .contact-box > .box > .item .fax-box > .sbttl, .contact-box > .box > .item .post-box > .sbttl, .contact-box > .box > .item .mail-box > .sbttl {
  font-size: 1.6rem;
  line-height: 1;
}

@media print, screen and (min-width: 768px) {
  .contact-box > .box > .item > .tel-box > .sbttl, .contact-box > .box > .item .fax-box > .sbttl, .contact-box > .box > .item .post-box > .sbttl, .contact-box > .box > .item .mail-box > .sbttl {
    position: absolute;
    top: 15px;
    left: 0;
  }
}

.contact-box > .box > .item > .tel-box > .sbttl-s, .contact-box > .box > .item .fax-box > .sbttl-s, .contact-box > .box > .item .post-box > .sbttl-s, .contact-box > .box > .item .mail-box > .sbttl-s {
  font-size: 1.6rem;
}

@media print, screen and (min-width: 768px) {
  .contact-box > .box > .item > .tel-box > .sbttl-s, .contact-box > .box > .item .fax-box > .sbttl-s, .contact-box > .box > .item .post-box > .sbttl-s, .contact-box > .box > .item .mail-box > .sbttl-s {
    position: absolute;
    top: 10px;
    left: 0;
  }
}

@media print, screen and (min-width: 768px) {
  .contact-box > .box > .item > .tel-box, .contact-box > .box > .item .fax-box {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-box > .box > .item > .tel-box, .contact-box > .box > .item .fax-box {
    padding: 10px 0;
  }
}

@media only screen and (max-width: 700px) {
  .contact-box > .box > .item > .tel-box, .contact-box > .box > .item .fax-box {
    margin-bottom: 10px;
    padding: 0 0 10px;
    border-bottom: 1px solid #808080;
  }
}

.contact-box > .box > .item > .tel-box > .fc, .contact-box > .box > .item .fax-box > .fc {
  display: flex;
  align-items: center;
}

.contact-box > .box > .item > .tel-box .icon-freecall, .contact-box > .box > .item .fax-box .icon-freecall {
  font-size: 4.2rem;
  margin: 5px 5px 0 0;
}

.contact-box > .box > .item > .tel-box .tel, .contact-box > .box > .item .fax-box .tel {
  display: inline-block;
  font-size: 3.2rem;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}

_:-ms-fullscreen, :root .contact-box > .box > .item > .tel-box .tel, :root .contact-box > .box > .item .fax-box .tel {
  padding-top: 10px;
}

@media only screen and (max-width: 767px) {
  .contact-box > .box > .item > .tel-box .tel, .contact-box > .box > .item .fax-box .tel {
    font-size: 4.3rem;
  }
}

@media only screen and (max-width: 375px) {
  .contact-box > .box > .item > .tel-box .tel, .contact-box > .box > .item .fax-box .tel {
    font-size: 11.5vw;
  }
}

.contact-box > .box > .item > .tel-box .tel > a, .contact-box > .box > .item .fax-box .tel > a {
  color: #333;
}

@media only screen and (max-width: 767px) {
  .contact-box > .box > .item > .tel-box .icon-freecall + .tel, .contact-box > .box > .item .fax-box .icon-freecall + .tel {
    font-size: 3.4rem;
  }
}

@media only screen and (max-width: 700px) {
  .contact-box > .box > .item > .tel-box .icon-freecall + .tel, .contact-box > .box > .item .fax-box .icon-freecall + .tel {
    font-size: 3.8rem;
  }
}

@media only screen and (max-width: 375px) {
  .contact-box > .box > .item > .tel-box .icon-freecall + .tel, .contact-box > .box > .item .fax-box .icon-freecall + .tel {
    font-size: 9.5vw;
  }
}

@media print, screen and (min-width: 768px) {
  .contact-box > .box > .item > .fax-box {
    margin-top: 10px;
  }
}

@media print, screen and (min-width: 768px) {
  .contact-box > .box > .item > .post-box {
    padding: 15px 0 20px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-box > .box > .item > .post-box {
    padding: 20px 0 50px 0;
  }
}

@media only screen and (max-width: 700px) {
  .contact-box > .box > .item > .post-box {
    margin-bottom: 10px;
    padding: 0 0 10px;
    border-bottom: 1px solid #808080;
  }
}

.contact-box > .box > .item > .post-box > .address {
  margin-bottom: 10px;
  font-size: 1.6rem;
}

@media only screen and (max-width: 767px) {
  .contact-box > .box > .item > .post-box > .address {
    margin: 10px 0 8px;
    font-size: 1.4rem;
  }
}

.contact-box > .box > .item > .post-box > .name {
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media print, screen and (min-width: 768px) {
  .contact-box > .box > .item > .mail-box {
    padding: 4px 0 0 75px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-box > .box > .item > .mail-box {
    padding: 10px 0;
  }
}

@media only screen and (max-width: 700px) {
  .contact-box > .box > .item > .mail-box {
    padding: 0 0 10px;
  }
}

.contact-box > .box > .item > .mail-box > .mail {
  margin-bottom: 5px;
  font-size: 2.6rem;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .contact-box > .box > .item > .mail-box > .mail {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 375px) {
  .contact-box > .box > .item > .mail-box > .mail {
    font-size: 7vw;
  }
}

.contact-box > .box > .item > .mail-box > .mail > a {
  text-decoration: none !important;
}

.contact-box > .box > .item > .mail-box > .mail > a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: underline !important;
}

@media print, screen and (min-width: 768px) {
  .contact-box > .box > .item > .mail-box > .mail > a:hover {
    text-decoration: underline !important;
  }
}

.contact-box > .box > .item > .mail-box > .mail.-long {
  margin-bottom: 5px;
  font-size: 2.3rem;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .contact-box > .box > .item > .mail-box > .mail.-long {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 375px) {
  .contact-box > .box > .item > .mail-box > .mail.-long {
    font-size: 6vw;
  }
}

.contact-box > .box > .item > .mail-box .note {
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  .contact-box > .box > .item > .mail-box .note {
    margin-top: 5px;
  }
}

.contact-box > .box > .item .txt-box {
  margin-top: 40px;
}

.contact-box > .box > .item .txt-box a {
  text-decoration: underline;
  text-indent: 0;
}

@media only screen and (max-width: 700px) {
  .contact-box > .box > .item .txt-box {
    margin-top: 0;
    padding: 10px 0;
    border-top: 1px solid #808080;
  }
}

@media print, screen and (min-width: 768px) {
  .contact-box > .box > .item > .fax-box:first-child {
    margin-top: 0;
  }
}

@media only screen and (max-width: 700px) {
  .contact-box > .box > .item > .fax-box:first-child {
    padding: 0;
    border-bottom: none;
  }
}

.contact-box.-soudankai > .ttl {
  background-color: #e62814;
}

.contact-box.-soudankai > .box > .division {
  color: #e62814;
}

.contact-box.-setsumeikai > .ttl {
  background-color: #e65014;
}

.contact-box.-setsumeikai > .box > .division {
  color: #e65014;
}

.contact-box.-hondas > .ttl {
  background-color: #60a718;
}

.contact-box.-hondas > .box > .division {
  color: #60a718;
}

/* ============================================================
	contact-area
============================================================ */
.contact-area {
  padding: 70px 0;
  height: 470px;
  background: #eaeaea url(/assets/images/bg_contact.jpg) top center no-repeat;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .contact-area {
    background-size: cover;
    padding: 30px 3%;
    width: 100%;
    height: auto;
  }
}

.contact-area > .ttl {
  display: inline-block;
  margin: 0 auto 20px;
  padding: 0 30px 15px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-size: 2.6rem;
  line-height: 1.2;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .contact-area > .ttl {
    padding: 0 0 15px;
    font-size: 2.2rem;
  }
}

.contact-area > .lead {
  font-size: 1.6rem;
  line-height: 1.4;
}

@media only screen and (max-width: 767px) {
  .contact-area > .lead {
    text-align: left;
    line-height: 1.5;
  }
}

.contact-area > .list {
  margin: 60px auto 0;
  width: 1080px;
}

@media print, screen and (min-width: 768px) {
  .contact-area > .list {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 767px) {
  .contact-area > .list {
    margin: 30px auto 0;
    width: 90%;
    max-width: 320px;
  }
}

.contact-area > .list > .linkbox {
  display: block;
  margin: 10px auto;
  padding: 40px;
  width: 332px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ccc;
  text-align: center;
  color: #333;
}

.contact-area > .list > .linkbox:visited {
  color: #333;
}

.contact-area > .list > .linkbox.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #333;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .contact-area > .list > .linkbox:hover {
    color: #333;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .contact-area > .list > .linkbox {
    padding: 20px;
    width: auto;
  }
}

.contact-area > .list > .linkbox > .ttl {
  position: relative;
  display: inline-block;
  margin: 0 auto 30px;
  padding-right: 20px;
  color: #36357e;
  font-size: 1.6rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

_:-ms-fullscreen, :root .contact-area > .list > .linkbox > .ttl {
  padding-top: 7px;
}

.contact-area > .list > .linkbox > .ttl::before {
  font-family: "bungeisha" !important;
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 1.2rem;
  transform: translate(0, -50%);
  display: inline-block;
  transition: right .1s;
}

.contact-area > .list > .linkbox > .lead {
  line-height: 1.5;
  text-align: left;
}

.contact-area > .list > .linkbox.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: rgba(255, 255, 255, 0.5);
}

.contact-area > .list > .linkbox.hover .ttl::before {
  right: -5px;
}

@media print, screen and (min-width: 768px) {
  .contact-area > .list > .linkbox:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  .contact-area > .list > .linkbox:hover .ttl::before {
    right: -5px;
  }
}

/* ============================================================
	allsearch
============================================================ */
.namazu-result-header {
  margin-bottom: 20px;
}

.namazu-result-header > h2 {
  display: block;
  margin-bottom: 20px;
  padding: 4px 8px;
  border-bottom: 2px solid #36357e;
  color: #555;
  font-size: 1.8rem;
  line-height: 1.4;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .namazu-result-header > h2 {
    margin-bottom: 15px;
    font-size: 1.6rem;
  }
}

.namazu-result-header > p {
  font-size: 1.4rem;
  line-height: 1.7;
}

@media only screen and (max-width: 767px) {
  .namazu-result-header > p {
    font-size: 1.4rem;
    line-height: 1.5;
  }
}

.namazu-result-header > p + p {
  margin-top: 5px;
}

.namazu-result-footer {
  margin-top: 20px;
  font-size: 1.4rem;
  line-height: 1.7;
}

.namazu-result-box {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fcfcfc;
  line-height: 1.7;
}

.namazu-result-box > a {
  font-size: 1.6rem;
}

.namazu-result-box > p {
  margin-top: 5px;
  font-size: 1.2rem;
}

.namazu-result-tips {
  font-size: 1.4rem;
  line-height: 1.7;
}

@media only screen and (max-width: 767px) {
  .namazu-result-tips {
    font-size: 1.4rem;
    line-height: 1.5;
  }
}

.namazu-result-tips > h3 {
  margin-bottom: 5px;
  font-size: 2rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.namazu-result-tips > .indent-list > li {
  margin-top: 20px;
}

.namazu-result-tips > .indent-list > li > code {
  font-size: 1.6rem;
}

/* ============================================================
	form-nav
============================================================ */
.form-nav {
  margin-bottom: 40px;
  display: table;
  table-layout: fixed;
  width: 100%;
  box-sizing: border-box;
  height: 48px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

@media only screen and (max-width: 767px) {
  .form-nav {
    margin-bottom: 20px;
  }
}

.form-nav > li {
  position: relative;
  display: table-cell;
  padding-left: 25px;
  text-align: center;
  vertical-align: middle;
  background-color: #eaeaea;
  color: #36357e;
  font-size: 1.6rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

@media only screen and (max-width: 767px) {
  .form-nav > li {
    font-size: 1.4rem;
  }
}

.form-nav > li:first-child {
  padding-left: 0;
}

.form-nav > li.current {
  background-color: #36357e;
  color: #fff;
  border-top: 1px solid #36357e;
  border-bottom: 1px solid #36357e;
}

.form-nav > li::before {
  content: "";
  position: absolute;
  top: -1px;
  right: -25px;
  bottom: 0;
  width: 0;
  height: 0;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  border-left: 24px solid #ccc;
  z-index: 10;
}

.form-nav > li:last-of-type:before {
  display: none;
}

.form-nav > li::after {
  content: "";
  position: absolute;
  top: -1px;
  right: -24px;
  bottom: 0;
  width: 0;
  height: 0;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  border-left: 24px solid #eaeaea;
  z-index: 10;
}

.form-nav > li:last-child::after {
  display: none;
}

.form-nav > li.current::after {
  border-left: 24px solid #36357e;
}

.form-nav.-publi > li {
  color: #e62814;
}

.form-nav.-publi > li.current {
  background-color: #e62814;
  color: #fff;
  border-top: 1px solid #e62814;
  border-bottom: 1px solid #e62814;
}

.form-nav.-publi > li.current::after {
  border-left: 24px solid #e62814;
}

.form-nav.-bkinfo > li {
  color: #ff9e00;
}

.form-nav.-bkinfo > li.current {
  background-color: #ff9e00;
  color: #fff;
  border-top: 1px solid #ff9e00;
  border-bottom: 1px solid #ff9e00;
}

.form-nav.-bkinfo > li.current::after {
  border-left: 24px solid #ff9e00;
}

.form-errmsg {
  font-size: 1.6rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f00;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .form-errmsg {
    margin-top: 30px;
  }
}

.form-errmsg > span {
  font-size: 1.6rem !important;
}

/* ============================================================
	formparts
============================================================ */
.ssl-box {
  margin-bottom: 60px;
}

@media print, screen and (min-width: 768px) {
  .ssl-box {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 767px) {
  .ssl-box {
    display: block;
    margin-bottom: 30px;
  }
}

.ssl-box > .seal {
  width: 120px;
  height: 59px;
}

.ssl-box > .wording {
  width: 930px;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .ssl-box > .wording {
    margin-top: 5px;
    width: auto;
  }
}

.form-lead {
  margin-bottom: 30px;
  font-size: 1.6rem;
  line-height: 1.7;
}

@media only screen and (max-width: 767px) {
  .form-lead {
    margin-bottom: 20px;
    font-size: 1.4rem;
  }
}

/* ============================================================
	file-box
============================================================ */
.file-box {
  display: flex;
  margin: 5px 0 15px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
  transition: all .3s;
}

.file-box #file-btn.btn,
.file-box #file-btn.btn-publi {
  display: inline-block;
  padding: 5px 15px;
  font-size: 1.6rem;
  white-space: nowrap;
}

.file-box #file-btn.btn::before,
.file-box #file-btn.btn-publi::before {
  display: none;
}

.file-box #file-select {
  display: none;
  width: 0 !important;
  height: 0;
  margin: 0;
  padding: 0;
  border: none;
}

.file-box input[type="text"] {
  background: none;
  box-shadow: none;
  border: none;
  margin: 0 0 0 10px;
  padding: 0;
  width: 100% !important;
  height: auto !important;
  color: #333;
}

/* ============================================================
	form-box
============================================================ */
.alert-block {
  margin: 30px 0;
  padding: 8px;
  width: 100%;
  background-color: #fbded9;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
}

.alert-block > p span {
  color: #f00;
}

.att-ttl {
  margin: 30px 0 0;
  padding: 8px;
  width: 100%;
  background-color: #fbded9;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
}

.att-ttl > p span {
  color: #f00;
}

.errMsg {
  font-size: 1.2rem;
  line-height: 1.2;
  color: #f00;
}

.form-box label {
  display: block;
}

.form-box > li {
  display: table;
  padding: 10px 0;
  border-bottom: 1px dotted #ccc;
  font-size: 1.4rem;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .form-box > li {
    display: block;
    border-bottom: none;
  }
}

.form-box > li > .ttl-box {
  display: table-cell;
  vertical-align: middle;
  width: 365px;
  padding: 10px 20px;
  background-color: #eaeaea;
}

.form-box > li > .ttl-box > .ttl span {
  margin-left: 5px;
  color: #f00;
}

@media only screen and (max-width: 767px) {
  .form-box > li > .ttl-box {
    display: block;
    width: 100%;
    padding: 8px 10px;
  }
}

.form-box > li > .item {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 715px;
  padding-left: 20px;
  word-break: break-all;
}

.form-box > li > .item > input[name="inputPostal"] {
  ime-mode: disabled;
}

@media only screen and (max-width: 767px) {
  .form-box > li > .item {
    display: block;
    width: 100%;
    padding: 5px 0 0 0;
  }
}

.form-box > li > .item .flex-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.form-box > li > .item .flex-wrap > label {
  display: inline-block;
}

.form-box .icon-check {
  position: relative;
  margin-left: 20px;
  padding: 5px;
  border-radius: 50%;
  background-color: #11cab6;
  font-size: 1.6rem;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .form-box .icon-check {
    margin-left: 10px;
  }
}

.form-box .icon-check.-multi {
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 10px;
}

@media only screen and (max-width: 767px) {
  .form-box .icon-check.-multi {
    top: 10%;
  }
}

.checkmark {
  display: none;
  vertical-align: middle;
}

.checkmark.is-show {
  display: inline-block;
}

.form-footer {
  margin: 40px 0;
  text-align: center;
}

.form-footer > a {
  width: 400px;
}

.form-footer > a.disabled {
  pointer-events: none;
  padding: 20px 40px;
  background-color: #ccc;
  border: 1px solid #ccc;
  color: #e62814;
}

.form-footer > a.disabled::before {
  display: none;
}

@media only screen and (max-width: 767px) {
  .form-footer {
    margin: 30px 0;
  }
  .form-footer > a {
    width: 100%;
  }
  .form-footer > a.disabled {
    padding: 20px 10px;
  }
}

.form-footer.-two > a {
  width: 200px;
}

@media only screen and (max-width: 767px) {
  .form-footer.-two > a {
    width: 40%;
  }
}

/* ============================================================
	form-item
============================================================ */
form label.validate-err {
  display: block;
  font-size: 1.4rem;
  color: #f00;
}

input:invalid {
  box-shadow: none;
}

input, textarea, select {
  margin: 5px 0;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  transition: all .3s;
}

input:focus,
textarea:focus,
select:focus {
  box-shadow: 0 0 8px #11cab6;
  border: 1px solid #11cab6;
  background-color: #d6ffff;
}

input[type="file"] {
  width: 100% !important;
  font-size: 1.6rem;
}

@media only screen and (max-width: 767px) {
  input[type="file"] {
    font-size: 1.2rem;
  }
}

input[type="text"] {
  width: 350px !important;
  height: 38px;
}

@media only screen and (max-width: 767px) {
  input[type="text"] {
    width: 82% !important;
  }
}

@media only screen and (max-width: 767px) {
  input[type="text"].size-full {
    width: 100% !important;
  }
}

input[type="number"] {
  width: 55px !important;
  height: 38px;
}

input[type="tel"] {
  width: 200px !important;
  height: 38px;
}

select {
  width: auto !important;
  min-width: 200px !important;
  height: 38px;
  cursor: pointer;
  background-image: url(/images/svg/arrow_down.svg);
  background-repeat: no-repeat;
  background-size: 12px 10px;
  background-position: right 10px center;
}

@media only screen and (max-width: 767px) {
  select {
    max-width: 82% !important;
  }
}

textarea {
  width: 100% !important;
  height: 150px;
  min-height: 150px;
}

.hiding_form textarea,
.hiding_form .hiding-wrap {
  display: none;
}

.hiding_form.-active textarea {
  display: block;
}

.hiding_form.-active .hiding-wrap {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .hiding_form.-active .hiding-wrap {
    display: block;
  }
}

.hiding_form.-active .hiding-wrap > p:nth-of-type(1) {
  margin-right: 10px;
  width: 200px;
}

.hiding_form.-active .hiding-wrap > p:nth-of-type(2) {
  margin-right: 10px;
  width: 200px;
}

.hiding_form.-active .hiding-wrap > p:nth-of-type(2) > input[type="text"] {
  width: 200px !important;
}

.hiding_form.-active .hiding-wrap > p:nth-of-type(3) {
  width: 100px;
}

.label-radio {
  margin: 10px 0;
}

.label-radio > input[type="radio"] {
  display: none;
}

.label-radio > .txt {
  margin-right: 20px;
  padding-left: 20px;
  position: relative;
  line-height: 1;
  cursor: pointer;
  transition: all .3s;
}

.label-radio > .txt::before {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  left: 0;
  width: 15px !important;
  height: 15px !important;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: #fff;
}

.label-radio > .txt.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #11cab6;
}

@media print, screen and (min-width: 768px) {
  .label-radio > .txt:hover {
    color: #11cab6;
  }
}

.label-radio > input[type="radio"]:checked + .txt::after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: 3px;
  width: 11px !important;
  height: 11px !important;
  background: #11cab6;
  border-radius: 50%;
}

.ui-autocomplete {
  width: 400px;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
}

.ui-menu-item-wrapper {
  padding-left: 5px;
}

.ui-state-active {
  padding-left: 5px;
  border: 1px solid #11cab6 !important;
  background: #eaeaea !important;
  font-weight: bold !important;
  color: #000;
  cursor: pointer;
}

.ui-helper-hidden-accessible > * {
  display: none;
}

/* ============================================================
	datepicker
============================================================ */
.appendDatepicker {
  position: relative;
}

.ui-datepicker {
  top: 0 !important;
  left: 0 !important;
}

.ui-datepicker-prev .ui-datepicker-next {
  transition: none;
}

.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
  top: 2px !important;
}

.ui-datepicker .ui-datepicker-prev-hover {
  left: 2px !important;
}

.ui-datepicker .ui-datepicker-next-hover {
  right: 2px !important;
}

input[type="text"].date {
  width: 238px !important;
}

/* 日曜日 */
.day-sunday .ui-state-default, .day-sunday .ui-state-active {
  background-image: none !important;
  background-color: #FFC0CB !important;
}

/* 土曜日 */
.day-saturday .ui-state-default, .day-saturday .ui-state-active {
  background-image: none !important;
  background-color: #AFEEEE !important;
}

/* 祝祭日 */
.day-holiday .ui-state-default, .day-holiday .ui-state-active {
  background-image: none !important;
  background-color: #FFC0CB !important;
}

/* 年月のリストメニュー */
.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
  width: auto;
}

.ui-datepicker-calendar .ui-state-active {
  border: 1px solid #003eff !important;
  background: #007fff !important;
  font-weight: normal !important;
}

/* ============================================================
	select-box
============================================================ */
.select-box {
  display: flex;
}

.select-box li select {
  min-width: 77px !important;
  margin-right: 2px;
}

.select-box li:first-of-type select {
  min-width: 86px !important;
}

.form-box .select-box .icon-check {
  margin-left: 0;
  margin-right: 6px;
  font-size: 1rem;
}

/* ============================================================
	toppage-main
============================================================ */
.toppage-main {
  width: 100%;
  height: 600px;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .toppage-main {
    width: 100%;
    height: auto;
  }
}

.toppage-main .video-wrap {
  position: absolute;
  display: block;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background-size: cover;
  opacity: 1;
}

.toppage-main .video-wrap > video {
  width: auto;
  height: auto;
  position: absolute;
  top: 20%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -20%);
}

.toppage-main .copy {
  z-index: 2;
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 44%;
  height: 165px;
  padding: 30px;
  text-align: left;
}

.toppage-main .copy img {
  width: auto;
  height: 100%;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
}

.toppage-main .copy_25th {
  z-index: 2;
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 43%;
  height: 220px;
  padding: 30px;
  text-align: left;
}

.toppage-main .copy_25th > a {
  display: inline-block;
  height: 160px;
}

.toppage-main .copy_25th img {
  width: auto;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .toppage-main > .container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 767px;
    margin-bottom: 30px;
    overflow: hidden;
  }
  .toppage-main .swiper-container {
    width: 100%;
    height: 100%;
    overflow: visible;
    z-index: 1;
  }
  @keyframes zoomUp {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.15);
    }
  }
  .toppage-main .swiper-container .swiper-slide-active .slide-img,
  .toppage-main .swiper-container .swiper-slide-duplicate-active .slide-img,
  .toppage-main .swiper-container .swiper-slide-prev .slide-img {
    animation: zoomUp 10s linear 0s 1 normal both;
  }
  .toppage-main .swiper-container .slide-img img {
    display: block;
  }
  .toppage-main .copy {
    right: 2vw;
    bottom: 2vw;
    width: auto;
    height: 70px;
    padding: 0 0 1vw 0;
    text-align: right;
  }
  .toppage-main .copy_25th {
    right: 2vw;
    bottom: 2vw;
    width: auto;
    height: 123px;
    padding: 0 1vw 1vw 0;
    text-align: right;
  }
  .toppage-main .copy_25th > a {
    display: block;
    width: auto;
    height: 100%;
  }
  .toppage-main .copy_25th img {
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  }
}

@media only screen and (max-width: 479px) {
  .toppage-main .copy {
    height: 50px;
  }
  .toppage-main .copy_25th {
    height: 103px;
  }
}

/* ============================================================
	toppage-news
============================================================ */
.toppage-news {
  margin: 20px auto 80px;
  position: relative;
  width: 1080px;
}

@media print, screen and (min-width: 768px) {
  .toppage-news {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 767px) {
  .toppage-news {
    margin: 0 3% 50px;
    width: 94%;
  }
}

.toppage-news > .ttl-box {
  color: #36357e;
}

@media print, screen and (min-width: 768px) {
  .toppage-news > .ttl-box {
    padding-top: 70px;
    width: 200px;
    min-height: 212px;
  }
}

.toppage-news > .ttl-box > .ttl {
  margin-bottom: 5px;
  font-size: 3.6rem;
  font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .toppage-news > .ttl-box > .ttl {
    font-size: 2.8rem;
  }
}

.toppage-news > .ttl-box > .jpn {
  font-size: 1.4rem;
}

@media only screen and (max-width: 767px) {
  .toppage-news > .ttl-box > .jpn {
    font-size: 1.2rem;
  }
}

.toppage-news > .btn-box {
  position: absolute;
}

@media print, screen and (min-width: 768px) {
  .toppage-news > .btn-box {
    bottom: 6px;
    left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .toppage-news > .btn-box {
    top: 6px;
    right: 0;
  }
}

.toppage-news .btnw {
  padding: 10px 40px 10px 20px;
  font-size: 1.6rem;
}

_:-ms-fullscreen, :root .toppage-news .btnw {
  padding-top: 17px;
}

@media only screen and (max-width: 767px) {
  .toppage-news .btnw {
    padding: 5px 30px 5px 10px;
    font-size: 1.3rem;
  }
  _:-ms-fullscreen, :root .toppage-news .btnw {
    padding-top: 12px;
  }
}

.toppage-news > .list {
  width: 800px;
}

@media only screen and (max-width: 767px) {
  .toppage-news > .list {
    margin-top: 20px;
    width: 100%;
  }
}

.toppage-news > .list > li {
  margin: 0 !important;
  display: block;
  border-top: 1px solid #ccc;
}

.toppage-news > .list > li:first-child {
  border-top: none;
}

.toppage-news > .list > li.info + li.news {
  position: relative;
  margin-top: 15px;
  padding-top: 15px;
  border-top: solid 1px #b4b4b4;
}

@media only screen and (max-width: 767px) {
  .toppage-news > .list > li.info + li.news {
    margin-top: 10px;
    padding-top: 10px;
    border-top: solid 2px #b4b4b4;
  }
}

.toppage-news > .list > li.info + li.news::after {
  position: absolute;
  content: "";
  display: block;
  border-top: solid 1px #36357e;
  top: -1px;
  width: 20%;
}

@media only screen and (max-width: 767px) {
  .toppage-news > .list > li.info + li.news::after {
    border-top: solid 2px #36357e;
    top: -2px;
    width: 50%;
  }
}

.toppage-news > .list > li > a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 6px 5px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .toppage-news > .list > li > a {
    padding: 10px 0;
  }
}

@media print, screen and (min-width: 768px) {
  .toppage-news > .list > li > a > p {
    margin: 0 5px;
  }
}

.toppage-news > .list > li > a > p.thumb {
  margin-right: 5px;
  width: 72px;
  border: 1px solid #eaeaea;
}

.toppage-news > .list > li > a > p.date {
  width: 106px;
  color: #a5a5a5;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .toppage-news > .list > li > a > p.date {
    margin-right: 5px;
    width: auto;
    font-size: 1.2rem;
  }
}

.toppage-news > .list > li > a > p.label {
  padding: 3px;
  border-radius: 3px;
  text-align: center;
  border: 1px solid #6d6564;
  color: #6d6564;
  white-space: nowrap;
  width: 90px;
  font-size: 1.2rem;
}

_:-ms-fullscreen, :root .toppage-news > .list > li > a > p.label {
  padding-top: 7px;
}

.toppage-news > .list > li > a > p.label.-c02 {
  border: 1px solid #7b76b4;
  color: #7b76b4;
}

.toppage-news > .list > li > a > p.label.-c03 {
  border: 1px solid #ed5c39;
  color: #ed5c39;
}

.toppage-news > .list > li > a > p.label.-c04 {
  border: 1px solid #6ba25a;
  color: #6ba25a;
}

.toppage-news > .list > li > a > p.label.-c05 {
  border: 1px solid #0aa59b;
  color: #0aa59b;
}

.toppage-news > .list > li > a > p.label.-c06 {
  border: 1px solid #ff9e00;
  color: #ff9e00;
}

.toppage-news > .list > li > a > p.label.-c07 {
  border: 1px solid #ba4986;
  color: #ba4986;
}

.toppage-news > .list > li > a > p.label.-c08 {
  border: 1px solid #4492c4;
  color: #4492c4;
}

@media only screen and (max-width: 767px) {
  .toppage-news > .list > li > a > p.label {
    width: auto;
    font-size: 1.2rem;
  }
}

.toppage-news > .list > li > a > p.txt {
  width: 482px;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .toppage-news > .list > li > a > p.txt {
    margin-top: 5px;
    width: 100%;
  }
}

.toppage-news > .list > li > a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 0.7;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .toppage-news > .list > li > a:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.toppage-news > .list > li > a.link-none {
  pointer-events: none;
  color: #333;
}

.toppage-news > .list > li > a.link-none.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 1;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .toppage-news > .list > li > a.link-none:hover {
    opacity: 1;
    text-decoration: none;
  }
}

/* ============================================================
	toppage-bkinfo
============================================================ */
.toppage-bkinfo {
  margin: 80px auto;
  position: relative;
  width: 1080px;
}

@media only screen and (max-width: 767px) {
  .toppage-bkinfo {
    margin: 0 3% 50px;
    width: 94%;
  }
}

.toppage-bkinfo > .ttl-box {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: #36357e;
}

.toppage-bkinfo > .ttl-box > .ttl {
  display: flex;
  align-items: center;
  font-size: 3.6rem;
  font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .toppage-bkinfo > .ttl-box > .ttl {
    font-size: 2.8rem;
  }
}

.toppage-bkinfo > .ttl-box > .ttl > span {
  display: block;
  padding-right: 20px;
  border-right: 1px solid #36357e;
}

@media only screen and (max-width: 767px) {
  .toppage-bkinfo > .ttl-box > .ttl > span {
    padding-right: 15px;
  }
}

.toppage-bkinfo > .ttl-box > .ttl > span.jpn {
  padding-left: 20px;
  border: none;
  font-size: 2rem;
  font-style: normal;
}

_:-ms-fullscreen, :root .toppage-bkinfo > .ttl-box > .ttl > span.jpn {
  padding-top: 7px;
}

@media only screen and (max-width: 767px) {
  .toppage-bkinfo > .ttl-box > .ttl > span.jpn {
    padding-left: 15px;
    font-size: 1.6rem;
  }
}

.toppage-bkinfo > .ttl-box > .link-box {
  margin-top: 0;
}

.toppage-bkinfo > .ttl-box > .link-box > .link-arrow {
  font-size: 1.6rem;
}

_:-ms-fullscreen, :root .toppage-bkinfo > .ttl-box > .link-box > .link-arrow {
  padding-top: 7px;
}

.toppage-bkinfo > .menu-area {
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.toppage-bkinfo > .menu-area > li {
  position: relative;
  padding: 18px 0;
  width: 260px;
  background-color: #eaeaea;
  color: #333;
  border-radius: 3px;
  font-size: 1.6rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  cursor: pointer;
}

_:-ms-fullscreen, :root .toppage-bkinfo > .menu-area > li {
  padding-top: 25px;
}

@media only screen and (max-width: 767px) {
  .toppage-bkinfo > .menu-area > li {
    padding: 12px 0;
    width: 20vw;
    font-size: 1.4rem;
  }
}

.toppage-bkinfo > .menu-area > li.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #36357e;
  color: #fff;
  text-decoration: none;
  transition: all .3s;
}

@media print, screen and (min-width: 768px) {
  .toppage-bkinfo > .menu-area > li:hover {
    background-color: #36357e;
    color: #fff;
    text-decoration: none;
    transition: all .3s;
  }
}

.toppage-bkinfo > .menu-area > li.current {
  background-color: #36357e;
  color: #fff;
}

.toppage-bkinfo > .menu-area > li.current span {
  color: #fff;
  transition: all .3s;
}

.toppage-bkinfo > .menu-area > li.current::before {
  content: "";
  position: absolute;
  top: 98%;
  left: 50%;
  margin-left: -15px;
  border-top: 20px solid #36357e;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
}

@media only screen and (max-width: 767px) {
  .toppage-bkinfo > .menu-area > li.current::before {
    margin-left: -10px;
    border-top: 15px solid #36357e;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }
}

@media only screen and (max-width: 767px) {
  .toppage-bkinfo > .menu-area > li:nth-of-type(1), .toppage-bkinfo > .menu-area > li:nth-of-type(4) {
    width: 25vw;
  }
}

.toppage-bkinfo > .book-area {
  display: none;
  width: 100%;
}

.toppage-bkinfo > .book-area.current {
  display: block;
}

.toppage-bkinfo > .book-area > .list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.toppage-bkinfo > .book-area > .list > li {
  display: block;
  padding: 0 40px;
  width: 270px;
}

@media only screen and (max-width: 767px) {
  .toppage-bkinfo > .book-area > .list > li {
    margin-bottom: 30px;
    padding: 0 10px;
    width: 50%;
  }
}

.toppage-bkinfo > .book-area > .list > li > a {
  display: block;
}

.toppage-bkinfo > .book-area > .list > li > a > p {
  margin: 10px 0;
}

.toppage-bkinfo > .book-area > .list > li > a > p.thumb {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  height: 222px;
}

@media only screen and (max-width: 767px) {
  .toppage-bkinfo > .book-area > .list > li > a > p.thumb {
    margin: 0;
    height: auto;
    min-height: 222px;
  }
}

.toppage-bkinfo > .book-area > .list > li > a > p.thumb img {
  width: auto;
  max-width: 192px;
  border: 1px solid #ccc;
}

@media print, screen and (min-width: 768px) {
  .toppage-bkinfo > .book-area > .list > li > a > p.thumb img {
    max-height: 222px;
  }
}

@media only screen and (max-width: 767px) {
  .toppage-bkinfo > .book-area > .list > li > a > p.thumb img {
    width: 100%;
    max-width: 142px;
  }
}

.toppage-bkinfo > .book-area > .list > li > a > p.ttl {
  font-size: 1.8rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
  text-align: left;
  word-break: break-all;
}

@media only screen and (max-width: 767px) {
  .toppage-bkinfo > .book-area > .list > li > a > p.ttl {
    font-size: 1.6rem;
  }
}

.toppage-bkinfo > .book-area > .list > li > a > p.name {
  padding-bottom: 10px;
  font-size: 1.2rem;
  border-bottom: 1px solid #ccc;
}

.toppage-bkinfo > .book-area > .list > li > a > p.txt {
  font-size: 1.3rem;
  line-height: 1.4;
  text-align: justify;
}

.toppage-bkinfo > .book-area > .list > li > a > p.data {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #a5a5a5;
}

.toppage-bkinfo > .book-area > .list > li > a > p.data > .label {
  padding: 3px;
  border-radius: 3px;
  text-align: center;
  border: 1px solid #6d6564;
  color: #6d6564;
  white-space: nowrap;
  margin-right: 5px;
  border: 1px solid #a5a5a5;
  font-size: 1rem;
  color: #a5a5a5;
}

_:-ms-fullscreen, :root .toppage-bkinfo > .book-area > .list > li > a > p.data > .label {
  padding-top: 7px;
}

.toppage-bkinfo > .book-area > .list > li > a > p.data > .label.-c02 {
  border: 1px solid #7b76b4;
  color: #7b76b4;
}

.toppage-bkinfo > .book-area > .list > li > a > p.data > .label.-c03 {
  border: 1px solid #ed5c39;
  color: #ed5c39;
}

.toppage-bkinfo > .book-area > .list > li > a > p.data > .label.-c04 {
  border: 1px solid #6ba25a;
  color: #6ba25a;
}

.toppage-bkinfo > .book-area > .list > li > a > p.data > .label.-c05 {
  border: 1px solid #0aa59b;
  color: #0aa59b;
}

.toppage-bkinfo > .book-area > .list > li > a > p.data > .label.-c06 {
  border: 1px solid #ff9e00;
  color: #ff9e00;
}

.toppage-bkinfo > .book-area > .list > li > a > p.data > .label.-c07 {
  border: 1px solid #ba4986;
  color: #ba4986;
}

.toppage-bkinfo > .book-area > .list > li > a > p.data > .label.-c08 {
  border: 1px solid #4492c4;
  color: #4492c4;
}

.toppage-bkinfo > .book-area > .list > li > a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 0.7;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .toppage-bkinfo > .book-area > .list > li > a:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.toppage-bkinfo > .btn-box {
  display: none;
}

@media only screen and (max-width: 767px) {
  .toppage-bkinfo > .btn-box {
    display: block;
  }
}

/* ============================================================
	toppage-pickup
============================================================ */
.toppage-pickup {
  position: relative;
  margin: 60px auto 0;
  padding: 40px;
  width: 100%;
  background-color: #eaeaea;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .toppage-pickup {
    margin: 0;
    padding: 30px 0;
  }
}

.toppage-pickup > .ttl-box {
  margin: 0 auto 40px;
  width: 1080px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: #36357e;
}

@media only screen and (max-width: 767px) {
  .toppage-pickup > .ttl-box {
    margin: 0 3% 30px;
    width: 94%;
  }
}

.toppage-pickup > .ttl-box > .ttl {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.6rem;
  font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .toppage-pickup > .ttl-box > .ttl {
    font-size: 2.8rem;
  }
}

.toppage-pickup > .ttl-box > .ttl > span {
  display: block;
  padding-right: 20px;
  border-right: 1px solid #36357e;
}

@media only screen and (max-width: 767px) {
  .toppage-pickup > .ttl-box > .ttl > span {
    padding-right: 15px;
  }
}

.toppage-pickup > .ttl-box > .ttl > span.jpn {
  padding-left: 20px;
  border: none;
  font-size: 2rem;
  font-style: normal;
}

_:-ms-fullscreen, :root .toppage-pickup > .ttl-box > .ttl > span.jpn {
  padding-top: 7px;
}

@media only screen and (max-width: 767px) {
  .toppage-pickup > .ttl-box > .ttl > span.jpn {
    padding-left: 15px;
    font-size: 1.6rem;
  }
}

.toppage-pickup > .ttl-box > .link-box {
  margin-top: 0;
}

.toppage-pickup > .ttl-box > .link-box > .link-arrow {
  font-size: 1.6rem;
}

.toppage-pickup > .container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  max-width: 370px;
}

@media only screen and (max-width: 767px) {
  .toppage-pickup > .container {
    margin-bottom: 30px;
    max-width: 190px;
  }
}

.toppage-pickup > .container > .swiper-container {
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 1;
}

.toppage-pickup > .container > .swiper-pagination {
  width: 100%;
  position: absolute;
  bottom: -40px !important;
  left: 50%;
  transform: translate(-50%, 0);
}

@media only screen and (max-width: 767px) {
  .toppage-pickup > .container > .swiper-pagination {
    bottom: -30px !important;
  }
}

.toppage-pickup .swiper-pagination-bullet {
  margin: 0 5px;
  width: 12px;
  height: 12px;
  transition: all .3s;
}

.toppage-pickup .swiper-pagination-bullet.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: .2;
}

@media print, screen and (min-width: 768px) {
  .toppage-pickup .swiper-pagination-bullet:hover {
    opacity: .2;
  }
}

.toppage-pickup .swiper-pagination-bullet-active {
  background: #36357e;
}

.toppage-pickup .swiper-slide a {
  display: inline-block;
  margin: 0 10px;
  width: 360px;
  height: 340px;
  background-color: #fff !important;
}

@media only screen and (max-width: 767px) {
  .toppage-pickup .swiper-slide a {
    width: 180px;
    height: 220px;
  }
}

.toppage-pickup .swiper-slide a > p.data {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  color: #a5a5a5;
}

@media only screen and (max-width: 767px) {
  .toppage-pickup .swiper-slide a > p.data {
    padding: 10px;
    font-size: 1.2rem;
  }
}

.toppage-pickup .swiper-slide a > p.data > .label {
  padding: 3px;
  border-radius: 3px;
  text-align: center;
  border: 1px solid #6d6564;
  color: #6d6564;
  white-space: nowrap;
  font-size: 1rem;
}

_:-ms-fullscreen, :root .toppage-pickup .swiper-slide a > p.data > .label {
  padding-top: 7px;
}

.toppage-pickup .swiper-slide a > p.data > .label.-c02 {
  border: 1px solid #7b76b4;
  color: #7b76b4;
}

.toppage-pickup .swiper-slide a > p.data > .label.-c03 {
  border: 1px solid #ed5c39;
  color: #ed5c39;
}

.toppage-pickup .swiper-slide a > p.data > .label.-c04 {
  border: 1px solid #6ba25a;
  color: #6ba25a;
}

.toppage-pickup .swiper-slide a > p.data > .label.-c05 {
  border: 1px solid #0aa59b;
  color: #0aa59b;
}

.toppage-pickup .swiper-slide a > p.data > .label.-c06 {
  border: 1px solid #ff9e00;
  color: #ff9e00;
}

.toppage-pickup .swiper-slide a > p.data > .label.-c07 {
  border: 1px solid #ba4986;
  color: #ba4986;
}

.toppage-pickup .swiper-slide a > p.data > .label.-c08 {
  border: 1px solid #4492c4;
  color: #4492c4;
}

.toppage-pickup .swiper-slide a > p.ttl {
  padding: 0 15px;
  font-size: 1.6rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  text-align: justify;
  overflow-wrap: break-word;
}

_:-ms-fullscreen, :root .toppage-pickup .swiper-slide a > p.ttl {
  word-wrap: break-word;
}

@media only screen and (max-width: 767px) {
  .toppage-pickup .swiper-slide a > p.ttl {
    padding: 0 10px;
    font-size: 1.4rem;
    line-height: 1.4;
  }
}

.toppage-pickup .swiper-slide a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 0.7;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .toppage-pickup .swiper-slide a:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

/* ============================================================
	toppage-calendar
============================================================ */
.toppage-calendar {
  position: relative;
  margin: 0 auto 60px;
  padding: 40px;
  width: 100%;
  text-align: center;
}

.toppage-calendar.-spring {
  background: url(/images/top/bg_calendar_spring.jpg) center no-repeat;
  background-size: cover;
}

.toppage-calendar.-summer {
  background: url(/images/top/bg_calendar_summer.jpg) center 60% no-repeat;
  background-size: cover;
}

.toppage-calendar.-autumn {
  background: url(/images/top/bg_calendar_autumn.jpg) center no-repeat;
  background-size: cover;
}

.toppage-calendar.-winter {
  background: url(/images/top/bg_calendar_winter.jpg) center no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 767px) {
  .toppage-calendar {
    margin: 0;
    padding: 30px 0;
  }
}

.toppage-calendar > .ttl-box {
  margin: 0 auto 40px;
  width: 1080px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .toppage-calendar > .ttl-box {
    margin: 0 3% 30px;
    width: 94%;
  }
}

.toppage-calendar > .ttl-box > .ttl {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.6rem;
  font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
  font-weight: 400;
  text-shadow: 0 0 3px #555, 0 0 4px #555, 0 0 5px #555;
}

@media only screen and (max-width: 767px) {
  .toppage-calendar > .ttl-box > .ttl {
    font-size: 2.8rem;
  }
}

.toppage-calendar > .ttl-box > .ttl > span {
  display: block;
  padding-right: 20px;
  border-right: 1px solid #fff;
}

@media only screen and (max-width: 767px) {
  .toppage-calendar > .ttl-box > .ttl > span {
    padding-right: 15px;
  }
}

.toppage-calendar > .ttl-box > .ttl > span.jpn {
  padding-left: 20px;
  border: none;
  font-size: 2rem;
  font-style: normal;
}

_:-ms-fullscreen, :root .toppage-calendar > .ttl-box > .ttl > span.jpn {
  padding-top: 7px;
}

@media only screen and (max-width: 767px) {
  .toppage-calendar > .ttl-box > .ttl > span.jpn {
    padding-left: 15px;
    font-size: 1.6rem;
  }
}

.toppage-calendar > .ttl-box > .link-box {
  margin-top: 0;
}

.toppage-calendar > .ttl-box > .link-box > .link-arrow {
  font-size: 1.6rem;
}

.toppage-calendar > .list {
  margin: 40px auto;
  display: flex;
  flex-wrap: wrap;
  width: 1080px;
}

@media only screen and (max-width: 767px) {
  .toppage-calendar > .list {
    margin: 0 3% 2px;
    width: 94%;
    height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .toppage-calendar > .list::-webkit-scrollbar {
    width: 10px;
  }
  .toppage-calendar > .list::-webkit-scrollbar-track {
    background: #eaeaea;
    border: 2px solid #eaeaea;
    border-radius: 5px;
    padding: 5px 0;
  }
  .toppage-calendar > .list::-webkit-scrollbar-thumb {
    background: #808080;
    border: 2px solid #eaeaea;
    border-radius: 5px;
    box-shadow: none;
  }
}

.toppage-calendar > .list > li {
  position: relative;
  margin: 2px 20px;
  width: 500px;
  text-align: left;
  background: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 767px) {
  .toppage-calendar > .list > li {
    margin: 2px 0;
    width: 100%;
  }
}

@media print, screen and (min-width: 768px) {
  .toppage-calendar > .list > li.item01 {
    order: 1;
  }
  .toppage-calendar > .list > li.item02 {
    order: 3;
  }
  .toppage-calendar > .list > li.item03 {
    order: 5;
  }
  .toppage-calendar > .list > li.item04 {
    order: 7;
  }
  .toppage-calendar > .list > li.item05 {
    order: 9;
  }
  .toppage-calendar > .list > li.item06 {
    order: 2;
  }
  .toppage-calendar > .list > li.item07 {
    order: 4;
  }
  .toppage-calendar > .list > li.item08 {
    order: 6;
  }
  .toppage-calendar > .list > li.item09 {
    order: 8;
  }
  .toppage-calendar > .list > li.item10 {
    order: 10;
  }
}

.toppage-calendar > .list > li > a {
  padding: 15px;
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
  color: #fff;
}

@media print, screen and (min-width: 768px) {
  .toppage-calendar > .list > li > a {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .toppage-calendar > .list > li > a {
    display: block;
    padding: 10px;
  }
}

.toppage-calendar > .list > li > a > p.data {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.toppage-calendar > .list > li > a > p.data > .label {
  padding: 3px;
  border-radius: 3px;
  text-align: center;
  border: 1px solid #6d6564;
  color: #6d6564;
  white-space: nowrap;
  margin-left: 5px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 1.2rem;
}

_:-ms-fullscreen, :root .toppage-calendar > .list > li > a > p.data > .label {
  padding-top: 7px;
}

.toppage-calendar > .list > li > a > p.data > .label.-c02 {
  border: 1px solid #7b76b4;
  color: #7b76b4;
}

.toppage-calendar > .list > li > a > p.data > .label.-c03 {
  border: 1px solid #ed5c39;
  color: #ed5c39;
}

.toppage-calendar > .list > li > a > p.data > .label.-c04 {
  border: 1px solid #6ba25a;
  color: #6ba25a;
}

.toppage-calendar > .list > li > a > p.data > .label.-c05 {
  border: 1px solid #0aa59b;
  color: #0aa59b;
}

.toppage-calendar > .list > li > a > p.data > .label.-c06 {
  border: 1px solid #ff9e00;
  color: #ff9e00;
}

.toppage-calendar > .list > li > a > p.data > .label.-c07 {
  border: 1px solid #ba4986;
  color: #ba4986;
}

.toppage-calendar > .list > li > a > p.data > .label.-c08 {
  border: 1px solid #4492c4;
  color: #4492c4;
}

.toppage-calendar > .list > li > a > p.ttl {
  margin-left: 10px;
  line-height: 1.2;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .toppage-calendar > .list > li > a > p.ttl {
    margin: 5px 0 0;
  }
}

.toppage-calendar > .list > li > a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 0.7;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .toppage-calendar > .list > li > a:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.toppage-calendar > .lead {
  margin: 0 auto;
  display: inline-block;
  color: #fff;
  text-align: left;
  text-shadow: 0 0 3px #333, 0 0 4px #333, 0 0 5px #333;
}

.toppage-calendar > .lead > span {
  display: block;
  margin-bottom: 10px;
  font-size: 1.8rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .toppage-calendar > .lead {
    margin: 20px 3% 0;
    width: 94%;
    line-height: 1.5;
  }
  .toppage-calendar > .lead > span {
    line-height: 1.2;
  }
}

.toppage-calendar.-spring > .list > li {
  border-left: #00e3d9 2px solid;
}

.toppage-calendar.-summer > .list > li {
  border-left: #6eeaff 2px solid;
}

.toppage-calendar.-autumn > .list > li {
  border-left: #ffb45a 2px solid;
}

.toppage-calendar.-winter > .list > li {
  border-left: #a49ede 2px solid;
}

.toppage-calendar.-spring > .lead > span {
  color: #00e3d9;
  text-shadow: 0 0 3px #555,0 0 4px #555,0 0 5px #555;
}

.toppage-calendar.-summer > .lead > span {
  color: #6eeaff;
  text-shadow: 0 0 3px #555,0 0 4px #555,0 0 5px #555;
}

.toppage-calendar.-autumn > .lead > span {
  color: #ffb45a;
}

.toppage-calendar.-winter > .lead > span {
  color: #3930a5;
  text-shadow: 0 0 3px #eaeaea, 0 0 4px #eaeaea, 0 0 5px #eaeaea;
}

/* ============================================================
	toppage-banner
============================================================ */
.toppage-banner {
  position: relative;
  margin: 60px auto;
  padding: 40px;
  width: 100%;
  background-color: #eaeaea;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .toppage-banner {
    margin: 30px auto;
    padding: 30px 0;
  }
}

.toppage-banner > .container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 240px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .toppage-banner > .container {
    margin-bottom: 30px;
  }
}

.toppage-banner > .container > .swiper-container {
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 1;
}

.toppage-banner > .container > .swiper-pagination {
  width: 100%;
  position: absolute;
  bottom: -50px !important;
  left: 50%;
  transform: translate(-50%, 0);
}

@media only screen and (max-width: 767px) {
  .toppage-banner > .container > .swiper-pagination {
    bottom: -35px !important;
  }
}

.toppage-banner .swiper-pagination-bullet {
  margin: 0 5px;
  width: 12px;
  height: 12px;
  transition: all .3s;
}

.toppage-banner .swiper-pagination-bullet.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: .2;
}

@media print, screen and (min-width: 768px) {
  .toppage-banner .swiper-pagination-bullet:hover {
    opacity: .2;
  }
}

.toppage-banner .swiper-pagination-bullet-active {
  background: #36357e;
}

.toppage-banner .swiper-slide a {
  display: inline-block;
  margin: 0 5px;
  width: 230px;
  height: 76px;
  background-color: #fff;
}

/* ============================================================
	news-box
============================================================ */
.news-box {
  margin: 0 auto 80px;
  position: relative;
  width: 1080px;
}

@media only screen and (max-width: 767px) {
  .news-box {
    margin: 0 auto 30px;
    width: 100%;
  }
}

.news-box > .ttl {
  position: relative;
  padding: 0 0 10px 5px;
  border-bottom: solid 1px #b4b4b4;
  font-size: 1.6rem;
  color: #36357e;
}

.news-box > .ttl::after {
  position: absolute;
  content: " ";
  display: block;
  border-bottom: solid 1px #36357e;
  bottom: -1px;
  left: -0;
  width: 20%;
}

@media only screen and (max-width: 767px) {
  .news-box > .ttl::after {
    width: 40%;
  }
}

.news-box > .list {
  width: 1080px;
}

@media only screen and (max-width: 767px) {
  .news-box > .list {
    width: 100%;
  }
}

.news-box > .list > li {
  margin: 0 !important;
  display: block;
  border-top: 1px solid #ccc;
}

.news-box > .list > li:first-child {
  border-top: none;
}

.news-box > .list > li > a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 5px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .news-box > .list > li > a {
    padding: 10px 0;
  }
}

@media print, screen and (min-width: 768px) {
  .news-box > .list > li > a > p {
    margin: 0 5px;
  }
}

.news-box > .list > li > a > p.thumb {
  margin-right: 5px;
  width: 72px;
  border: 1px solid #eaeaea;
}

.news-box > .list > li > a > p.date {
  width: 106px;
  color: #a5a5a5;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .news-box > .list > li > a > p.date {
    margin-right: 5px;
    width: auto;
    font-size: 1.2rem;
  }
}

.news-box > .list > li > a > p.label {
  padding: 3px;
  border-radius: 3px;
  text-align: center;
  border: 1px solid #6d6564;
  color: #6d6564;
  white-space: nowrap;
  width: 90px;
  font-size: 1.2rem;
}

_:-ms-fullscreen, :root .news-box > .list > li > a > p.label {
  padding-top: 7px;
}

.news-box > .list > li > a > p.label.-c02 {
  border: 1px solid #7b76b4;
  color: #7b76b4;
}

.news-box > .list > li > a > p.label.-c03 {
  border: 1px solid #ed5c39;
  color: #ed5c39;
}

.news-box > .list > li > a > p.label.-c04 {
  border: 1px solid #6ba25a;
  color: #6ba25a;
}

.news-box > .list > li > a > p.label.-c05 {
  border: 1px solid #0aa59b;
  color: #0aa59b;
}

.news-box > .list > li > a > p.label.-c06 {
  border: 1px solid #ff9e00;
  color: #ff9e00;
}

.news-box > .list > li > a > p.label.-c07 {
  border: 1px solid #ba4986;
  color: #ba4986;
}

.news-box > .list > li > a > p.label.-c08 {
  border: 1px solid #4492c4;
  color: #4492c4;
}

@media only screen and (max-width: 767px) {
  .news-box > .list > li > a > p.label {
    width: auto;
    font-size: 1.2rem;
  }
}

.news-box > .list > li > a > p.txt {
  width: 762px;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .news-box > .list > li > a > p.txt {
    margin-top: 5px;
    width: 100%;
  }
}

.news-box > .list > li > a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 0.7;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .news-box > .list > li > a:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.news-box > .list > li > a.link-none {
  pointer-events: none;
  color: #333;
}

.news-box > .list > li > a.link-none.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 1;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .news-box > .list > li > a.link-none:hover {
    opacity: 1;
    text-decoration: none;
  }
}

/* ============================================================
	pagination
============================================================ */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination p a, .pagination p strong {
  padding: 0 5px;
  display: inline-block;
  line-height: 2;
}

/* ============================================================
	news-article
============================================================ */
.news-ttl {
  padding: 40px 0 60px 0;
  font-size: 3.4rem;
  line-height: 1.4;
  color: #36357e;
}

@media only screen and (max-width: 767px) {
  .news-ttl {
    padding: 5px 0;
    font-size: 2rem;
  }
}

.news-data {
  padding: 15px 0;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: #a5a5a5;
}

@media only screen and (max-width: 767px) {
  .news-data {
    padding: 10px 0;
    font-size: 1.2rem;
  }
}

.news-data > .label {
  padding: 3px;
  border-radius: 3px;
  text-align: center;
  border: 1px solid #6d6564;
  color: #6d6564;
  white-space: nowrap;
  margin-left: 10px;
  font-size: 1rem;
}

_:-ms-fullscreen, :root .news-data > .label {
  padding-top: 7px;
}

.news-data > .label.-c02 {
  border: 1px solid #7b76b4;
  color: #7b76b4;
}

.news-data > .label.-c03 {
  border: 1px solid #ed5c39;
  color: #ed5c39;
}

.news-data > .label.-c04 {
  border: 1px solid #6ba25a;
  color: #6ba25a;
}

.news-data > .label.-c05 {
  border: 1px solid #0aa59b;
  color: #0aa59b;
}

.news-data > .label.-c06 {
  border: 1px solid #ff9e00;
  color: #ff9e00;
}

.news-data > .label.-c07 {
  border: 1px solid #ba4986;
  color: #ba4986;
}

.news-data > .label.-c08 {
  border: 1px solid #4492c4;
  color: #4492c4;
}

.news-article {
  border-top: 1px solid #ccc;
  padding-top: 20px;
  font-size: 1.6rem;
}

@media only screen and (max-width: 767px) {
  .news-article {
    padding-top: 0;
  }
}

.news-article [class^="img-area"] {
  text-align: center;
}

.news-article [class^="img-area"] img {
  margin: 30px 0 20px;
  width: auto;
  max-width: 1060px;
  height: auto;
}

@media only screen and (max-width: 767px) {
  .news-article [class^="img-area"] img {
    margin: 20px 0 10px;
    width: auto !important;
    max-width: 90%;
    max-height: 500px;
  }
}

.news-article h2 {
  margin-top: 15px;
  font-size: 2rem;
  line-height: 1.7;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .news-article h2 {
    font-size: 1.8rem;
    line-height: 1.5;
  }
}

.news-article p {
  margin-top: 15px;
  line-height: 1.7;
}

@media only screen and (max-width: 767px) {
  .news-article p {
    line-height: 1.5;
  }
}

.news-article a {
  text-decoration: underline;
  text-indent: 0;
}

.news-article a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .news-article a:hover {
    text-decoration: none;
  }
}

.news-article .book-area {
  margin-top: 50px;
}

@media only screen and (max-width: 767px) {
  .news-article .book-area {
    margin: 30px auto 0;
  }
}

.news-article .book-area .h4ttl-line {
  margin-bottom: 0;
}

.news-article .book-area .list {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .news-article .book-area .list {
    display: block;
  }
}

.news-article .book-area .list li {
  display: flex;
  margin: 20px 0 0;
  padding: 0 5px;
  width: 50%;
}

.news-article .book-area .list li:only-of-type {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .news-article .book-area .list li {
    padding: 0;
    width: 100%;
  }
}

.news-article .book-area .list li > .thumb {
  margin-right: 10px;
}

.news-article .book-area .list li > .thumb img {
  width: auto;
  max-width: 192px;
  border: 1px solid #ccc;
}

@media print, screen and (min-width: 768px) {
  .news-article .book-area .list li > .thumb img {
    max-height: 222px;
  }
}

@media only screen and (max-width: 767px) {
  .news-article .book-area .list li > .thumb img {
    max-width: 125px;
  }
}

.news-article .book-area .list li > .detail .ttl {
  font-size: 1.8rem;
  line-height: 1.4;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .news-article .book-area .list li > .detail .ttl {
    font-size: 1.6rem;
    line-height: 1.2;
  }
}

.news-article .book-area .list li > .detail .name {
  margin-top: 5px;
  font-size: 1.6rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .news-article .book-area .list li > .detail .name {
    font-size: 1.4rem;
  }
}

.news-article .book-area .list li > .detail .data {
  margin-top: 20px;
  font-size: 1.6rem;
}

@media only screen and (max-width: 767px) {
  .news-article .book-area .list li > .detail .data {
    margin-top: 15px;
    font-size: 1.4rem;
  }
}

.news-article .book-area .list li > .detail .other {
  margin-top: 10px;
  font-size: 1.4rem;
}

@media only screen and (max-width: 767px) {
  .news-article .book-area .list li > .detail .other {
    margin-top: 5px;
  }
}

.news-article .contact-box {
  margin: 60px auto 0;
}

@media only screen and (max-width: 767px) {
  .news-article .contact-box {
    margin: 40px auto 0;
    width: 100%;
  }
}

.news-article .contact-box > .box p {
  margin-top: 0;
  font-size: 1.4rem;
  line-height: 1.4;
}

/* ============================================================
	news-other
============================================================ */
.ttldeco-bgpubli {
  display: block;
  padding: 10px;
  width: 100%;
  background-color: #ed5c39;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2rem !important;
  line-height: 1.5;
  text-align: center;
  width: 100%;
  color: #fff;
}

/* ============================================================
	publishing-top
============================================================ */
.publitop-main {
  width: 100%;
  overflow-x: hidden;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .publitop-main {
    width: 100%;
    height: auto;
  }
}

.publitop-main a {
  display: inline-block;
  width: 1080px;
  height: 512px;
}

@media only screen and (max-width: 767px) {
  .publitop-main a {
    width: 100%;
    height: auto;
  }
}

.publittl-box > .ttl {
  display: inline-block;
  padding-left: 55px;
}

_:-ms-fullscreen, :root .publittl-box > .ttl {
  padding-top: 7px;
}

.publittl-box > .ttl::after {
  top: -6px;
  font-size: 50px;
}

.publittl-box > .ttl::before {
  top: -6px;
  font-size: 50px;
  color: #ed5c39;
}

@media only screen and (max-width: 767px) {
  .publittl-box > .ttl {
    padding-left: 45px;
  }
  .publittl-box > .ttl::after, .publittl-box > .ttl::before {
    top: -8px;
    font-size: 40px;
  }
}

.publittl-box > .ttl span {
  font-size: 3.4rem;
  font-family: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .publittl-box > .ttl span {
    font-size: 2.6rem;
  }
}

.publittl-box > .eng {
  margin-top: 10px;
  color: #555;
  font-size: 1.4rem;
  font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
  font-weight: 400;
  font-style: italic;
}

_:-ms-fullscreen, :root .publittl-box > .eng {
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  .publittl-box > .eng {
    margin-top: 8px;
  }
}

.publittl-box + .lead {
  margin-top: 20px;
  font-size: 1.6rem;
  line-height: 1.4;
}

@media only screen and (max-width: 767px) {
  .publittl-box + .lead {
    text-align: left;
    line-height: 1.5;
  }
}

/* ============================================================
	publitop-contest
============================================================ */
.publitop-contest {
  margin: 100px auto;
  width: 1080px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .publitop-contest {
    margin: 60px 3% 70px;
    width: 94%;
  }
}

.publitop-contest > .list {
  display: flex;
  justify-content: space-between;
  margin: 40px auto;
  width: 1080px;
}

@media only screen and (max-width: 767px) {
  .publitop-contest > .list {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
    width: 100%;
  }
}

.publitop-contest > .list > .item {
  width: 340px;
  text-align: center;
  line-height: 1.2;
}

@media only screen and (max-width: 767px) {
  .publitop-contest > .list > .item {
    padding: 5px 0;
    width: 49%;
  }
}

@media only screen and (max-width: 375px) {
  .publitop-contest > .list > .item {
    padding: 10px 0;
    width: 100%;
  }
}

@media print, screen and (min-width: 768px) {
  .publitop-contest > .list > .item.-column2 {
    margin: 0 auto;
  }
}

.publitop-contest > .list > .item.-column1 {
  margin: 0 auto;
}

/* ============================================================
	publitop-work
============================================================ */
.publitop-work {
  margin: 100px auto;
  width: 1080px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .publitop-work {
    margin: 50px 3% 30px;
    width: 94%;
  }
}

.publitop-work > .list {
  display: flex;
  justify-content: space-between;
  margin: 60px auto 0;
  width: 970px;
}

@media only screen and (max-width: 767px) {
  .publitop-work > .list {
    display: flex;
    flex-wrap: wrap;
    margin: 30px auto 0;
    width: 100%;
  }
  .publitop-work > .list > li {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
  }
}

.publitop-linkarea {
  display: block;
  position: relative;
  padding: 165px 75px 0;
  width: 450px;
  height: 430px;
  color: #333;
  cursor: pointer;
}

.publitop-linkarea:visited {
  color: #333;
}

.publitop-linkarea.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #333;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .publitop-linkarea:hover {
    color: #333;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .publitop-linkarea {
    margin: 0 auto;
    padding: 25px 20px 35px;
    width: 100%;
    height: auto;
  }
  .publitop-linkarea.-form {
    background: url(/publishing/images/top/bg_form.jpg) center 50% no-repeat;
    background-size: cover;
  }
  .publitop-linkarea.-webform {
    background: url(/publishing/images/top/bg_webform.jpg) center bottom no-repeat;
    background-size: cover;
  }
  .publitop-linkarea.-download {
    background: url(/publishing/images/top/bg_download.jpg) center bottom no-repeat;
    background-size: cover;
  }
}

.publitop-linkarea > .bg {
  position: absolute;
  left: 0;
  top: 0;
}

@media only screen and (max-width: 767px) {
  .publitop-linkarea > .bg {
    display: none;
  }
}

.publitop-linkarea > .ttl {
  position: relative;
  width: 100%;
  color: #e62814;
  font-size: 2rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  transition: all .3s;
  margin-bottom: 10px;
  text-shadow: 0 0 3px #fff, 0 0 4px #fff, 0 0 5px #fff;
}

@media only screen and (max-width: 767px) {
  .publitop-linkarea > .ttl {
    font-size: 1.6rem;
  }
}

.publitop-linkarea > .ttl .small {
  font-size: 1.4rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.publitop-linkarea > .ttl > .eng {
  display: block;
  margin-top: 12px;
  color: #555;
  font-size: 1.4rem;
  font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
  font-weight: 400;
  font-style: italic;
}

@media only screen and (max-width: 767px) {
  .publitop-linkarea > .ttl > .eng {
    margin-top: 8px;
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 767px) {
  .publitop-linkarea > .ttl {
    margin-bottom: 15px;
  }
}

.publitop-linkarea > .lead {
  position: relative;
  width: 100%;
  line-height: 1.7;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .publitop-linkarea > .lead {
    line-height: 1.5;
  }
}

.publitop-linkarea > .btn-box {
  position: absolute;
  right: -25px;
  bottom: -25px;
}

@media only screen and (max-width: 767px) {
  .publitop-linkarea > .btn-box {
    right: -5px;
    bottom: -10px;
  }
}

.publitop-linkarea > .btn-box > .btn-top {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s;
  width: 240px;
  height: 68px;
  background-color: #fff;
  box-shadow: 0 0 8px #ccc;
  color: #e62814;
  font-size: 1.6rem;
  text-align: center;
  border-radius: 3px;
}

_:-ms-fullscreen, :root .publitop-linkarea > .btn-box > .btn-top {
  padding-top: 7px;
}

@media only screen and (max-width: 767px) {
  .publitop-linkarea > .btn-box > .btn-top {
    padding: 10px 30px 10px 15px;
    width: auto;
    height: auto;
    font-size: 1.4rem;
  }
  _:-ms-fullscreen, :root .publitop-linkarea > .btn-box > .btn-top {
    padding-top: 17px;
  }
}

.publitop-linkarea > .btn-box > .btn-top::before {
  font-family: "bungeisha" !important;
  content: "";
  position: absolute;
  top: 50%;
  right: 35px;
  font-size: 1.2rem;
  transform: translate(0, -50%);
  display: inline-block;
  transition: right .1s;
}

@media only screen and (max-width: 767px) {
  .publitop-linkarea > .btn-box > .btn-top::before {
    right: 12px;
    font-size: 1rem;
  }
}

.publitop-linkarea.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 1;
}

.publitop-linkarea.hover > .bg, .publitop-linkarea.hover > .ttl, .publitop-linkarea.hover > .lead {
  opacity: 0.7;
}

.publitop-linkarea.hover > .btn-box > .btn-top {
  background-color: #e62814;
  box-shadow: 0 0 8px #666;
  color: #fff;
}

.publitop-linkarea.hover > .btn-box > .btn-top::before {
  right: 30px;
}

@media only screen and (max-width: 767px) {
  .publitop-linkarea.hover > .btn-box > .btn-top::before {
    right: 8px;
  }
}

@media print, screen and (min-width: 768px) {
  .publitop-linkarea:hover {
    opacity: 1;
  }
  .publitop-linkarea:hover > .bg, .publitop-linkarea:hover > .ttl, .publitop-linkarea:hover > .lead {
    opacity: 0.7;
  }
  .publitop-linkarea:hover > .btn-box > .btn-top {
    background-color: #e62814;
    box-shadow: 0 0 8px #666;
    color: #fff;
  }
  .publitop-linkarea:hover > .btn-box > .btn-top::before {
    right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .publitop-linkarea:hover > .btn-box > .btn-top::before {
    right: 8px;
  }
}

.publitop-linkarea.-webform,
.publitop-linkarea.-download {
  padding: 50px 0 0;
  height: 160px;
}

@media only screen and (max-width: 767px) {
  .publitop-linkarea.-webform,
  .publitop-linkarea.-download {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 30px 0 40px;
    width: 48%;
    height: auto;
  }
}

@media print, screen and (min-width: 768px) {
  .publitop-linkarea.-webform {
    margin-top: 40px;
  }
  .publitop-linkarea.-download {
    margin-top: 70px;
  }
}

/* ============================================================
	publitop-self, publitop-guide
============================================================ */
.publitop-self {
  margin: 100px auto;
  padding: 70px 0;
  width: 100%;
  background: #eaeaea;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .publitop-self {
    margin: 30px auto;
    padding: 50px 3%;
    width: 100%;
  }
}

.publitop-self > .list {
  display: flex;
  flex-wrap: wrap;
  margin: 60px auto 0;
  width: 1080px;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .publitop-self > .list {
    margin: 30px 0 0;
    width: 100%;
  }
}

.publitop-self > .list > li {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .publitop-self > .list > li {
    width: 50%;
  }
  .publitop-self > .list > li:first-of-type {
    display: none;
  }
  .publitop-self > .list > li:last-of-type {
    width: 100%;
  }
  .publitop-self > .list > li:nth-child(2) {
    padding-right: 2px;
  }
  .publitop-self > .list > li:nth-child(3) {
    padding-left: 2px;
  }
}

.publitop-guide {
  margin: 100px auto;
  width: 1080px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .publitop-guide {
    margin: 50px 3% 50px;
    width: 94%;
  }
}

.publitop-guide > .list {
  display: flex;
  flex-wrap: wrap;
  margin: 60px auto 0;
  width: 1080px;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .publitop-guide > .list {
    margin: 30px 0 0;
    width: 100%;
  }
}

.publitop-guide > .list > li {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .publitop-guide > .list > li {
    width: 50%;
  }
  .publitop-guide > .list > li:first-of-type {
    display: none;
  }
  .publitop-guide > .list > li:nth-child(2) {
    padding-right: 2px;
  }
  .publitop-guide > .list > li:nth-child(3) {
    padding-left: 2px;
  }
}

.publitop-linkbox {
  display: block;
  margin-left: 4px;
  width: 268px;
  height: 440px;
  background-color: #fff;
  box-shadow: 0 0 8px #ccc;
  color: #333;
  cursor: pointer;
}

.publitop-linkbox:visited {
  color: #333;
}

.publitop-linkbox.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #333;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .publitop-linkbox:hover {
    color: #333;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .publitop-linkbox {
    margin: 0;
    width: 100%;
    height: auto;
  }
}

.publitop-linkbox.-soudankai,
.publitop-linkbox.-setsumeikai {
  margin-left: 0;
  width: 536px;
  height: 440px;
}

.publitop-linkbox.-soudankai + .schedule-list,
.publitop-linkbox.-setsumeikai + .schedule-list {
  position: absolute;
  top: 21px;
  right: 15px;
  padding: 15px;
  width: 250px;
  min-height: 284px;
  background-color: #fff;
  text-align: left;
  border-radius: 3px;
}

.schedule-list > .ttl {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0 16px;
  color: #555;
  font-size: 1.6rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.schedule-list > .ttl > .icon-calendar {
  margin-right: 2px;
  font-size: 2.4rem;
}

.schedule-list > .data {
  padding: 6px 0;
  border-bottom: 1px dotted #ccc;
  line-height: 1.4;
}

.schedule-list > .data a {
  text-decoration: underline;
}

.schedule-list > .data a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .schedule-list > .data a:hover {
    text-decoration: none;
  }
}

.schedule-list > .txt {
  padding-top: 6px;
  font-size: 1.3rem;
  text-align: justify;
}

.publitop-linkbox .txt-area {
  padding: 28px 20px 20px;
}

@media only screen and (max-width: 767px) {
  .publitop-linkbox .txt-area {
    padding: 15px 0;
  }
}

.publitop-linkbox .txt-area > .ttl {
  position: relative;
  width: 100%;
  color: #e62814;
  font-size: 2rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  transition: all .3s;
}

@media only screen and (max-width: 767px) {
  .publitop-linkbox .txt-area > .ttl {
    font-size: 1.6rem;
  }
}

.publitop-linkbox .txt-area > .ttl .small {
  font-size: 1.4rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.publitop-linkbox .txt-area > .ttl > .eng {
  display: block;
  margin-top: 12px;
  color: #555;
  font-size: 1.4rem;
  font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
  font-weight: 400;
  font-style: italic;
}

@media only screen and (max-width: 767px) {
  .publitop-linkbox .txt-area > .ttl > .eng {
    margin-top: 8px;
    font-size: 1.2rem;
  }
}

.publitop-linkbox .txt-area > .ttl::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -34px;
  display: inline-block;
  width: 70px;
  /*線の長さ*/
  height: 1px;
  /*線の太さ*/
  transform: translateX(-50%);
  /*位置調整*/
  background-color: #ccc;
  /*線の色*/
  border-radius: 2px;
}

@media only screen and (max-width: 767px) {
  .publitop-linkbox .txt-area > .ttl::before {
    display: none;
  }
}

.publitop-linkbox .txt-area .lead {
  margin-top: 70px;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .publitop-linkbox .txt-area .lead {
    display: none;
  }
}

.publitop-linkbox .txt-area .link {
  position: relative;
  margin: 20px 5px 0 0;
  padding-right: 15px;
  color: #e62814;
  text-align: right;
}

.publitop-linkbox .txt-area .link::before {
  font-family: "bungeisha" !important;
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 1.2rem;
  transform: translate(0, -50%);
  display: inline-block;
  transition: right .1s;
}

@media only screen and (max-width: 767px) {
  .publitop-linkbox .txt-area .link::before {
    font-size: 1rem;
  }
}

.publitop-linkbox.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.publitop-linkbox.hover .txt-area .link::before {
  right: -5px;
}

@media print, screen and (min-width: 768px) {
  .publitop-linkbox:hover .txt-area .link::before {
    right: -5px;
  }
}

.publitop-linkbox.-soudankai .txt-area {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.publitop-linkbox.-soudankai .txt-area > .ttl {
  width: 200px;
}

.publitop-linkbox.-soudankai .txt-area > .ttl::before {
  display: none;
}

.publitop-linkbox.-soudankai .txt-area > .lead {
  margin-top: 0;
  padding-left: 26px;
  width: 296px;
  border-left: 1px solid #ccc;
}

.publitop-linkbox.-soudankai .txt-area > .link {
  margin-top: -8px;
  width: 100%;
}

.publitop-linkbox.-setsumeikai .txt-area {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.publitop-linkbox.-setsumeikai .txt-area > .ttl {
  width: 210px;
}

.publitop-linkbox.-setsumeikai .txt-area > .ttl::before {
  display: none;
}

.publitop-linkbox.-setsumeikai .txt-area > .lead {
  margin-top: 0;
  padding-left: 16px;
  width: 286px;
  border-left: 1px solid #ccc;
}

.publitop-linkbox.-setsumeikai .txt-area > .link {
  margin-top: -8px;
  width: 100%;
}

.publitop-linkbox.-syuppantaiken {
  margin: 4px 0 0;
  width: 1080px;
  height: 214px;
}

@media print, screen and (min-width: 768px) {
  .publitop-linkbox.-syuppantaiken {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 767px) {
  .publitop-linkbox.-syuppantaiken {
    width: 100%;
    height: auto;
  }
}

.publitop-linkbox.-syuppantaiken > .bg {
  width: 356px;
  height: 214px;
}

@media only screen and (max-width: 767px) {
  .publitop-linkbox.-syuppantaiken > .bg {
    width: 100%;
    height: 30vw;
    overflow: hidden;
  }
}

@media print, screen and (min-width: 768px) {
  .publitop-linkbox.-syuppantaiken > .txt-area {
    width: 690px;
  }
  .publitop-linkbox.-syuppantaiken > .txt-area > .ttl::before {
    bottom: -24px;
  }
  .publitop-linkbox.-syuppantaiken > .txt-area > .lead {
    margin-top: 45px;
  }
  .publitop-linkbox.-syuppantaiken > .txt-area > .link {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .publitop-linkbox.-syuppantaiken > .txt-area > .ttl::before {
    display: inline;
    bottom: -20px;
  }
  .publitop-linkbox.-syuppantaiken > .txt-area > .lead {
    display: inline-block;
    margin-top: 35px;
    padding: 0 10px;
  }
}

.publitop-linkbox.-kakitai .txt-area > .ttl,
.publitop-linkbox.-hondas .txt-area > .ttl {
  line-height: 1.2;
}

.publitop-linkbox.-kakitai .txt-area > .ttl::before,
.publitop-linkbox.-hondas .txt-area > .ttl::before {
  bottom: -14px;
}

.publitop-linkbox.-kakitai .txt-area > .lead,
.publitop-linkbox.-hondas .txt-area > .lead {
  margin-top: 40px;
}

/* ============================================================
	publitop-bnrarea
============================================================ */
.publitop-bnrarea {
  width: 1080px;
  text-align: center;
}

@media print, screen and (min-width: 768px) {
  .publitop-bnrarea {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px auto;
  }
}

@media only screen and (max-width: 767px) {
  .publitop-bnrarea {
    margin: 50px 3% 50px;
    width: 94%;
  }
}

.publitop-bnrarea > .item {
  display: block;
  width: 502px;
  height: 112px;
}

@media print, screen and (min-width: 768px) {
  .publitop-bnrarea > .item {
    margin: 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .publitop-bnrarea > .item {
    margin-top: 10px;
    width: 100%;
    height: auto;
  }
}

/* ============================================================
	publitop-contact
============================================================ */
.publitop-contact {
  padding: 70px 0;
  height: 470px;
  background: #eaeaea url(/assets/images/bg_contact.jpg) top center no-repeat;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .publitop-contact {
    background-size: cover;
    padding: 30px 3%;
    width: 100%;
    height: auto;
  }
}

.publitop-contact > .ttl {
  display: inline-block;
  margin: 0 auto 20px;
  padding: 0 30px 15px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-size: 2.6rem;
  line-height: 1.2;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .publitop-contact > .ttl {
    padding: 0 0 15px;
    font-size: 2.2rem;
  }
}

.publitop-contact > .lead {
  font-size: 1.6rem;
  line-height: 1.4;
}

@media only screen and (max-width: 767px) {
  .publitop-contact > .lead {
    text-align: left;
    line-height: 1.5;
  }
}

.publitop-contact > .list {
  margin: 60px auto 0;
  width: 1080px;
}

@media print, screen and (min-width: 768px) {
  .publitop-contact > .list {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 767px) {
  .publitop-contact > .list {
    margin: 30px auto 0;
    width: 90%;
    max-width: 320px;
  }
}

.publitop-contact > .list > .linkbox {
  display: block;
  margin: 10px auto;
  padding: 40px;
  width: 332px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ccc;
  text-align: center;
  color: #333;
}

.publitop-contact > .list > .linkbox:visited {
  color: #333;
}

.publitop-contact > .list > .linkbox.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #333;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .publitop-contact > .list > .linkbox:hover {
    color: #333;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .publitop-contact > .list > .linkbox {
    padding: 20px;
    width: auto;
  }
}

.publitop-contact > .list > .linkbox > .ttl {
  position: relative;
  display: inline-block;
  margin: 0 auto 30px;
  padding-right: 20px;
  color: #36357e;
  font-size: 1.6rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.publitop-contact > .list > .linkbox > .ttl::before {
  font-family: "bungeisha" !important;
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 1.2rem;
  transform: translate(0, -50%);
  display: inline-block;
  transition: right .1s;
}

.publitop-contact > .list > .linkbox > .lead {
  line-height: 1.5;
  text-align: left;
}

.publitop-contact > .list > .linkbox.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: rgba(255, 255, 255, 0.5);
}

.publitop-contact > .list > .linkbox.hover .ttl::before {
  right: -5px;
}

@media print, screen and (min-width: 768px) {
  .publitop-contact > .list > .linkbox:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  .publitop-contact > .list > .linkbox:hover .ttl::before {
    right: -5px;
  }
}

/* ============================================================
	publitop-sponly
============================================================ */
.publitop-sponly {
  display: none;
}

@media only screen and (max-width: 767px) {
  .publitop-sponly {
    display: block;
    margin: 40px 3% 60px;
    width: 94%;
  }
  .publitop-sponly > .list {
    margin: 0;
    width: 100%;
  }
  .publitop-sponly > .list > li {
    margin-bottom: 40px;
  }
  .publitop-sponly > .list > li > .schedule-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }
  .publitop-sponly > .list > li > .schedule-list > .ttl {
    margin: 10px 0 8px;
    width: 100%;
  }
  .publitop-sponly > .list > li > .schedule-list > .data {
    margin-right: 2%;
    padding: 6px 0;
    width: 32%;
  }
  .publitop-sponly > .list > li > .schedule-list > .data:nth-child(4), .publitop-sponly > .list > li > .schedule-list > .data:nth-child(7) {
    margin-right: 0;
  }
  .publitop-sponly .publitop-linkbox.-soudankaisp,
  .publitop-sponly .publitop-linkbox.-setsumeikaisp {
    width: 100%;
    height: auto;
    box-shadow: none;
  }
  .publitop-sponly .publitop-linkbox.-soudankaisp > .bg,
  .publitop-sponly .publitop-linkbox.-setsumeikaisp > .bg {
    position: relative;
    width: 100%;
    height: 30vw;
    overflow: hidden;
  }
  .publitop-sponly .publitop-linkbox.-soudankaisp > .bg img,
  .publitop-sponly .publitop-linkbox.-setsumeikaisp > .bg img {
    position: absolute;
    top: -50%;
    left: 0;
    width: 150%;
  }
  .publitop-sponly .publitop-linkbox.-soudankaisp > .txt-area,
  .publitop-sponly .publitop-linkbox.-setsumeikaisp > .txt-area {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 15px 0 5px;
  }
  .publitop-sponly .publitop-linkbox.-soudankaisp > .txt-area > .ttl,
  .publitop-sponly .publitop-linkbox.-setsumeikaisp > .txt-area > .ttl {
    width: 35%;
    border-right: 1px solid #ccc;
    line-height: 1.2;
  }
  .publitop-sponly .publitop-linkbox.-soudankaisp > .txt-area > .ttl .small,
  .publitop-sponly .publitop-linkbox.-setsumeikaisp > .txt-area > .ttl .small {
    font-size: 1.2rem;
  }
  .publitop-sponly .publitop-linkbox.-soudankaisp > .txt-area > .lead,
  .publitop-sponly .publitop-linkbox.-setsumeikaisp > .txt-area > .lead {
    display: inline-block;
    margin-top: 0;
    padding-left: 10px;
    width: 65%;
  }
  .publitop-sponly .publitop-linkbox.-soudankaisp > .txt-area > .link,
  .publitop-sponly .publitop-linkbox.-setsumeikaisp > .txt-area > .link {
    margin: 8px 5px 0 0;
    width: 100%;
  }
  .publitop-sponly .publitop-linkbox.-setsumeikaisp > .bg img {
    top: -120%;
  }
}

@media only screen and (max-width: 700px) {
  .publitop-sponly > .list > li > .schedule-list > .data {
    margin-right: 0;
    width: 48%;
  }
  .publitop-sponly > .list > li > .schedule-list > .data:nth-child(even) {
    margin-right: 4%;
  }
}

@media only screen and (max-width: 479px) {
  .publitop-sponly > .list > li > .schedule-list.-soudankai > .data {
    padding: 6px 6px 6px 40px;
    width: 100%;
  }
  .publitop-sponly > .list > li > .schedule-list.-soudankai > .data:nth-child(even) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 375px) {
  .publitop-sponly > .list > li > .schedule-list.-setsumeikai > .data {
    padding: 6px 6px 6px 40px;
    width: 100%;
  }
  .publitop-sponly > .list > li > .schedule-list.-setsumeikai > .data:nth-child(even) {
    margin-right: 0;
  }
}

/* ============================================================
	publishing-swiper
============================================================ */
.publitop-main > .container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1080px;
  margin-bottom: 30px;
}

.publitop-main .swiper-container {
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 1;
}

.publitop-main .swiper-pagination {
  bottom: -25px !important;
  left: 50%;
  transform: translate(-50%, 0);
}

.publitop-main .swiper-pagination-bullet {
  margin: 0 5px;
  width: 12px;
  height: 12px;
  transition: all .3s;
}

.publitop-main .swiper-pagination-bullet.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: .2;
}

@media print, screen and (min-width: 768px) {
  .publitop-main .swiper-pagination-bullet:hover {
    opacity: .2;
  }
}

.publitop-main .swiper-pagination-bullet-active {
  background: #e62814;
}

.publitop-main .swiper-slide-prev a,
.publitop-main .swiper-slide-next a {
  pointer-events: none;
}

.publitop-main .swiper-slide-prev a img,
.publitop-main .swiper-slide-next a img {
  opacity: .2;
}

.publitop-main .swiper-button-next,
.publitop-main .swiper-button-prev {
  margin-top: 0;
  background: none;
  background: #fff;
  width: 40px;
  height: 144px;
  transform: translate(0, -50%);
  transition: all .3s;
}

.publitop-main .swiper-button-next.hover,
.publitop-main .swiper-button-prev.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 0.7;
}

@media print, screen and (min-width: 768px) {
  .publitop-main .swiper-button-next:hover,
  .publitop-main .swiper-button-prev:hover {
    opacity: 0.7;
  }
}

.publitop-main .swiper-button-next {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.publitop-main .swiper-button-next::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 0;
  font-size: 4rem;
  content: "";
  color: #666;
}

.publitop-main .swiper-button-prev {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.publitop-main .swiper-button-prev::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 0;
  font-size: 4rem;
  content: "";
  color: #666;
}

.publitop-main .swiper-button-next, .publitop-main .swiper-container-rtl .swiper-button-prev {
  right: -40px;
}

.publitop-main .swiper-button-prev, .publitop-main .swiper-container-rtl .swiper-button-next {
  left: -40px;
}

/* ============================================================
	publishing/seminarsearch
============================================================ */
.seminarsearch-month {
  position: relative;
  margin-bottom: 100px;
}

@media only screen and (max-width: 767px) {
  .seminarsearch-month {
    margin-bottom: 80px;
  }
}

.seminarsearch-month > .ttl {
  padding: 14px 20px;
  background-color: #e62814;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 2rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  text-align: center;
}

_:-ms-fullscreen, :root .seminarsearch-month > .ttl {
  padding-top: 21px;
}

@media only screen and (max-width: 767px) {
  .seminarsearch-month > .ttl {
    font-size: 1.8rem;
  }
}

.seminarsearch-month > .box {
  border: 1px solid #ccc;
  border-top: none;
  padding: 30px 40px;
}

@media only screen and (max-width: 767px) {
  .seminarsearch-month > .box {
    padding: 20px 10px;
  }
}

.seminarsearch-month > .box > .lead-mcl {
  margin-top: 0;
}

@media print, screen and (min-width: 768px) {
  .seminarsearch-month > .box > .list {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 767px) {
  .seminarsearch-month > .box > .list {
    display: block;
  }
}

@media print, screen and (min-width: 768px) {
  .seminarsearch-month > .box > .list > li {
    display: flex;
  }
}

@media only screen and (max-width: 767px) {
  .seminarsearch-month > .box > .list > li {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}

.seminarsearch-month > .box > .list > li > .btn-month {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s;
  margin-right: 10px;
  padding: 20px;
  width: 220px;
  background-color: #fff;
  box-shadow: 0 0 8px #ccc;
  color: #e62814;
  font-size: 1.8rem;
  text-align: center;
  cursor: pointer;
  border-radius: 3px;
}

.seminarsearch-month > .box > .list > li > .btn-month:visited {
  color: #e62814;
}

.seminarsearch-month > .box > .list > li > .btn-month.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #e62814;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .seminarsearch-month > .box > .list > li > .btn-month:hover {
    color: #e62814;
    text-decoration: none;
  }
}

_:-ms-fullscreen, :root .seminarsearch-month > .box > .list > li > .btn-month {
  padding-top: 27px;
}

@media only screen and (max-width: 767px) {
  .seminarsearch-month > .box > .list > li > .btn-month {
    padding: 10px 15px;
    width: 100%;
    height: auto;
    font-size: 1.6rem;
  }
  _:-ms-fullscreen, :root .seminarsearch-month > .box > .list > li > .btn-month {
    padding-top: 17px;
  }
}

.seminarsearch-month > .box > .list > li > .btn-month:last-of-type {
  margin-right: 0;
}

.seminarsearch-month > .box > .list > li > .btn-month.-clear {
  font-size: 1.6rem;
}

.seminarsearch-month > .box > .list > li > .btn-month.-active {
  background-color: #e62814;
  box-shadow: 0 0 8px #666;
  color: #fff;
}

.seminarsearch-month > .box > .list > li > .btn-month.-nodata {
  background-color: #eaeaea;
  box-shadow: none;
  color: #fff;
  pointer-events: none;
}

.seminarsearch-month > .box > .list > li > .btn-month.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-color: #e62814;
  box-shadow: 0 0 8px #666;
  color: #fff;
}

.seminarsearch-month > .box > .list > li > .btn-month.hover::before {
  right: 30px;
}

@media only screen and (max-width: 767px) {
  .seminarsearch-month > .box > .list > li > .btn-month.hover::before {
    right: 8px;
  }
}

@media print, screen and (min-width: 768px) {
  .seminarsearch-month > .box > .list > li > .btn-month:hover {
    opacity: 1;
    background-color: #e62814;
    box-shadow: 0 0 8px #666;
    color: #fff;
  }
  .seminarsearch-month > .box > .list > li > .btn-month:hover::before {
    right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .seminarsearch-month > .box > .list > li > .btn-month:hover::before {
    right: 8px;
  }
}

.seminarsearch-month::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
  width: 0px;
  height: 0px;
  margin: auto;
  border-style: solid;
  border-color: #eaeaea transparent transparent transparent;
  border-width: 30px 115px 0 115px;
}

.seminarsearch-month.-none::before {
  display: none;
}

.seminarsearch-month.-none > .box > .lead-mcl {
  margin: 0;
}

.seminarsearch-place {
  position: relative;
  margin-bottom: 80px;
}

@media only screen and (max-width: 767px) {
  .seminarsearch-place {
    margin-bottom: 40px;
  }
}

.seminarsearch-place > .ttl {
  font-size: 2rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #e62814;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .seminarsearch-place > .ttl {
    font-size: 1.8rem;
  }
}

.seminarsearch-place > .list {
  margin: 40px auto 80px;
  padding: 0 80px;
  border-bottom: 1px solid #e62814;
  font-size: 0;
}

@media print, screen and (min-width: 768px) {
  .seminarsearch-place > .list {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 767px) {
  .seminarsearch-place > .list {
    margin: 0 auto 40px;
    padding: 0;
    border-bottom: none;
  }
  .seminarsearch-place > .list > li {
    margin-top: 5px;
    display: inline-block;
    width: 50%;
    border-bottom: 1px solid #e62814;
    text-align: center;
  }
}

.seminarsearch-place > .list .btn-place {
  display: block;
  position: relative;
  padding: 10px 30px 10px 10px;
  color: #ccc;
  font-size: 1.8rem;
  pointer-events: none;
}

_:-ms-fullscreen, :root .seminarsearch-place > .list .btn-place {
  padding-top: 17px;
}

@media only screen and (max-width: 767px) {
  .seminarsearch-place > .list .btn-place {
    font-size: 1.6rem;
  }
}

.seminarsearch-place > .list .btn-place.-active {
  color: #e62814;
  pointer-events: auto;
}

.seminarsearch-place > .list .btn-place.-active::before {
  position: absolute;
  top: 58%;
  transform: translate(0, -58%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 10px;
  font-size: 1.2rem;
  content: "";
  transition: top .3s;
}

.seminarsearch-place > .list .btn-place.-active.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

.seminarsearch-place > .list .btn-place.-active.hover::before {
  top: 68%;
  transform: translate(0, -68%);
}

@media print, screen and (min-width: 768px) {
  .seminarsearch-place > .list .btn-place.-active:hover {
    text-decoration: none;
  }
  .seminarsearch-place > .list .btn-place.-active:hover::before {
    top: 68%;
    transform: translate(0, -68%);
  }
}

.seminar-areattl {
  margin: 80px auto 35px;
  padding: 12px;
  border-bottom: 3px solid #e62814;
  color: #555;
  font-size: 2rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .seminar-areattl {
    margin: 40px auto 20px;
  }
}

.seminar-detail {
  box-shadow: 0 0 8px #ccc;
  margin: 35px 0 0;
  padding: 30px 0;
}

@media print, screen and (min-width: 768px) {
  .seminar-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .seminar-detail {
    margin: 20px 0 0;
    padding: 20px 0;
  }
}

.seminar-detail > .list {
  width: 750px;
  padding: 0 35px;
  border-right: 1px solid #ccc;
}

@media only screen and (max-width: 767px) {
  .seminar-detail > .list {
    width: 100%;
    padding: 0 15px;
    border-right: none;
  }
}

.seminar-detail > .list > li {
  display: flex;
  align-items: baseline;
  padding-bottom: 10px;
}

.seminar-detail > .list > li.place {
  border-top: 1px dotted #ccc;
  padding-top: 10px;
}

.seminar-detail > .list > li:last-of-type {
  padding-bottom: 0;
}

.seminar-detail > .list > li > .ttl {
  padding: 5px;
  width: 100px;
  color: #fff;
  font-size: 1.4rem;
  text-align: center;
  background-color: #a5a5a5;
}

_:-ms-fullscreen, :root .seminar-detail > .list > li > .ttl {
  padding-top: 12px;
}

.seminar-detail > .list > li > .ttl.-date {
  background-color: #e62814;
}

@media only screen and (max-width: 767px) {
  .seminar-detail > .list > li > .ttl {
    width: 92px;
    font-size: 1.2rem;
  }
}

.seminar-detail > .list > li > .txt-area {
  padding-left: 22px;
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.4;
}

@media only screen and (max-width: 767px) {
  .seminar-detail > .list > li > .txt-area {
    padding-left: 10px;
    font-size: 1.2rem;
  }
}

.seminar-detail > .list > li > .txt-area a {
  color: #e62814;
}

.seminar-detail > .list > li > .txt-area > .date {
  font-size: 1.8rem;
  color: #e62814;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .seminar-detail > .list > li > .txt-area > .date {
    font-size: 1.6rem;
  }
}

.seminar-detail > .list > li > .txt-area > .time {
  font-size: 1.8rem;
}

@media only screen and (max-width: 767px) {
  .seminar-detail > .list > li > .txt-area > .time {
    font-size: 1.6rem;
  }
}

.seminar-detail > .list > li > .txt-area > .note {
  margin-top: 5px;
}

.seminar-detail > .list > li > .txt-area > .name {
  font-size: 2rem;
}

@media only screen and (max-width: 767px) {
  .seminar-detail > .list > li > .txt-area > .name {
    font-size: 1.8rem;
  }
}

.seminar-detail > .list > li > .txt-area > .address {
  margin-top: 5px;
  font-size: 1.6rem;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .seminar-detail > .list > li > .txt-area > .address {
    font-size: 1.4rem;
    line-height: 1.4;
  }
}

.seminar-detail > .list > li > .txt-area > .address > .map {
  display: inline-block;
  font-size: 1.4rem;
}

.seminar-detail > .list > li > .txt-area > .address > .map > .icon-map {
  margin-left: 5px;
  font-size: 1.6rem;
}

.seminar-detail > .list > li > .txt-area > .shop {
  display: inline-block;
  font-size: 1.4rem;
}

.seminar-detail > .btn-box {
  width: 330px;
}

@media only screen and (max-width: 767px) {
  .seminar-detail > .btn-box {
    margin-top: 20px;
    width: 100%;
  }
}

.seminar-detail > .btn-box > .btn-publi {
  width: 260px;
  padding: 15px 50px;
}

_:-ms-fullscreen, :root .seminar-detail > .btn-box > .btn-publi {
  padding-top: 22px;
}

@media only screen and (max-width: 767px) {
  .seminar-detail > .btn-box > .btn-publi {
    padding: 10px 30px;
  }
}

/* ============================================================
	seminar-flow
============================================================ */
.-soudankai-flow,
.-setsumeikai-flow {
  background: url(/publishing/images/common/bg_flow.jpg) bottom center no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 767px) {
  .-soudankai-flow > .h2ttl-icon,
  .-setsumeikai-flow > .h2ttl-icon {
    font-size: 2rem;
  }
}

.-soudankai-flow > .flow-area,
.-setsumeikai-flow > .flow-area {
  margin: 50px auto 0;
  display: flex;
  flex-wrap: wrap;
  width: 960px;
}

@media only screen and (max-width: 767px) {
  .-soudankai-flow > .flow-area,
  .-setsumeikai-flow > .flow-area {
    margin: 25px auto 0;
    width: 100%;
  }
}

.-soudankai-flow .step-area,
.-setsumeikai-flow .step-area {
  background-color: #fff;
}

.-soudankai-flow .step-area > li,
.-setsumeikai-flow .step-area > li {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .-soudankai-flow .step-area > li,
  .-setsumeikai-flow .step-area > li {
    display: block;
  }
}

.-soudankai-flow .step-area > li > .ttl-box,
.-setsumeikai-flow .step-area > li > .ttl-box {
  position: relative;
  display: block;
  padding: 35px 10px;
  width: 200px;
  background: #e62814;
  text-align: center;
  color: #fff;
}

@media print, screen and (min-width: 768px) {
  .-soudankai-flow .step-area > li > .ttl-box,
  .-setsumeikai-flow .step-area > li > .ttl-box {
    border-bottom: 2px solid #fff;
  }
}

.-soudankai-flow .step-area > li > .ttl-box:last-child,
.-setsumeikai-flow .step-area > li > .ttl-box:last-child {
  border-bottom: none;
}

.-soudankai-flow .step-area > li > .ttl-box span.eng,
.-setsumeikai-flow .step-area > li > .ttl-box span.eng {
  display: block;
  font-size: 1.6rem;
  color: #eaeaea;
}

@media only screen and (max-width: 767px) {
  .-soudankai-flow .step-area > li > .ttl-box span.eng,
  .-setsumeikai-flow .step-area > li > .ttl-box span.eng {
    display: inline-block;
    font-size: 1.4rem;
  }
}

.-soudankai-flow .step-area > li > .ttl-box span.ttl,
.-setsumeikai-flow .step-area > li > .ttl-box span.ttl {
  display: block;
  margin-top: 5px;
  font-size: 2rem;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .-soudankai-flow .step-area > li > .ttl-box span.ttl,
  .-setsumeikai-flow .step-area > li > .ttl-box span.ttl {
    display: inline-block;
    margin-top: 0;
    margin-left: 5px;
    font-size: 1.8rem;
  }
}

@media print, screen and (min-width: 768px) {
  .-soudankai-flow .step-area > li > .ttl-box::before,
  .-setsumeikai-flow .step-area > li > .ttl-box::before {
    display: block;
    font-family: 'bungeisha' !important;
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -24px;
    font-size: 3.6rem;
    z-index: 1;
  }
}

@media only screen and (max-width: 767px) {
  .-soudankai-flow .step-area > li > .ttl-box,
  .-setsumeikai-flow .step-area > li > .ttl-box {
    display: block;
    padding: 5px 10px;
    width: 100%;
    text-align: left;
  }
}

.-soudankai-flow .step-area > li > .txt-box,
.-setsumeikai-flow .step-area > li > .txt-box {
  margin: 0 50px;
  padding: 30px 0;
  width: 660px;
  height: auto;
  background: #fff;
  font-size: 1.6rem;
  line-height: 1.5;
}

@media print, screen and (min-width: 768px) {
  .-soudankai-flow .step-area > li > .txt-box,
  .-setsumeikai-flow .step-area > li > .txt-box {
    border-bottom: 1px dotted #ccc;
  }
}

@media only screen and (max-width: 767px) {
  .-soudankai-flow .step-area > li > .txt-box,
  .-setsumeikai-flow .step-area > li > .txt-box {
    margin: 0;
    padding: 10px 10px 20px 10px;
    width: 100%;
    font-size: 1.4rem;
  }
}

.-soudankai-flow .step-area > li:last-child > .ttl-box,
.-setsumeikai-flow .step-area > li:last-child > .ttl-box {
  border-bottom: none;
}

.-soudankai-flow .step-area > li:last-child > .ttl-box::before,
.-setsumeikai-flow .step-area > li:last-child > .ttl-box::before {
  display: none;
}

.-soudankai-flow .step-area > li:last-child > .txt-box,
.-setsumeikai-flow .step-area > li:last-child > .txt-box {
  border-bottom: none;
}

.-soudankai-flow .guide,
.-setsumeikai-flow .guide {
  position: relative;
  background-color: #fff;
}

.-soudankai-flow .guide > .ttl,
.-setsumeikai-flow .guide > .ttl {
  padding: 10px;
  width: 100%;
  background-color: #808080;
  color: #fff;
  font-size: 1.8rem;
}

_:-ms-fullscreen, :root .-soudankai-flow .guide > .ttl, :root
.-setsumeikai-flow .guide > .ttl {
  padding-top: 17px;
}

@media only screen and (max-width: 767px) {
  .-soudankai-flow .guide > .ttl,
  .-setsumeikai-flow .guide > .ttl {
    font-size: 1.6rem;
  }
}

.-soudankai-flow .guide > .wrap,
.-setsumeikai-flow .guide > .wrap {
  padding: 20px;
  width: 100%;
  font-size: 1.6rem;
}

@media only screen and (max-width: 767px) {
  .-soudankai-flow .guide > .wrap,
  .-setsumeikai-flow .guide > .wrap {
    padding: 10px;
    font-size: 1.4rem;
  }
}

.-soudankai-flow .guide.-area01,
.-setsumeikai-flow .guide.-area01 {
  margin: 10px 10px 0 0;
  width: 370px;
}

@media only screen and (max-width: 767px) {
  .-soudankai-flow .guide.-area01,
  .-setsumeikai-flow .guide.-area01 {
    margin: 10px 0 0;
    width: 100%;
  }
}

.-soudankai-flow .guide.-area02,
.-setsumeikai-flow .guide.-area02 {
  margin: 10px 0 0;
  width: 580px;
}

@media only screen and (max-width: 767px) {
  .-soudankai-flow .guide.-area02,
  .-setsumeikai-flow .guide.-area02 {
    width: 100%;
  }
}

@media print, screen and (min-width: 768px) {
  .-soudankai-flow .guide.-area02 > .wrap,
  .-setsumeikai-flow .guide.-area02 > .wrap {
    padding-right: 180px;
  }
  .-soudankai-flow .guide.-area02 > .photo,
  .-setsumeikai-flow .guide.-area02 > .photo {
    position: absolute;
    top: 45px;
    right: 30px;
    width: 120px;
    font-size: 1rem;
    text-align: center;
  }
  .-soudankai-flow .guide.-area02 > .photo img,
  .-setsumeikai-flow .guide.-area02 > .photo img {
    margin-bottom: 3px;
  }
}

@media only screen and (max-width: 767px) {
  .-soudankai-flow .guide.-area02 > .photo,
  .-setsumeikai-flow .guide.-area02 > .photo {
    padding: 0 20px 20px 20px;
    font-size: 1.2rem;
    text-align: center;
  }
  .-soudankai-flow .guide.-area02 > .photo img,
  .-setsumeikai-flow .guide.-area02 > .photo img {
    margin-bottom: 5px;
  }
}

.-soudankai-flow .photo-area,
.-setsumeikai-flow .photo-area {
  margin: 10px 0 0;
  padding: 20px;
  width: 100%;
  background-color: #fff;
}

@media print, screen and (min-width: 768px) {
  .-soudankai-flow .photo-area,
  .-setsumeikai-flow .photo-area {
    display: flex;
    justify-content: space-between;
  }
}

.-soudankai-flow .photo-area > li,
.-setsumeikai-flow .photo-area > li {
  width: 300px;
}

@media only screen and (max-width: 767px) {
  .-soudankai-flow .photo-area > li,
  .-setsumeikai-flow .photo-area > li {
    width: 100%;
  }
}

.-soudankai-flow .photo-area > li img,
.-setsumeikai-flow .photo-area > li img {
  margin: 5px 0;
}

/* ============================================================
	soudankai/webmtg-box
============================================================ */
.webmtg-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding: 10px 20px;
  border: 1px solid #ccc;
}

.webmtg-box > .photo {
  width: 300px;
  height: auto;
}

.webmtg-box > .detail {
  width: 720px;
}

.webmtg-box > .detail > .txt {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .webmtg-box {
    display: block;
    padding: 10px;
    margin: 20px 0 10px;
  }
  .webmtg-box > .photo {
    margin: 10px 0;
    width: 100%;
    text-align: center;
  }
  .webmtg-box > .photo img {
    max-width: 300px;
  }
  .webmtg-box > .detail {
    width: 100%;
  }
}

/* ============================================================
	soudankai/othermtg-box
============================================================ */
.othermtg-box {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.othermtg-box > .detail {
  padding: 10px 20px;
  margin-right: 2%;
  width: 32%;
  border: 1px solid #ccc;
}

.othermtg-box > .detail:last-child {
  margin-right: 0;
}

.othermtg-box > .detail > .h4ttl-line > [class^="icon-"] {
  margin-right: 6px;
  font-size: 2.2rem;
}

.othermtg-box > .detail > .h4ttl-line > .label-red {
  background-color: #e62814;
  color: #fff;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 1.6rem;
  margin-right: 1rem;
}

.othermtg-box > .detail > .txt {
  margin-bottom: 20px;
  text-align: justify;
}

.othermtg-box > .detail.-long {
  width: 66%;
}

@media only screen and (max-width: 767px) {
  .othermtg-box > .detail.-long {
    width: 100%;
  }
}

.othermtg-box > .detail.-long > .webmtg {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.othermtg-box > .detail.-long > .webmtg > .photo {
  width: 300px;
  height: auto;
}

.othermtg-box > .detail.-long > .webmtg > .detail {
  width: 370px;
}

.othermtg-box > .detail.-long > .webmtg > .detail > .txt {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .othermtg-box > .detail.-long > .webmtg {
    display: block;
  }
  .othermtg-box > .detail.-long > .webmtg > .photo {
    margin: 10px 0;
    width: 100%;
    text-align: center;
  }
  .othermtg-box > .detail.-long > .webmtg > .photo img {
    max-width: 300px;
  }
  .othermtg-box > .detail.-long > .webmtg > .detail {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .othermtg-box {
    display: block;
    margin: 20px 0 10px;
  }
  .othermtg-box > .detail {
    padding: 10px;
    margin: 0 0 20px 0;
    width: 100%;
  }
  .othermtg-box > .detail:last-child {
    margin-bottom: 0;
  }
}

/* ============================================================
	soudankai/selectmtg-box
============================================================ */
.selectmtg-box {
  display: block;
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .selectmtg-box {
    margin: 20px 0 10px;
  }
}

.selectmtg-box > .detail {
  margin: 0 0 10px 0;
  padding: 10px 20px;
  width: 100%;
  border: 1px solid #ccc;
}

.selectmtg-box > .detail:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .selectmtg-box > .detail {
    padding: 10px;
  }
}

.selectmtg-box > .detail > .h4ttl-line > [class^="icon-"] {
  margin-right: 6px;
  font-size: 2.2rem;
}

.selectmtg-box > .detail > .h4ttl-line > .label-red {
  background-color: #e62814;
  color: #fff;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 1.6rem;
  margin-right: 1rem;
}

.selectmtg-box > .detail > .wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .selectmtg-box > .detail > .wrap {
    display: block;
  }
}

.selectmtg-box > .detail > .wrap > .txt {
  text-align: justify;
  flex: 1;
}

@media print, screen and (min-width: 768px) {
  .selectmtg-box > .detail > .wrap > .btn-box {
    margin-left: 20px;
    max-width: 272px;
  }
  .selectmtg-box > .detail > .wrap > .btn-box > .btn-publi:last-child {
    min-width: 272px;
  }
}

@media only screen and (max-width: 767px) {
  .selectmtg-box > .detail > .wrap > .btn-box {
    margin-top: 5px;
  }
  .selectmtg-box > .detail > .wrap > .btn-box > .btn-publi {
    min-width: 212px;
  }
  .selectmtg-box > .detail > .wrap > .btn-box > .btn-publi.-blank::before {
    font-size: 1.2rem;
  }
}

/* ============================================================
	publishing/form/choice-area
============================================================ */
.choice-area {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 840px;
}

@media only screen and (max-width: 767px) {
  .choice-area {
    width: 100%;
  }
}

.choice-area > .btn-publi {
  width: 400px;
}

@media only screen and (max-width: 767px) {
  .choice-area > .btn-publi {
    width: 48%;
  }
}

/* ============================================================
	publishing/form/web-flow
============================================================ */
.-web-flow {
  margin-bottom: 0 !important;
  background: url(/publishing/images/common/bg_web.jpg) top center no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 767px) {
  .-web-flow > .h2ttl-icon {
    font-size: 2rem;
  }
}

.-web-flow > .flow-area {
  margin: 30px auto 0;
  padding: 50px 80px;
  width: 960px;
  background-color: rgba(255, 255, 255, 0.7);
}

@media only screen and (max-width: 767px) {
  .-web-flow > .flow-area {
    margin: 25px auto 0;
    padding: 20px 15px;
    width: 100%;
  }
}

.-web-flow > .flow-area > .ttl {
  margin-bottom: 15px;
  font-size: 2rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #e62814;
}

@media only screen and (max-width: 767px) {
  .-web-flow > .flow-area > .ttl {
    font-size: 1.8rem;
  }
}

.-web-flow > .flow-area > .txt {
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .-web-flow > .flow-area > .txt {
    margin-bottom: 15px;
  }
}

.-web-flow > .flow-area > .btn-box {
  margin-bottom: 15px;
  text-align: left;
}

.-web-flow > .flow-area > .btn-box > .btn-publi {
  width: 400px;
}

@media only screen and (max-width: 767px) {
  .-web-flow > .flow-area > .btn-box > .btn-publi {
    width: 100%;
  }
}

.-web-flow > .flow-area > .indent + .ttl {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .-web-flow > .flow-area > .indent + .ttl {
    margin-top: 30px;
  }
}

.-web-flow > .flow-area .txt-box {
  margin-bottom: 15px;
  padding: 15px 30px;
  font-size: 1.6rem;
  line-height: 1.7;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 1px solid #ccc;
}

@media only screen and (max-width: 767px) {
  .-web-flow > .flow-area .txt-box {
    padding: 10px 15px;
  }
}

/* ============================================================
	publishing/form/post-flow
============================================================ */
.-post-flow {
  margin-top: 0 !important;
  background: url(/publishing/images/common/bg_post.jpg) top center no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 767px) {
  .-post-flow > .h2ttl-icon {
    font-size: 2rem;
  }
}

.-post-flow > .flow-area {
  margin: 30px auto 0;
  display: flex;
  flex-wrap: wrap;
  width: 960px;
}

@media only screen and (max-width: 767px) {
  .-post-flow > .flow-area {
    margin: 25px auto 0;
    width: 100%;
  }
}

.-post-flow .step-area {
  background-color: rgba(255, 255, 255, 0.6);
}

.-post-flow .step-area > li {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .-post-flow .step-area > li {
    display: block;
  }
}

.-post-flow .step-area > li > .ttl-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px 10px;
  width: 200px;
  background: #e62814;
  text-align: center;
  color: #fff;
}

@media print, screen and (min-width: 768px) {
  .-post-flow .step-area > li > .ttl-box {
    border-bottom: 2px solid #fff;
  }
}

.-post-flow .step-area > li > .ttl-box:last-child {
  border-bottom: none;
}

.-post-flow .step-area > li > .ttl-box span.eng {
  display: block;
  font-size: 1.6rem;
  color: #eaeaea;
  font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .-post-flow .step-area > li > .ttl-box span.eng {
    display: inline-block;
    font-size: 1.4rem;
  }
}

.-post-flow .step-area > li > .ttl-box span.ttl {
  display: block;
  margin-top: 5px;
  font-size: 2rem;
  line-height: 1.2;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .-post-flow .step-area > li > .ttl-box span.ttl {
    display: inline-block;
    margin-top: 0;
    margin-left: 5px;
    font-size: 1.8rem;
  }
}

@media print, screen and (min-width: 768px) {
  .-post-flow .step-area > li > .ttl-box::before {
    display: block;
    font-family: 'bungeisha' !important;
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -24px;
    font-size: 3.6rem;
    z-index: 1;
  }
}

@media only screen and (max-width: 767px) {
  .-post-flow .step-area > li > .ttl-box {
    display: block;
    padding: 5px 10px;
    width: 100%;
    text-align: left;
  }
}

.-post-flow .step-area > li > .txt-box {
  margin: 0 50px;
  padding: 30px 0;
  width: 660px;
  height: auto;
}

@media print, screen and (min-width: 768px) {
  .-post-flow .step-area > li > .txt-box {
    border-bottom: 1px dotted #ccc;
  }
}

@media only screen and (max-width: 767px) {
  .-post-flow .step-area > li > .txt-box {
    margin: 0;
    padding: 15px 10px 20px 10px;
    width: 100%;
    font-size: 1.4rem;
  }
}

.-post-flow .step-area > li > .txt-box > .ttl {
  margin-bottom: 10px;
  font-size: 2rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #e62814;
}

@media only screen and (max-width: 767px) {
  .-post-flow .step-area > li > .txt-box > .ttl {
    font-size: 1.8rem;
  }
}

.-post-flow .step-area > li > .txt-box > .txt {
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .-post-flow .step-area > li > .txt-box > .txt {
    margin-bottom: 15px;
  }
}

.-post-flow .step-area > li > .txt-box > .btn-box {
  margin-bottom: 15px;
  text-align: left;
}

.-post-flow .step-area > li > .txt-box > .btn-box > .btn-publi {
  width: 400px;
}

@media only screen and (max-width: 767px) {
  .-post-flow .step-area > li > .txt-box > .btn-box > .btn-publi {
    width: 100%;
  }
}

.-post-flow .step-area > li > .txt-box > .btn-box + .ttl {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .-post-flow .step-area > li > .txt-box > .btn-box + .ttl {
    margin-top: 30px;
  }
}

.-post-flow .step-area > li > .txt-box > .pcflexbw-wrap > .item {
  margin-top: 0;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.-post-flow .step-area > li > .txt-box > .add-box {
  display: inline-block;
  margin-bottom: 10px;
  padding: 10px 20px;
  font-size: 1.8rem;
  line-height: 1.5;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 2px solid #e62814;
}

.-post-flow .step-area > li > .txt-box > .add-box > span {
  font-size: 2.2rem;
}

@media only screen and (max-width: 767px) {
  .-post-flow .step-area > li > .txt-box > .add-box {
    display: block;
    padding: 10px;
    font-size: 1.6rem;
  }
  .-post-flow .step-area > li > .txt-box > .add-box > span {
    font-size: 2rem;
  }
}

.-post-flow .step-area > li:last-child > .ttl-box {
  border-bottom: none;
}

.-post-flow .step-area > li:last-child > .ttl-box::before {
  display: none;
}

.-post-flow .step-area > li:last-child > .txt-box {
  border-bottom: none;
}

/* ============================================================
	publishing/form/zeroen-box
============================================================ */
.zeroen-box {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 10px;
  border: 1px solid #ccc;
}

.zeroen-box > .logo {
  width: 300px;
  height: 222px;
}

.zeroen-box > .detail {
  width: 740px;
}

.zeroen-box > .detail > .lead {
  margin-bottom: 5px;
  font-size: 1.6rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.zeroen-box > .detail > .txt {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .zeroen-box {
    display: block;
    margin-top: 20px;
  }
  .zeroen-box > .logo {
    margin-bottom: 10px;
    width: 100%;
    height: auto;
    text-align: center;
  }
  .zeroen-box > .logo img {
    max-width: 300px;
  }
  .zeroen-box > .detail {
    width: 100%;
  }
  .zeroen-box > .detail > .lead {
    font-size: 1.4rem;
  }
}

/* ============================================================
	publishing/download-area
============================================================ */
@media print, screen and (min-width: 768px) {
  .download-area {
    display: flex;
    justify-content: space-between;
  }
  .download-area.-pdf {
    border-top: 1px dotted #ccc;
    margin-top: 20px;
    padding-top: 20px;
  }
  .download-area > .item-set > a {
    padding: 10px 40px 10px 30px;
  }
}

.download-area > .ttl {
  padding: 20px;
  width: 170px;
  background-color: #c6ecf1;
  font-size: 2rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .download-area > .ttl {
    padding: 10px;
    width: 100%;
    font-size: 1.8rem;
    text-align: left;
  }
}

.download-area > .item-set {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .download-area > .item-set {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 40px;
  }
}

.download-area > .item-set > .txt {
  margin-right: 5px;
  width: 256px;
  font-size: 1.8rem;
  line-height: 1.2;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .download-area > .item-set > .txt {
    margin-right: 5px;
    width: auto;
    font-size: 1.6rem;
  }
}

.download-area > .item-set > .txt > span {
  font-size: 1.4rem;
}

@media only screen and (max-width: 767px) {
  .download-area > .item-set > .txt > span {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 400px) {
  .download-area > .item-set > .txt > span {
    display: block;
    margin-left: 1em;
  }
}

/* ============================================================
	publishing/flow
============================================================ */
@media print, screen and (min-width: 768px) {
  .flow-area > .step-box {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .flow-area > .step-box {
    display: block;
    margin-bottom: 30px;
  }
}

.flow-area > .step-box > .ttl {
  margin-right: 20px;
  padding: 15px 10px;
  width: 180px;
  background-color: #e62814;
  font-size: 2rem;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .flow-area > .step-box > .ttl {
    margin: 0 0 10px;
    padding: 10px 5px;
    width: 100%;
    font-size: 1.8rem;
  }
}

.flow-area > .step-box > .ttl > span.eng {
  display: block;
  margin-bottom: 5px;
  font-size: 1.6rem;
  color: #eaeaea;
  font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .flow-area > .step-box > .ttl > span.eng {
    display: inline-block;
    margin: 0 5px 0;
  }
}

.flow-area > .step-box > .lead-box {
  width: 880px;
}

@media only screen and (max-width: 767px) {
  .flow-area > .step-box > .lead-box {
    width: 100%;
  }
}

.flow-area > .step-box > .lead-box > .part {
  display: inline-block;
  margin-bottom: 6px;
  padding: 4px 6px;
  border: 1px solid #8b92c7;
}

.flow-area > .step-box > .lead-box > .lead {
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flow-area > .step-box > .txt-box {
  margin: 15px 20px 0 0;
  width: 810px;
}

@media only screen and (max-width: 767px) {
  .flow-area > .step-box > .txt-box {
    margin: 10px 0 0 0;
    width: 100%;
  }
}

.flow-area > .step-box > .txt-box p {
  margin-bottom: 10px;
  line-height: 1.8;
}

.flow-area > .step-box > .photo {
  margin-top: 15px;
  width: 250px;
}

@media only screen and (max-width: 767px) {
  .flow-area > .step-box > .photo {
    width: 100%;
  }
}

/* ============================================================
	publishing/support
============================================================ */
.support-person {
  position: relative;
}

@media print, screen and (min-width: 768px) {
  .support-person {
    padding-left: 220px;
    min-height: 250px;
  }
}

@media print, screen and (min-width: 768px) {
  .support-person > .photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .support-person > .photo {
    margin-bottom: 10px;
    width: 100%;
  }
}

.support-person > .txt-area {
  margin-bottom: 20px;
  width: 860px;
  padding: 30px;
  background-color: #ffdada;
  border-radius: 5px;
}

@media only screen and (max-width: 767px) {
  .support-person > .txt-area {
    margin-bottom: 10px;
    width: 100%;
    padding: 15px 10px;
  }
}

.support-person > .txt-area > .lead {
  display: block;
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #ff8282;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.support-person > .txt-area > .lead > span {
  font-size: 2.6rem;
}

@media only screen and (max-width: 767px) {
  .support-person > .txt-area > .lead {
    margin-bottom: 10px;
    font-size: 1.6rem;
    line-height: 1.4;
  }
  .support-person > .txt-area > .lead > span {
    font-size: 2.4rem;
  }
}

.support-person > .txt-area > .part {
  display: block;
  font-size: 1.8rem;
  color: #ff8282;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .support-person > .txt-area > .part {
    font-size: 1.6rem;
  }
}

.support-box {
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .support-box {
    margin-top: 25px;
  }
}

.support-box > .list {
  display: flex;
  flex-wrap: wrap;
}

.support-box > .list > li {
  margin-top: 5px;
  width: 25%;
}

@media only screen and (max-width: 767px) {
  .support-box > .list > li {
    width: 50%;
  }
}

@media only screen and (max-width: 479px) {
  .support-box > .list > li {
    width: 100%;
  }
}

/* ============================================================
	publishing/support/partner
============================================================ */
.partner-pc {
  border: 1px solid #e62814;
  border-radius: 10px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .partner-pc {
    display: none;
  }
}

.partner-sp {
  display: none;
}

@media only screen and (max-width: 767px) {
  .partner-sp {
    display: block;
    margin-top: 0 !important;
  }
  .partner-sp > .txt {
    margin-bottom: 10px;
  }
  .partner-sp > .partner-nav {
    border: 1px solid #a5a5a5;
  }
  .partner-sp > .partner-nav > li.ttl {
    padding: 5px;
    background-color: #ccc;
    font-size: 1.6rem;
  }
  .partner-sp > .partner-nav > li.item-box {
    margin: 10px 0 0 10px;
  }
  .partner-sp > .partner-nav > li.item-box > a.item {
    display: inline-block;
    margin: 0 30px 10px 0;
  }
  .partner-sp > .partner-nav > li.item-box > a.item2 {
    display: inline-block;
    margin: 0 16px 10px 0;
  }
  .partner-sp > .partner-nav > li.item-box > a > .icon-arrow-right {
    font-size: 1rem;
  }
}

.partner-list {
  border: 1px solid #a5a5a5;
  width: 100%;
}

.partner-list tr, .partner-list td {
  border: 1px solid #a5a5a5;
  padding: 5px 8px;
}

.partner-list .pub-partner1 {
  margin-bottom: 12px;
  padding-left: 7px;
  border-width: 0px 0px 1px 6px;
  border-color: #e62814;
  border-style: solid;
  font-size: 1.6rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.partner-list .pub-partner2 {
  background: #e5d1b8;
}

.partner-list .pub-partner3 {
  background: #fff;
  width: 50%;
  vertical-align: text-top;
  line-height: 24px;
}

.partner-data {
  margin-top: 10px;
  text-align: right;
}

/* ============================================================
	publishing/support/storage
============================================================ */
.storage-box {
  margin-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .storage-box {
    margin-bottom: 30px;
  }
}

.storage-box .item-list {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .storage-box .item-list {
    display: block;
  }
}

.storage-box .item-list > .item {
  margin-right: 16px;
  width: 258px;
}

@media only screen and (max-width: 767px) {
  .storage-box .item-list > .item {
    margin: 0 0 20px;
    width: 100%;
  }
}

.storage-box .item-list > .item:last-of-type {
  margin-right: 0;
}

.storage-box .item-list > .item > .txt {
  margin-top: 5px;
}

/* ============================================================
	publishing/syuppantaiken-list
============================================================ */
.syuppantaiken-list {
  margin: 0;
  padding: 0;
}

@media print, screen and (min-width: 768px) {
  .syuppantaiken-list {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 767px) {
  .syuppantaiken-list {
    display: block;
  }
}

.syuppantaiken-list.-under {
  margin-bottom: 30px;
}

.syuppantaiken-list > li {
  margin: 0 20px 20px 0;
  width: 530px;
}

.syuppantaiken-list > li:nth-of-type(even) {
  margin-right: 0;
}

@media only screen and (max-width: 767px) {
  .syuppantaiken-list > li {
    margin: 0 0 20px 0;
    width: 100%;
  }
}

.syuppantaiken-list > li > .item {
  display: block;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  padding: 15px 12px;
  width: 528px;
  height: 114px;
  background: #fff;
  border: 2px solid #e62814;
  color: #333;
  text-decoration: none;
  transition: all .3s;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.syuppantaiken-list > li > .item.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 0.7;
}

@media print, screen and (min-width: 768px) {
  .syuppantaiken-list > li > .item:hover {
    opacity: 0.7;
  }
}

@media only screen and (max-width: 767px) {
  .syuppantaiken-list > li > .item {
    padding: 8px 0 8px 5px;
    width: 99.6%;
    height: auto;
    min-height: 82px;
  }
}

.syuppantaiken-list > li > .item > .name {
  margin: 0;
  padding: 0 0 10px;
  width: 354px;
  border-bottom: 1px solid #e62814;
  font-size: 2rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .syuppantaiken-list > li > .item > .name {
    box-sizing: border-box;
    padding: 0 109px 5px 0;
    width: 100%;
    font-size: 1.6rem;
  }
}

.syuppantaiken-list > li > .item > .title {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 354px;
  height: 66px;
  font-size: 1.6rem;
  line-height: 1.4;
}

@media only screen and (max-width: 767px) {
  .syuppantaiken-list > li > .item > .title {
    padding: 5px 109px 0 0;
    width: 100%;
    height: auto;
    font-size: 1.4rem;
  }
}

.syuppantaiken-list img {
  position: absolute;
  top: 0;
  right: 0;
  width: 140px;
  height: 110px;
}

@media only screen and (max-width: 767px) {
  .syuppantaiken-list img {
    width: 109px;
    height: 82px;
    border-left: 5px solid #fff;
  }
}

/* ============================================================
	publishing/syuppantaiken-article
============================================================ */
.syuppantaiken-article {
  position: relative;
  overflow: hidden;
  line-height: 2.0;
}

@media only screen and (max-width: 767px) {
  .syuppantaiken-article {
    line-height: 1.7;
  }
}

.syuppantaiken-article > .tit {
  padding-bottom: 5px;
  margin-bottom: 10px;
  font-size: 2.4rem;
  color: #e62814;
  border-bottom: 1px solid #e62814;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .syuppantaiken-article > .tit {
    font-size: 1.8rem;
  }
}

.syuppantaiken-article > .name {
  margin: 0 0 25px 0;
  font-size: 2rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .syuppantaiken-article > .name {
    font-size: 1.6rem;
  }
}

.syuppantaiken-article > .article-bg {
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
}

@media only screen and (max-width: 767px) {
  .syuppantaiken-article > .article-bg {
    bottom: 20px;
    text-align: right;
  }
  .syuppantaiken-article > .article-bg img {
    width: 100%;
    max-width: 270px;
    height: auto;
  }
}

.syuppantaiken-article > .lead {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px #808080 dotted;
  font-size: 1.6rem;
}

.syuppantaiken-article > .lead p {
  margin: 15px 0;
}

.syuppantaiken-article > .lead p:last-of-type {
  margin: 15px 0 0;
}

.syuppantaiken-article > .txt {
  width: 780px;
  position: relative;
  font-size: 1.6rem;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .syuppantaiken-article > .txt {
    padding-bottom: 200px;
    width: 100%;
  }
}

.syuppantaiken-article > .txt p {
  margin: 15px 0;
}

.syuppantaiken-article > .txt p.comment {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px #808080 dotted;
}

@media print, screen and (min-width: 768px) {
  .syuppantaiken-article > .txt.-short {
    padding-bottom: 100px;
  }
}

.syuppantaiken-article > .pict {
  margin: 0 auto;
  text-align: center;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .syuppantaiken-article > .pict {
    width: 100%;
    padding-bottom: 0;
    z-index: 1;
  }
}

.syuppantaiken-article > .pict img {
  max-width: 360px;
}

@media only screen and (max-width: 767px) {
  .syuppantaiken-article > .pict img {
    width: 100%;
    padding-bottom: 0;
    z-index: 1;
  }
}

/* ============================================================
	publishing/syuppantaiken-book
============================================================ */
.syuppantaiken-book {
  display: flex;
  flex-wrap: wrap;
}

.syuppantaiken-book > .bookdata {
  margin: 0 9px 10px;
  width: 102px;
  min-height: 200px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  display: block;
}

@media only screen and (max-width: 767px) {
  .syuppantaiken-book > .bookdata {
    margin: 0 2% 2%;
    min-height: auto;
  }
}

@media only screen and (max-width: 700px) {
  .syuppantaiken-book > .bookdata {
    width: 21%;
  }
}

@media only screen and (max-width: 375px) {
  .syuppantaiken-book > .bookdata {
    width: 29%;
  }
}

.syuppantaiken-book > .bookdata img {
  border: 1px solid #666;
  margin-bottom: 5px;
}

/* ============================================================
	publishing/kakitai-list
============================================================ */
.kakitai-list {
  margin: 20px 0;
}

.kakitai-list.-under {
  margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .kakitai-list.-under {
    margin-bottom: 30px;
  }
}

.kakitai-list > li {
  overflow: hidden;
  margin: 0 0 10px 0;
  padding: 0 0 10px 0;
  border-bottom: 1px dotted #ccc;
}

@media only screen and (max-width: 767px) {
  .kakitai-list > li {
    margin: 0 0 20px 0;
  }
}

.kakitai-list > li > .thumbnail {
  float: left;
  width: 90px;
  height: 90px;
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .kakitai-list > li > .thumbnail {
    margin: 0 5px 5px 0;
  }
}

@media print, screen and (min-width: 768px) {
  .kakitai-list > li > .detail {
    float: left;
    margin-top: 10px;
    width: 970px;
  }
}

.kakitai-list > li > .detail > .date {
  display: inline-block;
  margin: 0;
  font-size: 1.4rem;
  color: #666;
}

@media only screen and (max-width: 767px) {
  .kakitai-list > li > .detail > .date {
    margin: 0 5px 5px 0;
  }
}

.kakitai-list > li > .detail > .category {
  display: inline-block;
  margin: 0;
  font-size: 1.4rem;
  color: #666;
}

@media only screen and (max-width: 767px) {
  .kakitai-list > li > .detail > .category {
    margin: 0 5px 5px 0;
  }
}

.kakitai-list > li > .detail > .title {
  display: block;
  margin: 10px 0 5px;
  font-size: 1.6rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .kakitai-list > li > .detail > .title {
    margin: 5px 0 5px 0;
    line-height: 1.4;
  }
}

.kakitai-list > li > .detail > .text {
  display: inline-block;
  margin: 5px 0 0;
  font-size: 1.4rem;
}

@media only screen and (max-width: 767px) {
  .kakitai-list > li > .detail > .text {
    display: inline;
    margin: 10px 0;
    font-size: 1.6rem;
    line-height: 1.4;
  }
}

/* ============================================================
	publishing/kakitai-article
============================================================ */
.kakitai-article > .contents_head {
  padding: 0 0 15px 0;
  font-size: 1.6rem;
}

.kakitai-article > .contents_head > h2 {
  font-size: 2.4rem;
  line-height: 1.5;
  padding: 0;
  margin: 0 0 10px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .kakitai-article > .contents_head > h2 {
    font-size: 2rem;
  }
}

.kakitai-article > .contents_head > .date {
  padding-right: 15px;
}

@media only screen and (max-width: 767px) {
  .kakitai-article > .contents_head > .date {
    padding-right: 0;
  }
}

.kakitai-article > .contents_social {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 10px;
  padding: 5px 2px 10px 10px;
  background: #ddd;
  border: 1px solid #ccc;
}

@media only screen and (max-width: 360px) {
  .kakitai-article > .contents_social {
    padding: 5px 2px 10px 8px;
  }
}

.kakitai-article > .contents_social > div {
  display: inline-block;
  margin-top: 5px;
  padding: 0 5px 0 0;
  height: 20px;
  line-height: 30px;
}

.kakitai-article > .contents_social > div.facebook {
  margin-top: -5px;
  padding: 0;
  width: 91px;
  height: auto;
}

.kakitai-article > .contents_paging {
  margin-top: 20px;
}

.kakitai-article > .contents-main {
  padding: 15px 0 20px;
  font-size: 1.6rem;
}

.kakitai-article > .contents-main h2, .kakitai-article > .contents-main h3 {
  position: relative;
  font-size: 1.8rem;
  line-height: 1.2;
  margin-top: 40px;
  padding: 10px 0 10px 0.5em;
  background-color: #ffdada;
  border-left: 10px solid #ffc1c1;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.kakitai-article > .contents-main h2:first-of-type, .kakitai-article > .contents-main h3:first-of-type {
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  .kakitai-article > .contents-main h2, .kakitai-article > .contents-main h3 {
    margin-top: 30px;
    font-size: 1.6rem;
  }
}

.kakitai-article > .contents-main h4 {
  margin: 15px 0;
  font-size: 1.4rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.kakitai-article > .contents-main p {
  margin: 16px 0;
  line-height: 1.7;
}

@media only screen and (max-width: 767px) {
  .kakitai-article > .contents-main p {
    line-height: 1.5;
  }
}

.kakitai-article > .contents-main a {
  text-decoration: underline;
  text-indent: 0;
}

.kakitai-article > .contents-main a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .kakitai-article > .contents-main a:hover {
    text-decoration: none;
  }
}

.kakitai-article > .contents-main > .blockquote {
  margin: 16px 0;
  border-left: none;
  background-color: #ffedff;
  border-radius: 10px;
  padding: 3em 1em;
  position: relative;
}

.kakitai-article > .contents-main > .blockquote::before {
  content: "“";
  font-size: 400%;
  line-height: 1em;
  color: #ffc1c1;
  position: absolute;
  left: 0.1em;
  top: 0.1em;
}

.kakitai-article > .contents-main > .blockquote::after {
  content: "”";
  font-size: 400%;
  line-height: 0em;
  color: #ffc1c1;
  position: absolute;
  right: 0.1em;
  bottom: -0.1em;
}

.kakitai-article > .contents-main > .blockquote p:first-of-type {
  margin-top: 0;
}

.kakitai-article > .contents-main > .blockquote p:last-of-type {
  margin-bottom: 0;
}

.kakitai-article > .contents-main > .blockquote .source {
  font-size: 1.2rem;
  display: block;
  text-align: right;
}

.kakitai-article > .contents-main > .affiliate-area {
  margin: 50px 0 0;
}

.kakitai-article > .contents-main > .affiliate-area + .affiliate-area {
  margin: 20px 0 0;
}

.kakitai-article > .contents-main > .affiliate-area .memo {
  margin: 0;
  font-size: 1.2rem;
}

.kakitai-article > .contents-main > .affiliate-area > .tit {
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.4;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.kakitai-article > .contents-main > .affiliate-area > .tit > span {
  font-size: 1.2rem;
  font-weight: 500;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
}

.kakitai-article > .contents-main > .affiliate-area > .tit + .note {
  margin: 0;
}

.kakitai-article > .contents-main > .affiliate-area > .list li {
  display: inline-block;
  margin: 10px 10px 0 0;
  font-size: 1.2rem;
  vertical-align: top;
}

.kakitai-article > .contents-main > .affiliate-area > .list li:last-of-type {
  margin-right: 0;
}

.kakitai-article > .contents-main > .affiliate-area > .list li img {
  width: auto;
  border: 1px solid #ccc;
}

.kakitai-article > .contents-main > .affiliate-area > .list li > span {
  display: block;
  margin-top: 5px;
  font-size: 1.2rem;
  line-height: 1.4;
}

.kakitai-article > .contents-main > .affiliate-area > iframe {
  margin-top: 5px;
}

/* ============================================================
	publishing/hondas
============================================================ */
.hondas-illust {
  padding-right: 100px;
  font-size: 6rem;
  text-align: center;
  color: #60a718;
}

.hondas-illust > span {
  display: block;
}

.hondas-illust > .name {
  margin-top: 5px;
  font-size: 1.4rem;
  color: #333;
}

@media only screen and (max-width: 767px) {
  .hondas-illust {
    padding-right: 0;
  }
  .hondas-illust > .name {
    font-size: 1.2rem;
  }
}

.hondas-for {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.hondas-for > .ttl {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 20px;
  width: 350px;
  background-color: #ccff99;
  font-size: 1.8rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.hondas-for > .ttl img {
  width: auto;
  margin-right: 10px;
}

@media print, screen and (min-width: 768px) {
  .hondas-for > .ttl:last-of-type {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .hondas-for > .ttl {
    padding: 10px;
    width: 100%;
    font-size: 1.6rem;
  }
}

.hondas-for > .txt {
  margin: 0 0 10px 30px;
  width: 700px;
}

.hondas-for > .txt:last-of-type {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .hondas-for > .txt {
    margin: 0 0 20px 0;
    width: 100%;
  }
}

.hondas-sbttl {
  font-size: 1.8rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hondas-point {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
}

.hondas-point > .ttl {
  display: flex;
  align-items: center;
  padding: 12px;
  width: 350px;
  color: #fff;
  font-size: 1.8rem;
  line-height: 1.4;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

.hondas-point > .ttl img {
  width: auto;
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .hondas-point > .ttl {
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    font-size: 1.6rem;
  }
}

.hondas-point > .ttl.-first {
  background-color: #d3764e;
}

.hondas-point > .ttl.-second {
  background-color: #135dad;
}

.hondas-point > .ttl .icon-hondas {
  font-size: 3rem;
}

.hondas-point > .ttl > .number {
  margin: 0 10px 0 8px;
  font-size: 2.2rem;
}

.hondas-point > .txt {
  margin-left: 30px;
  width: 700px;
}

@media only screen and (max-width: 767px) {
  .hondas-point > .txt {
    margin-left: 0;
    width: 100%;
  }
}

.hondas-ttlbox {
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 12px;
  background-color: #5eb226;
  color: #fff;
  font-size: 1.8rem;
  line-height: 1.4;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

.hondas-ttlbox img {
  width: auto;
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .hondas-ttlbox {
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    font-size: 1.6rem;
  }
}

.hondas-ttlbox .icon-hondas {
  font-size: 3rem;
}

.hondas-ttlbox > .number {
  margin: 0 10px 0 8px;
  font-size: 2.2rem;
}

@media print, screen and (min-width: 768px) {
  .hondas-kit {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}

.hondas-kit > .txt-box {
  width: 650px;
}

@media only screen and (max-width: 767px) {
  .hondas-kit > .txt-box {
    width: 100%;
  }
}

.hondas-kit > .txt-box > .kit-list {
  margin-top: 15px;
}

@media print, screen and (min-width: 768px) {
  .hondas-kit > .txt-box > .kit-list {
    display: flex;
  }
}

@media only screen and (max-width: 767px) {
  .hondas-kit > .txt-box > .kit-list {
    margin-top: 10px;
  }
}

.hondas-kit > .txt-box > .kit-list > li {
  width: 50%;
  line-height: 1.8;
}

@media only screen and (max-width: 767px) {
  .hondas-kit > .txt-box > .kit-list > li {
    width: 100%;
    line-height: 1.5;
  }
  .hondas-kit > .txt-box > .kit-list > li:last-child {
    margin-top: 5px;
  }
}

.hondas-kit > .img {
  width: 400px;
}

@media only screen and (max-width: 767px) {
  .hondas-kit > .img {
    margin-top: 10px;
    width: 100%;
  }
}

@media print, screen and (min-width: 768px) {
  .hondas-sample {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.hondas-sample > .txt-box {
  width: 580px;
}

@media only screen and (max-width: 767px) {
  .hondas-sample > .txt-box {
    width: 100%;
  }
}

.hondas-sample > .txt-box .ttl {
  margin-bottom: 5px;
  font-size: 1.8rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f00;
}

.hondas-sample > .txt-box .sbttl {
  margin: 20px 0 5px;
  font-size: 1.6rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #60a718;
}

.hondas-sample > .img {
  width: 500px;
  text-align: center;
}

.hondas-sample > .img img {
  width: auto;
}

@media only screen and (max-width: 767px) {
  .hondas-sample > .img {
    margin-top: 10px;
    width: 100%;
  }
}

/* ============================================================
	publishing/faq
============================================================ */
.qa-list {
  margin: 0 auto 20px;
  width: 100%;
  border-bottom: 1px dashed #ccc;
}

@media only screen and (max-width: 767px) {
  .qa-list {
    width: 100%;
  }
}

.qa-list > dt {
  border-top: 1px dashed #ccc;
}

.qa-list > dt:first-of-type {
  border-top: none;
}

.qa-list > dt > .item {
  display: block;
  position: relative;
  padding: 10px 0 10px 22px;
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 1.5;
}

.qa-list > dt > .item .q {
  position: absolute;
  top: 10px;
  left: 0;
}

.qa-list > dd {
  display: none;
  margin: 0 0 15px 22px;
  padding: 10px 15px 15px 15px;
  border: 1px solid #e62814;
  border-radius: 5px;
}

.qa-list > dd .txt-box {
  position: relative;
}

.qa-list > dd .answer {
  position: absolute;
  top: 6px;
  left: 0;
  color: #e62814;
  width: 40px;
  font-size: 1.8rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.qa-list > dd .txt-box .wrap {
  padding: 5px 0 0 30px;
}

.qa-list > dd .txt-box .wrap > p,
.qa-list > dd .txt-box .wrap > ul {
  margin-bottom: 10px;
  font-size: 1.4rem;
  line-height: 1.7;
}

.qa-list > dd .txt-box .wrap > p:last-child,
.qa-list > dd .txt-box .wrap > ul:last-child {
  margin-bottom: 0;
}

.qa-list > dd .txt-box .wrap > p > a,
.qa-list > dd .txt-box .wrap > ul li > a {
  text-decoration: underline;
}

.qa-list > dd .txt-box .wrap > p > a.hover,
.qa-list > dd .txt-box .wrap > ul li > a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .qa-list > dd .txt-box .wrap > p > a:hover,
  .qa-list > dd .txt-box .wrap > ul li > a:hover {
    text-decoration: none;
  }
}

.qa-menu {
  margin: 40px auto 80px;
  padding: 0 80px;
  border-bottom: 1px solid #e62814;
  font-size: 0;
}

@media print, screen and (min-width: 768px) {
  .qa-menu {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 767px) {
  .qa-menu {
    margin: 0 auto 40px;
    padding: 0;
    border-bottom: none;
  }
  .qa-menu > li {
    margin-top: 5px;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #e62814;
    text-align: center;
  }
}

.qa-menu .btn-anker {
  display: block;
  position: relative;
  padding: 10px 30px 10px 10px;
  color: #e62814;
  font-size: 1.8rem;
}

_:-ms-fullscreen, :root .qa-menu .btn-anker {
  padding-top: 17px;
}

.qa-menu .btn-anker::before {
  position: absolute;
  top: 58%;
  transform: translate(0, -58%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 10px;
  font-size: 1.2rem;
  content: "";
  transition: top .3s;
}

.qa-menu .btn-anker.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

.qa-menu .btn-anker.hover::before {
  top: 68%;
  transform: translate(0, -68%);
}

@media print, screen and (min-width: 768px) {
  .qa-menu .btn-anker:hover {
    text-decoration: none;
  }
  .qa-menu .btn-anker:hover::before {
    top: 68%;
    transform: translate(0, -68%);
  }
}

@media only screen and (max-width: 767px) {
  .qa-menu .btn-anker {
    font-size: 1.6rem;
  }
}

/* ============================================================
	publishing/cashico
============================================================ */
.cashico-area {
  margin: 20px auto -18px;
  padding-top: 24px;
  border-top: 1px solid #ccc;
}

@media print, screen and (min-width: 768px) {
  .cashico-area {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}

@media only screen and (max-width: 767px) {
  .cashico-area {
    margin: 16px auto -12px;
    padding-top: 20px;
  }
}

.cashico-area > .img {
  width: 500px;
}

@media only screen and (max-width: 767px) {
  .cashico-area > .img {
    margin-bottom: 10px;
    width: 100%;
  }
}

.cashico-area > .txt {
  width: 540px;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .cashico-area > .txt {
    width: 100%;
    line-height: 1.4;
  }
}

.cashico-area > .txt > p {
  margin-bottom: 10px;
}

/* ============================================================
	contest-list
============================================================ */
.contest-ttl {
  margin-bottom: 30px;
  text-align: center;
}

.contest-ttl > .ttl {
  margin: 0 auto;
  display: inline-block;
  padding-left: 55px;
}

.contest-ttl > .ttl::after, .contest-ttl > .ttl::before {
  top: -6px;
  font-size: 50px;
}

.contest-ttl > .ttl::before {
  color: #ed5c39;
}

@media only screen and (max-width: 767px) {
  .contest-ttl > .ttl {
    padding-left: 45px;
  }
  .contest-ttl > .ttl::after, .contest-ttl > .ttl::before {
    top: -8px;
    font-size: 40px;
  }
}

.contest-ttl > .ttl span {
  font-size: 3.4rem;
  font-family: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .contest-ttl > .ttl span {
    font-size: 2.6rem;
  }
}

@media print, screen and (min-width: 701px) {
  .contest-list {
    display: flex;
    flex-wrap: wrap;
  }
}

.contest-list > .item-box {
  position: relative;
  margin: 16px 16px 0 0;
  width: 258px;
  border: 1px solid #ccc;
}

.contest-list > .item-box:nth-child(4n) {
  margin: 16px 0 0 0;
}

@media only screen and (max-width: 767px) {
  .contest-list > .item-box {
    margin: 16px 0 0 0;
    width: 48.6%;
  }
  .contest-list > .item-box:nth-child(odd) {
    margin: 16px 16px 0 0;
  }
}

@media only screen and (max-width: 700px) {
  .contest-list > .item-box {
    margin: 16px 0 0 0;
    width: 100%;
  }
}

.contest-list > .item-box a.item {
  display: block;
  padding: 14px;
  width: 258px;
  color: #333;
  cursor: pointer;
}

.contest-list > .item-box a.item:visited {
  color: #333;
}

.contest-list > .item-box a.item.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #333;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .contest-list > .item-box a.item:hover {
    color: #333;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .contest-list > .item-box a.item {
    margin: 0;
    width: 100%;
    height: auto;
  }
}

.contest-list > .item-box a.item > .ttl {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.contest-list > .item-box a.item > .bnr {
  margin: 15px 0;
}

.contest-list > .item-box a.item > .txt {
  min-height: 57px;
  font-size: 1.4rem;
  line-height: 1.4;
  text-align: justify;
}

.contest-list > .item-box a.item > .info {
  margin: 15px 0;
  min-height: 38px;
  font-size: 1.4rem;
  line-height: 1.4;
}

.contest-list > .item-box a.item .link {
  position: relative;
  margin: 20px 0 0 0;
  padding-right: 15px;
  color: #e62814;
  text-align: right;
}

.contest-list > .item-box a.item .link::before {
  font-family: "bungeisha" !important;
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 1.2rem;
  transform: translate(0, -50%);
  display: inline-block;
  transition: right .1s;
}

@media only screen and (max-width: 767px) {
  .contest-list > .item-box a.item .link::before {
    font-size: 1rem;
  }
}

.contest-list > .item-box a.item > .status {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 3;
  color: #fff;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.contest-list > .item-box a.item.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.contest-list > .item-box a.item.hover .link::before {
  right: -5px;
}

@media print, screen and (min-width: 768px) {
  .contest-list > .item-box a.item:hover .link::before {
    right: -5px;
  }
}

.contest-list > .item-box a.item.-now::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 5em solid transparent;
  border-left: 5em solid #00a1e9;
  z-index: 2;
}

.contest-list > .item-box a.item.-shortly::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 5em solid transparent;
  border-left: 5em solid #e62814;
  z-index: 2;
}

.contest-list > .item-box a.item.-shortly > .info {
  color: #e62814;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.contest-list > .item-box a.item.-finish::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 5em solid transparent;
  border-left: 5em solid #a5a5a5;
  z-index: 2;
}

.contest-list > .item-box a.item.-result::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 5em solid transparent;
  border-left: 5em solid #c1ab05;
  z-index: 2;
}

.past-wrap {
  text-align: center;
}

.past-btn {
  display: inline-block;
  margin-top: 30px;
}

.past-item {
  position: relative;
  overflow: hidden;
  height: 0;
  text-align: left;
  opacity: 0;
  transition: 0.8s;
}

.past-trigger {
  display: none;
}

.past-trigger:checked + .past-btn {
  display: none;
}

.past-trigger:checked ~ .past-item {
  height: auto;
  opacity: 1;
}

/* ============================================================
	contest-parts
============================================================ */
.contest-main {
  margin-bottom: 15px;
}

.contest-guide {
  border: 1px solid #ccc;
  table-layout: fixed;
}

@media print, screen and (min-width: 768px) {
  .contest-guide {
    width: 1080px;
  }
}

.contest-guide > tbody tr, .contest-guide > tbody th, .contest-guide > tbody td {
  border: 1px solid #ccc;
}

.contest-guide > tbody th, .contest-guide > tbody td {
  padding: 15px;
  line-height: 1.5;
}

.contest-guide > tbody th {
  width: 140px;
  background: #ffe6d8;
  text-align: center;
  vertical-align: top;
}

.contest-guide > tbody td {
  width: 939px;
  text-align: left;
}

@media print, screen and (min-width: 768px) {
  .contest-guide > tbody td > .list {
    display: flex;
    flex-wrap: wrap;
  }
  .contest-guide > tbody td > .list > .ttl {
    width: 90px;
    margin-bottom: 0;
  }
  .contest-guide > tbody td > .list > .txt {
    width: 810px;
  }
  .contest-guide > tbody td > .list > .txt:last-of-type {
    margin-bottom: 0;
  }
}

.contest-guide > tbody td .attention {
  margin-bottom: 20px;
  padding: 8px;
  background-color: #fbded9;
  border: 1px solid #f00;
}

.contest-guide > tbody tr.finish th {
  background: #f5b1aa;
}

.contest-guide > tbody tr.finish td {
  background: #fff9f9;
}

.contest-guide > tbody tr.finish td p:first-of-type {
  margin-bottom: 10px;
}

.contest-guide .template-area {
  margin: 15px auto;
  padding: 15px 45px 15px 15px;
  background-color: #eaeaea;
}

@media print, screen and (min-width: 768px) {
  .contest-guide .template-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.contest-guide .template-area .btnw-publi {
  width: 260px;
  font-size: 1.4rem;
  line-height: 1.4;
}

@media print, screen and (min-width: 768px) {
  .contest-guide .template-area .btnw-publi {
    padding: 10px 50px 10px 40px;
  }
}

.contest-guide .template-area .btnw-publi > span {
  font-size: 1.2rem;
}

.contest-guide .btn-entry {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.contest-guide .btn-entry .btn-publi {
  width: 500px;
}

@media print, screen and (min-width: 768px) {
  .contest-guide .btn-entry .btn-publi {
    padding: 10px 50px 10px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .contest-guide .btn-entry .btn-publi {
    margin-bottom: 10px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .contest-guide > tbody th, .contest-guide > tbody td {
    display: block;
    padding: 5px;
    line-height: 1.5;
    border: none;
    width: 100%;
  }
  .contest-guide > tbody th {
    border-bottom: 1px solid #ccc;
    text-align: left;
  }
  .contest-guide .template-area {
    margin: 15px auto;
    padding: 15px;
  }
  .contest-guide .template-area .btnw-publi {
    margin-top: 10px;
    width: 100%;
  }
}

.contest-alert {
  margin-bottom: 20px;
  padding: 15px;
  width: 100%;
  background-color: #fbded9;
  font-size: 1.6rem;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .contest-alert {
    padding: 10px;
    font-size: 1.4rem;
    line-height: 1.4;
  }
}

.contest-entryinfo {
  margin-bottom: 20px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.contest-entryinfo > .txt {
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 500;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
}

@media only screen and (max-width: 767px) {
  .contest-entryinfo > .txt {
    font-size: 1.4rem;
    line-height: 1.5;
  }
}

.contest-entrybox {
  position: relative;
  margin: 10px 0 0 0;
  padding: 20px 15px 20px 98px;
  border: 1px solid #ff0000;
  border-radius: 5px;
  overflow: hidden;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .contest-entrybox {
    padding: 40px 10px 10px 10px;
  }
}

.contest-entrybox > .ttl {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 800;
  text-align: center;
  background: #ff0000;
}

.contest-entrybox > .sbttl {
  margin-bottom: 10px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.contest-entrybox.-post > .add {
  font-size: 1.8rem;
  line-height: 1.5;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.contest-entrybox.-mail > .add {
  margin: 20px 0;
  font-size: 2.8rem;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}

.contest-entrybox.-fax {
  min-height: 100px;
}

.contest-entrybox.-fax > .number {
  margin-top: 10px;
  font-size: 2.8rem;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}

@media print, screen and (min-width: 768px) {
  .contest-entrybox.-post::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 83px;
    height: 100%;
    text-align: center;
    background: #ff0000 url(/contest/images/contest_entry_01.gif) 50% 50% no-repeat;
    border-radius: 4px 0 0 4px;
  }
  .contest-entrybox.-mail::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 83px;
    height: 100%;
    text-align: center;
    background: #ff0000 url(/contest/images/contest_entry_02.gif) 50% 50% no-repeat;
    border-radius: 4px 0 0 4px;
  }
  .contest-entrybox.-web::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 83px;
    height: 100%;
    text-align: center;
    background: #ff0000 url(/contest/images/contest_entry_03.gif) 50% 50% no-repeat;
    border-radius: 4px 0 0 4px;
  }
  .contest-entrybox.-fax:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 83px;
    height: 100%;
    text-align: center;
    background: #ff0000 url(/contest/images/contest_entry_04.gif) 50% 50% no-repeat;
    border-radius: 4px 0 0 4px;
  }
}

.contest-entrybox .btn-area {
  margin: 15px auto 0;
  padding: 15px;
  background-color: #eaeaea;
  font-size: 1.4rem;
  line-height: 1.5;
}

@media print, screen and (min-width: 768px) {
  .contest-entrybox .btn-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media print, screen and (min-width: 768px) {
  .contest-entrybox .btn-area .btnw-publi {
    padding: 10px 50px 10px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .contest-entrybox .btn-area .btnw-publi {
    margin-top: 10px;
    width: 100%;
  }
}

.contest-entrybox .btn-area .btn-publi {
  width: 344px;
}

@media print, screen and (min-width: 768px) {
  .contest-entrybox .btn-area .btn-publi {
    padding: 10px 50px 10px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .contest-entrybox .btn-area .btn-publi {
    margin-top: 10px;
    width: 100%;
  }
}

.contest-entrynote {
  margin: 15px auto;
  padding: 15px;
  background-color: #eaeaea;
  font-size: 1.4rem;
  line-height: 1.5;
}

.contest-inquiry {
  margin: 0 auto;
}

@media print, screen and (min-width: 768px) {
  .contest-inquiry {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.contest-inquiry > li {
  display: inline-block;
  margin: 0;
  padding-left: 60px;
  color: #fc0d1b;
  font-size: 3.2rem;
  line-height: 1;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media print, screen and (min-width: 768px) {
  .contest-inquiry > li.mail {
    display: flex;
    align-items: center;
    height: 43px;
    background: url(/contest/images/contest_inquiry_mail.png) left center no-repeat;
  }
  .contest-inquiry > li.tel-box {
    background: url(/contest/images/contest_inquiry_tel.png) left center no-repeat;
  }
  .contest-inquiry > li.fax {
    background: url(/contest/images/contest_inquiry_fax.png) left center no-repeat;
  }
  .contest-inquiry > li > p.ttl {
    display: none;
  }
}

.contest-inquiry > li.mail {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}

.contest-inquiry > li > .tel {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}

.contest-inquiry > li > .number {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}

.contest-inquiry > li > .time {
  margin-top: 5px;
  color: #333;
  font-size: 1.4rem;
  font-weight: 500;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
}

@media only screen and (max-width: 767px) {
  .contest-inquiry > li {
    display: block;
    font-size: 3rem;
    padding: 10px 0;
    border-bottom: 1px dotted #cccccc;
  }
  .contest-inquiry > li:first-of-type {
    padding-top: 0;
  }
  .contest-inquiry > li > p {
    display: inline-block;
  }
  .contest-inquiry > li > p.ttl {
    margin-right: 5px;
    font-size: 1.4rem;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
  }
  .contest-inquiry > li.tel-box a {
    color: #fc0d1b;
  }
  .contest-inquiry > li > .time {
    margin-top: 0;
    font-size: 1.2rem;
  }
}

/* ============================================================
	contest-extend
============================================================ */
.contest-extend {
  margin-bottom: 20px;
  padding: 8px;
  background-color: #fff2f2;
  border: 1px solid #f00;
  line-height: 1.5;
}

/* ============================================================
	contest-report
============================================================ */
.contest-report {
  margin: 30px 0 0;
}

.contest-report > h2 {
  font-size: 2.0rem;
  margin-bottom: 15px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.contest-report > .txt-box {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.7;
}

.contest-report > .txt-box .ph-box {
  float: right;
  margin: 5px 0 8px 10px;
  width: 260px;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1;
  clear: both;
}

.contest-report > .txt-box .ph-box img {
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .contest-report > .txt-box .ph-box {
    margin: 0 0 25px;
    width: 100%;
  }
  .contest-report > .txt-box .ph-box img {
    width: 260px;
  }
}

/* ============================================================
	contest-result
============================================================ */
.contest-result {
  margin: 30px 0 40px;
  padding: 30px 20px;
  box-sizing: border-box;
  border: 1px solid #1572b8;
  background-color: #f2f8fc;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .contest-result {
    padding: 20px 10px;
    width: 100%;
  }
}

.contest-result > h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  text-align: center;
  color: #36357e;
  font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
  font-weight: 700;
  text-shadow: white 2px 0px 2px, white -2px 0px 2px, white 0px -2px 2px, white -2px 0px 2px, white 2px 2px 2px, white -2px 2px 2px, white 2px -2px 2px, white -2px -2px 2px, white 1px 2px 2px, white -1px 2px 2px, white 1px -2px 2px, white -1px -2px 2px, white 2px 1px 2px, white -2px 1px 2px, white 2px -1px 2px, white -2px -1px 2px, white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px;
  transform: rotate(0.03deg);
}

@media only screen and (max-width: 767px) {
  .contest-result > h2 {
    margin-bottom: 30px;
  }
}

.contest-result > h2 span {
  font-size: 2rem;
}

@media only screen and (max-width: 767px) {
  .contest-result > h2 span {
    font-size: 1.6rem;
  }
}

.contest-result > h2 span.ttl {
  margin: 0 20px;
  font-size: 3.6rem;
}

@media only screen and (max-width: 767px) {
  .contest-result > h2 span.ttl {
    margin: 0 10px;
    font-size: 2.8rem;
  }
}

.contest-result > .genre01 {
  margin-bottom: 30px;
  font-size: 3rem;
  color: #1467b3;
}

@media only screen and (max-width: 767px) {
  .contest-result > .genre01 {
    margin-bottom: 20px;
    font-size: 2.4rem;
  }
}

.contest-result > .genre02 {
  margin: 60px 0 20px;
  font-size: 3rem;
  color: #257b8a;
}

@media only screen and (max-width: 767px) {
  .contest-result > .genre02 {
    margin: 40px 0 20px;
    font-size: 2.4rem;
  }
}

.contest-result > h5 {
  margin: 30px 0 10px;
  padding: 5px 10px;
  background: #1572b8;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 700;
  color: #ffffff;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .contest-result > h5 {
    margin: 20px 0 10px;
  }
}

.contest-result > .txt {
  padding: 0 10px;
  text-align: justify;
}

.contest-result .grand {
  color: #00548a;
}

.contest-result .grand > h4 {
  margin: 0;
  font-size: 3.6rem;
  line-height: 1;
  font-weight: 700;
  color: #b38813;
  font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
  text-shadow: white 2px 0px 2px, white -2px 0px 2px, white 0px -2px 2px, white -2px 0px 2px, white 2px 2px 2px, white -2px 2px 2px, white 2px -2px 2px, white -2px -2px 2px, white 1px 2px 2px, white -1px 2px 2px, white 1px -2px 2px, white -1px -2px 2px, white 2px 1px 2px, white -2px 1px 2px, white 2px -1px 2px, white -2px -1px 2px, white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px;
}

@media only screen and (max-width: 767px) {
  .contest-result .grand > h4 {
    font-size: 2.8rem;
  }
}

.contest-result .grand > .prize {
  margin: 10px 0;
  font-size: 1.8rem;
  line-height: 1.4;
  font-weight: 700;
  color: #b38813;
  font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
  text-shadow: white 2px 0px 2px, white -2px 0px 2px, white 0px -2px 2px, white -2px 0px 2px, white 2px 2px 2px, white -2px 2px 2px, white 2px -2px 2px, white -2px -2px 2px, white 1px 2px 2px, white -1px 2px 2px, white 1px -2px 2px, white -1px -2px 2px, white 2px 1px 2px, white -2px 1px 2px, white 2px -1px 2px, white -2px -1px 2px, white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px;
}

@media only screen and (max-width: 767px) {
  .contest-result .grand > .prize {
    font-size: 1.6rem;
  }
}

.contest-result .grand > .title {
  margin: 20px 0 10px;
  font-size: 4.0rem;
  line-height: 1.4;
  font-weight: 700;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .contest-result .grand > .title {
    margin: 15px 0 10px;
    font-size: 3rem;
  }
}

.contest-result .grand > .author {
  font-size: 2.8rem;
  line-height: 1;
  font-weight: 700;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .contest-result .grand > .author {
    font-size: 2.2rem;
  }
}

.contest-result .grand > .author > span {
  font-size: 2.2rem;
}

@media only screen and (max-width: 767px) {
  .contest-result .grand > .author > span {
    font-size: 1.8rem;
  }
}

.contest-result .selected {
  margin-top: 60px;
  color: #00548a;
}

@media only screen and (max-width: 767px) {
  .contest-result .selected {
    margin-top: 40px;
  }
}

.contest-result .selected > h4 {
  margin: 0;
  font-size: 3rem;
  line-height: 1;
  font-weight: 700;
  color: #888;
  font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
  text-shadow: white 2px 0px 2px, white -2px 0px 2px, white 0px -2px 2px, white -2px 0px 2px, white 2px 2px 2px, white -2px 2px 2px, white 2px -2px 2px, white -2px -2px 2px, white 1px 2px 2px, white -1px 2px 2px, white 1px -2px 2px, white -1px -2px 2px, white 2px 1px 2px, white -2px 1px 2px, white 2px -1px 2px, white -2px -1px 2px, white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px;
}

@media only screen and (max-width: 767px) {
  .contest-result .selected > h4 {
    font-size: 2.6rem;
  }
}

.contest-result .selected > .prize {
  margin: 10px 0;
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: 700;
  color: #888;
  font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
  text-shadow: white 2px 0px 2px, white -2px 0px 2px, white 0px -2px 2px, white -2px 0px 2px, white 2px 2px 2px, white -2px 2px 2px, white 2px -2px 2px, white -2px -2px 2px, white 1px 2px 2px, white -1px 2px 2px, white 1px -2px 2px, white -1px -2px 2px, white 2px 1px 2px, white -2px 1px 2px, white 2px -1px 2px, white -2px -1px 2px, white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px;
}

@media only screen and (max-width: 767px) {
  .contest-result .selected > .prize {
    font-size: 1.4rem;
    line-height: 1.3;
  }
}

.contest-result .selected > .title {
  margin: 20px 0 10px;
  font-size: 3.4rem;
  line-height: 1.4;
  font-weight: 700;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .contest-result .selected > .title {
    margin: 15px 0 10px;
    font-size: 2.8rem;
  }
}

.contest-result .selected > .author {
  font-size: 2.6rem;
  line-height: 1;
  font-weight: 700;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .contest-result .selected > .author {
    font-size: 2rem;
  }
}

.contest-result .selected > .author > span {
  font-size: 2rem;
}

@media only screen and (max-width: 767px) {
  .contest-result .selected > .author > span {
    font-size: 1.6rem;
  }
}

.contest-result > ul.list {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .contest-result > ul.list {
    display: block;
  }
}

.contest-result > ul.list > li {
  margin: 10px 5px 0;
  padding: 5px 10px;
  width: 336px;
  background: #ffffff;
  border: 1px solid #257b8a;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 700;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .contest-result > ul.list > li {
    width: auto;
  }
}

.contest-result > ul.list > li span {
  text-align: right;
  font-weight: 500;
  font-size: 1.5rem;
}

/* ============================================================
	contest-ehon
============================================================ */
.ehon-info {
  padding: 10px;
  text-align: center;
  font-size: 2.2rem;
  line-height: 1.4;
  color: #fff;
  font-weight: 500 !important;
}

_:-ms-fullscreen, :root .ehon-info {
  padding-top: 17px;
}

@media only screen and (max-width: 767px) {
  .ehon-info {
    font-size: 1.6rem;
  }
}

.ehon-memo {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #ffc99e;
  background: #fffde7;
  color: #8a745d;
  font-size: 1.4rem;
  line-height: 1.5;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .ehon-memo {
    padding: 10px;
    font-size: 1.2rem;
    line-height: 1.4;
    text-align: left;
  }
}

.ehon-report {
  margin: 30px 0 0;
}

.ehon-report > h2 {
  font-size: 2.0rem;
  margin-bottom: 15px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ehon-report > .txt-box {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.7;
}

.ehon-report > .txt-box .ph-box {
  float: right;
  margin: 5px 0 8px 10px;
  width: 260px;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1;
  clear: both;
}

.ehon-report > .txt-box .ph-box img {
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .ehon-report > .txt-box .ph-box {
    margin: 0 0 25px;
    width: 100%;
  }
  .ehon-report > .txt-box .ph-box img {
    width: 260px;
  }
}

.ehon-result {
  margin: 30px 0 40px;
  padding: 30px 100px 60px;
  box-sizing: border-box;
  background-color: #ffffef;
  border: 1px solid #e8542d;
}

@media only screen and (max-width: 767px) {
  .ehon-result {
    margin: 20px 0 30px;
    padding: 20px 10px 10px;
  }
}

.ehon-result > h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  text-align: center;
  color: #e8542d;
  font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
  font-weight: 700;
  text-shadow: white 2px 0px 2px, white -2px 0px 2px, white 0px -2px 2px, white -2px 0px 2px, white 2px 2px 2px, white -2px 2px 2px, white 2px -2px 2px, white -2px -2px 2px, white 1px 2px 2px, white -1px 2px 2px, white 1px -2px 2px, white -1px -2px 2px, white 2px 1px 2px, white -2px 1px 2px, white 2px -1px 2px, white -2px -1px 2px, white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px;
  transform: rotate(0.03deg);
}

@media only screen and (max-width: 767px) {
  .ehon-result > h2 {
    margin-bottom: 20px;
  }
}

.ehon-result > h2 span {
  font-size: 2rem;
}

@media only screen and (max-width: 767px) {
  .ehon-result > h2 span {
    font-size: 1.6rem;
  }
}

.ehon-result > h2 span.ttl {
  margin: 0 20px;
  font-size: 3.6rem;
}

@media only screen and (max-width: 767px) {
  .ehon-result > h2 span.ttl {
    margin: 0 10px;
    font-size: 2.8rem;
  }
}

.ehon-result .in-box {
  text-align: center;
  border-radius: 12px;
}

@media only screen and (max-width: 767px) {
  .ehon-result .in-box {
    border-radius: 6px;
  }
}

.ehon-result .in-box > h3 {
  margin: 30px 0;
  position: relative;
  text-align: center;
  font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
  text-shadow: white 2px 0px 2px, white -2px 0px 2px, white 0px -2px 2px, white -2px 0px 2px, white 2px 2px 2px, white -2px 2px 2px, white 2px -2px 2px, white -2px -2px 2px, white 1px 2px 2px, white -1px 2px 2px, white 1px -2px 2px, white -1px -2px 2px, white 2px 1px 2px, white -2px 1px 2px, white 2px -1px 2px, white -2px -1px 2px, white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px;
}

@media only screen and (max-width: 767px) {
  .ehon-result .in-box > h3 {
    margin: 20px 0;
  }
}

.ehon-result .in-box > h3 span {
  position: relative;
  z-index: 2;
  display: inline-block;
  margin: 0 4em;
  padding: 0 1em;
  background-color: #efffff;
  text-align: left;
  color: #086eff;
  font-size: 3rem;
}

@media only screen and (max-width: 767px) {
  .ehon-result .in-box > h3 span {
    position: relative;
    z-index: 2;
    display: inline-block;
    margin: 0 1em;
    padding: 0 .5em;
    font-size: 2.4rem;
  }
}

.ehon-result .in-box > h3::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 100%;
  height: 2px;
  background: #086eff;
}

.ehon-result .in-box.-ehon {
  background-color: #efffff;
  border: 1px solid #086eff;
  color: #086eff;
}

.ehon-result .in-box.-story {
  margin-top: 40px;
  background-color: #f0fff0;
  border: 1px solid #01a447;
  color: #01a447;
}

@media only screen and (max-width: 767px) {
  .ehon-result .in-box.-story {
    margin-top: 20px;
  }
}

.ehon-result .in-box.-story > h3 span {
  background-color: #f0fff0;
  color: #01a447;
}

.ehon-result .in-box.-story > h3::before {
  background: #01a447;
}

.ehon-result .in-box .grand > h4 {
  margin: 0;
  font-size: 3.6rem;
  line-height: 1;
  font-weight: 700;
  color: #b38813;
  font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
  text-shadow: white 2px 0px 2px, white -2px 0px 2px, white 0px -2px 2px, white -2px 0px 2px, white 2px 2px 2px, white -2px 2px 2px, white 2px -2px 2px, white -2px -2px 2px, white 1px 2px 2px, white -1px 2px 2px, white 1px -2px 2px, white -1px -2px 2px, white 2px 1px 2px, white -2px 1px 2px, white 2px -1px 2px, white -2px -1px 2px, white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px;
}

@media only screen and (max-width: 767px) {
  .ehon-result .in-box .grand > h4 {
    font-size: 2.8rem;
  }
}

.ehon-result .in-box .grand > .prize {
  margin: 10px 0;
  font-size: 2rem;
  line-height: 1;
  font-weight: 700;
  color: #b38813;
  font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
  text-shadow: white 2px 0px 2px, white -2px 0px 2px, white 0px -2px 2px, white -2px 0px 2px, white 2px 2px 2px, white -2px 2px 2px, white 2px -2px 2px, white -2px -2px 2px, white 1px 2px 2px, white -1px 2px 2px, white 1px -2px 2px, white -1px -2px 2px, white 2px 1px 2px, white -2px 1px 2px, white 2px -1px 2px, white -2px -1px 2px, white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px;
}

@media only screen and (max-width: 767px) {
  .ehon-result .in-box .grand > .prize {
    font-size: 1.6rem;
  }
}

.ehon-result .in-box .grand > .title {
  margin: 20px 0 10px;
  font-size: 4.0rem;
  line-height: 1.4;
  font-weight: 700;
  text-align: center;
  text-shadow: white 2px 0px 2px, white -2px 0px 2px, white 0px -2px 2px, white -2px 0px 2px, white 2px 2px 2px, white -2px 2px 2px, white 2px -2px 2px, white -2px -2px 2px, white 1px 2px 2px, white -1px 2px 2px, white 1px -2px 2px, white -1px -2px 2px, white 2px 1px 2px, white -2px 1px 2px, white 2px -1px 2px, white -2px -1px 2px, white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px, 0 3px 10px rgba(0, 0, 0, 0.7);
}

@media only screen and (max-width: 767px) {
  .ehon-result .in-box .grand > .title {
    margin: 15px 0 10px;
    font-size: 3rem;
  }
}

.ehon-result .in-box .grand > .author {
  font-size: 2.8rem;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  text-shadow: white 2px 0px 2px, white -2px 0px 2px, white 0px -2px 2px, white -2px 0px 2px, white 2px 2px 2px, white -2px 2px 2px, white 2px -2px 2px, white -2px -2px 2px, white 1px 2px 2px, white -1px 2px 2px, white 1px -2px 2px, white -1px -2px 2px, white 2px 1px 2px, white -2px 1px 2px, white 2px -1px 2px, white -2px -1px 2px, white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px, 0 3px 10px rgba(0, 0, 0, 0.7);
}

@media only screen and (max-width: 767px) {
  .ehon-result .in-box .grand > .author {
    font-size: 2.2rem;
  }
}

.ehon-result .in-box .grand > .author > span {
  font-size: 2.2rem;
}

@media only screen and (max-width: 767px) {
  .ehon-result .in-box .grand > .author > span {
    font-size: 1.8rem;
  }
}

.ehon-result .in-box .grand > .txt {
  margin: 20px 0;
  padding: 0 40px;
  text-align: justify;
  font-size: 1.6rem;
}

@media only screen and (max-width: 767px) {
  .ehon-result .in-box .grand > .txt {
    margin: 20px 0;
    padding: 0 10px;
    font-size: 1.4rem;
  }
}

.ehon-result .in-box .selected {
  margin: 50px 0;
}

@media only screen and (max-width: 767px) {
  .ehon-result .in-box .selected {
    margin: 40px 0;
  }
}

.ehon-result .in-box .selected > h5 {
  margin: 0;
  font-size: 3rem;
  line-height: 1;
  font-weight: 700;
  color: #888;
  font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
  text-shadow: white 2px 0px 2px, white -2px 0px 2px, white 0px -2px 2px, white -2px 0px 2px, white 2px 2px 2px, white -2px 2px 2px, white 2px -2px 2px, white -2px -2px 2px, white 1px 2px 2px, white -1px 2px 2px, white 1px -2px 2px, white -1px -2px 2px, white 2px 1px 2px, white -2px 1px 2px, white 2px -1px 2px, white -2px -1px 2px, white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px;
}

@media only screen and (max-width: 767px) {
  .ehon-result .in-box .selected > h5 {
    font-size: 2.6rem;
  }
}

.ehon-result .in-box .selected > .title {
  margin: 20px 0 10px;
  font-size: 3.4rem;
  line-height: 1.4;
  font-weight: 700;
  text-align: center;
  text-shadow: white 2px 0px 2px, white -2px 0px 2px, white 0px -2px 2px, white -2px 0px 2px, white 2px 2px 2px, white -2px 2px 2px, white 2px -2px 2px, white -2px -2px 2px, white 1px 2px 2px, white -1px 2px 2px, white 1px -2px 2px, white -1px -2px 2px, white 2px 1px 2px, white -2px 1px 2px, white 2px -1px 2px, white -2px -1px 2px, white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px, 0 3px 10px rgba(0, 0, 0, 0.7);
}

@media only screen and (max-width: 767px) {
  .ehon-result .in-box .selected > .title {
    margin: 15px 0 10px;
    font-size: 2.8rem;
  }
}

.ehon-result .in-box .selected > .author {
  font-size: 2.6rem;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  text-shadow: white 2px 0px 2px, white -2px 0px 2px, white 0px -2px 2px, white -2px 0px 2px, white 2px 2px 2px, white -2px 2px 2px, white 2px -2px 2px, white -2px -2px 2px, white 1px 2px 2px, white -1px 2px 2px, white 1px -2px 2px, white -1px -2px 2px, white 2px 1px 2px, white -2px 1px 2px, white 2px -1px 2px, white -2px -1px 2px, white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px, 0 3px 10px rgba(0, 0, 0, 0.7);
}

@media only screen and (max-width: 767px) {
  .ehon-result .in-box .selected > .author {
    font-size: 2rem;
  }
}

.ehon-result .in-box .selected > .author > span {
  font-size: 2rem;
}

@media only screen and (max-width: 767px) {
  .ehon-result .in-box .selected > .author > span {
    font-size: 1.6rem;
  }
}

/* ===================================================================
	ehon-awardlist
=================================================================== */
.ehon-awardlist {
  box-sizing: border-box;
  margin: 0;
  width: 1080px;
  padding: 25px 20px 20px;
  background: #fed001 url(/ehon/images/awardlist_bg.png) repeat;
  border: 1px solid #fd8900;
  border-radius: 5px;
  text-align: center;
}

.ehon-awardlist .award-set {
  box-sizing: border-box;
  margin: 30px auto 0;
  padding: 14px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  text-align: center;
}

.ehon-awardlist > .award-set:first-child {
  margin-top: 0;
}

.ehon-awardlist .award-set > .title {
  display: inline-block;
  position: relative;
  height: 45px;
  vertical-align: middle;
  text-align: center;
  box-sizing: border-box;
  margin: -30px 0 0;
}

.ehon-awardlist .award-set > .title::before {
  content: '';
  position: absolute;
  width: 10px;
  bottom: -10px;
  left: -35px;
  border: 20px solid #f7f7f7;
  border-left-color: transparent;
}

.ehon-awardlist .award-set > .title::after {
  content: '';
  position: absolute;
  width: 10px;
  bottom: -10px;
  right: -35px;
  border: 20px solid #f7f7f7;
  border-right-color: transparent;
}

.ehon-awardlist .award-set > .title > h3 {
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0 20px;
  line-height: 45px;
  font-size: 1.8rem;
  font-weight: 700;
  color: #fe0000;
  background: #fff;
  z-index: 1;
}

.ehon-awardlist .award-set > .title > h3::before {
  position: absolute;
  content: '';
  top: 100%;
  left: 0;
  border: none;
  border-bottom: solid 10px transparent;
  border-right: solid 15px #ccc;
}

.ehon-awardlist .award-set > .title > h3::after {
  position: absolute;
  content: '';
  top: 100%;
  right: 0;
  border: none;
  border-bottom: solid 10px transparent;
  border-left: solid 15px #ccc;
}

.ehon-awardlist .award-set > .box {
  margin: 20px 0 0;
}

@media print, screen and (min-width: 768px) {
  .ehon-awardlist .award-set > .box {
    display: flex;
    justify-content: space-between;
  }
}

.ehon-awardlist .award-set > .box > li {
  position: relative;
  width: 498px;
  min-height: 280px;
  border: 1px solid #ff3366;
  border-radius: 10px;
  padding-top: 31px;
}

.ehon-awardlist .award-set > .box > li.award01 {
  padding-top: 0;
  width: 100%;
}

.ehon-awardlist .award-set > .box > li.award-single {
  width: 100%;
}

.ehon-awardlist .award-set > .box > li > h4 {
  position: absolute;
  top: 0;
  display: block;
  margin: 0;
  padding: 8px 0 7px 0;
  width: 100%;
  color: #fff;
  font-size: 1.6rem;
  background: #ff3366;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ehon-awardlist .award-set > .note {
  margin-top: 10px;
  text-align: right;
}

@media print, screen and (min-width: 768px) {
  .ehon-awardlist .award-set > .box dl {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: 0;
  }
}

.ehon-awardlist .award-set > .box dl dt {
  margin: 0;
  padding: 0 10px 0 0;
  text-align: center;
}

.ehon-awardlist .award-set > .box dl dt > img {
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
  width: auto;
}

.ehon-awardlist .award-set > .box dl dd {
  margin: 0;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  min-width: 230px;
}

.ehon-awardlist .award-set > .box dl dd > h5 {
  margin: 0 0 10px;
  color: #ff3366;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.2;
  text-shadow: white 2px 0px 2px, white -2px 0px 2px, white 0px -2px 2px, white -2px 0px 2px, white 2px 2px 2px, white -2px 2px 2px, white 2px -2px 2px, white -2px -2px 2px, white 1px 2px 2px, white -1px 2px 2px, white 1px -2px 2px, white -1px -2px 2px, white 2px 1px 2px, white -2px 1px 2px, white 2px -1px 2px, white -2px -1px 2px, white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px;
}

.ehon-awardlist .award-set > .box dl dd > h5.long {
  letter-spacing: -.1em;
}

.ehon-awardlist .award-set > .box dl dd > .name {
  margin: 0 0 15px;
  color: #ff3366;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.4;
  text-shadow: white 2px 0px 2px, white -2px 0px 2px, white 0px -2px 2px, white -2px 0px 2px, white 2px 2px 2px, white -2px 2px 2px, white 2px -2px 2px, white -2px -2px 2px, white 1px 2px 2px, white -1px 2px 2px, white 1px -2px 2px, white -1px -2px 2px, white 2px 1px 2px, white -2px 1px 2px, white 2px -1px 2px, white -2px -1px 2px, white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px;
}

.ehon-awardlist .award-set > .box dl dd > .detail {
  margin: 0 0 5px;
  font-size: 1.2rem;
  line-height: 1.4;
}

.ehon-awardlist .award-set > .box dl dd > a {
  position: relative;
  display: inline-block;
  font-size: 1.6rem;
  line-height: 24px;
  height: 36px;
  margin: 10px 0 0;
  padding: 6px 15px;
  text-decoration: none;
  color: #ffffff;
  font-weight: 700;
  border-radius: 5px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.8);
  border: 1px solid #fff;
  text-shadow: 0 0 5px #336690;
  background: linear-gradient(to bottom, #aeec65 0%, #55aa04 100%);
  backface-visibility: hidden;
}

.ehon-awardlist .award-set > .box dl dd > a:hover {
  opacity: .8;
}

.ehon-awardlist > .award-past > .past-btn {
  display: inline-block;
  margin: 20px 0 0;
  padding: 8px 8px 8px 27px;
  border-radius: 2px;
  background: #4169e1;
  color: #fff;
  font-size: 1.4rem;
  cursor: pointer;
  transition: .2s ease;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  position: relative;
}

.ehon-awardlist > .award-past > .past-btn:hover {
  background: #fff;
  color: #4169e1;
}

.ehon-awardlist > .award-past > .past-btn::before {
  position: absolute;
  content: "";
  top: 9px;
  left: 10px;
  width: 10px;
  height: 10px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
  box-sizing: border-box;
}

.ehon-awardlist > .award-past > .past-btn:hover::before {
  border-left: 2px solid #4169e1;
  border-bottom: 2px solid #4169e1;
}

.ehon-awardlist > .award-past > .past-item {
  position: relative;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: 0.8s;
}

.ehon-awardlist > .award-past > .past-trigger {
  display: none;
}

.ehon-awardlist > .award-past > .past-trigger:checked + .past-btn {
  display: none;
}

.ehon-awardlist > .award-past > .past-trigger:checked ~ .past-item {
  height: auto;
  opacity: 1;
}

/* ===================================================================
	ehon-awardlist-sp
=================================================================== */
@media only screen and (max-width: 767px) {
  .ehon-awardlist {
    width: 100%;
    padding: 25px 10px 10px;
  }
  .ehon-awardlist .award-set {
    padding: 10px;
  }
  .ehon-awardlist .award-set > .title {
    height: 35px;
    margin: -20px 0 0;
  }
  .ehon-awardlist .award-set > .title::before {
    bottom: -8px;
    left: -22px;
    border: 13px solid #f7f7f7;
    border-left-color: transparent;
  }
  .ehon-awardlist .award-set > .title::after {
    bottom: -8px;
    right: -22px;
    border: 13px solid #f7f7f7;
    border-right-color: transparent;
  }
  .ehon-awardlist .award-set > .title > h3 {
    padding: 0 15px;
    line-height: 35px;
    font-size: 1.8rem;
  }
  .ehon-awardlist .award-set > .title > h3::before {
    border: none;
    border-bottom: solid 8px transparent;
    border-right: solid 15px #ccc;
  }
  .ehon-awardlist .award-set > .title > h3::after {
    border: none;
    border-bottom: solid 8px transparent;
    border-left: solid 15px #ccc;
  }
  .ehon-awardlist .award-set > .box > li {
    margin: 20px 0 0;
    width: 100%;
    min-height: auto;
    padding-top: 0;
  }
  .ehon-awardlist .award-set > .box > li > h4 {
    position: relative;
    top: auto;
  }
  .ehon-awardlist .award-set > .box dl {
    display: block;
    margin: 0;
    padding: 15px 5px 15px 15px;
  }
  .ehon-awardlist .award-set > .box dl dt {
    padding: 0 10px 20px 0;
  }
  .ehon-awardlist .award-set > .box dl dd {
    padding: 0 10px 0 0;
    white-space: normal;
    min-width: auto;
  }
  .ehon-awardlist .award-set > .box dl dd > h5 {
    font-size: 2rem;
    line-height: 1.2;
  }
  .ehon-awardlist .award-set > .box dl dd > .name {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 345px) {
  .ehon-awardlist .award-set > .title > h3 {
    padding: 0 10px;
    font-size: 1.7rem;
  }
}

/* ============================================================
	contest-old
============================================================ */
.old-wrap {
  font-size: 1.4rem;
  line-height: 1.5;
}

@media print, screen and (min-width: 768px) {
  .old-wrap img {
    width: auto;
  }
}

.old-wrap h2, .old-wrap h3, .old-wrap h4, .old-wrap h5, .old-wrap h6 {
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .old-wrap img.wauto {
    width: auto;
  }
}

@media only screen and (max-width: 320px) {
  .old-wrap img.wauto {
    width: auto;
    max-width: 280px;
  }
}

@media only screen and (max-width: 767px) {
  .old-wrap {
    font-size: 1.2rem;
  }
}

.old-wrap .result-box {
  margin: 40px auto;
  padding: 10px;
  width: 96% !important;
  border-radius: 3px;
  background: #f0b6b6;
  box-shadow: 0 0 10px 10px #f0b6b6;
  border: 1px solid #f0b6b6;
}

@media only screen and (max-width: 767px) {
  .old-wrap .result-box {
    padding: 10px 0 5px;
    width: 92% !important;
  }
}

.old-wrap .result-box > h2 {
  text-align: center;
}

.old-wrap .result-box > h2.ttl {
  margin: 0;
  padding: 0;
  font-size: 3.6rem;
  color: #9B1041;
  text-shadow: -1px 1px 1px #fff, 0 0 5px #fff;
}

.old-wrap .result-box > h2.ttl > .deco {
  color: #f8a5c9;
  font-family: YuMincho, '游明朝', 'メイリオ', sans-serif;
}

@media only screen and (max-width: 767px) {
  .old-wrap .result-box > h2.ttl {
    font-size: 3.2rem;
  }
}

.old-wrap .result-box .memo {
  margin-top: 5px;
  font-size: 1.2rem;
  text-align: right;
  color: #808080;
}

.old-wrap table.result-list {
  margin-top: 15px;
  width: 100%;
  border: #e18888 1px solid;
  border-collapse: collapse;
  background-color: #fff;
  text-align: left;
}

.old-wrap table.result-list tr {
  border: #e18888 1px solid;
}

.old-wrap table.result-list td {
  border: #e18888 1px solid;
  padding: 3px 10px;
  vertical-align: top;
}

.old-wrap table.result-list td.list1 {
  background: #fad6d6;
  width: 136px;
  min-width: 70px;
  white-space: nowrap;
}

.old-wrap table.result-list td.list2 {
  background: #fad6d6;
  width: 294px;
}

.old-wrap table.result-list td.list3 {
  background: #fad6d6;
  width: 90px;
  min-width: 35px;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .old-wrap table.result-list td {
    padding: 3px 2px;
  }
}

.old-wrap ul.prize-area {
  margin: 20px 4px 0;
  padding: 25px 5px;
  color: #36367E;
  border: 1px solid #ffffff;
  box-shadow: 0 0 0 4px #af96ad;
  background: #ffffff;
}

@media only screen and (max-width: 767px) {
  .old-wrap ul.prize-area {
    padding: 20px 5px;
  }
}

.old-wrap ul.prize-area > li {
  text-align: center;
  line-height: 1.2;
  font-weight: 700;
}

.old-wrap ul.prize-area > li ruby > rt {
  font-size: 30%;
}

.old-wrap ul.prize-area > li .prize {
  font-size: 1.6rem;
}

@media only screen and (max-width: 767px) {
  .old-wrap ul.prize-area > li .prize {
    font-size: 1.4rem;
  }
}

.old-wrap ul.prize-area > li .author {
  margin-top: 10px;
}

.old-wrap ul.prize-area > li.gold h3 {
  margin: 0;
  font-size: 3.6rem;
  color: #c19314;
  line-height: 1;
  text-shadow: -1px 1px 1px #ffffff, 0 0 5px #e7ce87;
}

.old-wrap ul.prize-area > li.gold .prize {
  margin: 10px 0 15px;
  color: #c19314;
}

.old-wrap ul.prize-area > li.gold .workttl {
  font-size: 3.6rem;
}

.old-wrap ul.prize-area > li.gold .author {
  font-size: 2.8rem;
  line-height: 1;
}

.old-wrap ul.prize-area > li.gold .author span {
  font-size: 1.6rem;
}

@media only screen and (max-width: 767px) {
  .old-wrap ul.prize-area > li.gold h3 {
    font-size: 3rem;
  }
  .old-wrap ul.prize-area > li.gold .workttl {
    font-size: 3rem;
  }
  .old-wrap ul.prize-area > li.gold .author {
    font-size: 2.2rem;
  }
}

.old-wrap ul.prize-area > li.silver {
  margin-top: 30px;
}

.old-wrap ul.prize-area > li.silver h3 {
  font-size: 3.2rem;
  color: #aaa;
  text-shadow: -1px 1px 1px #ffffff, 0 0 5px #c0c0c0;
}

.old-wrap ul.prize-area > li.silver .prize {
  margin: 5px 0 15px;
  color: #aaa;
}

.old-wrap ul.prize-area > li.silver .workttl {
  font-size: 3.2rem;
}

.old-wrap ul.prize-area > li.silver .author {
  font-size: 2.2rem;
}

.old-wrap ul.prize-area > li.silver .author span {
  font-size: 1.6rem;
}

@media only screen and (max-width: 767px) {
  .old-wrap ul.prize-area > li.silver h3 {
    font-size: 2.6rem;
  }
  .old-wrap ul.prize-area > li.silver .workttl {
    font-size: 2.6rem;
  }
  .old-wrap ul.prize-area > li.silver .author {
    font-size: 1.8rem;
  }
}

.old-wrap ul.prize-area > li.bronze {
  margin-top: 30px;
}

.old-wrap ul.prize-area > li.bronze h3 {
  font-size: 3.2rem;
  color: #ac6b25;
  text-shadow: -1px 1px 1px #ffffff, 0 0 5px #ac6b25;
}

.old-wrap ul.prize-area > li.bronze .prize {
  margin: 5px 0 15px;
  color: #ac6b25;
}

.old-wrap ul.prize-area > li.bronze .workttl {
  font-size: 3.2rem;
}

.old-wrap ul.prize-area > li.bronze .author {
  font-size: 2.2rem;
}

.old-wrap ul.prize-area > li.bronze .author span {
  font-size: 1.6rem;
}

@media only screen and (max-width: 767px) {
  .old-wrap ul.prize-area > li.bronze h3 {
    font-size: 2.6rem;
  }
  .old-wrap ul.prize-area > li.bronze .workttl {
    font-size: 2.6rem;
  }
  .old-wrap ul.prize-area > li.bronze .author {
    font-size: 1.8rem;
  }
}

/* ============================================================
	ehon_old
============================================================ */
.ehonold-report {
  margin: 30px 0 0;
}

.ehonold-report > h2 {
  margin-bottom: 15px;
  font-size: 2.0rem;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .ehonold-report > h2 {
    font-size: 1.8rem;
  }
}

.ehonold-report > .txt-box {
  font-size: 1.4rem;
  line-height: 1.7;
}

@media only screen and (max-width: 767px) {
  .ehonold-report > .txt-box {
    line-height: 1.5;
  }
}

.ehonold-report > .txt-box .ph-box {
  float: right;
  margin: 5px 0 8px 5px;
  clear: both;
  width: 224px;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .ehonold-report > .txt-box .ph-box {
    width: 180px;
  }
}

.ehonold-report > .txt-box .ph-box img {
  margin-bottom: 5px;
}

.ehonold-result {
  margin: 30px 0 0;
  position: relative;
}

@media print, screen and (min-width: 768px) {
  .ehonold-result img {
    vertical-align: top;
  }
  .ehonold-result p {
    position: absolute;
    left: 40px;
    max-width: 500px;
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .ehonold-result p.ehon {
    top: 320px;
    color: #0a6cff;
  }
  .ehonold-result p.story {
    top: 1095px;
    color: #4fb000;
  }
}

@media only screen and (max-width: 767px) {
  .ehonold-result {
    text-align: center;
    position: relative;
    z-index: 1;
    padding: 20px 10px;
    width: 100%;
  }
  .ehonold-result::before {
    content: "";
    z-index: -1;
    -ms-filter: blur(4px);
    filter: blur(4px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: #c9f6ff;
    background: linear-gradient(to bottom, #c9f6ff, #effbff);
  }
  .ehonold-result h3 {
    margin: 5px 0 20px;
    font-size: 2.6rem;
    font-weight: 700;
    color: #9f8a00;
    text-shadow: -1px 1px 1px #ffffff, 0 0 5px #fff178;
  }
  .ehonold-result h3 > span {
    font-size: 1.8rem;
    font-family: "游明朝体", YuMincho, "游明朝", "Yu Mincho", "メイリオ", sans-serif;
  }
  .ehonold-result h4 {
    margin-bottom: 15px;
    font-size: 2.2rem;
    font-weight: 700;
    color: #884e00;
    text-shadow: -1px 1px 1px #ffffff, 0 0 5px #e7bd6f;
  }
  .ehonold-result h4 > span {
    font-size: 1.4rem;
  }
  .ehonold-result h5 {
    margin: 30px 0 15px;
    font-size: 1.8rem;
    font-weight: 700;
    color: #884e00;
    text-shadow: -1px 1px 1px #ffffff, 0 0 5px #e7bd6f;
  }
  .ehonold-result p {
    text-shadow: -1px -1px #fff,1px -1px #fff,-1px 1px #fff,1px 1px #fff;
    font-weight: 700;
  }
  .ehonold-result p.ttl-ehon {
    margin-bottom: 15px;
    font-size: 2.2rem;
    color: #0a6cff;
  }
  .ehonold-result p.ttl-story {
    margin-bottom: 15px;
    font-size: 2.2rem;
    color: #4fb000;
  }
  .ehonold-result p.-ehon {
    color: #0a6cff;
  }
  .ehonold-result p.-story {
    color: #4fb000;
  }
  .ehonold-result p.first-ttl {
    margin-bottom: 5px;
    font-size: 2.4rem;
  }
  .ehonold-result p.first-name {
    margin-bottom: 15px;
    font-size: 1.8rem;
  }
  .ehonold-result p.first-txt {
    font-size: 1.4rem;
    line-height: 1.5;
    text-align: left;
  }
  .ehonold-result p.second-ttl {
    margin-bottom: 5px;
    font-size: 2rem;
  }
  .ehonold-result p.second-name {
    margin-bottom: 15px;
    font-size: 1.6rem;
  }
  .ehonold-result p.memo {
    font-size: 1.2rem;
    text-align: right;
    color: #808080;
  }
}

/* ============================================================
	bookinfo-top
============================================================ */
.bkinfotop-main {
  width: 100%;
  overflow-x: hidden;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-main {
    width: 100%;
    height: auto;
  }
}

.bkinfotop-main a {
  position: relative;
  display: inline-block;
  width: 624px;
  height: 260px;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-main a {
    width: 100%;
    height: auto;
  }
}

.bkinfotop-main a.link-blank::before {
  position: absolute;
  bottom: 10px;
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 10px;
  font-size: 1.6rem;
  content: "";
}

@media only screen and (max-width: 767px) {
  .bkinfotop-main a.link-blank::before {
    font-size: 1.4rem;
  }
}

.bkinfotop-main a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #ff6600 !important;
}

@media print, screen and (min-width: 768px) {
  .bkinfotop-main a:hover {
    color: #ff6600 !important;
  }
}

/* ============================================================
	bkinfotop-newbooks
============================================================ */
.bkinfotop-newbooks {
  margin: 80px auto 30px;
  position: relative;
  width: 1080px;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-newbooks {
    margin: 40px 3% 80px;
    width: 94%;
  }
}

.bkinfotop-newbooks > .ttl-box {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: #36357e;
}

.bkinfotop-newbooks > .ttl-box > .ttl {
  display: flex;
  align-items: center;
  font-size: 3.6rem;
  font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-newbooks > .ttl-box > .ttl {
    font-size: 2.8rem;
  }
}

.bkinfotop-newbooks > .ttl-box > .ttl > span {
  display: block;
  padding-right: 20px;
  border-right: 1px solid #36357e;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-newbooks > .ttl-box > .ttl > span {
    padding-right: 15px;
  }
}

.bkinfotop-newbooks > .ttl-box > .ttl > span.jpn {
  padding-left: 20px;
  border: none;
  font-size: 2rem;
  font-style: normal;
}

_:-ms-fullscreen, :root .bkinfotop-newbooks > .ttl-box > .ttl > span.jpn {
  padding-top: 7px;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-newbooks > .ttl-box > .ttl > span.jpn {
    padding-left: 15px;
    font-size: 1.6rem;
  }
}

.bkinfotop-newbooks > .ttl-box > .link-box {
  margin-top: 0;
}

.bkinfotop-newbooks > .ttl-box > .link-box > .link-arrow {
  font-size: 1.6rem;
}

_:-ms-fullscreen, :root .bkinfotop-newbooks > .ttl-box > .link-box > .link-arrow {
  padding-top: 7px;
}

.bkinfotop-newbooks > .book-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.bkinfotop-newbooks > .book-box > .book-area {
  margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-newbooks > .book-box > .book-area {
    margin-bottom: 0;
  }
}

.bkinfotop-newbooks > .book-box > .book-area > .ttl-link {
  margin-bottom: 20px;
  padding: 10px 12px;
  display: block;
  width: 100%;
  background-color: #f5f5f8;
  border-radius: 5px;
  text-decoration: none !important;
  color: #36357e;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-newbooks > .book-box > .book-area > .ttl-link {
    margin-bottom: 10px;
  }
}

.bkinfotop-newbooks > .book-box > .book-area > .ttl-link > .ttl {
  display: inline-block;
  position: relative;
  margin-right: 5px;
  padding-left: 10px;
  padding-right: 15px;
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-left: 2px solid #36357e;
}

_:-ms-fullscreen, :root .bkinfotop-newbooks > .book-box > .book-area > .ttl-link > .ttl {
  padding-top: 7px;
}

.bkinfotop-newbooks > .book-box > .book-area > .ttl-link > .ttl::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.2rem;
  content: "";
  right: 0;
  font-size: 1rem;
  transition: right .1s;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-newbooks > .book-box > .book-area > .ttl-link > .ttl::before {
    padding-top: .1rem;
  }
}

.bkinfotop-newbooks > .book-box > .book-area > .ttl-link.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.bkinfotop-newbooks > .book-box > .book-area > .ttl-link.hover > .ttl::before {
  right: -5px;
}

@media print, screen and (min-width: 768px) {
  .bkinfotop-newbooks > .book-box > .book-area > .ttl-link:hover > .ttl::before {
    right: -5px;
  }
}

.bkinfotop-newbooks > .book-box > .book-area > .list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.bkinfotop-newbooks > .book-box > .book-area > .list > li {
  display: block;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-newbooks > .book-box > .book-area > .list > li {
    margin-bottom: 30px;
    padding: 0 5px;
  }
}

.bkinfotop-newbooks > .book-box > .book-area > .list > li > a {
  display: block;
}

.bkinfotop-newbooks > .book-box > .book-area > .list > li > a > p {
  margin: 0;
}

.bkinfotop-newbooks > .book-box > .book-area > .list > li > a > p.thumb {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-newbooks > .book-box > .book-area > .list > li > a > p.thumb {
    height: auto;
    min-height: 230px;
  }
}

@media only screen and (max-width: 320px) {
  .bkinfotop-newbooks > .book-box > .book-area > .list > li > a > p.thumb {
    min-height: 210px;
  }
}

.bkinfotop-newbooks > .book-box > .book-area > .list > li > a > p.thumb img {
  width: auto;
  border: 1px solid #ccc;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-newbooks > .book-box > .book-area > .list > li > a > p.thumb img {
    width: 100%;
    max-width: 160px;
  }
}

.bkinfotop-newbooks > .book-box > .book-area > .list > li > a > p.ttl {
  margin: 10px 0 8px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  max-height: calc(1.4em * 3);
  word-break: break-all;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-newbooks > .book-box > .book-area > .list > li > a > p.ttl {
    margin: 8px auto;
    max-width: 160px;
  }
}

.bkinfotop-newbooks > .book-box > .book-area > .list > li > a > p.name {
  font-size: 1.2rem;
  line-height: 1.2;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-newbooks > .book-box > .book-area > .list > li > a > p.name {
    margin: 0 auto;
    max-width: 160px;
  }
}

.bkinfotop-newbooks > .book-box > .book-area > .list > li > a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 0.7;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .bkinfotop-newbooks > .book-box > .book-area > .list > li > a:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.bkinfotop-newbooks > .book-box > .book-area.-column1 {
  width: 100%;
}

@media print, screen and (min-width: 768px) {
  .bkinfotop-newbooks > .book-box > .book-area.-column1 > .list > li {
    padding: 0 25px;
    width: 188px;
  }
  .bkinfotop-newbooks > .book-box > .book-area.-column1 > .list > li:nth-of-type(1) {
    padding: 0 25px 0 1px;
    width: 164px;
  }
  .bkinfotop-newbooks > .book-box > .book-area.-column1 > .list > li:nth-of-type(6) {
    padding: 0 1px 0 25px;
    width: 164px;
  }
}

@media only screen and (max-width: 767px) {
  .bkinfotop-newbooks > .book-box > .book-area.-column1 > .list > li {
    width: 25%;
  }
}

@media only screen and (max-width: 700px) {
  .bkinfotop-newbooks > .book-box > .book-area.-column1 > .list > li {
    width: 50%;
  }
}

@media print, screen and (min-width: 768px) {
  .bkinfotop-newbooks > .book-box > .book-area.-column1 > .list > li > a > p.thumb {
    height: 194px;
  }
}

@media print, screen and (min-width: 768px) {
  .bkinfotop-newbooks > .book-box > .book-area.-column1 > .list > li > a > p.thumb img {
    max-width: 140px;
    max-height: 194px;
  }
}

.bkinfotop-newbooks > .book-box > .book-area.-column4 {
  margin-right: 26.66px;
  width: 250px;
}

.bkinfotop-newbooks > .book-box > .book-area.-column4:nth-of-type(6), .bkinfotop-newbooks > .book-box > .book-area.-column4:nth-of-type(10) {
  margin-right: 0;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-newbooks > .book-box > .book-area.-column4 {
    margin-right: 2%;
    width: 49%;
  }
  .bkinfotop-newbooks > .book-box > .book-area.-column4:nth-child(even) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 700px) {
  .bkinfotop-newbooks > .book-box > .book-area.-column4 {
    margin-right: 0;
    width: 100%;
  }
}

@media print, screen and (min-width: 768px) {
  .bkinfotop-newbooks > .book-box > .book-area.-column4 > .list > li:nth-of-type(1) {
    padding-right: 11px;
    width: 125px;
  }
  .bkinfotop-newbooks > .book-box > .book-area.-column4 > .list > li:nth-of-type(2) {
    padding-left: 11px;
    width: 125px;
  }
}

@media only screen and (max-width: 767px) {
  .bkinfotop-newbooks > .book-box > .book-area.-column4 > .list > li {
    width: 50%;
  }
}

@media print, screen and (min-width: 768px) {
  .bkinfotop-newbooks > .book-box > .book-area.-column4 > .list > li > a > p.thumb {
    height: 162px;
  }
}

@media print, screen and (min-width: 768px) {
  .bkinfotop-newbooks > .book-box > .book-area.-column4 > .list > li > a > p.thumb img {
    max-width: 114px;
    max-height: 162px;
  }
}

.bkinfotop-newbooks > .btn-box {
  display: none;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-newbooks > .btn-box {
    display: block;
  }
}

/* ============================================================
	bkinfotop-topics
============================================================ */
.bkinfotop-topics {
  margin: 0 auto 80px;
  position: relative;
  width: 1080px;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-topics {
    margin: 0 3% 60px;
    width: 94%;
  }
}

.bkinfotop-topics > .ttl-box {
  margin: 0 auto 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: #36357e;
}

.bkinfotop-topics > .ttl-box > .ttl {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.6rem;
  font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-topics > .ttl-box > .ttl {
    font-size: 2.8rem;
  }
}

.bkinfotop-topics > .ttl-box > .ttl > span {
  display: block;
  padding-right: 20px;
  border-right: 1px solid #36357e;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-topics > .ttl-box > .ttl > span {
    padding-right: 15px;
  }
}

.bkinfotop-topics > .ttl-box > .ttl > span.jpn {
  padding-left: 20px;
  border: none;
  font-size: 2rem;
  font-style: normal;
}

_:-ms-fullscreen, :root .bkinfotop-topics > .ttl-box > .ttl > span.jpn {
  padding-top: 7px;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-topics > .ttl-box > .ttl > span.jpn {
    padding-left: 15px;
    font-size: 1.6rem;
  }
}

.bkinfotop-topics > .ttl-box > .link-box {
  margin-top: 0;
}

.bkinfotop-topics > .ttl-box > .link-box > .link-arrow {
  font-size: 1.6rem;
}

/* ============================================================
	bkinfotop-twitter
============================================================ */
.bkinfotop-twitter {
  margin: 0 auto 80px;
  padding: 0 100px;
  position: relative;
  width: 1080px;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-twitter {
    margin: 0 3% 60px;
    width: 94%;
    padding: 0;
  }
}

@media only screen and (max-width: 700px) {
  .bkinfotop-twitter {
    display: block;
  }
}

.bkinfotop-twitter > .twitter-box {
  margin: 0 auto;
  width: 402px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

@media only screen and (max-width: 767px) {
  .bkinfotop-twitter > .twitter-box {
    width: 45%;
  }
}

@media only screen and (max-width: 700px) {
  .bkinfotop-twitter > .twitter-box {
    width: 100%;
    max-width: 402px;
  }
  .bkinfotop-twitter > .twitter-box:first-child {
    margin-bottom: 30px;
  }
}

_:-ms-fullscreen, :root .bkinfotop-twitter {
  display: none;
}

/* ============================================================
	bookinfo-swiper
============================================================ */
.bkinfotop-main > .container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 624px;
  margin-bottom: 30px;
}

.bkinfotop-main .swiper-container {
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 1;
}

.bkinfotop-main .swiper-pagination {
  bottom: -25px !important;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
}

.bkinfotop-main .swiper-pagination-bullet {
  margin: 0 5px;
  width: 12px;
  height: 12px;
  transition: all .3s;
}

.bkinfotop-main .swiper-pagination-bullet.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: .2;
}

@media print, screen and (min-width: 768px) {
  .bkinfotop-main .swiper-pagination-bullet:hover {
    opacity: .2;
  }
}

.bkinfotop-main .swiper-pagination-bullet-active {
  background: #ff9e00;
}

.bkinfotop-main .swiper-button-next,
.bkinfotop-main .swiper-button-prev {
  display: none;
}

/* ============================================================
	bookhot-list
============================================================ */
.bookhot-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.bookhot-list > li {
  display: block;
}

@media print, screen and (min-width: 768px) {
  .bookhot-list > li {
    padding: 0 25px;
    width: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .bookhot-list > li {
    margin-bottom: 30px;
    padding: 0 5px;
    width: 25%;
  }
}

@media only screen and (max-width: 700px) {
  .bookhot-list > li {
    width: 33%;
  }
}

@media only screen and (max-width: 539px) {
  .bookhot-list > li {
    width: 50%;
  }
}

.bookhot-list > li > a {
  display: block;
}

@media only screen and (max-width: 767px) {
  .bookhot-list > li > a {
    padding: 0 10px;
  }
}

.bookhot-list > li > a > p {
  margin: 0;
}

.bookhot-list > li > a > p.thumb {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media print, screen and (min-width: 768px) {
  .bookhot-list > li > a > p.thumb {
    height: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .bookhot-list > li > a > p.thumb {
    height: auto;
    min-height: 210px;
  }
}

@media only screen and (max-width: 320px) {
  .bookhot-list > li > a > p.thumb {
    min-height: 180px;
  }
}

.bookhot-list > li > a > p.thumb img {
  width: auto;
  border: 1px solid #ccc;
}

@media print, screen and (min-width: 768px) {
  .bookhot-list > li > a > p.thumb img {
    max-width: 130px;
    max-height: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .bookhot-list > li > a > p.thumb img {
    width: 100%;
    max-width: 150px;
  }
}

.bookhot-list > li > a > p.ttl {
  margin: 10px 0 8px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  max-height: calc(1.4em * 3);
}

@media only screen and (max-width: 767px) {
  .bookhot-list > li > a > p.ttl {
    margin: 8px auto;
    max-width: 160px;
  }
}

.bookhot-list > li > a > p.name {
  font-size: 1.2rem;
  line-height: 1.2;
}

@media only screen and (max-width: 767px) {
  .bookhot-list > li > a > p.name {
    margin: 0 auto;
    max-width: 160px;
  }
}

.bookhot-list > li > a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 0.7;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .bookhot-list > li > a:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

/* ============================================================
	search-box
============================================================ */
.search-box {
  margin: 0 auto;
  padding: 30px 0;
  width: 100%;
  background-color: #fffbf2;
}

@media only screen and (max-width: 767px) {
  .search-box {
    padding: 20px 0;
  }
}

.search-box .category-list {
  margin: 0 auto;
  width: 1080px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .search-box .category-list {
    margin: 0 3% 0;
    width: 94%;
  }
}

.search-box .category-list > .ttl {
  margin: 0 0 5px 0;
  font-size: 1.8rem;
  font-weight: 700;
  color: #555;
}

@media only screen and (max-width: 767px) {
  .search-box .category-list > .ttl {
    font-size: 1.6rem;
  }
}

.search-box .category-list .genre {
  display: inline-block;
  margin: 10px 15px 0 0;
  padding: 12px 16px;
  border: 1px solid #36357e;
  background: #fff;
  font-size: 1.4rem;
  color: #36357e !important;
  text-decoration: none !important;
  min-width: 50px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .search-box .category-list .genre {
    margin: 5px 5px 0 0;
    padding: 8px 4px;
    font-size: 1.2rem;
  }
}

.search-box .category-list .genre.-current {
  background: #36357e;
  color: #fff !important;
  text-decoration: none !important;
}

.search-box .category-list .genre.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #36357e;
  color: #fff !important;
}

@media print, screen and (min-width: 768px) {
  .search-box .category-list .genre:hover {
    background: #36357e;
    color: #fff !important;
  }
}

.search-box .category-list select.genre-list {
  margin: 5px 0 0;
  padding: 12px 16px;
  width: auto !important;
  max-width: inherit !important;
  height: auto;
  border-radius: 0;
  border: 1px solid #36357e;
  font-size: 1.4rem !important;
  color: #36357e !important;
  background-image: url(/images/svg/arrow_down.svg);
  background-repeat: no-repeat;
  background-size: 12px 10px;
  background-position: right 10px center;
}

.search-box form + form {
  margin-top: 20px;
}

/* ============================================================
	booksearch-ttl
============================================================ */
.booksearch-ttl {
  display: block;
  padding: 4px 8px;
  border-bottom: 2px solid #36357e;
  color: #555;
  font-size: 1.6rem;
  line-height: 1.4;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .booksearch-ttl {
    font-size: 1.4rem;
  }
}

/* ============================================================
	booksearch-list
============================================================ */
.booksearch-list {
  display: block;
  margin-bottom: 30px;
  width: 100%;
}

.booksearch-list > li {
  display: block;
  width: 100%;
  border-bottom: 1px dotted #ccc;
}

.booksearch-list > li:nth-child(even) {
  background-color: #f7f7fa;
}

.booksearch-list > li > a {
  display: flex;
  width: 100%;
  padding: 20px 25px;
  color: #333;
  position: relative;
}

@media print, screen and (min-width: 768px) {
  .booksearch-list > li > a {
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .booksearch-list > li > a {
    padding: 15px 8px;
  }
}

.booksearch-list > li > a .thumb {
  margin-right: 24px;
  width: 106px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .booksearch-list > li > a .thumb {
    margin-right: 15px;
    flex-basis: 106px;
  }
}

.booksearch-list > li > a .thumb img {
  width: auto;
  border: 1px solid #ccc;
  max-width: 106px;
  max-height: 146px;
}

.booksearch-list > li > a .detail {
  width: 900px;
}

@media only screen and (max-width: 767px) {
  .booksearch-list > li > a .detail {
    width: auto;
    flex-basis: auto;
  }
}

.booksearch-list > li > a .detail > p {
  margin: 0;
}

.booksearch-list > li > a .detail > p.booktype {
  margin-bottom: 8px;
  padding: 6px 0;
  border-radius: 5px;
  width: 80px;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  white-space: nowrap;
}

_:-ms-fullscreen, :root .booksearch-list > li > a .detail > p.booktype {
  padding-top: 7px;
}

.booksearch-list > li > a .detail > p.booktype.-ebook1 {
  background-color: #6867b0;
}

.booksearch-list > li > a .detail > p.booktype.-ebook2 {
  background-color: #36357e;
}

.booksearch-list > li > a .detail > p.booktype.-pod {
  background-color: #f3a828;
}

.booksearch-list > li > a .detail > p.booktype.-ttz {
  background-color: #389839;
}

.booksearch-list > li > a .detail > p.booktype.-pdf {
  background-color: #ec423d;
}

.booksearch-list > li > a .detail > p.ttl {
  margin-bottom: 8px;
  font-size: 1.8rem;
  line-height: 1.4;
  color: #36357e;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.booksearch-list > li > a .detail > p.name {
  line-height: 1.4;
  color: #36357e;
}

.booksearch-list > li > a .detail > p.txt {
  margin: 20px 0 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  max-height: 1.4em;
}

@media only screen and (max-width: 767px) {
  .booksearch-list > li > a .detail > p.txt {
    margin: 15px 0 8px;
    -webkit-line-clamp: 3;
    max-height: calc(1.4em * 3);
  }
}

.booksearch-list > li > a .detail > p.other {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #666;
}

.booksearch-list > li > a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 0.7;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .booksearch-list > li > a:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

.booksearch-list.-reprint > li > a {
  display: block;
}

.booksearch-list.-reprint > li > a .reprint {
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  background-color: #fffbf2;
  font-size: 1.6rem;
  color: #844700;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  flex-basis: 100%;
}

.booksearch-list.-reprint > li > a > .box {
  display: flex;
}

@media print, screen and (min-width: 768px) {
  .booksearch-list.-reprint > li > a > .box {
    align-items: center;
  }
}

/* ============================================================
	search-area
============================================================ */
.search-area {
  margin: 0 auto;
  width: 1080px;
}

@media only screen and (max-width: 767px) {
  .search-area {
    margin: 0 3% 0;
    width: 94%;
  }
}

.search-area > li {
  align-items: center;
  white-space: nowrap;
  width: 1080px;
}

.search-area > li:first-child {
  display: flex;
  flex-wrap: wrap;
}

.search-area > li:last-child {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .search-area > li {
    width: 100%;
  }
}

.search-area > li > .ttl {
  margin: 10px 20px 10px 0;
  font-size: 1.8rem;
  font-weight: 700;
  color: #555;
}

@media only screen and (max-width: 767px) {
  .search-area > li > .ttl {
    margin: 0 20px 5px 0;
    width: 100%;
  }
}

.search-area input[type="text"] {
  margin: 0;
  width: 570px !important;
  height: 40px;
}

@media only screen and (max-width: 767px) {
  .search-area input[type="text"] {
    width: 82% !important;
  }
}

.search-area .btn-search {
  display: inline-block;
  position: relative;
  margin: 0 0 0 10px;
  padding: 10px 20px;
  color: #fff;
  font-size: 1.8rem !important;
  text-align: center;
  line-height: 1;
  border-radius: 4px;
  transition: all .3s;
  cursor: pointer;
  background-color: #36357e;
  border: 1px solid #36357e;
}

_:-ms-fullscreen, :root .search-area .btn-search {
  padding-top: 12px;
}

.search-area .btn-search:visited {
  color: #fff;
}

.search-area .btn-search.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-color: #fff;
  color: #36357e;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .search-area .btn-search:hover {
    opacity: 1;
    background-color: #fff;
    color: #36357e;
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) {
  .title-area + .search-box {
    margin-top: 40px;
  }
}

/* ============================================================
	search-word
============================================================ */
.search-word {
  margin: 0 auto;
  padding: 20px 0;
  width: 100%;
  background-color: #fffbf2;
}

@media only screen and (max-width: 767px) {
  .search-word {
    padding: 10px 0;
  }
}

.search-word > .title {
  margin: 0 auto;
  width: 1080px;
  font-size: 1.6rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .search-word > .title {
    margin: 0 3% 0;
    width: 94%;
    font-size: 1.4rem;
  }
}

/* ============================================================
	catalog-list
============================================================ */
.catalog-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 50px;
}

.catalog-box:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .catalog-box {
    margin-bottom: 30px;
  }
}

.catalog-box > .genre-area > .ttl-link,
.catalog-box > .link-area > .ttl-link {
  margin-bottom: 20px;
  padding: 10px 12px;
  display: block;
  width: 100%;
  background-color: #f5f5f8;
  border-radius: 5px;
  text-decoration: none !important;
  color: #36357e;
}

@media only screen and (max-width: 767px) {
  .catalog-box > .genre-area > .ttl-link,
  .catalog-box > .link-area > .ttl-link {
    margin-bottom: 10px;
  }
}

.catalog-box > .genre-area > .ttl-link > .ttl,
.catalog-box > .link-area > .ttl-link > .ttl {
  display: inline-block;
  position: relative;
  margin-right: 5px;
  padding-left: 10px;
  padding-right: 15px;
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-left: 2px solid #36357e;
}

_:-ms-fullscreen, :root .catalog-box > .genre-area > .ttl-link > .ttl, :root
.catalog-box > .link-area > .ttl-link > .ttl {
  padding-top: 7px;
}

.catalog-box > .genre-area > .ttl-link > .ttl::before,
.catalog-box > .link-area > .ttl-link > .ttl::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.2rem;
  content: "";
  right: 0;
  font-size: 1rem;
  transition: right .1s;
}

@media only screen and (max-width: 767px) {
  .catalog-box > .genre-area > .ttl-link > .ttl::before,
  .catalog-box > .link-area > .ttl-link > .ttl::before {
    padding-top: .1rem;
  }
}

.catalog-box > .genre-area > .ttl-link.hover,
.catalog-box > .link-area > .ttl-link.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.catalog-box > .genre-area > .ttl-link.hover > .ttl::before,
.catalog-box > .link-area > .ttl-link.hover > .ttl::before {
  right: -5px;
}

@media print, screen and (min-width: 768px) {
  .catalog-box > .genre-area > .ttl-link:hover > .ttl::before,
  .catalog-box > .link-area > .ttl-link:hover > .ttl::before {
    right: -5px;
  }
}

.catalog-box > .genre-area > .ttl-link.-blank > .ttl::before,
.catalog-box > .link-area > .ttl-link.-blank > .ttl::before {
  content: "";
}

.catalog-box > .genre-area > .ttl-link.-blank.hover,
.catalog-box > .link-area > .ttl-link.-blank.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.catalog-box > .genre-area > .ttl-link.-blank.hover > .ttl::before,
.catalog-box > .link-area > .ttl-link.-blank.hover > .ttl::before {
  right: 0;
}

@media print, screen and (min-width: 768px) {
  .catalog-box > .genre-area > .ttl-link.-blank:hover > .ttl::before,
  .catalog-box > .link-area > .ttl-link.-blank:hover > .ttl::before {
    right: 0;
  }
}

.catalog-box > .genre-area {
  margin-bottom: 50px;
  width: 510px;
}

.catalog-box > .genre-area:nth-child(odd) {
  margin-right: 60px;
}

.catalog-box > .genre-area:last-child {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .catalog-box > .genre-area {
    margin-bottom: 30px;
    width: 100%;
  }
  .catalog-box > .genre-area:nth-child(odd) {
    margin-right: 0;
  }
}

.catalog-box > .genre-area .box {
  padding: 0 14px;
}

.catalog-box > .genre-area .box > .genre {
  margin-bottom: 5px;
  line-height: 1.7;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.catalog-box > .genre-area .box > .list {
  margin: 0;
  line-height: 1.7;
}

.catalog-box > .genre-area .box > .list + .genre {
  margin-top: 15px;
}

.catalog-box > .link-area {
  width: 510px;
}

.catalog-box > .link-area:nth-child(odd) {
  margin-right: 60px;
}

@media only screen and (max-width: 767px) {
  .catalog-box > .link-area {
    width: 100%;
  }
  .catalog-box > .link-area:nth-child(odd) {
    margin-right: 0;
  }
}

/* ============================================================
	search-year
============================================================ */
.search-year {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .search-year {
    display: flex;
    flex-wrap: wrap;
  }
  .search-year > li:first-child {
    width: 100%;
  }
}

.search-year .ttl {
  margin-right: 10px;
  font-size: 1.8rem;
  font-weight: 700;
  color: #555;
}

@media only screen and (max-width: 767px) {
  .search-year .ttl {
    margin: 0 0 10px 0;
    font-size: 1.6rem;
  }
}

.search-year select {
  margin: 0;
  width: auto !important;
  font-size: 1.8rem !important;
  height: 40px;
}

@media only screen and (max-width: 767px) {
  .search-year select {
    font-size: 1.4rem !important;
    height: 36px;
  }
}

.search-year .btn-search {
  display: inline-block;
  position: relative;
  margin: 0 0 0 10px;
  padding: 10px 20px;
  color: #fff;
  font-size: 1.8rem !important;
  text-align: center;
  line-height: 1;
  border-radius: 4px;
  transition: all .3s;
  cursor: pointer;
  background-color: #36357e;
  border: 1px solid #36357e;
}

_:-ms-fullscreen, :root .search-year .btn-search {
  padding-top: 12px;
}

@media only screen and (max-width: 767px) {
  .search-year .btn-search {
    padding: 10px;
    font-size: 1.4rem !important;
  }
}

.search-year .btn-search:visited {
  color: #fff;
}

.search-year .btn-search.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-color: #fff;
  color: #36357e;
  text-decoration: none;
}

@media print, screen and (min-width: 768px) {
  .search-year .btn-search:hover {
    opacity: 1;
    background-color: #fff;
    color: #36357e;
    text-decoration: none;
  }
}

/* ============================================================
	pickup-list
============================================================ */
.pickup-list {
  margin: 10px 0 30px;
}

.pickup-list > li {
  display: flex;
  align-items: center;
  padding: 20px 25px;
  width: 100%;
  border-bottom: 1px dotted #ccc;
}

@media only screen and (max-width: 767px) {
  .pickup-list > li {
    align-items: flex-start;
    padding: 15px 8px;
  }
}

.pickup-list > li:nth-child(even) {
  background-color: #f7f7fa;
}

.pickup-list > li .thumb {
  margin-right: 25px;
  width: 50px;
}

@media only screen and (max-width: 767px) {
  .pickup-list > li .thumb {
    margin-right: 15px;
    flex-basis: 65px;
  }
}

.pickup-list > li .thumb img {
  width: 50px;
  border: 1px solid #ccc;
}

.pickup-list > li > .box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .pickup-list > li > .box {
    flex-basis: auto;
  }
}

.pickup-list > li > .box > .label {
  padding: 3px;
  border-radius: 3px;
  text-align: center;
  border: 1px solid #6d6564;
  color: #6d6564;
  white-space: nowrap;
  margin-right: 10px;
  background-color: #fff;
  border: 1px solid #653308;
  color: #653308;
  width: 100px;
  font-size: 1.2rem;
}

_:-ms-fullscreen, :root .pickup-list > li > .box > .label {
  padding-top: 7px;
}

.pickup-list > li > .box > .label.-c02 {
  border: 1px solid #7b76b4;
  color: #7b76b4;
}

.pickup-list > li > .box > .label.-c03 {
  border: 1px solid #ed5c39;
  color: #ed5c39;
}

.pickup-list > li > .box > .label.-c04 {
  border: 1px solid #6ba25a;
  color: #6ba25a;
}

.pickup-list > li > .box > .label.-c05 {
  border: 1px solid #0aa59b;
  color: #0aa59b;
}

.pickup-list > li > .box > .label.-c06 {
  border: 1px solid #ff9e00;
  color: #ff9e00;
}

.pickup-list > li > .box > .label.-c07 {
  border: 1px solid #ba4986;
  color: #ba4986;
}

.pickup-list > li > .box > .label.-c08 {
  border: 1px solid #4492c4;
  color: #4492c4;
}

@media only screen and (max-width: 767px) {
  .pickup-list > li > .box > .label {
    margin-bottom: 5px;
  }
}

.pickup-list > li > .box > .date {
  color: #a5a5a5;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .pickup-list > li > .box > .date {
    margin-bottom: 5px;
  }
}

.pickup-list > li > .box > .txt {
  margin-top: 10px;
  width: 100%;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .pickup-list > li > .box > .txt {
    margin-top: 0;
    width: 100%;
  }
}

/* ============================================================
	drama-list
============================================================ */
.drama-list > li {
  position: relative;
  margin-bottom: 80px;
  width: 100%;
  height: 480px;
}

@media only screen and (max-width: 767px) {
  .drama-list > li {
    margin-bottom: 40px;
    height: auto;
  }
}

.drama-list > li > .bg {
  position: absolute;
  top: 0;
  width: 802px;
  height: 482px;
  border: 1px solid #eee;
}

.drama-list > li > .bg > img {
  opacity: .8;
}

@media only screen and (max-width: 767px) {
  .drama-list > li > .bg {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .drama-list > li > .bg > img {
    width: auto;
    height: 100%;
  }
}

.drama-list > li > .thumb {
  width: 194px;
}

.drama-list > li > .thumb > img {
  border: 1px solid #ccc;
}

@media only screen and (max-width: 767px) {
  .drama-list > li > .thumb {
    position: relative;
    margin: 0 auto;
    padding: 30px 0 0;
  }
}

@media print, screen and (min-width: 768px) {
  .drama-list > li > .box {
    max-width: 660px;
  }
}

@media only screen and (max-width: 767px) {
  .drama-list > li > .box {
    position: relative;
    padding: 30px 10px;
  }
}

.drama-list > li > .box > .title {
  margin-bottom: 5px;
  display: table;
  padding: 10px 20px;
  background-color: #36357e;
  color: #fff;
  font-size: 1.8rem;
  font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
  font-weight: 700;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .drama-list > li > .box > .title {
    font-size: 1.6rem;
  }
}

.drama-list > li > .box > .title > span {
  font-size: 3rem;
}

@media only screen and (max-width: 767px) {
  .drama-list > li > .box > .title > span {
    font-size: 2.4rem;
  }
}

.drama-list > li > .box > .actor {
  margin-bottom: 10px;
  display: table;
  padding: 10px 20px;
  background-color: #36357e;
  color: #fff;
  font-size: 1.4rem;
  line-height: 1.4;
}

.drama-list > li > .box > .book {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  background-color: #fff;
  width: 570px;
  box-shadow: 0 0 8px #ccc;
}

@media only screen and (max-width: 767px) {
  .drama-list > li > .box > .book {
    width: 100%;
  }
}

@media print, screen and (min-width: 768px) {
  .drama-list > li > .box > .book > .ttl-box {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .drama-list > li > .box > .book > .ttl-box {
    display: block;
  }
}

.drama-list > li > .box > .book > .ttl-box > .label {
  display: table;
  margin-right: 10px;
  padding: 4px 10px;
  border-radius: 3px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #36357e;
  color: #36357e;
  white-space: nowrap;
  font-size: 1.4rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  text-align: center;
}

.drama-list > li > .box > .book > .ttl-box > .label > span:first-child {
  margin-right: 5px;
}

@media only screen and (max-width: 767px) {
  .drama-list > li > .box > .book > .ttl-box > .label {
    margin: 0 0 8px;
  }
}

.drama-list > li > .box > .book > .ttl-box > .ttl {
  color: #36357e;
  font-size: 2rem;
  font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
  font-weight: 700;
  line-height: 1.2;
}

@media print, screen and (min-width: 768px) {
  .drama-list > li > .box > .book > .ttl-box > .ttl {
    max-width: 395px;
  }
}

.drama-list > li > .box > .book > .ttl-box > .ttl > .author {
  font-size: 1.4rem;
}

.drama-list > li > .box > .book > .txt {
  margin: 10px 0;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  max-height: calc(1.7em * 3);
}

.drama-list > li > .box > .book > .link {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
}

.drama-list > li > .box > .book > .link > a {
  margin-left: auto;
}

@media print, screen and (min-width: 768px) {
  .drama-list > li:nth-child(odd) > .bg {
    right: 0;
  }
  .drama-list > li:nth-child(odd) > .thumb {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 50px;
  }
  .drama-list > li:nth-child(odd) > .box {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
  }
  .drama-list > li:nth-child(even) > .bg {
    left: 0;
  }
  .drama-list > li:nth-child(even) > .thumb {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 50px;
  }
  .drama-list > li:nth-child(even) > .box {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
  }
}

/* ============================================================
	bookdetail-wrap
============================================================ */
.bookdetail-wrap {
  margin-bottom: 140px;
  width: 100%;
  border-top: 1px solid #36357e;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap {
    margin-bottom: 60px;
  }
}

.bookdetail-wrap > .bookdetail {
  margin: 0 auto;
  width: 1000px;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail {
    margin: 0 3% 0;
    width: 94%;
  }
}

.bookdetail-wrap > .bookdetail > .main {
  position: relative;
  padding-top: 40px;
  min-height: 688px;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .main {
    display: block;
    padding-top: 20px;
  }
}

.bookdetail-wrap > .bookdetail > .main > .title {
  order: 3;
  margin: 30px 0 0 520px;
  font-size: 2.4rem;
  line-height: 1.4;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #36357e;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .main > .title {
    order: 1;
    margin: 0;
    font-size: 2.2rem;
  }
}

.bookdetail-wrap > .bookdetail > .main > .subtitle {
  order: 4;
  margin: 5px 0 0 520px;
  font-size: 1.4rem;
  line-height: 1.4;
  color: #36357e;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .main > .subtitle {
    order: 2;
    margin: 5px 0 0 0;
  }
}

.bookdetail-wrap > .bookdetail > .main > .author {
  order: 5;
  margin: 15px 0 30px 520px;
  font-size: 1.8rem;
  line-height: 1.4;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bookdetail-wrap > .bookdetail > .main > .author > span {
  font-size: 1.2rem;
  font-weight: 500;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
}

.bookdetail-wrap > .bookdetail > .main > .author > span:nth-of-type(1) {
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .main > .author {
    order: 3;
    margin: 15px 0 30px 0;
    font-size: 1.6rem;
  }
}

.bookdetail-wrap > .bookdetail > .main > .box {
  order: 2;
  width: 430px;
  height: 520px;
  margin-bottom: -520px;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .main > .box {
    order: 4;
    width: auto;
    height: auto;
    margin: 0 auto 30px;
  }
}

.bookdetail-wrap > .bookdetail > .main > .box > .bookimg {
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 0 8px #ccc;
  width: 430px;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookdetail-wrap > .bookdetail > .main > .box > .bookimg > img {
  border: 1px solid #ccc;
  width: auto;
  max-width: 386px;
  max-height: 386px;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .main > .box > .bookimg {
    width: auto;
    height: auto;
    max-width: 426px;
    max-height: 446px;
    padding: 30px 20px;
  }
  .bookdetail-wrap > .bookdetail > .main > .box > .bookimg > img {
    max-width: 100%;
  }
}

.bookdetail-wrap > .bookdetail > .main > .box > .purchase {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.bookdetail-wrap > .bookdetail > .main > .box > .purchase > .btnw {
  margin: 0 10px;
  padding: 10px 35px 10px 25px;
  width: 195px;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .main > .box > .purchase > .btnw {
    margin: 0 5px;
    padding: 10px 25px 10px 15px;
    width: 180px;
  }
}

@media only screen and (max-width: 320px) {
  .bookdetail-wrap > .bookdetail > .main > .box > .purchase > .btnw {
    font-size: 1.4rem;
  }
}

.bookdetail-wrap > .bookdetail > .main > .box > .purchase > .btnw.-pod {
  width: 270px;
}

.bookdetail-wrap > .bookdetail > .main > .lead {
  order: 1;
  margin-bottom: 40px;
  color: #36357e;
  font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
  font-size: 3.2rem;
  line-height: 1.4;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media print, screen and (min-width: 768px) {
  .bookdetail-wrap > .bookdetail > .main > .lead {
    min-height: 44px;
  }
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .main > .lead {
    order: 5;
    margin-bottom: 20px;
    font-size: 2rem;
    text-align: justify;
  }
  .bookdetail-wrap > .bookdetail > .main > .lead > br {
    display: none;
  }
}

.bookdetail-wrap > .bookdetail > .main > .sentei {
  order: 6;
  margin-left: 520px;
  text-align: right;
}

.bookdetail-wrap > .bookdetail > .main > .sentei > p {
  display: inline-block;
  border-radius: 3px;
  margin: 0 0 10px 5px;
  padding: 4px;
  font-size: 1rem;
  color: #fff;
}

.bookdetail-wrap > .bookdetail > .main > .sentei > p.sla {
  background-color: #44691a;
}

.bookdetail-wrap > .bookdetail > .main > .sentei > p.jla {
  background-color: #3d2929;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .main > .sentei {
    margin-left: 0;
  }
}

.bookdetail-wrap > .bookdetail > .main > .txt {
  order: 7;
  margin: 0 0 30px 520px;
  font-size: 1.5rem;
  line-height: 1.8;
  text-align: justify;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .main > .txt {
    margin: 0 0 30px 0;
  }
}

.bookdetail-wrap > .bookdetail > .main > .more {
  order: 8;
  margin-left: 520px;
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .main > .more {
    margin-left: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.bookdetail-wrap > .bookdetail > .main > .more > a {
  margin: 0 0 30px 10px;
  padding: 10px 20px;
  font-size: 1.6rem;
  background-color: #6869ae;
  width: 180px;
}

_:-ms-fullscreen, :root .bookdetail-wrap > .bookdetail > .main > .more > a {
  padding-top: 17px;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .main > .more > a {
    margin: 0 5px 30px 5px;
    padding: 10px;
  }
}

.bookdetail-wrap > .bookdetail > .main > .more > a > p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookdetail-wrap > .bookdetail > .main > .more > a:visited {
  color: #fff;
}

.bookdetail-wrap > .bookdetail > .main > .more > a.-blank::before {
  top: 12px;
  right: 8px;
  font-size: 1rem;
}

@media only screen and (max-width: 320px) {
  .bookdetail-wrap > .bookdetail > .main > .more > a.-blank::before {
    top: 8px;
    right: 4px;
  }
}

.bookdetail-wrap > .bookdetail > .main > .more > a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-color: #fff;
  color: #36357e;
  text-decoration: none !important;
}

@media print, screen and (min-width: 768px) {
  .bookdetail-wrap > .bookdetail > .main > .more > a:hover {
    opacity: 1;
    background-color: #fff;
    color: #36357e;
    text-decoration: none !important;
  }
}

.bookdetail-wrap > .bookdetail > .main > .more > a [class^="icon-"] {
  margin-right: 5px;
  font-size: 2rem;
}

.bookdetail-wrap > .bookdetail > .main > .info {
  order: 9;
  margin-left: 520px;
  padding: 20px;
  background-color: #f4f4f4;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .main > .info {
    margin-left: 0;
    padding: 10px;
  }
}

.bookdetail-wrap > .bookdetail > .main > .info > p {
  display: inline-block;
  margin: 0px 10px 5px 0;
  font-size: 1.4rem;
  line-height: 1.4;
  color: #666;
}

.bookdetail-wrap > .bookdetail > .main > .info > p.isbn {
  margin-right: 0;
  display: block;
}

.bookdetail-wrap > .bookdetail > .main > .info > p.genre {
  margin: 0;
  display: table;
}

.bookdetail-wrap > .bookdetail > .main > .info > p.genre > span {
  display: table-cell;
}

.bookdetail-wrap > .bookdetail > .main > .info > p.genre > span:first-child {
  white-space: nowrap;
}

.bookdetail-wrap > .bookdetail > .main > .note {
  order: 10;
  margin-left: 520px;
  margin-top: 10px;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .main > .note {
    margin-left: 0;
  }
}

.bookdetail-wrap > .bookdetail .menu {
  margin: 80px auto 0;
  padding-left: 8px;
  border-bottom: 1px dotted #36357e;
  font-size: 0;
}

@media print, screen and (min-width: 768px) {
  .bookdetail-wrap > .bookdetail .menu {
    display: flex;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail .menu {
    margin: 40px auto 0;
    padding: 0;
    border-bottom: none;
  }
  .bookdetail-wrap > .bookdetail .menu > li {
    margin-top: 5px;
    display: inline-block;
    width: 100%;
    border-bottom: 1px dotted #36357e;
    text-align: center;
  }
}

.bookdetail-wrap > .bookdetail .menu .btn-anker {
  display: block;
  position: relative;
  margin-right: 30px;
  padding: 10px 30px 10px 0;
  color: #36357e;
  font-size: 1.4rem;
}

_:-ms-fullscreen, :root .bookdetail-wrap > .bookdetail .menu .btn-anker {
  padding-top: 17px;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail .menu .btn-anker {
    margin-right: 0;
  }
}

.bookdetail-wrap > .bookdetail .menu .btn-anker::before {
  position: absolute;
  top: 58%;
  transform: translate(0, -58%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 10px;
  font-size: 1.2rem;
  content: "";
  top: 56%;
  font-size: 1.1rem;
  transition: top .3s;
}

.bookdetail-wrap > .bookdetail .menu .btn-anker.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

.bookdetail-wrap > .bookdetail .menu .btn-anker.hover::before {
  top: 68%;
  transform: translate(0, -68%);
}

@media print, screen and (min-width: 768px) {
  .bookdetail-wrap > .bookdetail .menu .btn-anker:hover {
    text-decoration: none;
  }
  .bookdetail-wrap > .bookdetail .menu .btn-anker:hover::before {
    top: 68%;
    transform: translate(0, -68%);
  }
}

.bookdetail-wrap > .bookdetail > .other {
  margin-top: 80px;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .other {
    margin-top: 50px;
  }
}

.bookdetail-wrap > .bookdetail > .other > .ttl-line {
  display: block;
  margin-bottom: 20px;
  padding: 10px 8px;
  border-bottom: 1px solid #36357e;
  color: #36357e;
  font-size: 1.8rem;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .other > .ttl-line {
    margin-bottom: 15px;
  }
}

.bookdetail-wrap > .bookdetail > .other > .box {
  position: relative;
  font-size: 1.4rem;
  line-height: 1.7;
}

.bookdetail-wrap > .bookdetail > .other > .box > .list {
  margin: 10px 0;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .other > .box > .list {
    margin: 10px 0 5px;
  }
}

.bookdetail-wrap > .bookdetail > .other > .box > .link {
  margin: 10px 0;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .other > .box > .link {
    margin: 10px 0 5px;
  }
}

.bookdetail-wrap > .bookdetail > .other > .box > .link > a {
  display: table;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns {
  margin: 14px 0;
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .other > .box > .sns {
    margin: 0 0 10px;
  }
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a {
  margin-right: 10px;
  padding: 7px 10px;
  font-size: 1.2rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  border-radius: 6px;
  width: 125px;
}

_:-ms-fullscreen, :root .bookdetail-wrap > .bookdetail > .other > .box > .sns > a {
  padding-top: 12px;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .other > .box > .sns > a {
    margin: 10px 10px 0 0;
    padding: 7px 5px;
    width: 158px;
  }
}

@media only screen and (max-width: 320px) {
  .bookdetail-wrap > .bookdetail > .other > .box > .sns > a {
    width: 140px;
  }
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a > p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a > p > span.logo {
  margin-right: 5px;
  line-height: 1;
  width: 16px;
  height: 16px;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a > p > span.logo.fb {
  background: no-repeat center/16px url("/bookinfo/images/detail/icon-fb.png");
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a > p > span.logo.icon-x {
  margin-right: 0;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a > p > span.logo.icon-x + span {
  display: none;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a > p > span.logo.nt {
  background: no-repeat center/50px url("/bookinfo/images/detail/icon-note.png");
  width: 50px;
  margin-right: 0;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a > p > span.logo.nt + span {
  display: none;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a [class^="logo icon-"] {
  font-size: 1.6rem;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a [class^="logo icon-note"] {
  font-size: 50px;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a:visited {
  color: #36357e;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-blank::before {
  top: 8px;
  right: 3px;
  font-size: 1rem;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-color: #36357e;
  color: #fff;
  text-decoration: none !important;
}

@media print, screen and (min-width: 768px) {
  .bookdetail-wrap > .bookdetail > .other > .box > .sns > a:hover {
    opacity: 1;
    background-color: #36357e;
    color: #fff;
    text-decoration: none !important;
  }
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-fb {
  color: #1877f2;
  border: 1px solid #1877f2;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-fb.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #1877f2;
  color: #fff;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-fb.hover span.fb {
  background: no-repeat center/16px url("/bookinfo/images/detail/icon-fb_w.png");
}

@media print, screen and (min-width: 768px) {
  .bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-fb:hover {
    background-color: #1877f2;
    color: #fff;
  }
  .bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-fb:hover span.fb {
    background: no-repeat center/16px url("/bookinfo/images/detail/icon-fb_w.png");
  }
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-tw {
  color: #1da1f2;
  border: 1px solid #1da1f2;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-tw.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #1da1f2;
  color: #fff;
}

@media print, screen and (min-width: 768px) {
  .bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-tw:hover {
    background-color: #1da1f2;
    color: #fff;
  }
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-x {
  color: #000;
  border: 1px solid #000;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-x.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #000;
  color: #fff;
}

@media print, screen and (min-width: 768px) {
  .bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-x:hover {
    background-color: #000;
    color: #fff;
  }
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-nt {
  color: #000;
  border: 1px solid #000;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-nt.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #000;
  color: #fff;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-nt.hover span.nt {
  background: no-repeat center/50px url("/bookinfo/images/detail/icon-note_w.png");
}

@media print, screen and (min-width: 768px) {
  .bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-nt:hover {
    background-color: #000;
    color: #fff;
  }
  .bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-nt:hover span.nt {
    background: no-repeat center/50px url("/bookinfo/images/detail/icon-note_w.png");
  }
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-yt {
  color: #ff0000;
  border: 1px solid #ff0000;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-yt.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #ff0000;
  color: #fff;
}

@media print, screen and (min-width: 768px) {
  .bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-yt:hover {
    background-color: #ff0000;
    color: #fff;
  }
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-ig {
  color: #cf2e92;
  border: 1px solid #cf2e92;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-ig.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #cf2e92;
  color: #fff;
}

@media print, screen and (min-width: 768px) {
  .bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-ig:hover {
    background-color: #cf2e92;
    color: #fff;
  }
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-blog {
  color: #f56800;
  border: 1px solid #f56800;
}

.bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-blog.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #f56800;
  color: #fff;
}

@media print, screen and (min-width: 768px) {
  .bookdetail-wrap > .bookdetail > .other > .box > .sns > a.-blog:hover {
    background-color: #f56800;
    color: #fff;
  }
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .other > .box > .list + .sns,
  .bookdetail-wrap > .bookdetail > .other > .box > .link + .sns {
    margin-top: -2px;
  }
}

.bookdetail-wrap > .bookdetail > .other > .box > .media-list > li {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
}

.bookdetail-wrap > .bookdetail > .other > .box > .media-list > li:last-child {
  margin-bottom: 0;
}

.bookdetail-wrap > .bookdetail > .other > .box > .media-list > li > .label {
  padding: 4px;
  border-radius: 3px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #653308;
  color: #653308;
  white-space: nowrap;
  width: 100px;
  font-size: 1.2rem;
  line-height: 1;
  text-align: center;
}

.bookdetail-wrap > .bookdetail > .other > .box > .media-list > li > .date {
  margin: 0 15px;
  width: 106px;
  white-space: nowrap;
}

.bookdetail-wrap > .bookdetail > .other > .box > .media-list > li > .txt {
  width: 744px;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .other > .box > .media-list > li > .txt {
    margin-top: 5px;
    width: 100%;
  }
}

.bookdetail-wrap > .bookdetail > .other > .box > .media-list > li.link a {
  margin-left: auto;
}

.bookdetail-wrap > .bookdetail > .other > .box .interview-btn {
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
  display: inline-block;
  padding: 10px 35px 10px 25px;
  color: #36357e;
  font-size: 1.4rem;
  line-height: 1;
  text-align: center;
  border-radius: 8px;
  transition: all .3s;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #36357e;
}

.bookdetail-wrap > .bookdetail > .other > .box .interview-btn:visited {
  color: #36357e;
}

.bookdetail-wrap > .bookdetail > .other > .box .interview-btn.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-color: #36357e;
  color: #fff;
  text-decoration: none;
}

.bookdetail-wrap > .bookdetail > .other > .box .interview-btn.hover::before {
  right: 15px;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .other > .box .interview-btn.hover::before {
    right: 8px;
  }
}

@media print, screen and (min-width: 768px) {
  .bookdetail-wrap > .bookdetail > .other > .box .interview-btn:hover {
    opacity: 1;
    background-color: #36357e;
    color: #fff;
    text-decoration: none;
  }
  .bookdetail-wrap > .bookdetail > .other > .box .interview-btn:hover::before {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .other > .box .interview-btn:hover::before {
    right: 8px;
  }
}

.bookdetail-wrap > .bookdetail > .other > .box .interview-btn::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.2rem;
  content: "";
  font-size: 1rem;
  right: 15px;
  transition: top .3s;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .other > .box .interview-btn::before {
    right: 12px;
  }
}

.bookdetail-wrap > .bookdetail > .other > .box .interview-btn.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;
  background-color: #36357e;
}

.bookdetail-wrap > .bookdetail > .other > .box .interview-btn.hover::before {
  right: 15px;
  top: 58%;
  transform: translate(0, -58%);
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .other > .box .interview-btn.hover::before {
    right: 12px;
  }
}

@media print, screen and (min-width: 768px) {
  .bookdetail-wrap > .bookdetail > .other > .box .interview-btn:hover {
    text-decoration: none;
    background-color: #36357e;
  }
  .bookdetail-wrap > .bookdetail > .other > .box .interview-btn:hover::before {
    right: 15px;
    top: 58%;
    transform: translate(0, -58%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .other > .box .interview-btn:hover::before {
    right: 12px;
  }
}

.bookdetail-wrap > .bookdetail > .other > .box .interview-area {
  position: relative;
  overflow: hidden;
}

.bookdetail-wrap > .bookdetail > .other > .box .interview-area.is-hide {
  height: 138px;
}

.bookdetail-wrap > .bookdetail > .other > .box .interview-area > p + p {
  margin-top: 16px;
}

.bookdetail-wrap > .bookdetail > .other > .box .interview-area::before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 60px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 70%);
  background-color: transparent;
}

.bookdetail-wrap > .bookdetail > .other > .box .interview-btn.is-show + .interview-area::before {
  display: none;
}

.bookdetail-wrap > .bookdetail > .other > .box .drama-list > li {
  position: relative;
  margin: 5px 0 0 0;
  display: table;
  text-align: left;
  width: auto;
  height: auto;
}

.bookdetail-wrap > .bookdetail > .other > .box .drama-list > li > p {
  display: table-cell;
  font-size: 1.4rem;
  line-height: 1.7;
}

.bookdetail-wrap > .bookdetail > .other > .box .drama-list > li > p:first-child {
  width: 70px;
  text-align: right;
  white-space: nowrap;
}

.bookdetail-wrap > .bookdetail > .other > .box .youtube-box {
  margin: 0 auto;
  width: 600px;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .other > .box .youtube-box {
    position: relative;
    width: 100% !important;
    padding-top: 56.25%;
  }
  .bookdetail-wrap > .bookdetail > .other > .box .youtube-box > iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.bookdetail-wrap > .bookdetail > .other > .box + .note {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .other > .box + .note {
    margin-top: 30px;
  }
}

.bookdetail-wrap > .bookdetail .purchase-area {
  margin-top: 120px;
  padding: 10px 51px;
  border: 1px solid #36357e;
  background-color: #f7f7fa;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail .purchase-area {
    margin-top: 60px;
    padding: 5px 15px;
  }
}

.bookdetail-wrap > .bookdetail .purchase-area > .box {
  padding: 20px 0;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail .purchase-area > .box {
    padding: 15px 0;
  }
}

.bookdetail-wrap > .bookdetail .purchase-area > .box > .ttl {
  display: block;
  color: #36357e;
  font-size: 1.6rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bookdetail-wrap > .bookdetail .purchase-area > .box > .txt {
  margin-top: 10px;
}

.bookdetail-wrap > .bookdetail .purchase-area > .box > .list {
  display: flex;
  flex-wrap: wrap;
}

.bookdetail-wrap > .bookdetail .purchase-area > .box > .list > li {
  margin-top: 10px;
  margin-right: 16px;
  width: 136px;
  height: 39px;
}

.bookdetail-wrap > .bookdetail .purchase-area > .box > .list > li:nth-of-type(6) {
  margin-right: 0px;
}

.bookdetail-wrap > .bookdetail .purchase-area > .box > .list > li > a {
  display: block;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail .purchase-area > .box > .list > li {
    width: 32%;
    height: auto;
    margin-right: 2%;
  }
  .bookdetail-wrap > .bookdetail .purchase-area > .box > .list > li:nth-child(3n) {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 539px) {
  .bookdetail-wrap > .bookdetail .purchase-area > .box > .list > li {
    width: 48%;
    height: auto;
    margin-right: 4%;
  }
  .bookdetail-wrap > .bookdetail .purchase-area > .box > .list > li:nth-child(3n) {
    margin-right: 4%;
  }
  .bookdetail-wrap > .bookdetail .purchase-area > .box > .list > li:nth-child(even) {
    margin-right: 0px;
  }
}

.bookdetail-wrap > .bookdetail .purchase-area > .box > .h5ttl-line {
  margin-top: 20px;
}

.bookdetail-wrap > .bookdetail .purchase-area > .box > .btn-wrap {
  margin: 10px 0 30px;
}

.bookdetail-wrap > .bookdetail .purchase-attention {
  margin-top: 10px;
  padding: 20px 0;
  border-top: 1px solid #ccc;
}

.bookdetail-wrap > .bookdetail .purchase-attention > .txt {
  margin-top: 5px;
  font-size: 1.2rem;
  line-height: 1.5;
}

.bookdetail-wrap > .bookdetail .purchase-attention > .indent-multilist {
  margin: 10px 0 20px;
}

.bookdetail-wrap > .bookdetail > .banner-box {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .bookdetail-wrap > .bookdetail > .banner-box {
    margin: 20px 0 10px;
  }
}

.bookdetail-wrap > .bookdetail > .banner-box > a {
  margin: 0 auto;
  display: block;
  box-shadow: 0 0 8px #ccc;
}

/* ============================================================
	bookservice
============================================================ */
.bookservice {
  margin: 40px auto 0;
  text-align: center;
}

.bookservice > .txt {
  margin-bottom: 10px;
  font-size: 1.8rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #36357e;
  display: inline-block;
  text-align: left;
}

.bookservice > .indent-multilist {
  margin: 0 auto;
  display: inline-block;
}

.bookservice > .indent-multilist > li {
  color: #36357e;
  font-size: 1.8rem;
  line-height: 1.2;
}

.bookservice > .indent-multilist > li .tel {
  font-family: 'Lato', sans-serif;
}

.bookservice > .indent-multilist > li span:nth-of-type(2) {
  font-size: 1.4rem;
}

/* ============================================================
	contact-form
============================================================ */
.contact-wrap {
  display: flex;
  justify-content: space-between;
}

@media print, screen and (min-width: 768px) {
  .contact-wrap {
    margin-top: 30px;
  }
}

.contact-wrap > .item {
  width: auto;
}

@media only screen and (max-width: 767px) {
  .contact-wrap {
    display: block;
  }
  .contact-wrap > .item {
    width: 100%;
    margin-top: 20px;
  }
}

.contact-wrap .item > .contact-list {
  margin-left: 10px;
  font-size: 1.5rem;
  line-height: 1.7;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

/* ============================================================
	about
============================================================ */
.about-box > .txt {
  margin-top: 20px;
  font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
  font-size: 1.6rem;
  line-height: 1.9;
}

@media only screen and (max-width: 767px) {
  .about-box > .txt {
    margin-top: 15px;
    font-size: 1.4rem;
    line-height: 1.7;
  }
}

.about-box > .txt.-large {
  font-size: 2rem;
  line-height: 1.9;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .about-box > .txt.-large {
    margin-top: 15px;
    font-size: 1.6rem;
    line-height: 1.7;
  }
}

.message-name {
  margin-top: 20px;
  font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
  font-size: 1.8rem;
  line-height: 1.7;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .message-name {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}

.message-name > span {
  font-size: 2.2rem;
}

@media only screen and (max-width: 767px) {
  .message-name > span {
    font-size: 2rem;
  }
}

/* ============================================================
	profile
============================================================ */
.profile-box {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
}

@media only screen and (max-width: 479px) {
  .profile-box {
    border-bottom: none;
  }
}

.profile-box > li {
  padding: 30px 60px;
  border-top: 1px solid #ccc;
  font-size: 1.6rem;
  line-height: 1.7;
}

@media only screen and (max-width: 767px) {
  .profile-box > li {
    padding: 15px 25px;
    font-size: 1.4rem;
    line-height: 1.5;
  }
}

@media only screen and (max-width: 479px) {
  .profile-box > li {
    border-top: none;
  }
}

.profile-box > li:nth-child(odd) {
  background: #eaeaea;
  width: 380px;
}

@media only screen and (max-width: 767px) {
  .profile-box > li:nth-child(odd) {
    width: 30%;
  }
}

@media only screen and (max-width: 479px) {
  .profile-box > li:nth-child(odd) {
    width: 100%;
    padding: 6px 10px;
  }
}

.profile-box > li:nth-child(even) {
  background: #fff;
  width: 700px;
}

@media only screen and (max-width: 767px) {
  .profile-box > li:nth-child(even) {
    width: 70%;
  }
}

@media only screen and (max-width: 479px) {
  .profile-box > li:nth-child(even) {
    width: 100%;
    padding: 10px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .profile-box > li:last-child {
    width: 70%;
  }
}

@media only screen and (max-width: 479px) {
  .profile-box > li:last-child {
    width: 100%;
  }
}

/* ============================================================
	map
============================================================ */
.map-wrap {
  margin-bottom: 30px;
  width: 1080px;
  height: 500px;
}

@media only screen and (max-width: 767px) {
  .map-wrap {
    margin-bottom: 10px;
    width: 100%;
    height: 300px;
  }
}

.map-box {
  display: flex;
  flex-wrap: wrap;
}

.map-box > li:nth-child(odd) {
  padding-right: 30px;
  width: 730px;
}

@media only screen and (max-width: 767px) {
  .map-box > li:nth-child(odd) {
    width: 70%;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 479px) {
  .map-box > li:nth-child(odd) {
    width: 100%;
    padding-right: 0;
  }
}

.map-box > li:nth-child(even) {
  width: 350px;
}

@media only screen and (max-width: 767px) {
  .map-box > li:nth-child(even) {
    width: 30%;
  }
}

@media only screen and (max-width: 479px) {
  .map-box > li:nth-child(even) {
    width: 100%;
    padding-top: 10px;
  }
}

.map-box > li > ul.access-box {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #ccc;
}

@media only screen and (max-width: 479px) {
  .map-box > li > ul.access-box {
    border-top: none;
  }
}

.map-box > li > ul.access-box > li {
  padding: 30px 50px;
  border-bottom: 1px solid #ccc;
  font-size: 1.6rem;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .map-box > li > ul.access-box > li {
    padding: 15px 10px;
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 479px) {
  .map-box > li > ul.access-box > li {
    border-bottom: none;
  }
}

.map-box > li > ul.access-box > li:nth-child(odd) {
  background: #eaeaea;
  width: 200px;
}

@media only screen and (max-width: 767px) {
  .map-box > li > ul.access-box > li:nth-child(odd) {
    width: 22%;
  }
}

@media only screen and (max-width: 479px) {
  .map-box > li > ul.access-box > li:nth-child(odd) {
    width: 100%;
    padding: 6px 10px;
  }
}

.map-box > li > ul.access-box > li:nth-child(even) {
  background: #fff;
  width: 500px;
}

@media only screen and (max-width: 767px) {
  .map-box > li > ul.access-box > li:nth-child(even) {
    width: 78%;
  }
}

@media only screen and (max-width: 479px) {
  .map-box > li > ul.access-box > li:nth-child(even) {
    width: 100%;
    padding: 10px;
  }
}

.map-route {
  margin: 100px auto 0;
  width: 600px;
}

.map-route > iframe {
  width: 600px;
  height: 330px;
}

@media only screen and (max-width: 767px) {
  .map-route {
    position: relative;
    margin: 30px auto 0;
    padding-top: 55.25%;
    width: 100% !important;
  }
  .map-route > iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

/* ============================================================
	history
============================================================ */
.history-box {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #ccc;
}

.history-box > li {
  padding: 15px 20px;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  font-size: 1.4rem;
  line-height: 1.7;
}

@media only screen and (max-width: 767px) {
  .history-box > li {
    padding: 6px 10px;
    line-height: 1.5;
  }
}

.history-box > li:nth-child(odd) {
  background: #eaeaea;
  border-left: 1px solid #ccc;
  width: 140px;
}

@media only screen and (max-width: 767px) {
  .history-box > li:nth-child(odd) {
    width: 22%;
    border-left: 1px solid #ccc;
  }
}

@media only screen and (max-width: 479px) {
  .history-box > li:nth-child(odd) {
    width: 100%;
  }
}

.history-box > li:nth-child(even) {
  background: #fff;
  width: 940px;
}

@media only screen and (max-width: 767px) {
  .history-box > li:nth-child(even) {
    width: 78%;
  }
}

@media only screen and (max-width: 479px) {
  .history-box > li:nth-child(even) {
    width: 100%;
    border-left: 1px solid #ccc;
  }
}

.history-data {
  margin-top: 10px;
  font-size: 1.2rem;
  text-align: right;
}

/* ============================================================
	group
============================================================ */
@media print, screen and (min-width: 701px) {
  .group-list {
    display: flex;
    flex-wrap: wrap;
  }
}

.group-list > .item-box {
  position: relative;
  margin: 18px 18px 0 0;
  padding: 10px;
  width: 348px;
  border: 1px solid #ccc;
}

.group-list > .item-box:nth-child(3n) {
  margin: 18px 0 0 0;
}

@media only screen and (max-width: 767px) {
  .group-list > .item-box {
    display: flex;
    justify-content: space-between;
    margin: 18px 0 0 0;
    width: 100%;
  }
}

@media only screen and (max-width: 375px) {
  .group-list > .item-box {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .group-list > .item-box > .txt-box {
    width: 68%;
  }
}

@media only screen and (max-width: 375px) {
  .group-list > .item-box > .txt-box {
    width: 100%;
  }
}

.group-list > .item-box > .txt-box > .ttl {
  display: inline-block;
  margin-top: 15px;
  font-size: 1.8rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .group-list > .item-box > .txt-box > .ttl {
    margin-top: 0;
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 375px) {
  .group-list > .item-box > .txt-box > .ttl {
    margin-top: 15px;
  }
}

.group-list > .item-box > .txt-box > .txt {
  display: inline-block;
  margin-top: 10px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .group-list > .item-box > .logo {
    width: 30%;
  }
}

@media only screen and (max-width: 375px) {
  .group-list > .item-box > .logo {
    width: 100%;
    text-align: center;
  }
  .group-list > .item-box > .logo img {
    max-width: 163px;
  }
}

/* ============================================================
	principles
============================================================ */
.principles-box {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px dotted #ccc;
}

.principles-box > li:nth-child(odd) {
  padding: 30px 20px 30px 40px;
  width: 260px;
  font-size: 1.6rem;
  line-height: 1.7;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 767px) {
  .principles-box > li:nth-child(odd) {
    padding: 35px 0 20px;
    width: 100%;
    line-height: 1;
  }
}

.principles-box > li:nth-child(even) {
  padding: 30px 40px 30px 20px;
  width: 820px;
}

@media only screen and (max-width: 767px) {
  .principles-box > li:nth-child(even) {
    padding: 0 0 30px;
    width: 100%;
  }
}

.principles-box:first-child > li {
  padding-top: 0;
}

/* ============================================================
	recruit

	.recruit-box {
	@include flex-wrap;
	border-top: $borderSol;
	margin-top: 20px;
	@include media(lx) {margin-top: 15px;}

	> li {
		padding: 10px;
		border-bottom: $borderSol;
		border-right: $borderSol;
		@include fs-16l;
		@include media(lx) {
			padding: 6px 10px;
			font-size: $fs_m;
		}

		&:nth-child(even) {
			background: $gray-e;
			border-left: $borderSol;
			width: 130px;
			@include media(lx) {width: 22%;border-left: $borderSol;}
			@include media(sx) {width: $fullWidth;}
		}
		&:nth-child(odd) {
			background: $white;
			width: 950px;
			@include media(lx) {width: 78%;}
			@include media(sx) {width: $fullWidth;border-left: $borderSol;}
		}

		&.ttl {
			width: 100% !important;
			border-left: $borderSol;
			@include fs-18l;
			@include bold;
			@include media(lx) {@include fs-16m;}
		}

	}//li
}//recruit-box
============================================================ */
.recruit-detail {
  margin-top: 80px;
}

.recruit-detail:first-of-type {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .recruit-detail {
    margin-top: 40px;
  }
  .recruit-detail:first-of-type {
    margin-top: 20px;
  }
}

.recruit-detail > .h4ttl-line {
  margin-bottom: 10px;
  padding: 0 8px 4px;
  border-bottom: 1px solid #36357e;
}

@media only screen and (max-width: 767px) {
  .recruit-detail > .h4ttl-line {
    margin-bottom: 5px;
    padding: 0 4px 4px;
  }
}

.recruit-detail > .list > li {
  display: flex;
  align-items: baseline;
  padding: 10px 0;
  border-bottom: 1px dotted #ccc;
}

.recruit-detail > .list > li > .ttl {
  padding: 5px;
  width: 120px;
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.7;
  text-align: center;
  background-color: #a5a5a5;
}

_:-ms-fullscreen, :root .recruit-detail > .list > li > .ttl {
  padding-top: 12px;
}

.recruit-detail > .list > li > .ttl.-date {
  background-color: #e62814;
}

@media only screen and (max-width: 767px) {
  .recruit-detail > .list > li > .ttl {
    width: 72px;
    font-size: 1.4rem;
    line-height: 1.5;
  }
}

.recruit-detail > .list > li > .txt-area {
  padding-left: 22px;
  width: 100%;
  font-size: 1.6rem;
  line-height: 1.7;
}

@media only screen and (max-width: 767px) {
  .recruit-detail > .list > li > .txt-area {
    padding-left: 10px;
    font-size: 1.4rem;
    line-height: 1.5;
  }
}

/* ============================================================
	protection
============================================================ */
.protection-box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.protection-box > li:nth-child(odd) {
  padding-right: 30px;
  width: 542px;
}

@media only screen and (max-width: 767px) {
  .protection-box > li:nth-child(odd) {
    width: 40%;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 700px) {
  .protection-box > li:nth-child(odd) {
    width: 100%;
    padding-right: 0;
  }
}

.protection-box > li:nth-child(even) {
  width: 538px;
}

@media only screen and (max-width: 767px) {
  .protection-box > li:nth-child(even) {
    width: 60%;
  }
}

@media only screen and (max-width: 700px) {
  .protection-box > li:nth-child(even) {
    width: 100%;
    padding-top: 10px;
  }
}

.protection-box > li > img {
  border: 1px solid #ccc;
}

/* ============================================================
	privacypolicy
============================================================ */
.contact-box.-about {
  margin: 60px auto 0;
}

@media only screen and (max-width: 767px) {
  .contact-box.-about {
    margin: 40px auto 0;
    width: 100%;
  }
}

.contact-box.-about > .box p {
  margin-top: 0;
  font-size: 1.4rem;
  line-height: 1.4;
}

.contact-box.-about > .box > .item .txt-box {
  margin-top: 20px;
}

@media print, screen and (min-width: 768px) {
  .contact-box.-about > .box > .item .txt-box {
    padding-bottom: 8px;
  }
}

@media only screen and (max-width: 700px) {
  .contact-box.-about > .box > .item .txt-box {
    margin-top: 0;
  }
}

/* ============================================================
	sitepolicy
============================================================ */
.banner-list {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.banner-list > li {
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .banner-list > li {
    margin-right: 10px;
  }
}

.banner-list > li > p {
  margin-top: 5px;
}

.banner-list > li img {
  margin-top: 10px;
}

.banner-list > li:nth-of-type(1) > img {
  width: 171px;
}

.banner-list > li:nth-of-type(2) > img {
  width: 129px;
}

.banner-list > li:nth-of-type(3) > img {
  width: 88px;
}

/* ============================================================
	sitemap-list
============================================================ */
a.sitemap-ttl {
  position: relative;
  display: block;
  padding: 6px 10px 10px 10px;
  border-left: 10px solid #36357e;
  border-bottom: 2px solid #36357e;
  font-size: 1.9rem;
  line-height: 1;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  a.sitemap-ttl {
    margin-bottom: 10px;
  }
}

a.sitemap-ttl.-publi {
  border-left: 10px solid #e62814;
  border-bottom: 2px solid #e62814;
}

a.sitemap-ttl.-bkinfo {
  border-left: 10px solid #ff9e00;
  border-bottom: 2px solid #ff9e00;
}

.sitemap-list {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 30px;
}

@media only screen and (max-width: 767px) {
  .sitemap-list {
    margin: 10px 0 30px;
  }
}

.sitemap-list > li {
  margin-right: 20px;
  width: 255px;
}

@media only screen and (max-width: 767px) {
  .sitemap-list > li {
    margin-right: 0;
    width: 49%;
  }
}

@media only screen and (max-width: 539px) {
  .sitemap-list > li {
    margin-right: 0;
    width: 100%;
  }
}

.sitemap-list > li:nth-child(4n) {
  margin-right: 0;
}

.sitemap-list > li > .ctg-ttl {
  display: block;
  margin-bottom: 20px;
  padding: 4px 8px;
  border-bottom: 1px solid #36357e;
  color: #555;
  font-size: 1.7rem;
  line-height: 1.4;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .sitemap-list > li > .ctg-ttl {
    margin-bottom: 15px;
    font-size: 1.6rem;
  }
}

.sitemap-list > li > .ctg-ttl.-publi {
  border-bottom: 1px solid #e62814;
}

.sitemap-list > li > .ctg-ttl.-bkinfo {
  border-bottom: 1px solid #ff9e00;
}

@media only screen and (max-width: 539px) {
  .sitemap-list > li:nth-child(2) > .ctg-ttl {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .sitemap-list > li:nth-child(3) > .ctg-ttl, .sitemap-list > li:nth-child(4) > .ctg-ttl {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .sitemap-list > li:nth-child(1), .sitemap-list > li:nth-child(3) {
    margin-right: 2%;
  }
}

@media only screen and (max-width: 539px) {
  .sitemap-list > li:nth-child(1), .sitemap-list > li:nth-child(3) {
    margin-right: 0;
  }
}

.site-link {
  margin: 15px 0 0 4px;
}

.site-link > a {
  padding-right: 15px;
  position: relative;
  display: inline-block;
  font-size: 1.5rem;
  line-height: 1;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .site-link > a {
    font-size: 1.4rem;
  }
}

.site-link > a::before {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-family: "bungeisha" !important;
  display: inline-block;
  right: 20px;
  font-size: 1.2rem;
  content: "";
  right: 0;
  transition: right .1s;
  font-size: 1rem;
}

.site-link > a.hover {
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: underline !important;
}

.site-link > a.hover::before {
  right: -5px;
}

@media print, screen and (min-width: 768px) {
  .site-link > a:hover {
    text-decoration: underline !important;
  }
  .site-link > a:hover::before {
    right: -5px;
  }
}

.site-link > .lv {
  margin-right: 5px;
  vertical-align: top;
}

.site-link.-blank > a::before {
  display: none;
}

/* ============================================================
	print
============================================================ */
@media print {
  header {
    position: relative !important;
    height: 74px !important;
  }
  header.smaller {
    padding: 4px 0 0 !important;
    border-bottom: 0 !important;
    background: #fff !important;
  }
  header.smaller .global-header > .logo {
    top: 22px !important;
  }
  header.smaller .global-header > .lead {
    top: 0 !important;
  }
  header.smaller .global-header > .tel-box {
    top: 22px !important;
  }
  .global-nav, .header-nav, footer, .pagetop, .bc-area,
  .base-wrap.-faq-area {
    display: none !important;
  }
  .content-area {
    margin-top: 10px;
  }
  .base-wrap:last-of-type,
  .old-wrap:last-of-type {
    margin-bottom: 0;
  }
  .no-print {
    display: none !important;
  }
  .for-print {
    display: block !important;
  }
  .qa-list > dd {
    display: block;
  }
  .social-box, .template-area, .btn-entry,
  .contest-entrybox .btn-area,
  .contest-entrybox.-web, .ehon_awardlist,
  .fancybox-wrap {
    display: none !important;
  }
  .contest-inquiry {
    align-items: flex-start;
  }
  .contents_social, .contents_paging {
    display: none !important;
  }
}
