@charset "utf-8";

/* ============================================================
	header
============================================================ */
header {
	position: fixed;
	top: 0;
	padding: 4px 0 0;
	width: $fullWidth;
	height: 105px;
	background: $white;
	border-bottom: $borderSol;
	@include transition;
	@include z-index(header);

	@include media(xlx) {
		width: $baseWidthPlus;
	}

	@include media(lx) {
		padding: 0;
		width: $fullWidth;
		height: 56px;
	}
	@include media(ln) {
		&.mgdr-publi, &.mgdr-bkinfo, &.mgdr-about {
			//changeold height: 178px;
			//changenew
			height: 204px;
			border-bottom: none;
		}
	}
}//header


.header-inner {
	position: relative;
	@include margin;
	width: $fullWidth;
}


@include media(ln) {
/* ============================================================
	global-header, global-search, global-nav
============================================================ */
.global-header {
	position: relative;
	@include margin;
	width: $baseWidth;
	//changeold height: 74px;
	//changenew
	height: 100px;

	> .logo {
		position: absolute;
		top: 22px;
		left: 0;
		width: 162px;
		height: 38px;
	}

	> .lead {
		position: absolute;
		top: 2px;
		left: 0;
		color: $textColor;
		font-size: $fs_s;
		@include bold;
		@include transition;
	}

	> .tel-box {
		position: absolute;
		top: 22px;
		left: 180px;
		color: $textColor;
		@include transition;

		.time {
			font-size: $fs_s;
			@include bold;
			> .label {
				margin-left: 3px;
				padding: 2px 6px 3px;
				@include ie-hack(gteIE11) {padding: 2px 6px 0;font-size: $fs_xs;}
				color: $white;
				background-color: $baseColor-light;
				@include radius(2);
			}//label
		}//time

		.tel {
			margin-top: 6px;
			font-size: $fs_xxl;
			@include gfont-Lato700;
		}//tel
	}//tel-box
}//global-header

@supports (-ms-ime-align: auto) {
	.global-header > .tel-box .time .label {padding: 1px 6px;font-size: $fs_xs;}
}

.global-search {
	@include flex-center;
	position: absolute;
	top: 5px;
	right: 0;
	@include transition;

	> li {white-space: nowrap;}

	input[type="text"] {
		margin: 0;
		width : 180px !important;
		height: 26px;
		@include media(lx) {width: 82% !important;}
	}

	.btn-search {
		display: inline-block;
		position: relative;
		margin: 0 0 0 5px;
		padding: 5px 10px;
		@include ie-hack(gteIE11) {padding-top: 12px;}
		color: $white;
		font-size: $fs_m;
		text-align: center;
		line-height: 1;
		@include radius(4);
		@include transition;
		cursor: pointer;
		background-color: $baseColor;
		border: 1px solid $baseColor;

		&:visited {color: $white;}

		@include hover() {
			opacity: 1;
			background-color: $white;
			color: $baseColor;
			text-decoration: none;
		}
	}//btn-search
}//global-search

.global-searchbtn, .global-searchsp, .global-searchsp.-open {display: none !important;}

.global-nav {
	@include flex;
	position: absolute;
	//changeold top: 20px;
	//changenew
	top: 50px;
	right: 0;
	@include transition;

	> li {
		position: relative;
		margin-left: 25px;
	}

	> li > .ctg {
		display: block;
		text-align: center;
		@include link-black;

		[class^="icon-"] {margin-right: 2px;}
		.icon-publishing {margin-right: 0;}

		> .ttl {
			margin-bottom: 5px;
			font-size: $fs_l;
			@include bold;
		}
		> .eng {
			color: $titleColor;
			font-size: $fs_xs;
			@include eng;
		}

		&::after, &::before {
			content: '';
			position: absolute;
			top: 115%;
			width: 0;
			height: 2px;
			background: $baseColor;
			@include transition;
		}
		&::after {right: 50%;}
		&::before {left: 50%;}

		@include hover {
			> .ttl {color: $baseColor;}
			&::after, &::before {width: 50%;}
		}
		&.-current {
			> .ttl {color: $baseColor;}
			&::after, &::before {width: 50%;}
		}

	}//ctg
}//global-nav


/* ============================================================
	header-nav, menu-mgdr
============================================================ */
.header-nav {
	display: none;
}

header[class^="mgdr-"] .header-nav {
	display: block;
	@include margin;
	padding: 0;
	width: $fullWidth;
	@include box-shadow;
	@include transition;
}

.menu-mgdr {
	display: table;
	table-layout: fixed;
	position: relative;
	@include margin;
	width: $baseWidth;

	.first {
		display: table-cell;
		padding: 10px;
		height: 100px;
		@include z-index(first);
	}

	.first > .ctg {
		position: relative;
		display: block;
		height: 80px;
		text-align: center;
		@include link-title;

		&.-toplink {display: none;}

		&.-current {
			background: $about-hoverColor;
			> .ttl {color: $about-iconColor}
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: -10px;
			bottom: 0;
			@include margin(auto 0);
			height: 80px;
			border-right: $borderDot;
		}

		> .ttl {
			margin-bottom: 3px;
			font-size: $fs_l;
			@include bold;
		}
		> .eng {
			color: $about-iconColor;
			font-size: $fs_xs;
			font-style: italic;
			@include eng;
		}
	}//ctg

	.first.-bkinfo#bkinfo05 > .ctg::after {
		content: '';
		position: absolute;
		top: 0;
		right: -10px;
		bottom: 0;
		@include margin(auto 0);
		height: 80px;
		border-right: $borderDot;
	}

	.first:last-child > .ctg::after {
		content: '';
		position: absolute;
		top: 0;
		right: -10px;
		bottom: 0;
		@include margin(auto 0);
		height: 80px;
		border-right: $borderDot;
	}

	.first > .second {
		display: flex;
		position: absolute;
		top: 100px;
		left: 0;
		right: 0;
		padding: 20px 10px;
		width: $baseWidth;
		background: rgba(70,70,70,.9);
		text-align: left;
		@include z-index(default);
		cursor: default !important;
	}
	.first.-bkinfo > .second {
		background: rgba(252,252,247,.98);
	}

	.first > .second > a {
		@include margin(0 10px);
		width: 248px;
		text-align: center;
		@include link-white;
		> .ttl {
			margin-top: 5px;
			font-size:  $fs_l;
			line-height: $lh_s;
		}
	}
	.first.-bkinfo > .second > a {
		@include link-black;
	}
	.first.-bkinfo > .second.-hotitems > a {
		width: 198px;
	}

	.first > .second > .list {
		margin-left: 40px;
		> a {
			position: relative;
			display: table;
			@include margin(20px 0);
			width: auto;
			text-align: left;
			@include link-white;
			> .ttl {
				margin-top: 0;
				padding-right: 15px;
				font-size:  $fs_l;
				line-height: 1;
			}
			&::before {
				@include position-center-tb;
				@include icon-bfaf;
				content: $icon-arrow-right;
				right: 0;
				transition: right .1s;
				font-size:  1rem;
			}
			@include hover() {
				&::before {
					right: -5px;
				}
			}
		}//a
	}//list
	.first > .second.-ebook > .list,
	.first > .second.-library > .list {
		margin-left: 60px;
		//background-color: aliceblue;
		&.-line {
			margin-left: 10px;
			padding-right: 40px;
			border-right: 1px solid $borderColor;
		}
		&.-genre:nth-of-type(3),&.-genre:nth-of-type(4) {
			padding-top: 27px;
		}
		&.-genre > .title {
			margin-bottom: 15px;
			font-size:  $fs_s;
			color: $titleColor;
			@include bold;
		}
		&.-genre > a > .ttl {font-size:  $fs_m;}
		> a {
			margin: 0 0 15px;
			@include link-black;
			&:last-child {margin: 0;}
			>.ttl {@include bold;}
		}//a
	}//.second.-ebook > .list

	.first > .second.-library > .list {
		&.-main {
			margin-left: 10px;
			padding-right: 27px;
			>.box {
				margin-top: 20px;
				padding-top: 4px;
				border-top: 1px solid $borderColor;
				> a {
					position: relative;
					display: table;
					margin: 15px 0 0;
					width: auto;
					text-align: left;
					@include link-black;
					> .ttl {
						margin-top: 0;
						padding-right: 15px;
						font-size:  $fs_m;
						line-height: 1;
						@include bold;
					}
					&::before {
						@include position-center-tb;
						@include icon-bfaf;
						content: $icon-arrow-right;
						right: 0;
						transition: right .1s;
						font-size:  1rem;
					}
					@include hover() {
						&::before {
							right: -5px;
						}
					}
				}//a
			}//box
		}//main
	}//.second.-library > .list

	.first > .second.-neo > .neo-list {
		margin: 0 10px;
		width: $fullWidth;
		@include flex-between;
		align-items: flex-end;
		> li {
			width: 240px;
			&:last-child {width: 210px;}
			> .title {
				margin-bottom: 10px;
				color: $titleColor;
				@include bold;
			}
			> a {
				display: flex;
				align-items: center;
				@include link-black;
				> img {
					margin-right: 10px;
					width: auto;
					height: 108px;
					//box-shadow: 3px 3px 6px 0px #a5a5a5;
					//&:last-child {box-shadow: none;}
				}
				> .ttl {
					line-height: $lh_m;
					@include bold;
					> span {
						display: block;
						margin-top: 5px;
						font-size:  $fs_s;
						color: $titleColor;
						@include normal;
					}
				}//ttl
			}//a
		}//li
	}//neo-list

//	.first > .second.-library {display: flex !important;}

	.first {
		@include hover {
			cursor: pointer;
			.ctg {
				background: $about-hoverColor;
				opacity: 1;
				> .ttl {color: $about-iconColor;}
			}
		}
	}//first

	[class^="icon2-"] {
		display: inline-block;
		&::before {
			color: $about-iconColor;
		}
	}
	.icon2-contest {
		width: 48px;
		height: 46px;
		&::after {font-size: 48px;}
		&::before {font-size: 48px;}
	}
	.icon2-publi-work {
		top: 6px;
		left: 2px;
		width: 34px;
		height: 46px;
		&::after {font-size: 34px;}
		&::before {font-size: 34px;}
	}
	.icon2-publi-self {
		top: 3px;
		width: 44px;
		height: 46px;
		&::after {font-size: 44px;}
		&::before {font-size: 44px;}
	}
	.icon2-publi-guide {
		top: 4px;
		width: 40px;
		height: 46px;
		&::after {font-size: 40px;}
		&::before {font-size: 40px;}
	}
	.icon2-contact {
		width: 46px;
		height: 46px;
		&::after {font-size: 46px;}
		&::before {font-size: 46px;}
	}

	.icon2-hot {
		top: 1px;
		width: 45px;
		height: 46px;
		&::after {font-size: 45px;}
		&::before {font-size: 45px;}
	}
	.icon2-neo {
		top: 5px;
		width: 37px;
		height: 46px;
		&::before {font-size: 37px;}
	}
	.icon2-picturebook {
		top: 4px;
		width: 38px;
		height: 46px;
		&::after {font-size: 38px;}
		&::before {font-size: 38px;}
	}
	.icon2-ebook {
		top: 4px;
		width: 38px;
		height: 46px;
		&::after {font-size: 38px;}
		&::before {font-size: 38px;}
	}
	.icon2-library {
		top: 4px;
		width: 38px;
		height: 46px;
		&::after {font-size: 38px;}
		&::before {font-size: 38px;}
	}

	.icon2-company {
		top: 4px;
		width: 38px;
		height: 46px;
		&::after {font-size: 38px;}
		&::before {font-size: 38px;}
	}
	.icon2-philosophy {
		top: 4px;
		width: 40px;
		height: 46px;
		&::after {font-size: 40px;}
		&::before {font-size: 40px;}
	}
	.icon2-commitment {
		top: 5px;
		width: 36px;
		height: 46px;
		&::before {font-size: 36px;}
	}

	.sp-drawer {display: none;}
}//menu-mgdr


/* ===================================================================
	header.smaller
=================================================================== */
header.smaller {
	padding: 0;
	height: 69px;
	border-bottom: $borderSol;
	background: rgba(255, 255, 255, 0.9);

	.header-inner {width: $baseWidth;}

	.global-header {
		position: relative;
		margin: 0;
		height: 68px;
		> .logo {top: 15px;}
		> .lead {top: -120px;}
		> .tel-box {top: 15px;}
		.global-search {top: -100px;}
		> .global-nav {top: 18px;}
	}//global-header

	.header-nav {
		position: absolute;
		top: 0;
		right: 0;
		width: 890px;
		height: 100%;
		box-shadow: none;
	}//header-nav

	.menu-mgdr {
		width: $fullWidth;

		.first {
			margin: 0;
			padding: 5px 10px;
			height: 68px;
		}

		.first > .ctg {
			height: 58px;
			&::before {height: 58px;}

			&.-current {
				background: $about-hoverColor;
			}

			> .ttl {
				@include margin(-5px 0 0);
				font-size: 1.2rem;
			}
			> .eng {display: none;}
		}

		.first.-bkinfo#bkinfo05 > .ctg::after,
		.first:last-child > .ctg::after {height: 58px;}

		.first > .second {
			top: 68px;
			left: -190px;
		}

		.first {
			@include hover {
				.ctg {
					background: $about-hoverColor;
					opacity: 1;
				}
			}
		}

//icon
	.icon2-contest {top: -2px;}
	.icon2-publi-work {top: 3px;}
	.icon2-publi-self {top: 0;}
	.icon2-publi-guide {top: 1px;}
	.icon2-hot {top: -3px;}
	.icon2-neo {top: 3px;}
	.icon2-picturebook {top: 1px;}
	.icon2-ebook {top: 1px;}
	.icon2-library {top: 1px;}
	.icon2-company {top: 2px;}
	.icon2-philosophy {top: 1px;}
	.icon2-commitment {top: 3px;}
	}//menu-mgdr
}//header.smaller

header.mgdr-publi.smaller,
header.mgdr-bkinfo.smaller,
header.mgdr-about.smaller {
	.global-header {
		> .tel-box {top: -100px;}
		> .global-nav {top: -100px;}
	}//global-header

}//header.mgdr-**.smaller

header.mgdr-publi.smaller .header-nav {display: block;}
header.mgdr-bkinfo.smaller .header-nav {display: block;}
header.mgdr-about.smaller .header-nav {display: block;}


/* ===================================================================
	header-navsp
=================================================================== */
.header-navsp {display: none;}

/* ============================================================
	drawer
.first.-bkinfo {display: none;}
============================================================ */



/* ============================================================
	header.mgdr-publi
============================================================ */
header.mgdr-publi {
	.menu-mgdr [class^="icon2-"] {
		&::before {
			color: $publi-iconColor;
		}
	}

	.menu-mgdr .first {
		> .ctg {
			&.-current {
				background: $publi-hoverColor;
				> .ttl {color: $publi-iconColor}
			}
			> .eng {
				color: $publi-iconColor;
			}
		}//ctg

		@include hover {
			.ctg {
				background: $publi-hoverColor;
				> .ttl {color: $publi-iconColor;}
			}
		}
	}//first

	.first.-bkinfo,
	.first.-about {display: none;}
}


/* ============================================================
	header.mgdr-bkinfo
============================================================ */
header.mgdr-bkinfo {
	.menu-mgdr [class^="icon2-"] {
		&::before {
			color: $bkinfo-iconColor;
		}
	}

	.menu-mgdr .first {
		> .ctg {
			&.-current {
				background: $bkinfo-hoverColor;
				> .ttl {color: $bkinfo-iconColor}
			}
			> .eng {
				color: $bkinfo-iconColor;
			}
		}//ctg

		@include hover {
			.ctg {
				background: $bkinfo-hoverColor;
				> .ttl {color: $bkinfo-iconColor;}
			}
		}
	}//first

	.first.-publi,
	.first.-about,
	.first.-contact {display: none;}
}


/* ============================================================
	header.mgdr-about
============================================================ */
header.mgdr-about .first.-publi,
header.mgdr-about .first.-bkinfo {display: none;}


/* ========================================================= */
}//media(ln)


/* ============================================================
	header-simple
============================================================ */
.header-simple {
	position: fixed;
	top: 0;
	padding: 4px 0 0;
	width: $fullWidth;
	background: rgba(255, 255, 255, 0.9);
	border-bottom: $borderSol;
	@include z-index(header);

	@include media(xlx) {
		width: $baseWidthPlus;
	}

	@include media(lx) {
		margin-bottom: 0;
		padding: 0;
		width: $fullWidth;
		height: 56px;
	}

	> .global-header {
		position: relative;
		@include margin;
		width: $baseWidth;
		height: 70px;

		@include media(lx) {
			background: none;
			width: $fullWidth;
			height: 56px;
			> .tel-box {display: none;}
		}
	}//global-header

}//header-simple

