@charset "utf-8";

//IEハック ------------------------------------------------------------------------------------------
@mixin ie-hack($IE-ver: null) {
	@if $IE-ver == 'gteIE11' { // IE11以上
		@at-root _:-ms-fullscreen,:root & {
			@content;
		}
	}
}
//IEハック ------------------------------------------------------------------------------------------

@mixin margin($size: 0 auto) {
	margin: $size;
}

@mixin margin-sp($mt: 0, $mb: 0) {
	margin: $mt 3% $mb;
	width: $spWidth;
}

@mixin padding-sp($size: 0) {
	padding: $size 3%;
	width: $fullWidth;
}

@mixin radius($size: 5) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
	background-color: $gray;
	color: $white;
}

@mixin transition {
	transition: all .3s;
}

@mixin opacity($opacity: .7) {
	opacity: $opacity;
}

@mixin media($breakpoint) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}

@mixin z-index($key) {
	z-index: map-get($layer, $key);
}

@mixin indent {
	padding-left: 1em;
	text-indent: -1em;
	text-align: left;
}

@mixin box-shadow {
	box-shadow: 0 0 8px $borderColor;
}
@mixin box-shadow-hov {
	box-shadow: 0 0 8px $textColor-light;
}

@mixin txt {
	@include fs-14xl;
	@include media(lx) {
		@include fs-14l;
	}
}

@mixin label {
	padding: 3px;
	@include ie-hack(gteIE11) {padding-top: 7px;}
	@include radius(3);
	text-align: center;
	border: 1px solid $news-iconColor;
	color: $news-iconColor;
	white-space: nowrap;
	&.-c02 {border: 1px solid #7b76b4;color: #7b76b4;}//読む＆書く
	&.-c03 {border: 1px solid $publi-iconColor;color: $publi-iconColor;}//コンテスト, publi配下
	&.-c04 {border: 1px solid #6ba25a;color: #6ba25a;}//キャンペーン
	&.-c05 {border: 1px solid #0aa59b;color: #0aa59b;}//イベント
	&.-c06 {border: 1px solid $bkinfo-baseColor;color: $bkinfo-baseColor;}//書籍紹介, bkinfo配下
	&.-c07 {border: 1px solid #ba4986;color: #ba4986;}//増刷情報
	&.-c08 {border: 1px solid #4492c4;color: #4492c4;}//メディア掲載
}


//font-size ------------------------------------------------------------------------------------------
@mixin fs-10s {font-size: $fs_xs;line-height: $lh_s;}
@mixin fs-10m {font-size: $fs_xs;line-height: $lh_m;}
@mixin fs-12s {font-size: $fs_s;line-height: $lh_s;}
@mixin fs-12m {font-size: $fs_s;line-height: $lh_m;}
@mixin fs-12l {font-size: $fs_s;line-height: $lh_l;}
@mixin fs-12xl {font-size: $fs_s;line-height: $lh_xl;}
@mixin fs-12xxl {font-size: $fs_s;line-height: $lh_xxl;}
@mixin fs-14s {font-size: $fs_m;line-height: $lh_s;}
@mixin fs-14m {font-size: $fs_m;line-height: $lh_m;}
@mixin fs-14l {font-size: $fs_m;line-height: $lh_l;}
@mixin fs-14xl {font-size: $fs_m;line-height: $lh_xl;} //1.4remの基本
@mixin fs-14xxl {font-size: $fs_m;line-height: $lh_xxl;}
@mixin fs-16s {font-size: $fs_l;line-height: $lh_s;}
@mixin fs-16m {font-size: $fs_l;line-height: $lh_m;}
@mixin fs-16l {font-size: $fs_l;line-height: $lh_l;} //1.6remの基本
@mixin fs-16xl {font-size: $fs_l;line-height: $lh_xl;} //PC版 総合リード文
@mixin fs-16xxl {font-size: $fs_l;line-height: $lh_xxl;}
@mixin fs-18s {font-size: $fs_xl;line-height: $lh_s;}
@mixin fs-18m {font-size: $fs_xl;line-height: $lh_m;}
@mixin fs-18l {font-size: $fs_xl;line-height: $lh_l;}
@mixin fs-18xl {font-size: $fs_xl;line-height: $lh_xl;}
@mixin fs-18xxl {font-size: $fs_xl;line-height: $lh_xxl;}
@mixin fs-20s {font-size: $fs_xxl;line-height: $lh_s;} //SP版 総合リードタイトル文
@mixin fs-20m {font-size: $fs_xxl;line-height: $lh_m;}
@mixin fs-20l {font-size: $fs_xxl;line-height: $lh_l;}
@mixin fs-20xl {font-size: $fs_xxl;line-height: $lh_xl;}
@mixin fs-20xxl {font-size: $fs_xxl;line-height: $lh_xxl;}


//hover ------------------------------------------------------------------------------------------
@mixin hover($tap-highlight: false) {
	@if $tap-highlight == false {
		&.hover {
			background-color: transparent;
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			@content;
		}
	} @else {
		&.hover {
			@content;
		}
	}
	@include media(ln) {
		&:hover {
			@content;
		}
	}
}


//flex ------------------------------------------------------------------------------------------
@mixin flex {
	display: flex;
}
//flex:段落ち無し中央配置
@mixin flex-center {
	@include flex;
	justify-content: center;
	align-items: center;
}
//flex:段落ち有り
@mixin flex-wrap {
	@include flex;
	flex-wrap: wrap;
}
//flex:2要素-左右寄せ
@mixin flex-between {
	@include flex;
	justify-content: space-between;
}
//以下は＠include flexと一緒に使う
//flex:右から左
@mixin flex-row {
	flex-direction: row-reverse;
}
//flex:上下のみ中央配置
@mixin flex-center-tb {
	align-items: center;
}
//flex:左右のみ中央配置
@mixin flex-center-lr {
	justify-content: center;
}

//flex-style ------------------------------------------------------------------------------------------
@mixin pcflexbw-wrap {
	@include flex-between;
	> .item {width: auto;}
	@include media(lx) {
		display: block;
		> .item {width: $fullWidth;margin-top: 20px;}
	}
}


//link-style (text) --------------------------------------------------------
@mixin link-text {
	text-decoration: underline;
	@include hover {text-decoration: none;}
}//link-text

//link-style (text-decoration: none;visited-color) --------------------------------------------------------
@mixin link-black {
	color: $textColor;
	&:visited {color: $textColor;}
	@include hover {color: $textColor;text-decoration: none;}
}//link-black

@mixin link-gray {
	color: $textColor-light;
	&:visited {color: $textColor-light;}
	@include hover {color: $textColor-light;text-decoration: none;}
}//link-gray

@mixin link-white {
	color: $white;
	&:visited {color: $white;}
	@include hover {color: $white;text-decoration: none;}
}//link-white

@mixin link-title {
	color: $titleColor;
	&:visited {color: $titleColor;}
	@include hover {color: $titleColor;text-decoration: none;}
}//link-title

@mixin link-base {
	color: $baseColor;
	&:visited {color: $baseColor;}
	@include hover {color: $baseColor;text-decoration: none;}
}//link-base

@mixin link-publi {
	color: $publi-baseColor;
	&:visited {color: $publi-baseColor;}
	@include hover {color: $publi-baseColor;text-decoration: none;}
}//link-title


//position ------------------------------------------------------------------------------------------
//position:上下のみ中央配置
@mixin position-center-tb {
	position: absolute;
	top: 50%;
	transform: translate(0,-50%);
}
//position:左右のみ中央配置
@mixin position-center-lr {
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
}


//icon-base ------------------------------------------------------------------------------------------
@mixin icon-bfaf($right: 20px, $fs: $fs_s) {
	font-family: '#{$icomoon-font-family}' !important;
	display: inline-block;
	right: $right;
	font-size: $fs;
}

@mixin icon-bfaf-left($left: 20px, $fs: $fs_s) {
	font-family: '#{$icomoon-font-family}' !important;
	display: inline-block;
	right: auto;
	left: $left;
	font-size: $fs;
}


//btn ------------------------------------------------------------------------------------------
@mixin btn {
	display: inline-block;
	position: relative;
	padding: 20px 50px 20px 40px;
	@include ie-hack(gteIE11) {padding-top: 27px;}
	color: $white;
	font-size: $fs_xl;
	line-height: $lh_m;
	text-align: center;
	@include radius(8);
	@include transition;
	cursor: pointer;
	text-decoration: none !important;
	background-color: $baseColor;
	border: 1px solid $baseColor;
	@include media(lx) {
		padding: 10px 30px 10px 20px;
		@include ie-hack(gteIE11) {padding-top: 17px;}
		font-size: $fs_l;
	}

	&:visited {color: $white;}

	&::before {
		@include position-center-tb;
		@include icon-bfaf;
		content: $icon-arrow-right;
		transition: right .1s;
		@include media(lx) {
			right: 10px;
		}
	}

	@include hover() {
		opacity: 1;
		background-color: $white;
		color: $baseColor;
		text-decoration: none !important;
		&::before {
			right: 15px;
			@include media(lx) {
				right: 8px;
			}
		}
	}
}//btn

@mixin btn-publi {
	@include btn;
	background-color: $publi-baseColor;
	border: 1px solid $publi-baseColor;

	@include hover() {
		background-color: $white;
		color: $publi-baseColor;
	}
}//btn-publi

@mixin btn-bkinfo {
	@include btn;
	background-color: $bkinfo-baseColor;
	border: 1px solid $bkinfo-baseColor;

	@include hover() {
		background-color: $white;
		color: $bkinfo-baseColor;
	}
}//btn-bkinfo

@mixin btn-back {
	display: inline-block;
	position: relative;
	margin-right: 25px;
	padding: 20px 40px 20px 50px;
	@include ie-hack(gteIE11) {padding-top: 27px;}
	color: $white;
	font-size: $fs_xl;
	line-height: $lh_m;
	text-align: center;
	@include radius(8);
	@include transition;
	cursor: pointer;

	background-color: $gray;
	border: 1px solid $gray;

	@include media(lx) {
		margin-right: 15px;
		padding: 10px 20px 10px 30px;
		@include ie-hack(gteIE11) {padding-top: 17px;}
		font-size: $fs_l;
	}

	&:visited {color: $white;}

	&::before {
		@include position-center-tb;
		@include icon-bfaf-left;
		content: $icon-arrow-left;
		transition: left .1s;
		@include media(lx) {
			left: 10px;
		}
	}

	@include hover() {
		opacity: 1;
		background-color: $white;
		color: $darkgray;
		text-decoration: none;
		&::before {
			left: 15px;
			@include media(lx) {
				left: 8px;
			}
		}
	}
}//btn-back

//btnw (white) ------------------------------------------------------------------------------------------
@mixin btnw {
	display: inline-block;
	position: relative;
	padding: 20px 50px 20px 40px;
	@include ie-hack(gteIE11) {padding-top: 27px;}
	color: $baseColor;
	font-size: $fs_xl;
	line-height: $lh_m;
	text-align: center;
	@include radius(8);
	@include transition;
	cursor: pointer;
	background-color: $white;
	border: 1px solid $baseColor;
	@include media(lx) {
		padding: 10px 35px 10px 15px;
		@include ie-hack(gteIE11) {padding-top: 17px;}
		font-size: $fs_l;
	}

	&:visited {color: $baseColor;}

	&::before {
		@include position-center-tb;
		@include icon-bfaf;
		content: $icon-arrow-right;
		transition: right .1s;
		@include media(lx) {
			right: 10px;
		}
	}

	@include hover() {
		opacity: 1;
		background-color: $baseColor;
		color: $white;
		text-decoration: none;
		&::before {
			right: 15px;
			@include media(lx) {
				right: 8px;
			}
		}
	}
}//btnw

@mixin btnw-publi {
	@include btnw;
	color: $publi-baseColor;
	border: 1px solid $publi-baseColor;
	&:visited {color: $publi-baseColor;}
	@include hover() {
		background-color: $publi-baseColor;
		color: $white;
	}
}//btn-publi

@mixin btnw-bkinfo {
	@include btnw;
	color: $bkinfo-baseColor;
	border: 1px solid $bkinfo-baseColor;
	&:visited {color: $bkinfo-baseColor;}
	@include hover() {
		background-color: $bkinfo-baseColor;
		color: $white;
	}
}//btn-bkinfo


//accordion-btn ------------------------------------------------------------------------------------------
@mixin plus {
	@include position-center-tb;
	@include icon-bfaf($fs: $fs_l);
	content: $icon-plus;
}
@mixin minus {
	@include position-center-tb;
	@include icon-bfaf($fs: $fs_l);
	content: $icon-minus;
}
@mixin arrow-right {
	@include position-center-tb;
	@include icon-bfaf();
	content: $icon-arrow-right;
}
@mixin arrow-down {
	position: absolute;
	top: 58%;
	transform: translate(0,-58%);
	@include icon-bfaf($right: 10px);
	content: $icon-arrow-down;
}
@mixin dl {
	@include position-center-tb;
	@include icon-bfaf($fs: $fs_l);
	content: $icon-download;
}
@mixin anker {
	@include position-center-tb;
	@include icon-bfaf();
	content: $icon-arrow-down;
}
@mixin anker2 {
	@include position-center-tb;
	@include icon-bfaf();
	content: $icon-arrow-open;
}
@mixin blank {
	@include position-center-tb;
	@include icon-bfaf($fs: $fs_l);
	content: $icon-blank;
}


//font-family ------------------------------------------------------------------------------------------
@mixin bold {
	font-weight: 700;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@mixin bold600 {
	font-weight: 600;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@mixin normal {
	font-weight: 500;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: unset;
}
@mixin eng {
	font-family: Avenir, 'Segoe UI', 'Lato', sans-serif;
	font-weight: 400;
}
@mixin jpn {
	font-family: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif;
}

//Lato:400,700
@mixin gfont-Lato700 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
}
@mixin gfont-Lato400 {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
}


//txt-deco borderwhite------------------------------------------------------------------------------------------
@mixin txt-deco_bdw {
	text-shadow:
	white 2px 0px 2px, white -2px 0px 2px,
	white 0px -2px 2px, white -2px 0px 2px,
	white 2px 2px 2px, white -2px 2px 2px,
	white 2px -2px 2px, white -2px -2px 2px,
	white 1px 2px 2px, white -1px 2px 2px,
	white 1px -2px 2px, white -1px -2px 2px,
	white 2px 1px 2px, white -2px 1px 2px,
	white 2px -1px 2px, white -2px -1px 2px,
	white 1px 1px 2px, white -1px 1px 2px,
	white 1px -1px 2px, white -1px -1px 2px;
}

//txt-deco borderwhite & shadowblack ------------------------------------------------------------------------------------------
@mixin txt-deco_bdw_sdb {
	text-shadow: 
	white 2px 0px 2px, white -2px 0px 2px,
	white 0px -2px 2px, white -2px 0px 2px,
	white 2px 2px 2px, white -2px 2px 2px,
	white 2px -2px 2px, white -2px -2px 2px,
	white 1px 2px 2px, white -1px 2px 2px,
	white 1px -2px 2px, white -1px -2px 2px,
	white 2px 1px 2px, white -2px 1px 2px,
	white 2px -1px 2px, white -2px -1px 2px,
	white 1px 1px 2px, white -1px 1px 2px,
	white 1px -1px 2px, white -1px -1px 2px,
	0 3px 10px rgba(0,0,0,0.7);
}


//publishing ------------------------------------------------------------------------------------------
@mixin publitop-h3ttl {
	position: relative;
	width: $fullWidth;
	color: $publi-baseColor;
	font-size: $fs_xxl;
	@include bold;
	text-align: center;
	@include transition;
	@include media(lx) {
		font-size: $fs_l;
	}

	.small {
		font-size: $fs_m;
		@include bold;
	}
	> .eng {
		display: block;
		margin-top: 12px;
		color: $titleColor;
		font-size: $fs_m;
		@include eng;
		font-style: italic;
		@include media(lx) {
			margin-top: 8px;
			font-size: $fs_s;
		}
	}
}//publitop-h3ttl
