@charset "utf-8";

/* ============================================================
	bookinfo-top
============================================================ */
.bkinfotop-main {
	width: $fullWidth;
	overflow-x: hidden;
	text-align: center;
	@include media(lx) {
		width: $fullWidth;
		height: auto;
	}

	a {
		position: relative;
		display: inline-block;
		width: 624px;
		height: 260px;
		@include media(lx) {
			width: $fullWidth;
			height: auto;
		}
		&.link-blank {
			&::before {
				position: absolute;
				bottom: 10px;
				@include icon-bfaf($right: 10px, $fs: $fs_l);
				content: $icon-blank;
				@include media(lx) {font-size: $fs_m;}
			}
		}//link-blank::before
		@include hover() {color: $textColor-hover !important;}
	}//a
}//bkinfo-main


/* ============================================================
	bkinfotop-newbooks
============================================================ */
.bkinfotop-newbooks {
	margin: 80px auto 30px;
	position: relative;
	width: $baseWidth;
	@include media(lx) {
		@include margin-sp(40px, 80px);
	}

	> .ttl-box {
		margin-bottom: 30px;
		@include flex-between;
		@include flex-center-tb;
		position: relative;
		color: $baseColor;
		> .ttl {
			@include flex;
			@include flex-center-tb;
			font-size: 3.6rem;
			@include eng;
			@include media(lx) {font-size: 2.8rem;}
			> span {
				display: block;
				padding-right: 20px;
				border-right: $borderSol-baseC1;
				@include media(lx) {padding-right: 15px;}
				&.jpn {
					@include ie-hack(gteIE11) {padding-top: 7px;}
					padding-left: 20px;
					border: none;
					font-size: 2rem;
					font-style: normal;
					@include media(lx) {
						padding-left: 15px;
						font-size: $fs_l;
					}
				}
			}
		}//ttl
		> .link-box {
			margin-top: 0;
			> .link-arrow {
				font-size: $fs_l;
				@include ie-hack(gteIE11) {padding-top: 7px;}
			}
		}
	}//ttl-box

	> .book-box {
		@include flex-wrap;
		width: $fullWidth;
	}
	> .book-box > .book-area {
		margin-bottom: 50px;
		@include media(lx) {margin-bottom: 0;}

		> .ttl-link {
			margin-bottom: 20px;
			padding: 10px 12px;
			display: block;
			width: $fullWidth;
			background-color: $baseColor-titbg;
			border-radius: 5px;
			text-decoration: none !important;
			color: $baseColor;
			@include media(lx) {margin-bottom: 10px;}
			> .ttl {
				display: inline-block;
				position: relative;
				margin-right: 5px;
				padding-left: 10px;
				padding-right: 15px;
				@include ie-hack(gteIE11) {padding-top: 7px;}
				font-size: $fs_l;
				line-height: $lh_s;
				@include bold;
				border-left: 2px solid $baseColor;
				&::before {
					@include position-center-tb;
					@include icon-bfaf;
					content: $icon-arrow-right;
					right: 0;
					font-size: $fs_xs;
					transition: right .1s;
					@include media(lx) {padding-top: .1rem;}
				}
			}//ttl
			@include hover() {
				> .ttl::before {
					right: -5px;
				}
			}
		}//ttl-link

		> .list {
			@include flex-wrap;
			width: $fullWidth;
			> li {
				display: block;
				@include media(lx) {
					margin-bottom: 30px;
					padding: 0 5px;
				}
				> a {
					display: block;
					> p {
						margin: 0;
						&.thumb {
							@include flex-center;
							@include media(lx) {
								height: auto;
								min-height: 230px;
							}
							@include media(xxsx) {min-height: 210px;}
							img {
								width: auto;
								border: 1px solid $borderColor;
								@include media(lx) {
									width: 100%;
									max-width: 160px;
								}
							}
						}//thumb
						&.ttl {
							margin: 10px 0 8px;
							@include bold;
							line-height: $lh-m;
							text-align: left;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 3;
							overflow: hidden;
							max-height: calc(1.4em * 3);
							word-break: break-all;
							@include media(lx) {
								margin: 8px auto;
								max-width: 160px;
							}
						}
						&.name {
							font-size: $fs_s;
							line-height: $lh-s;
							@include media(lx) {
								margin: 0 auto;
								max-width: 160px;
							}
						}
					}//p
					@include hover {
						@include opacity;
						text-decoration: none;
					}//hover
				}//a
			}//li
		}//list

		&.-column1 {
			width: $fullWidth;
			> .list > li {
				@include media(ln) {
					padding: 0 25px;
					width: 188px;
					&:nth-of-type(1) {padding: 0 25px 0 1px;width: 164px;}
					&:nth-of-type(6) {padding: 0 1px 0 25px;width: 164px;}
				}
				@include media(lx) {width: 25%;}
				@include media(mx) {width: 50%;}
				> a > p {
					&.thumb {
						@include media(ln) {height: 194px;}
						img {
							@include media(ln) {
								max-width: 140px;
								max-height: 194px;
							}
						}
					}
				}//a p
			}//li
		}//column1

		&.-column4 {
			//margin-right: calc(80px / 3);
			margin-right: 26.66px;
			&:nth-of-type(6),&:nth-of-type(10) {margin-right: 0;}
			width: 250px;
			@include media(lx) {
				margin-right: 2%;
				&:nth-child(even) {margin-right: 0;}
				width: 49%;
			}
			@include media(mx) {
				margin-right: 0;
				width: 100%;
			}
			> .list > li {
				@include media(ln) {
					&:nth-of-type(1) {padding-right: 11px;width: 125px;}
					&:nth-of-type(2) {padding-left: 11px;width: 125px;}
				}
				@include media(lx) {width: 50%;}
				> a > p {
					&.thumb {
						@include media(ln) {height: 162px;}
						img {
							@include media(ln) {
								max-width: 114px;
								max-height: 162px;
							}
						}
					}
				}//a p
			}//li
		}//column4

	}//book-area

	> .btn-box {
		display: none;
		@include media(lx) {display: block;}
	}
}//bkinfotop-newbooks


/* ============================================================
	bkinfotop-topics
============================================================ */
.bkinfotop-topics {
	margin: 0 auto 80px;
	position: relative;
	width: $baseWidth;
	@include media(lx) {
		@include margin-sp(0, 60px);
	}

	> .ttl-box {
		margin: 0 auto 20px;
		@include flex-between;
		@include flex-center-tb;
		position: relative;
		color: $baseColor;
		> .ttl {
			@include flex-center;
			font-size: 3.6rem;
			@include eng;
			@include media(lx) {font-size: 2.8rem;}
			> span {
				display: block;
				padding-right: 20px;
				border-right: $borderSol-baseC1;
				@include media(lx) {padding-right: 15px;}
				&.jpn {
					@include ie-hack(gteIE11) {padding-top: 7px;}
					padding-left: 20px;
					border: none;
					font-size: 2rem;
					font-style: normal;
					@include media(lx) {
						padding-left: 15px;
						font-size: $fs_l;
					}
				}
			}
		}//ttl
		> .link-box {
			margin-top: 0;
			> .link-arrow {font-size: $fs_l;}
		}
	}//ttl-box

}//bkinfotop-topics


/* ============================================================
	bkinfotop-twitter
============================================================ */
.bkinfotop-twitter {
	margin: 0 auto 80px;
	padding: 0 100px;
	position: relative;
	width: $baseWidth;
	@include flex;
	@include media(lx) {
		@include margin-sp(0, 60px);
		padding: 0;
	}
	@include media(mx) {
		display: block;
	}
	> .twitter-box {
		margin: 0 auto;
		width: 402px;
		border: 1px solid $borderColor;
		border-radius: 5px;
		@include media(lx) {width: 45%;}
		@include media(mx) {
			width: $fullWidth;
			max-width: 402px;
			&:first-child {margin-bottom: 30px;}
		}
	}//twitter-box
	@include ie-hack(gteIE11) {display: none;}
}//bkinfotop-twitter