@charset "utf-8";

/* ============================================================
	publishing-swiper
============================================================ */
.publitop-main {

	>.container {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		max-width: 1080px;
		margin-bottom: 30px;
	}

//swiper.js
	.swiper-container {
		width: 100%;
		height: 100%;
		overflow: visible;
		z-index: 1;
	}

	.swiper-pagination {
		bottom: -25px !important;
		left: 50%;
		transform: translate(-50%, 0);
	}
	.swiper-pagination-bullet {
		margin: 0 5px;
		width: 12px;
		height: 12px;
		@include transition;
		@include hover() {
			opacity: .2;
		}
	}
	.swiper-pagination-bullet-active {
		background: $publi-baseColor;
	}

	.swiper-slide-prev a,
	.swiper-slide-next a {
		pointer-events: none;
	}
	.swiper-slide-prev a img,
	.swiper-slide-next a img {
		opacity: .2;
	}

	.swiper-button-next,
	.swiper-button-prev {
		margin-top: 0;
		background: none;
		background: $white;
		width: 40px;
		height: 144px;
		transform: translate(0,-50%);
		@include transition;
		@include hover() {
			@include opacity;
		}
	}
	.swiper-button-next {
		border-top-right-radius : 10px;
		border-bottom-right-radius : 10px;
		&::before {
			@include position-center-tb;
			@include icon-bfaf($right: 0, $fs: 4rem);
			content: $icon-arrow-right;
			color: $textColor-light;
		}
	}
	.swiper-button-prev {
		border-top-left-radius : 10px;
			border-bottom-left-radius : 10px;
		&::before {
			@include position-center-tb;
			@include icon-bfaf($right: 0, $fs: 4rem);
			content: $icon-arrow-left;
			color: $textColor-light;
		}
	}

	.swiper-button-next, .swiper-container-rtl .swiper-button-prev {right: -40px;}
	.swiper-button-prev, .swiper-container-rtl .swiper-button-next {left: -40px;}

}//publitop-main