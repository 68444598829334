@charset "utf-8";

/* ============================================================
	footer
============================================================ */
footer {
	padding-top: 50px;
	width: $fullWidth;
	background: $gray-fc;
	@include media(xlx) {
		width: $baseWidthPlus;
	}
	@include media(lx) {
		padding: 0;
		width: $fullWidth;
	}
}//footer

.global-footer {
	@include media(ln) {
		@include flex;
	}
	@include margin;
	width: $baseWidth;
	@include media(lx) {
		width: $fullWidth;
	}
}//global-footer

.footer-item {
	margin-right: 15px;
	width: 140px;

	> .logo {margin-bottom: 35px;}
	> .sns-ttl {
		margin-bottom: 10px;
		font-size: $fs_m;
		@include bold;
	}
	> .sns-list {
		@include flex;
		> li {
			margin-right: 10px;
			width: 26px;
			height: 26px;
			&:last-of-type {margin-right: 0;}
		}
	}

	@include media(lx) {
		padding: 30px 0;
		width: $fullWidth;
		text-align: center;
		> .logo, > .sns-ttl {display: none;}
		> .sns-list {
			@include flex-center;
		}
	}
}//footer-item

.footer-nav {
	margin-left: 25px;
	@include media(lx) {
		margin: 0;
		width: $fullWidth;
	}

	> .ctg,
	> .acc-area .ctg {
		position: relative;
		margin-bottom: 5px;
		line-height: $lh_xl;
		@include media(lx) {
			line-height: 1;
			margin-bottom: 0;
		}

		a, label {
			display: inline-block;
		}//a

		&.-lrg a,
		&.-lrg label {
			padding-left: 20px;
			@include bold;
			color: $textColor;
		}
		&.-mdl {
			padding-left: 20px;
			color: $textColor;
			@include bold;
		}
		&.-sml.-sp {
			display: none;
		}
		&.-sml {
			padding-left: 20px;
			font-size: $fs_s;
			a {color: $textColor-light;}
		}

		&.-lrg a::before,
		&.-lrg label::before {
			font-family: '#{$icomoon-font-family}' !important;
			content: $icon-minus_bold;
			position: absolute;
			top: 50%;
			left: 6px;
			transform: translate(0,-50%);
			font-size: $fs_xs;
		}
		&.-lrg.-publi a::before {color: $publi-baseColor;}
		&.-lrg.-bkinfo a::before {color: $bkinfo-baseColor;}
		&.-lrg.-about a::before {color: $baseColor;}
		&.-lrg.-news a::before {color: $news-baseColor;}
		&.-lrg.-publi label::before {color: $publi-baseColor;}
		&.-lrg.-bkinfo label::before {color: $bkinfo-baseColor;}
		&.-lrg.-about label::before {color: $baseColor;}

		@include media(lx) {
			margin: 0;
			a, label {
				display: block;
				padding: 10px 20px 10px 30px !important;
				width: $fullWidth;
				border-top: $borderSol;
			}
			&.-lrg a::before,
			&.-lrg label::before {
				left: 16px;
			}
			&.-lrg a::after,
			&.-lrg label::after {
				@include plus;
				color: $baseColor;
			}
			&.-mdl {border-top: $borderSol;padding: 10px 30px;font-size: $fs_s;}
			&.-sml {padding-left: 0;}
			&.-sml.-sp {display: list-item;}
			&.-sml a::after {
				@include arrow-right;
				color: $baseColor;
			}
			&.-sml.-blank a::after {content: $icon-blank;}
			&.size-sp {display: block !important;}
		}//media(lx)
	}//ctg

	> .acc-area {
			display: block;
			@include media(lx) {
				.ctg {
					height: 0;
					padding: 0;
					overflow: hidden;
					@include opacity(0);
					@include transition;
					&.-mdl {border-top: 0;padding: 0 30px;font-size: $fs_s;}
				}
			}
		}//acc-area

		@include media(lx) {
			> .acc-check:checked + .ctg + .acc-area .ctg {
				height: 33px;
				@include opacity(1);
				&.-mdl {border-top: $borderSol;padding: 10px 30px;font-size: $fs_s;}
			}
			> .acc-check:checked + .ctg label::after {
				@include minus;
			}
		}//media(lx)

	.acc-check {display: none;}
}//footer-nav
.footer-nav.-other > .ctg.-lrg a::after {display: none;}

.footer-btm {
	margin-top: 50px;
	padding: 20px 0;
	width: $fullWidth;
	background: $baseColor;
	text-align: center;
	> .item {
		@include media(ln) {
			@include flex-between;
		}
		@include margin;
		width: $baseWidth;
		font-size: $fs_s;
		color: $white;
		> .copy {
			@include eng;
		}
	}
	a {
		color: $white;
		//text-decoration: none;
	}

	@include media(lx) {
		margin: 0;
		padding: 0;
		> .item {
			width: $fullWidth;
			> .nav {
				@include flex-wrap;
				width: $fullWidth;
				span {display: none;}
				a {
					@include flex-center;
					padding: 15px 10px;
					width: $halfWidth;
					line-height: $lh_m;
					text-align: center;
					border-bottom: $borderSol-white1;
					@include hover {
						@include opacity(1);
					}
				}
				a:nth-child(1), a:nth-child(5) {
					border-right: $borderSol-white1;
				}
			}//nav
			> .copy {
				padding: 20px 0;
				width: $fullWidth;
				text-align: center;
			}
		}
	}
}//footer-btm

/* ============================================================
	footer-simple
============================================================ */
.footer-simple {
	padding: 20px;
	width: $fullWidth;
	background: $baseColor;
	text-align: center;
	> .copy {
		margin: 0 auto;
		font-size: 1.2rem;
		color: $white;
		@include eng;
		width: $baseWidth;
		@include media(lx) {width: $fullWidth;}
	}
}//footer-simple


/* ============================================================
	pagetop
============================================================ */
.pagetop {
	@include z-index(pagetop);
	position: fixed;
	bottom: 120px;
	right: 30px;
	@include media(lx) {
		bottom: 10px;
		right: 10px;
	}

	a {
		position: relative;
		@include circle(80);
		@include flex-center;
		@include opacity(.5);
		padding-top: 30px;
		font-size: $fs_xs;
		@include eng;
		@include link-white;
		cursor: pointer;
		&::before {
			font-family: 'bungeisha' !important;
			content: $icon-arrow-pagetop;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			@include position-center-lr;
			top: 18%;
			font-size: 3rem;
		}
		@include hover() {
			background-color: $gray;
			@include opacity(.3);
		}
		@include media(lx) {
			padding-top: 20px;
			@include circle(60);
			&::before {
				font-size: 2rem;
			}
		}
	}

}//pagetop
