@charset "utf-8";

/* ============================================================
	bookhot-list
============================================================ */
.bookhot-list {
	@include flex-wrap;
	width: $fullWidth;
	> li {
		display: block;
		@include media(ln) {
			padding: 0 25px;
			width: 180px;
		}
		@include media(lx) {
			margin-bottom: 30px;
			padding: 0 5px;
			width: 25%;
		}
		@include media(mx) {width: 33%;}
		@include media(xmx) {width: 50%;}

		> a {
			display: block;
			@include media(lx) {padding: 0 10px;}
			> p {
				margin: 0;
				&.thumb {
					@include flex-center;
					@include media(ln) {height: 180px;}
					@include media(lx) {
						height: auto;
						min-height: 210px;
					}
					@include media(xxsx) {min-height: 180px;}
					img {
						width: auto;
						border: 1px solid $borderColor;
						@include media(ln) {
							max-width: 130px;
							max-height: 180px;
						}
						@include media(lx) {
							width: 100%;
							max-width: 150px;
						}
					}//img
				}//thumb
				&.ttl {
					margin: 10px 0 8px;
					@include bold;
					line-height: $lh-m;
					text-align: left;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					overflow: hidden;
					max-height: calc(1.4em * 3);
					@include media(lx) {
						margin: 8px auto;
						max-width: 160px;
					}
				}//ttl
				&.name {
					font-size: $fs_s;
					line-height: $lh-s;
					@include media(lx) {
						margin: 0 auto;
						max-width: 160px;
					}
				}//name
			}//p
			@include hover {
				@include opacity;
				text-decoration: none;
			}//hover
		}//a

	}//li
}//bookhot-list


/* ============================================================
	search-box
============================================================ */
.search-box {
	@include margin(0 auto);
	padding: 30px 0;
	width: $fullWidth;
	background-color: #fffbf2;
	@include media(lx) {
		padding: 20px 0;
	}
	.category-list {
		@include margin(0 auto);
		width: $baseWidth;
		font-size: 0;
		@include media(lx) {
			@include margin-sp();
		}
		> .ttl {
			margin: 0 0 5px 0;
			font-size: $fs_xl;
			font-weight: 700;
			color: $titleColor;
					@include media(lx) {font-size: $fs_l;}
		}
		.genre {
			display: inline-block;
			margin: 10px 15px 0 0;
			padding: 12px 16px;
			border: 1px solid $baseColor;
			background: #fff;
			font-size: $fs-m;
			color: $baseColor !important;
			text-decoration: none !important;
			min-width: 50px;
			text-align: center;
			@include media(lx) {
				margin: 5px 5px 0 0;
				padding: 8px 4px;
				font-size: $fs-s;
			}
//		&:nth-of-type(1) {margin: 10px 24px 0 0;}
//		&:nth-of-type(2) {margin: 10px 24px 0 0;}
//		&:nth-of-type(3) {margin: 10px 24px 0 0;}
//		&:nth-of-type(4) {margin: 10px 24px 0 0;}
//		&:nth-of-type(5) {margin: 10px 24px 0 0;}
//		&:nth-of-type(6) {margin: 10px 24px 0 0;}
//		&:nth-of-type(7) {margin: 10px 24px 0 0;}
//		&:nth-of-type(8) {margin: 10px 24px 0 0;}
//		&:nth-of-type(9) {margin: 10px 24px 0 0;}
//		&:nth-of-type(10) {margin: 10px 24px 0 0;}
//		&:nth-of-type(11) {margin: 10px 24px 0 0;}
//		&:nth-of-type(12) {margin: 10px 24px 0 0;}
//		&:nth-of-type(13) {margin: 10px 24px 0 0;}
//		&:nth-of-type(14) {margin: 10px 24px 0 0;}
			&.-current {
				background: $baseColor;
				color: #fff !important;
				text-decoration: none !important;
			}
			@include hover {
				background: $baseColor;
				color: #fff !important;
			}//hover
		}//genre

		select.genre-list {
			margin: 5px 0 0;
			padding: 12px 16px;
			width: auto !important;
			max-width: inherit !important;
			height: auto;
			border-radius: 0;
			border: 1px solid $baseColor;
			font-size: $fs-m !important;
			color: $baseColor !important;
			background-image: url(/images/svg/arrow_down.svg);
			background-repeat: no-repeat;
			background-size: 12px 10px;
			background-position: right 10px center;
		}

//		select:focus {
//			box-shadow: 0 0 8px $about-iconColor;
//			border: 1px solid $about-iconColor;
//			background-color: $about-hoverColor;
//		}

	}//category-list
	form + form {margin-top: 20px;}
}//search-box


/* ============================================================
	booksearch-ttl
============================================================ */
.booksearch-ttl {
	display: block;
	padding: 4px 8px;
	border-bottom: $borderSol-baseC2;
	color: $titleColor;
	font-size: $fs_l;
	line-height: $lh_m;
	text-align: left;
	@include media(lx) {
		font-size: $fs_m;
	}
}


/* ============================================================
	booksearch-list
============================================================ */
.booksearch-list {
	display: block;
	margin-bottom: 30px;
	width: $fullWidth;
	> li {
		display: block;
		width: $fullWidth;
		border-bottom: 1px dotted $borderColor;
		&:nth-child(even) {
			background-color: $baseColor-bg;
		}

		> a {
			@include flex;
			@include media(ln) {@include flex-center-tb;}
			width: $fullWidth;
			padding: 20px 25px;
			color: $textColor;
			position: relative;
			@include media(lx) {
				padding: 15px 8px;
			}

			.thumb {
				margin-right: 24px;
				width: 106px;
				text-align: center;
				@include media(lx) {
					margin-right: 15px;
					flex-basis: 106px;
				}
				img {
					width: auto;
					border: 1px solid $borderColor;
					max-width: 106px;
					max-height: 146px;
				}//img
			}//thumb

			.detail {
				width: 900px;
				@include media(lx) {
					width: auto;
					flex-basis: auto;
				}
			}
			.detail > p {
				margin: 0;
				&.booktype {
					margin-bottom: 8px;
					padding: 6px 0;
					@include ie-hack(gteIE11) {padding-top: 7px;}
					@include radius(5);
					width: 80px;
					font-size: $fs_xs;
					color: $white;
					text-align: center;
					white-space: nowrap;
					&.-ebook1 {background-color: $about-iconColor;}//電子版あり
					&.-ebook2 {background-color: $about-baseColor;}//電子版のみ
					&.-pod {background-color: #f3a828;}//POD書籍
					&.-ttz {background-color: #389839;}//T-Time版
					&.-pdf {background-color: #ec423d;}//PDF版
				}//booktype
				&.ttl {
					margin-bottom: 8px;
					font-size: $fs_xl;
					line-height: $lh-m;
					color: $baseColor;
					@include bold;
				}//ttl
				&.name {
					line-height: $lh-m;
					color: $baseColor;
				}//name
				&.txt {
					margin: 20px 0 8px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					overflow: hidden;
					max-height: 1.4em;
					@include media(lx) {
						margin: 15px 0 8px;
						-webkit-line-clamp: 3;
						max-height: calc(1.4em * 3);
					}
				}//txt
				&.other {
					font-size: $fs_s;
					line-height: $lh-l;
					color: $textColor-light;
				}//other
			}//p

			@include hover {
				@include opacity;
				text-decoration: none;
			}//hover
		}//a

	}//li
}//booksearch-list

.booksearch-list.-reprint {
	> li {
		> a {
			display: block;

			.reprint {
				margin-bottom: 10px;
				padding: 10px;
				width: $fullWidth;
				background-color: #fffbf2;
				font-size: $fs_l;
				color: #844700;
				@include bold;
				flex-basis: 100%;
			}

			>.box {
				@include flex;
				@include media(ln) {@include flex-center-tb;}
			}

		}//a
	}//li
}//booksearch-list


/* ============================================================
	search-area
============================================================ */
.search-area {
	@include margin(0 auto);
	width: $baseWidth;
	@include media(lx) {
		@include margin-sp();
	}

	> li {
		&:first-child {
			@include flex-wrap;
		}
		&:last-child {
			@include flex;
		}
		@include flex-center-tb;
		white-space: nowrap;
		width: $baseWidth;
		@include media(lx) {width: $fullWidth;}

		> .ttl {
			margin: 10px 20px 10px 0;
			font-size: $fs_xl;
			font-weight: 700;
			color: $titleColor;
			@include media(lx) {
				margin: 0 20px 5px 0;
				width: $fullWidth;
			}
		}//ttl

//		.label-radio > input[type="radio"]:checked + .txt::after {
//			background: $about-iconColor;
//		}
//		.label-radio > .txt:hover {
//			color: $about-iconColor;
//		}
//		input:focus {
//			box-shadow: 0 0 8px $about-iconColor;
//			border: 1px solid $about-iconColor;
//			background-color: $about-hoverColor;
//		}
	}//li

	input[type="text"] {
		margin: 0;
		width : 570px !important;
		height: 40px;
		@include media(lx) {width: 82% !important;}
	}

	.btn-search {
		display: inline-block;
		position: relative;
		margin: 0 0 0 10px;
		padding: 10px 20px;
		@include ie-hack(gteIE11) {padding-top: 12px;}
		color: $white;
		font-size: $fs_xl !important;
		text-align: center;
		line-height: 1;
		@include radius(4);
		@include transition;
		cursor: pointer;
		background-color: $baseColor;
		border: 1px solid $baseColor;

		&:visited {color: $white;}

		@include hover() {
			opacity: 1;
			background-color: $white;
			color: $baseColor;
			text-decoration: none;
		}
	}//btn-search
}//search-area

@include media(ln) {
	.title-area + .search-box {margin-top: 40px;}
}


/* ============================================================
	search-word
============================================================ */
.search-word {
	@include margin(0 auto);
	padding: 20px 0;
	width: $fullWidth;
	background-color: #fffbf2;
	@include media(lx) {
		padding: 10px 0;
	}
	> .title {
		@include margin(0 auto);
		width: $baseWidth;
		font-size: $fs_l;
		@include bold;
		@include media(lx) {
			@include margin-sp();
			font-size: $fs_m;
		}
	}//title
}//search-word


/* ============================================================
	catalog-list
============================================================ */
.catalog-box {
	@include flex-wrap;
	width: $fullWidth;
	margin-bottom: 50px;
	&:last-child {margin-bottom: 0;}
	@include media(lx) {margin-bottom: 30px;}

	> .genre-area,
	> .link-area {
		> .ttl-link {
			margin-bottom: 20px;
			padding: 10px 12px;
			display: block;
			width: $fullWidth;
			background-color: $baseColor-titbg;
			border-radius: 5px;
			text-decoration: none !important;
			color: $baseColor;
			@include media(lx) {margin-bottom: 10px;}
			> .ttl {
				display: inline-block;
				position: relative;
				margin-right: 5px;
				padding-left: 10px;
				padding-right: 15px;
				@include ie-hack(gteIE11) {padding-top: 7px;}
				font-size: $fs_l;
				line-height: $lh_s;
				@include bold;
				border-left: 2px solid $baseColor;
				&::before {
					@include position-center-tb;
					@include icon-bfaf;
					content: $icon-arrow-right;
					right: 0;
					font-size: $fs_xs;
					transition: right .1s;
					@include media(lx) {padding-top: .1rem;}
				}
			}//ttl
			@include hover() {
				> .ttl::before {
					right: -5px;
				}
			}
		}//ttl-link
		> .ttl-link.-blank {
			> .ttl {
				&::before {
					content: $icon-blank;
				}
			}//ttl
			@include hover() {
				> .ttl::before {
					right: 0;
				}
			}
		}//ttl-link.-blank
	}//genre-area,.link-area

	> .genre-area {
		margin-bottom: 50px;
		width: 510px;
		&:nth-child(odd) {margin-right: 60px;}
		&:last-child {margin-bottom: 20px;}
		@include media(lx) {
			margin-bottom: 30px;
			width: $fullWidth;
			&:nth-child(odd) {margin-right: 0;}
		}

		.box {
			padding: 0 14px;
			> .genre {
				margin-bottom: 5px;
				line-height: $lh-xl;
				@include bold;
			}
			> .list {
				margin: 0;
				line-height: $lh-xl;
			}
			>.list + .genre {margin-top: 15px;}
		}//box
	}//genre-area

	> .link-area {
		width: 510px;
		&:nth-child(odd) {margin-right: 60px;}
		@include media(lx) {
			width: $fullWidth;
			&:nth-child(odd) {margin-right: 0;}
		}
	}//link-area
}//catalog-box


/* ============================================================
	search-year
============================================================ */
.search-year {
	@include flex;
	@include flex-center-tb;
	@include media(lx) {
		@include flex-wrap;
		> li:first-child {width: $fullWidth;}
	}

	.ttl {
		margin-right: 10px;
		font-size: $fs_xl;
		font-weight: 700;
		color: $titleColor;
		@include media(lx) {
			margin: 0 0 10px 0;
			font-size: $fs_l;
		}
	}

	select {
		margin: 0;
		width: auto !important;
		font-size: $fs_xl !important;
		height: 40px;

		@include media(lx) {
			font-size: $fs_m !important;
			height: 36px;
		}
	}

	.btn-search {
		display: inline-block;
		position: relative;
		margin: 0 0 0 10px;
		padding: 10px 20px;
		@include ie-hack(gteIE11) {padding-top: 12px;}
		color: $white;
		font-size: $fs_xl !important;
		text-align: center;
		line-height: 1;
		@include radius(4);
		@include transition;
		cursor: pointer;
		background-color: $baseColor;
		border: 1px solid $baseColor;
		@include media(lx) {
			padding: 10px;
			font-size: $fs_m !important;
		}

		&:visited {color: $white;}

		@include hover() {
			opacity: 1;
			background-color: $white;
			color: $baseColor;
			text-decoration: none;
		}
	}//btn-search
}//search-year


/* ============================================================
	pickup-list
============================================================ */
.pickup-list {
	margin: 10px 0 30px;
	> li {
		@include flex;
		@include flex-center-tb;
		padding: 20px 25px;
		width: $fullWidth;
		border-bottom: 1px dotted $borderColor;
		@include media(lx) {
			align-items: flex-start;
			padding: 15px 8px;
		}
		&:nth-child(even) {
			background-color: $baseColor-bg;
		}

		.thumb {
			margin-right: 25px;
			width: 50px;
			@include media(lx) {
				margin-right: 15px;
				flex-basis: 65px;
			}
			img {
				width: 50px;
				border: 1px solid $borderColor;
			}//img
		}//thumb

		> .box {
			@include flex-wrap;
			@include flex-center-tb;
			@include media(lx) {
				flex-basis: auto;
			}

			> .label {
				@include label;
				margin-right: 10px;
				background-color: $white;
				border: 1px solid #653308;
				color: #653308;
				width: 100px;
				font-size: $fs_s;
				@include media(lx) {
					margin-bottom: 5px;
				}
			}//label
			> .date {
				color: $gray;
				white-space: nowrap;
				@include media(lx) {
					margin-bottom: 5px;
				}
			}//date
			> .txt {
				margin-top: 10px;
				width: 100%;
				line-height: 1.5;
				@include media(lx) {
					margin-top: 0;
					width: 100%;
				}
			}//txt

		}//box
	}//li
}//pickup-list


/* ============================================================
	drama-list
============================================================ */
.drama-list {
	> li {
		position: relative;
		margin-bottom: 80px;
		width: $fullWidth;
		height: 480px;
		@include media(lx) {
			margin-bottom: 40px;
			height: auto;
		}

		> .bg {
			position: absolute;
			top: 0;
			width: 802px;
			height: 482px;
			border: 1px solid #eee;

			> img {
			opacity: .8;
//			filter: blur(1px);
			}
			@include media(lx) {
				overflow: hidden;
				width: $fullWidth;
				height: $fullWidth;
				> img {
					width: auto;
					height: $fullWidth;
				}
			}
		}//bg

		> .thumb {
			width: 194px;
			> img {border: 1px solid $borderColor;}
			@include media(lx) {
				position: relative;
				margin: 0 auto;
				padding: 30px 0 0;
			}
		}//thumb

		> .box {
			@include media(ln) {max-width: 660px;}
			@include media(lx) {
				position: relative;
				padding: 30px 10px;
			}

			> .title {
				margin-bottom: 5px;
				display: table;
				padding: 10px 20px;
				background-color: $baseColor;
				color: $white;
				font-size: $fs-xl;
				font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
				font-weight: 700;
				line-height: $lh-l;
				@include media(lx) {font-size: $fs-l;}
				> span {
					font-size: 3rem;
					@include media(lx) {font-size: 2.4rem;}
				}
			}
			> .actor {
				margin-bottom: 10px;
				display: table;
				padding: 10px 20px;
				background-color: $baseColor;
				color: $white;
				font-size: $fs-m;
				line-height: $lh-m;
			}

			> .book {
				@include flex-wrap;
				padding: 20px;
				background-color: $white;
				width: 570px;
				@include box-shadow;
				@include media(lx) {width: $fullWidth;}

				> .ttl-box {
					@include media(ln) {
						@include flex-wrap;
						align-items: flex-start;
					}
					@include media(lx) {display: block;}

					> .label {
						display: table;
						margin-right: 10px;
						padding: 4px 10px;
						border-radius: 3px;
						text-align: center;
						background-color: $white;
						border: 1px solid $baseColor;
						color: $baseColor;
						white-space: nowrap;
						font-size: $fs_m;
						@include bold;
						line-height: 1;
						text-align: center;
						> span:first-child {margin-right: 5px;}
						@include media(lx) {margin: 0 0 8px;}
					}

					> .ttl {
						color: $baseColor;
						font-size: 2rem;
						font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
						font-weight: 700;
						line-height: $lh-s;
						@include media(ln) {max-width: 395px;}
						> .author {font-size: $fs_m;}
					}

				}//ttl-box

				> .txt {
					margin: 10px 0;
					width: $fullWidth;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					overflow: hidden;
					max-height: calc(1.7em * 3);
				}
				> .link {
					@include flex-wrap;
					align-items: flex-start;
					width: 100%;
					> a {margin-left: auto;}
				}

			}//book

		}//box
	}//li

	@include media(ln) {
		> li:nth-child(odd) {
			> .bg {right: 0;}
			> .thumb {
				@include position-center-tb;
				right: 50px;
			}
			> .box {
				@include position-center-tb;
				left: 0;
			}
		}//li:odd
		> li:nth-child(even) {
			> .bg {left: 0;}
			> .thumb {
				@include position-center-tb;
				left: 50px;
			}
			> .box {
				@include position-center-tb;
				right: 0;
			}
		}//li:even
	}//media(lx)

}//drama-list