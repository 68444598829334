@charset "utf-8";

/* ============================================================
	bookinfo-swiper
============================================================ */
.bkinfotop-main {

	>.container {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		max-width: 624px;
		margin-bottom: 30px;
	}

//swiper.js
	.swiper-container {
		width: 100%;
		height: 100%;
		overflow: visible;
		z-index: 1;
	}

	.swiper-pagination {
		bottom: -25px !important;
		left: 50%;
		transform: translate(-50%, 0);
		width: $fullWidth;
	}
	.swiper-pagination-bullet {
		margin: 0 5px;
		width: 12px;
		height: 12px;
		@include transition;
		@include hover() {
			opacity: .2;
		}
	}
	.swiper-pagination-bullet-active {
		background: $bkinfo-baseColor;
	}

	.swiper-button-next,
	.swiper-button-prev {
		display: none;
	}

}//bkinfo-main