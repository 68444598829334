@charset "utf-8";

/* ============================================================
	recommend
============================================================ */
.recommend-area {
	@include margin(20px auto 0);
	@include flex-between;
	width: 804px;
	@include media(lx) {
		width: $fullWidth;
	}
	@include media(sx) {
		@include margin(10px auto 0);
		display: block;
	}

	> .item {
		display: block;
		width: 248px;
		> .ttl {margin-top: 5px;text-align: center;}
		@include media(lx) {
			width: 32%;
			> .ttl {line-height: $lh_m;}
		}
		@include media(sx) {
			@include flex-center;
			margin-top: 5px;
			width: $fullWidth;
			img {width: 124px;}
			> .ttl {
				margin: 0 0 0 15px;
				width: $fullWidth;
				text-align: left;
			}//ttl
		}//media(sx)
	}//item
}//recommend-area


/* ============================================================
	faq-qlist
============================================================ */
.faq-qlist {
	@include margin(0 auto 20px);
	width: $base2Width;
	@include media(lx) {
		width: $fullWidth;
	}
	> li {border-bottom: $borderDash;}
	> li > .item {
		display: block;
		position: relative;
		padding: 10px 0 10px 22px;
		@include fs-16l;
		.q {
			position: absolute;
			top: 10px;
			left:0;
		}
	}//item
}//faq-qlist


/* ============================================================
	contact-box
============================================================ */
.contact-box {
	position: relative;
	@include margin(140px auto);
	width: $base2Width;
	@include media(lx) {
		margin: 60px auto;
		width: $spWidth;
	}
	@include media(mx) {
		max-width: 352px;
	}
	@include media(xsx) {
		@include margin-sp(60px, 60px);
	}
	> .ttl {
		margin: 0;
		padding: 14px 20px;
		@include ie-hack(gteIE11) {padding-top: 21px;}
		background-color: $baseColor;
		border-top-left-radius : 8px;
		border-top-right-radius : 8px;
		font-size: $fs_xxl;
		line-height: 1;
		@include bold;
		color: $white;
		text-align: center;
		@include media(lx) {
			padding: 10px;
			@include fs-18s;
		}
	}
	> .box {
		@include media(mn) {@include flex-wrap;}
		border: $borderSol-dgray;
		border-top: none;
		border-bottom-left-radius : 8px;
		border-bottom-right-radius : 8px;
		padding: 20px;
		@include media(lx) {
			padding: 20px 25px 10px;
		}
	}//box
	> .box > .division {
		width: $fullWidth;
		margin-bottom: 15px;
		font-size: 2.8rem;
		@include bold;
		color: $baseColor;
		text-align: center;
		@include media(lx) {
			font-size: 2rem;
			line-height: 1.2;
		}
	}
	> .box > .item {
		padding: 0 30px;
		@include media(mn) {
			width: $halfWidth;
			&:first-of-type {border-right: $borderSol-dgray;}
		}
		@include media(lx) {padding: 0 10px;}
		@include media(mx) {padding: 0;}

		>.tel-box,.fax-box,.post-box,.mail-box {
			position: relative;
			.memo {
				display: inline-block;
				line-height: $lh_m;
				@include normal;
				@include media(lx) {margin-top: 2px;font-size: $fs_m;}
				@include media(xxsx) {font-size: $fs_s;}
			}
			> .sbttl {
				@include media(ln) {
					position: absolute;
					top: 15px;
					left: 0;
				}
				font-size: $fs_l;
				line-height: 1;
			}//sbttl
			> .sbttl-s {
				@include media(ln) {
					position: absolute;
					top: 10px;
					left: 0;
				}
				font-size: $fs_l;
			}//sbttl
		}

		> .tel-box,.fax-box {
			@include media(ln) {padding-left: 50px;}
			@include media(lx) {padding: 10px 0;}
			@include media(mx) {
				margin-bottom: 10px;
				padding: 0 0 10px;
				border-bottom: $borderSol-dgray;
			}
			> .fc {
				@include flex;
				@include flex-center-tb;
			}
			.icon-freecall {font-size: 4.2rem;margin: 5px 5px 0 0;}
			.tel {
				display: inline-block;
				font-size: 3.2rem;
				@include gfont-Lato700;
				@include ie-hack(gteIE11) {padding-top: 10px;}
				@include media(lx) {font-size: 4.3rem;}
				@include media(xsx) {font-size: 11.5vw;}
				> a {color: $textColor;}
			}
			.icon-freecall + .tel {
				@include media(lx) {font-size: 3.4rem;}
				@include media(mx) {font-size: 3.8rem;}
				@include media(xsx) {font-size: 9.5vw;}
			}
		}//tel,fax
		> .fax-box {
			@include media(ln) {margin-top: 10px;}
		}//fax

		> .post-box {
			@include media(ln) {padding: 15px 0 20px 50px;}
			@include media(lx) {padding: 20px 0 50px 0;}
			@include media(mx) {
				margin-bottom: 10px;
				padding: 0 0 10px;
				border-bottom: $borderSol-dgray;
			}
			> .address {
				margin-bottom: 10px;
				font-size: $fs_l;
				@include media(lx) {@include margin(10px 0 8px);font-size: $fs_m;}
			}
			> .name {
				font-size: $fs_xxl;
				line-height: 1.2;
				@include bold;
			}
		}//post-box

		> .mail-box {
			@include media(ln) {padding: 4px 0 0 75px;}
			@include media(lx) {padding: 10px 0;}
			@include media(mx) {padding: 0 0 10px;}
			> .mail {
				margin-bottom: 5px;
				font-size: 2.6rem;
				@include gfont-Lato700;
				@include media(lx) {
					margin-top: 5px;
				}
				@include media(xsx) {font-size: 7vw;}
				> a {
					text-decoration: none !important;
					@include hover {
						text-decoration: underline !important;
					}
				}//a
			}
			> .mail.-long {
				margin-bottom: 5px;
				font-size: 2.3rem;
				@include gfont-Lato700;
				@include media(lx) {
					margin-top: 5px;
				}
				@include media(xsx) {font-size: 6vw;}
			}
			.note {
				margin-top: 0;
				@include media(lx) {margin-top: 5px;}
			}
		}//mail-box

		.txt-box {
			margin-top: 40px;
			a {
				text-decoration: underline;
				text-indent: 0;
			}
			@include media(mx) {
				margin-top: 0;
				padding: 10px 0;
				border-top: $borderSol-dgray;
			}
		}//txt-box

	}//item

	> .box > .item > .fax-box:first-child {
		@include media(ln) {margin-top: 0;}
		@include media(mx) {padding: 0;border-bottom: none;}
	}

}//contact-box

.contact-box.-soudankai {
	> .ttl {background-color: $publi-baseColor;}
	> .box > .division {color: $publi-baseColor;}
}
.contact-box.-setsumeikai {
	> .ttl {background-color: $orange;}
	> .box > .division {color: $orange;}
}
.contact-box.-hondas {
	> .ttl {background-color: $hondas-baseColor;}
	> .box > .division {color: $hondas-baseColor;}
}


/* ============================================================
	contact-area
============================================================ */
.contact-area {
	padding: 70px 0;
	height: 470px;
	background: $gray-e url(/assets/images/bg_contact.jpg) top center no-repeat;
	text-align: center;
	@include media(lx) {
		background-size: cover;
		@include padding-sp(30px);
		height: auto;
	}

	> .ttl {
		display: inline-block;
		margin: 0 auto 20px;
		padding: 0 30px 15px;
		border-bottom: $borderSol;
		color: $textColor-light;
		font-size: 2.6rem;
		line-height: $lh_s;
		@include bold;
		@include media(lx) {
			padding: 0 0 15px;
			font-size: 2.2rem;
		}
	}
	> .lead {
		font-size: $fs_l;
		line-height: $lh_m;
		@include media(lx) {
			text-align: left;
			line-height: $lh_l;
		}
	}
	> .list {
		@include media(ln) {
			@include flex-between;
		}
		margin: 60px auto 0;
		width: $baseWidth;
		@include media(lx) {
			margin: 30px auto 0;
			width: 90%;
			max-width: 320px;
		}

		> .linkbox {
			display: block;
			margin: 10px auto;
			padding: 40px;
			width: 332px;
			background: rgba(255,255,255,0.5);
			border: $borderSol;
			text-align: center;
			@include link-black;
			@include media(lx) {
				padding: 20px;
				width: auto;
			}

			> .ttl {
				position: relative;
				display: inline-block;
				margin: 0 auto 30px;
				padding-right: 20px;
				@include ie-hack(gteIE11) {padding-top: 7px;}
				color: $baseColor;
				font-size: $fs_l;
				@include bold;
				text-align: center;
				&::before {
					font-family: '#{$icomoon-font-family}' !important;
					content: $icon-arrow-right;
					position: absolute;
					top: 50%;
					right: 0;
					font-size: $fs_s;
					transform: translate(0,-50%);
					display: inline-block;
					transition: right .1s;
				}
			}
			> .lead {
				line-height: $lh_l;
				text-align: left;
			}

			@include hover() {
				background: rgba(255,255,255,0.5);
				.ttl::before {right: -5px;}
			}

		}//linkbox
	}//list

}//contact-area


/* ============================================================
	allsearch
============================================================ */
.namazu-result-header {
	margin-bottom: 20px;
	> h2 {
		display: block;
		margin-bottom: 20px;
		padding: 4px 8px;
		border-bottom: $borderSol-baseC2;
		color: $titleColor;
		font-size: $fs_xl;
		line-height: $lh_m;
		@include bold;
		text-align: left;
		@include media(lx) {
			margin-bottom: 15px;
			font-size: $fs_l;
		}
	}//h2
	> p {
		@include txt;
	}
	> p + p {
		margin-top: 5px;
	}
}//namazu-result-header

.namazu-result-footer {
	margin-top: 20px;
	font-size: $fs_m;
	line-height: $lh_xl;
}//namazu-result-footer

.namazu-result-box {
	margin-top: 10px;
	padding: 10px;
	border: 1px solid #ccc;
	background-color: #fcfcfc;
	line-height: $lh_xl;
	> a {font-size: $fs_l;}
	> p {
		margin-top: 5px;
		font-size: $fs_s;
	}

}//namazu-result-box

.namazu-result-tips {
	@include txt;
	> h3 {
		margin-bottom: 5px;
		font-size: 2rem;
		@include bold;
	}
	> .indent-list {
		> li {
			margin-top: 20px;
			> code {font-size: $fs_l;}
		}
	}//indent-list
}//namazu-result-tips
