@charset "utf-8";

/* ============================================================
	reset
============================================================ */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, a, ins, kbd, q, samp,
small, strong, sub, sup, var, b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	box-sizing: border-box;
	vertical-align: baseline;
}
h1, h2, h3, h4, h5, h6, b {font-weight: 500;}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
img {vertical-align: bottom;}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote::before, blockquote::after,
q::before, q::after {
	content: '';
	content: none;
}
abbr[title], dfn[title] {
	border-bottom: 1px dotted;
	cursor: help;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

input, button, textarea, select {
	margin: 0;
	padding: 0;
	background: none;
	border: none;
	border-radius: 0;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	vertical-align: middle;
	box-sizing: border-box;
	font-family: inherit;
	font-size: 100% !important;
	color: #333;
}
a:focus {
	outline: none;
}
textarea {
	resize: vertical;
}
input::-ms-clear {
	visibility: hidden;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance:textfield;
}

body {
	line-height: 1;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-print-color-adjust: exact;
}
