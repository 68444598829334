@charset "utf-8";

/* ============================================================
	contact-form
============================================================ */
.contact-wrap {
	@include media(ln) {margin-top: 30px;}
	@include pcflexbw-wrap;
	.item > .contact-list {
		margin-left: 10px;
		font-size: 1.5rem;
		line-height: 1.7;
		@include gfont-Lato400;
	}
}//contact-wrap


/* ============================================================
	about
============================================================ */
.about-box >.txt {
	margin-top: 20px;
	font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
	@include fs-16xxl;
	@include media(lx) {
		margin-top: 15px;
		@include fs-14xl;
	}
}
.about-box >.txt.-large {
	@include fs-20xxl;
	@include bold;
	@include media(lx) {
		margin-top: 15px;
		@include fs-16xl;
	}
}

.message-name {
	margin-top: 20px;
	font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
	@include fs-18xl;
	@include bold;
	text-align: right;
	@include media(lx) {@include fs-16l;}
	> span {
		font-size: 2.2rem;
		@include media(lx) {font-size: $fs-xxl;}
	}
}


/* ============================================================
	profile
============================================================ */
.profile-box {
	@include flex-wrap;
	border-bottom: $borderSol;
	@include media(sx) {border-bottom: none;}
	> li {
		padding: 30px 60px;
		border-top: $borderSol;
		@include fs-16xl;
		@include media(lx) {
			padding: 15px 25px;
			@include fs-14l;
		}
		@include media(sx) {border-top: none;}

		&:nth-child(odd) {
			background: $gray-e;
			width: 380px;
			@include media(lx) {width: 30%;}
			@include media(sx) {width: $fullWidth;padding: 6px 10px;}
		}
		&:nth-child(even) {
			background: $white;
			width: 700px;
			@include media(lx) {width: 70%;}
			@include media(sx) {width: $fullWidth;padding: 10px 10px;}
		}

		&:last-child {
			@include media(lx) {width: 70%;}
			@include media(sx) {width: $fullWidth;}
		}
	}//li
}//profile-box


/* ============================================================
	map
============================================================ */
.map-wrap {
	margin-bottom: 30px;
	width: 1080px;
	height: 500px;
	@include media(lx) {
		margin-bottom: 10px;
		width: $fullWidth;
		height: 300px;
	}
}

.map-box {
	@include flex-wrap;

	> li {
		&:nth-child(odd) {
			padding-right: 30px;
			width: 730px;
			@include media(lx) {width: 70%;padding-right: 10px;}
			@include media(sx) {width: $fullWidth;padding-right: 0;}
		}
		&:nth-child(even) {
			width: 350px;
			@include media(lx) {width: 30%;}
			@include media(sx) {width: $fullWidth;padding-top: 10px;}
		}

		> ul.access-box {
			@include flex-wrap ;
			border-top: $borderSol;
			//&:last-child {border-bottom: none;}
			@include media(sx) {border-top: none;}

			> li {
				padding: 30px 50px;
				border-bottom: $borderSol;
				@include fs-16l;
				@include media(lx) {
					padding: 15px 10px;
					font-size: $fs_m;
				}
				@include media(sx) {border-bottom: none;}
				//&:last-child {border-bottom: none;}

				&:nth-child(odd) {
					background: $gray-e;
					width: 200px;
					@include media(lx) {width: 22%;}
					@include media(sx) {width: $fullWidth;padding: 6px 10px;}
				}
				&:nth-child(even) {
					background: $white;
					width: 500px;
					@include media(lx) {width: 78%;}
					@include media(sx) {width: $fullWidth;padding: 10px;}
				}

			}//li
		}//ul

	}//ul
}//map-box

.map-route {
	margin: 100px auto 0;
	width: 600px;
	> iframe {
		width: 600px;
		height: 330px;
	}
	@include media(lx) {
		position: relative;
		margin: 30px auto 0;
		padding-top: 55.25%;
		width: 100% !important;
		> iframe {
			position: absolute;
			top: 0;
			right: 0;
			width: 100% !important;
			height: 100% !important;
		}
	}
}//map-route


/* ============================================================
	history
============================================================ */
.history-box {
	@include flex-wrap;
	border-top: $borderSol;

	> li {
		padding: 15px 20px;
		border-bottom: $borderSol;
		border-right: $borderSol;
		@include fs-14xl;
		@include media(lx) {
			padding: 6px 10px;
			line-height: 1.5;
		}

		&:nth-child(odd) {
			background: $gray-e;
			border-left: $borderSol;
			width: 140px;
			@include media(lx) {width: 22%;border-left: $borderSol;}
			@include media(sx) {width: $fullWidth;}
		}
		&:nth-child(even) {
			background: $white;
			width: 940px;
			@include media(lx) {width: 78%;}
			@include media(sx) {width: $fullWidth;border-left: $borderSol;}
		}
	}//li
}//history-box
.history-data {
	margin-top: 10px;
	font-size: $fs_s;
	text-align: right;
}


/* ============================================================
	group
============================================================ */
.group-list {
	@include media(mn) {@include flex-wrap;}

	> .item-box {
		position: relative;
		margin: 18px 18px 0 0;
		padding: 10px;
		width: 348px;
		border: $borderSol;
		&:nth-child(3n) {margin: 18px 0 0 0;}
		@include media(lx) {
			@include flex-between;
			margin: 18px 0 0 0;
			width: $fullWidth;
		}
		@include media(xsx) {display: block;}

		> .txt-box {
			@include media(lx) {width: 68%;}
			@include media(xsx) {width: $fullWidth;}

			> .ttl {
				display: inline-block;
				margin-top: 15px;
				font-size: $fs-xl;
				@include bold;
				@include media(lx) {
					margin-top: 0;
					font-size: $fs_l;
				}
				@include media(xsx) {margin-top: 15px;}
			}
			> .txt {
				display: inline-block;
				margin-top: 10px;
				width: $fullWidth;
			}
		}//txt-box

		> .logo {
			@include media(lx) {width: 30%;}
			@include media(xsx) {
				width: $fullWidth;
				text-align: center;
				img {max-width: 163px;}
			}
		}

	}//item-box

}//group-box


/* ============================================================
	principles
============================================================ */
.principles-box {
	@include flex-wrap;
	border-bottom: $borderDot;

	> li {
		&:nth-child(odd) {
			padding: 30px 20px 30px 40px;
			width: 260px;
			@include fs-16xl;
			@include bold;
			//background: $gray-e;
			@include media(lx) {
				padding: 35px 0 20px;
				width: $fullWidth;
				line-height: 1;
			}
		}//odd
		&:nth-child(even) {
			padding: 30px 40px 30px 20px;
			width: 820px;
			@include media(lx) {
				padding: 0 0 30px;
				width: $fullWidth;
			}
		}//even

	}//li
}//principles-box

.principles-box:first-child {
	//border-top: none;
	> li {padding-top: 0;}
}


/* ============================================================
	recruit

	.recruit-box {
	@include flex-wrap;
	border-top: $borderSol;
	margin-top: 20px;
	@include media(lx) {margin-top: 15px;}

	> li {
		padding: 10px;
		border-bottom: $borderSol;
		border-right: $borderSol;
		@include fs-16l;
		@include media(lx) {
			padding: 6px 10px;
			font-size: $fs_m;
		}

		&:nth-child(even) {
			background: $gray-e;
			border-left: $borderSol;
			width: 130px;
			@include media(lx) {width: 22%;border-left: $borderSol;}
			@include media(sx) {width: $fullWidth;}
		}
		&:nth-child(odd) {
			background: $white;
			width: 950px;
			@include media(lx) {width: 78%;}
			@include media(sx) {width: $fullWidth;border-left: $borderSol;}
		}

		&.ttl {
			width: 100% !important;
			border-left: $borderSol;
			@include fs-18l;
			@include bold;
			@include media(lx) {@include fs-16m;}
		}

	}//li
}//recruit-box
============================================================ */
.recruit-detail {
	margin-top: 80px;
	&:first-of-type {margin-top: 40px;}
	@include media(lx) {
		margin-top: 40px;
		&:first-of-type {margin-top: 20px;}
	}

	> .h4ttl-line {
			margin-bottom: 10px;
			padding: 0 8px 4px;
			border-bottom: $borderSol-baseC1;
			@include media(lx) {
				margin-bottom: 5px;
				padding: 0 4px 4px;
			}
		}
	> .list {
		> li {
			@include flex;
			align-items: baseline;
			padding: 10px 0;
			border-bottom: $borderDot;

			> .ttl {
				padding: 5px;
				@include ie-hack(gteIE11) {padding-top: 12px;}
				width: 120px;
				color: $white;
				@include fs-16xl;
				text-align: center;
				background-color: $gray;
				&.-date {background-color: $publi-baseColor;}
				@include media(lx) {
					width: 72px;
					@include fs-14l;
				}
			}//ttl
			> .txt-area {
				padding-left: 22px;
				width: $fullWidth;
				@include fs-16xl;
				@include media(lx) {
					padding-left: 10px;
					@include fs-14l;
				}
			}//txt-area
		}//li
	}//list

}//recruit-detail


/* ============================================================
	protection
============================================================ */
.protection-box {
	@include flex-wrap;
	margin-top: 20px;
	> li {

		&:nth-child(odd) {
			padding-right: 30px;
			width: 542px;
			@include media(lx) {width: 40%;padding-right: 10px;}
			@include media(mx) {width: $fullWidth;padding-right: 0;}
		}
		&:nth-child(even) {
			width: 538px;
			@include media(lx) {width: 60%;}
			@include media(mx) {width: $fullWidth;padding-top: 10px;}
		}

		> img {border: $borderSol;}

	}//li
}//protection-box


/* ============================================================
	privacypolicy
============================================================ */
.contact-box.-about {
	margin: 60px auto 0;
	@include media(lx) {
		margin: 40px auto 0;
		width: $fullWidth;
	}

	> .box {

		p {
			margin-top: 0;
			@include fs-14m;
		}//p

		> .item {
		.txt-box {
			margin-top: 20px;
			@include media(ln) {padding-bottom: 8px;}
			@include media(mx) {margin-top: 0;}
		}//txt-box
	}//item

	}//box
}//contact-box.-about


/* ============================================================
	sitepolicy
============================================================ */
.banner-list {
	margin-top: 15px;
	@include flex-wrap;
	align-items: flex-end;
	> li {
		margin-right: 20px;
		@include media(lx) {margin-right: 10px;}
		> p {margin-top: 5px;}
		img {margin-top: 10px;}
	}//li
	> li:nth-of-type(1) > img {width: 171px;}
	> li:nth-of-type(2) > img {width: 129px;}
	> li:nth-of-type(3) > img {width: 88px;}
}//banner-list


/* ============================================================
	sitemap-list
============================================================ */
a.sitemap-ttl {
	position: relative;
	display: block;
	padding: 6px 10px 10px 10px;
	border-left: 10px solid $baseColor;
	border-bottom: $borderSol-baseC2;
	font-size: 1.9rem;
	line-height: 1;
	@include bold;
	text-align: left;

	@include media(lx) {
		margin-bottom: 10px;
	}

	&.-publi {
		border-left: 10px solid $publi-baseColor;
		border-bottom: $borderSol-publi2;
	}
	&.-bkinfo {
		border-left: 10px solid $bkinfo-baseColor;
		border-bottom: $borderSol-bkinfo2;
	}
}//sitemap-ttl

.sitemap-list {
	@include flex-wrap;
	margin: 20px 0 30px;
	@include media(lx) {
		margin: 10px 0 30px;
	}

	> li {
		margin-right: 20px;
		width: 255px;

		@include media(lx) {
			margin-right: 0;
			width: 49%;
		}
		@include media(xmx) {
			margin-right: 0;
			width: $fullWidth;
		}

		&:nth-child(4n) {margin-right: 0;}

		> .ctg-ttl {
			display: block;
			margin-bottom: 20px;
			padding: 4px 8px;
			border-bottom: $borderSol-baseC1;
			color: $titleColor;
			font-size: 1.7rem;
			line-height: $lh_m;
			@include bold;
			text-align: left;

			@include media(lx) {
				margin-bottom: 15px;
				font-size: $fs_l;
			}

			&.-publi {border-bottom: $borderSol-publi1;}
			&.-bkinfo {border-bottom: $borderSol-bkinfo1;}
		}

		&:nth-child(2) {
			> .ctg-ttl {
				@include media(xmx) {margin-top: 20px;}
			}
		}

		&:nth-child(3),
		&:nth-child(4) {
			> .ctg-ttl {
				@include media(lx) {margin-top: 20px;}
			}
		}

		&:nth-child(1),
		&:nth-child(3) {
			@include media(lx) {margin-right: 2%;}
			@include media(xmx) {margin-right: 0;}
		}

	}//li
}//sitemap-list

.site-link {
	margin: 15px 0 0 4px;
	> a {
		padding-right: 15px;
		position: relative;
		display: inline-block;
		font-size:  1.5rem;
		line-height: 1;
		text-align: left;
		@include media(lx) {font-size:  1.4rem;}

		&::before {
			@include position-center-tb;
			@include icon-bfaf;
			content: $icon-arrow-right;
			right: 0;
			transition: right .1s;
			font-size:  1rem;
		}

	@include hover() {
		text-decoration: underline !important;
		&::before {
			right: -5px;
		}
	}
	}
	> .lv {
		margin-right: 5px;
		vertical-align: top;
	}
}//site-link

.site-link.-blank {
	> a {
		&::before {
			display: none;
		}
	}
}//site-link.-blank
