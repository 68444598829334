@charset "utf-8";

/* ============================================================
	bookdetail-wrap
============================================================ */
.bookdetail-wrap {
	margin-bottom: 140px;
	width: $fullWidth;
	border-top: 1px solid $baseColor;
	@include media(lx) {margin-bottom: 60px;}

	> .bookdetail {
		@include margin(0 auto);
		width: 1000px;
		@include media(lx) {
			@include margin-sp();
		}

//============================================================
//main
//============================================================
		> .main {
			position: relative;
			padding-top: 40px;
			min-height: 688px;
			@include flex;
			flex-direction: column;
			@include media(lx) {
				display: block;
				padding-top: 20px;
			}

			> .title {
				order: 3;
				margin: 30px 0 0 520px;
				font-size: 2.4rem;
				line-height: $lh_m;
				@include bold;
				color: $baseColor;
				@include media(lx) {
					order: 1;
					margin: 0;
					font-size: 2.2rem;
				}
			}//ttl

			> .subtitle {
				order: 4;
				margin: 5px 0 0 520px;
				font-size: $fs-m;
				line-height: $lh_m;
				color: $baseColor;
				@include media(lx) {
					order: 2;
					margin: 5px 0 0 0;
				}
			}//ttl

			> .author {
				order: 5;
				margin: 15px 0 30px 520px;
				font-size: $fs-xl;
				line-height: $lh_m;
				@include bold;
				> span {
					font-size: $fs-s;
					@include normal;
					&:nth-of-type(1) {display: inline-block;}
				}
				@include media(lx) {
					order: 3;
					margin: 15px 0 30px 0;
					font-size: $fs-l;
				}
			}//author

			> .box {
				order: 2;
				width: 430px;
				height: 520px;
				margin-bottom: -520px;
				@include media(lx) {
					order: 4;
					width: auto;
					height: auto;
					margin: 0 auto 30px;
				}

				> .bookimg {
					margin: 0 auto;
					background-color: #fff;
					@include box-shadow;
					width: 430px;
					height: 450px;
					@include flex-center;
					> img {
						border: 1px solid #ccc;
						width: auto;
						max-width: 386px;
						max-height: 386px;
					}
					@include media(lx) {
						width: auto;
						height: auto;
						max-width: 426px;
						max-height: 446px;
						padding: 30px 20px;
						> img {
							max-width: 100%;
						}
					}
				}//bookimg-box

				> .purchase {
					@include flex-center;
					margin-top: 20px;
					> .btnw {
						margin: 0 10px;
						padding: 10px 35px 10px 25px;
						width: 195px;
						@include media(lx) {
							margin: 0 5px;
							padding: 10px 25px 10px 15px;
							width: 180px;
						}
						@include media(xxsx) {
							font-size: $fs-m;
						}

						&.-pod {width: 270px;}
					}//btnw
				}//purchase

			}//box

			> .lead {
				order: 1;
				margin-bottom: 40px;
				color: $baseColor;
				font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
				font-size: 3.2rem;
				line-height: $lh_m;
				@include bold600;
				@include media(ln) {min-height: 44px;}
				@include media(lx) {
					order: 5;
					margin-bottom: 20px;
					font-size: 2rem;
					text-align: justify;
					> br {display: none;}
				}
			}//lead

			> .sentei {
				order: 6;
				margin-left: 520px;
				text-align: right;
				> p {
					display: inline-block;
					@include radius(3);
					margin: 0 0 10px 5px;
					padding: 4px;
					font-size: $fs_xs;
					color: $white;
					&.sla {background-color: #44691a;}
					&.jla {background-color: #3d2929;}
				}//p
				@include media(lx) {margin-left: 0;}
			}//sentei

			> .txt {
				order: 7;
				margin: 0 0 30px 520px;
				font-size: 1.5rem;
				line-height: 1.8;
				text-align: justify;
				@include media(lx) {
					margin: 0 0 30px 0;
				}
			}

			> .more {
				order: 8;
				margin-left: 520px;
				text-align: right;
				@include media(lx) {
					margin-left: 0;
					text-align: center;
					@include flex-center;
				}

				> a {
					margin: 0 0 30px 10px;
					padding: 10px 20px;
					@include ie-hack(gteIE11) {padding-top: 17px;}
					font-size: $fs_l;
					background-color: #6869ae;
					width: 180px;
					@include media(lx) {
						margin: 0 5px 30px 5px;
						padding: 10px;
					}

					> p {
						@include flex-center;
					}

					&:visited {color: $white;}

					&.-blank {
						&::before {
							top: 12px;
							right: 8px;
							font-size: $fs_xs;
							@include media(xxsx) {top: 8px;right: 4px;}
						}
					}//-blank

					@include hover() {
						opacity: 1;
						background-color: $white;
						color: $baseColor;
						text-decoration: none !important;
					}

					[class^="icon-"] {
						margin-right: 5px;
						font-size: 2rem;
					}
				}//a
			}//more

			> .info {
				order: 9;
				margin-left: 520px;
				padding: 20px;
				background-color: #f4f4f4;
				@include media(lx) {
					margin-left: 0;
					padding: 10px;
				}

				> p {
					display: inline-block;
					margin: 0px 10px 5px 0;
					font-size: $fs-m;
					line-height: $lh_m;
					color: $textColor-light;
					&.isbn {
						margin-right: 0;
						display: block;
					}
					&.genre {
						margin: 0;
						display: table;
						> span {
							display: table-cell;
							&:first-child {white-space: nowrap;}
						}
					}//genre
				}//p
			}//info
			> .note {
				order: 10;
				margin-left: 520px;
				margin-top: 10px;
				@include media(lx) {margin-left: 0;}
			}

		}//main

//============================================================
//menu
//============================================================
	.menu {
		@include margin(80px auto 0);
		padding-left: 8px;
		@include media(ln) {
			@include flex;
			justify-content: flex-start;
		}
		border-bottom: 1px dotted $baseColor;
		font-size: 0;
		@include media(lx) {
			@include margin(40px auto 0);
			padding: 0;
			border-bottom: none;
			> li {
				margin-top: 5px;
				display: inline-block;
				width: 100%;
				border-bottom: 1px dotted $baseColor;
				text-align: center;
			}
		}

		.btn-anker {
			display: block;
			position: relative;
			margin-right: 30px;
			padding: 10px 30px 10px 0;
			@include ie-hack(gteIE11) {padding-top: 17px;}
			color: $baseColor;
			font-size: $fs_m;
			@include media(lx) {
				margin-right: 0;
			}

			&::before {
				@include arrow-down;
				top: 56%;
				font-size: 1.1rem;
				transition: top .3s;
			}
			@include hover() {
				text-decoration: none;
				&::before {
					top: 68%;
					transform: translate(0,-68%);
				}
			}
		}//btn-anker
	}//menu

//============================================================
//other
//============================================================
	> .other {
		margin-top: 80px;
		@include media(lx) {margin-top: 50px;}

		> .ttl-line {
			display: block;
			margin-bottom: 20px;
			padding: 10px 8px;
			border-bottom: $borderSol-baseC1;
			color: $baseColor;
			font-size: $fs_xl;
			text-align: left;

			@include media(lx) {
				margin-bottom: 15px;
			}
		}//ttl-line

		> .box {
			position: relative;
			font-size: $fs_m;
			line-height: $lh_xl;

//============================================================
//profile
//============================================================
			> .list {
				margin: 10px 0;
				@include media(lx) {margin: 10px 0 5px;}
			}

			> .link {
				margin: 10px 0;
				@include media(lx) {margin: 10px 0 5px;}
				> a {display: table;}
			}

			> .sns {
				margin: 14px 0;
				font-size: 0;
				@include flex-wrap;
				@include media(lx) {margin: 0 0 10px;}

				> a {
					margin-right: 10px;
					padding: 7px 10px;
					@include ie-hack(gteIE11) {padding-top: 12px;}
					font-size: $fs_s;
					@include bold;
					background-color: $white;
					@include radius(6);
					width: 125px;
					@include media(lx) {
						margin: 10px 10px 0 0;
						padding: 7px 5px;
						width: 158px;
					}
					@include media(xxsx) {
						width: 140px;
					}

					> p {
						@include flex-center;
						> span.logo {
							margin-right: 5px;
							line-height: 1;
							width: 16px;
							height: 16px;
							&.fb {background: no-repeat center / 16px url("/bookinfo/images/detail/icon-fb.png");}
							&.icon-x {margin-right: 0;}
							&.icon-x + span {display: none;}
							&.nt {
								background: no-repeat center / 50px url("/bookinfo/images/detail/icon-note.png");
								width: 50px;
								margin-right: 0;
							}
							&.nt + span {display: none;}

//							&.yt {background: no-repeat center / 90px url("/bookinfo/images/detail/icon-youtube.png");width: 90px;height: 20px;}
//							&.ig {background: no-repeat center / 16px url("/bookinfo/images/detail/icon-ig.png");}
						}//span
					}//p
					[class^="logo icon-"] {font-size: $fs_l;}
					[class^="logo icon-note"] {font-size: 50px;}

					&:visited {color: $baseColor;}

					&.-blank {
						&::before {
							top: 8px;
							right: 3px;
							font-size: $fs_xs;
						}
					}//-blank

					@include hover() {
						opacity: 1;
						background-color: $baseColor;
						color: $white;
						text-decoration: none !important;
					}

					&.-fb {
						color: #1877f2;
						border: 1px solid #1877f2;
						@include hover() {
							background-color: #1877f2;
							color: $white;
							span.fb {background: no-repeat center / 16px url("/bookinfo/images/detail/icon-fb_w.png");}
						}
					}//-fb
					&.-tw {
						color: #1da1f2;
						border: 1px solid #1da1f2;
						@include hover() {
							background-color: #1da1f2;
							color: $white;
						}
					}//-tw
					&.-x {
						color: $black;
						border: 1px solid $black;
						@include hover() {
							background-color: $black;
							color: $white;
						}
					}//-x
					&.-nt {
						color: $black;
						border: 1px solid $black;
						@include hover() {
							background-color: $black;
							color: $white;
							span.nt {background: no-repeat center / 50px url("/bookinfo/images/detail/icon-note_w.png");}
						}
					}//-nt
					&.-yt {
						color: #ff0000;
						border: 1px solid #ff0000;
						@include hover() {
							background-color: #ff0000;
							color: $white;
						}
					}//-yt
					&.-ig {
						color: #cf2e92;
						border: 1px solid #cf2e92;
						@include hover() {
							background-color: #cf2e92;
							color: $white;
//							span.ig {background: no-repeat center / 16px url("/bookinfo/images/detail/icon-ig_w.png");}
						}
					}//-ig
					&.-blog {
						color: #f56800;
						border: 1px solid #f56800;
						@include hover() {
							background-color: #f56800;
							color: $white;
						}
					}//-blog
				}//a
			}//sns
			> .list + .sns,
			> .link + .sns {
				@include media(lx) {margin-top: -2px;}
			}

//============================================================
//media (pickup-list)
//============================================================
			> .media-list > li {
				margin-bottom: 10px;
				@include flex-wrap;
				align-items: flex-start;
				width: 100%;
				&:last-child {margin-bottom: 0;}
				> .label {
					padding: 4px;
					border-radius: 3px;
					text-align: center;
					background-color: $white;
					border: 1px solid #653308;
					color: #653308;
					white-space: nowrap;
					width: 100px;
					font-size: $fs_s;
					line-height: 1;
					text-align: center;
				}
				> .date {
					margin: 0 15px;
					width: 106px;
					white-space: nowrap;
				}
				> .txt {
					width: 744px;
					@include media(lx) {
						margin-top: 5px;
						width: 100%;
					}
				}
			}//li
			> .media-list > li.link a {margin-left: auto;}

//============================================================
//interview
//============================================================
			.interview-btn {
				z-index: 2;
				@include position-center-lr;
				bottom: 0;
				display: inline-block;
				padding: 10px 35px 10px 25px;
				color: #36357e;
				font-size: $fs_m;
				line-height: 1;
				text-align: center;
				border-radius: 8px;
				transition: all .3s;
				cursor: pointer;
				background-color: #fff;
				border: 1px solid #36357e;
				&:visited {color: $baseColor;}
				@include hover() {
					opacity: 1;
					background-color: $baseColor;
					color: $white;
					text-decoration: none;
					&::before {
						right: 15px;
						@include media(lx) {right: 8px;}
					}
				}
				&::before {
					@include anker2;
					font-size: $fs-xs;
					right: 15px;
					transition: top .3s;
					@include media(lx) {right: 12px;}
				}
				@include hover() {
					text-decoration: none;
					background-color: $baseColor;
					&::before {
						right: 15px;
						top: 58%;
						transform: translate(0,-58%);
						@include media(lx) {right: 12px;}
					}
				}//hover
			}//interview-btn

			.interview-area {
				position: relative;
				overflow: hidden;
				&.is-hide {height: 138px;} //隠した後の高さ
				> p + p {margin-top: 16px;}
				&::before {
					display: block;
					position: absolute;
					bottom: 0;
					left: 0;
					content: "";
					width: 100%;
					height: 60px; //グラデーションで隠す高さ
					background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 70%);
					background-color: transparent;
				}
			}//interview-area
			.interview-btn.is-show + .interview-area::before {display: none;}

//============================================================
//drama
//============================================================
			.drama-list {
				> li {
					position: relative;
					@include margin(5px 0 0 0);
					display: table;
					text-align: left;
					width: auto;
					height: auto;
					> p {
						display: table-cell;
						font-size: $fs_m;
						line-height: $lh_xl;
						&:first-child {
							width: 70px;
							text-align: right;
							white-space: nowrap;
						}
					}
				}//li
			}//drama-list

//============================================================
//promotion
//============================================================
			.youtube-box {
				margin: 0 auto;
				width: 600px;
				@include media(lx) {
					position: relative;
					width: 100% !important;
					padding-top: 56.25%;
					> iframe {
						position: absolute;
						top: 0;
						right: 0;
						width: 100% !important;
						height: 100% !important;
					}//iframe
				}//media
			}//youtube-box

		}//box
		> .box + .note {
			margin-top: 40px;
			@include media(lx) {margin-top: 30px;}
		}
	}//other

//============================================================
//purchase
//============================================================
	.purchase-area {
		margin-top: 120px;
		padding: 10px 51px;
		border: $borderSol-baseC1;
		background-color: $baseColor-bg;
		@include media(lx) {
			margin-top: 60px;
			padding: 5px 15px;
		}

		> .box {
			padding: 20px 0;
			@include media(lx) {padding: 15px 0;}
			>.ttl {
				display: block;
				color: $baseColor;
				font-size: $fs_l;
				@include bold;
			}
			> .txt {margin-top: 10px;}
			> .list {
				@include flex-wrap;
				> li {
					margin-top: 10px;
					margin-right: 16px;
					&:nth-of-type(6) {margin-right: 0px;}
					width: 136px;
					height: 39px;
					> a {display: block;}

					@include media(lx) {
						width: 32%;
						height: auto;
						margin-right: 2%;
						&:nth-child(3n) {margin-right: 0px;}
					}

					@include media(xmx) {
						width: 48%;
						height: auto;
						margin-right: 4%;
						&:nth-child(3n) {margin-right: 4%;}
						&:nth-child(even) {margin-right: 0px;}
					}
				}//li
			}//list
			> .h5ttl-line {margin-top: 20px;}
			> .btn-wrap {margin: 10px 0 30px;}

		}//box
	}//purchase-area

	.purchase-attention {
		margin-top: 10px;
		padding: 20px 0;
		border-top: 1px solid $borderColor;
		>.txt {
			margin-top: 5px;
			font-size: $fs-s;
			line-height: $lh_l;
		}
		>.indent-multilist {
			margin: 10px 0 20px;
		}
	}//purchase-attention

//============================================================
//banner-box
//============================================================
		>.banner-box {
			margin-top: 40px;
			@include media(lx) {
				margin: 20px 0 10px;
			}
			> a {
				margin: 0 auto;
				display: block;
				@include box-shadow;
			}
		}//banner-box

	}//bookdetail
}//bookdetail-wrap


/* ============================================================
	bookservice
============================================================ */
.bookservice {
	margin: 40px auto 0;
	text-align: center;
	> .txt {
		margin-bottom: 10px;
		font-size: $fs-xl;
		@include bold;
		color: $baseColor;
		display: inline-block;
		text-align: left;
	}
	> .indent-multilist {
		margin: 0 auto;
		display: inline-block;
		> li {
			color: $baseColor;
			font-size: $fs-xl;
			line-height: $lh_s;
			.tel {font-family: 'Lato', sans-serif;}
			span:nth-of-type(2) {font-size: $fs-m;}
		}//li
	}//indent-list
}//bookservice