@charset "utf-8";

/* ============================================================
	publishing/seminarsearch
============================================================ */
.seminarsearch-month {
	position: relative;
	margin-bottom: 100px;
	@include media(lx) {
		margin-bottom: 80px;
	}
	> .ttl {
		padding: 14px 20px;
		@include ie-hack(gteIE11) {padding-top: 21px;}
		background-color: $publi-baseColor;
		border-top-left-radius : 10px;
		border-top-right-radius : 10px;
		font-size: $fs_xxl;
		@include bold;
		color: $white;
		text-align: center;
		@include media(lx) {
			font-size: $fs_xl;
		}
	}
	> .box {
		border: $borderSol;
		border-top: none;
		padding: 30px 40px ;
		@include media(lx) {
			padding: 20px 10px;
		}
		> .lead-mcl {margin-top: 0;}
	}//box

	> .box > .list {
		@include media(ln) {
			@include flex-between;
		}
		@include media(lx) {
			display: block;
		}
		> li {
			@include media(ln) {
				@include flex;
			}
			@include media(lx) {
				@include flex-between;
				margin-top: 10px;
			}

			> .btn-month {
				position: relative;
				@include flex-center;
				@include transition;
				margin-right: 10px;
				padding: 20px;
				width: 220px;
				background-color: $white;
				@include box-shadow;
				@include link-publi;
				font-size: $fs_xl;
				text-align: center;
				cursor: pointer;
				@include radius(3);
				@include ie-hack(gteIE11) {padding-top: 27px;}
				@include media(lx) {
					padding: 10px 15px;
					@include ie-hack(gteIE11) {padding-top: 17px;}
					width: 100%;
					height: auto;
					font-size: $fs_l;
				}

				&:last-of-type {margin-right: 0;}
				&.-clear {font-size: $fs_l;}
				&.-active {
					background-color: $publi-baseColor;
					@include box-shadow-hov;
					color: $white;
				}
				&.-nodata {
					background-color: $gray-e;
					box-shadow: none;
					color: $white;
					pointer-events: none;
				}

				@include hover() {
					@include opacity(1);
					background-color: $publi-baseColor;
					@include box-shadow-hov;
					color: $white;
					&::before {right: 30px;}
					@include media(lx) {
						&::before {right: 8px;}
					}
				}//hover
			}//btn-month
		}//li
	}//list

	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: -40px;
		width: 0px;
		height: 0px;
		margin: auto;
		border-style: solid;
		border-color: $gray-e transparent transparent transparent;
		border-width: 30px 115px 0 115px;
	}//before

	&.-none {
		&::before {display: none;}
		> .box > .lead-mcl {margin: 0;}
	}

}//seminarsearch-month

.seminarsearch-place {
	position: relative;
	margin-bottom: 80px;
	@include media(lx) {
		margin-bottom: 40px;
	}
	> .ttl {
		font-size: $fs_xxl;
		@include bold;
		color: $publi-baseColor;
		text-align: center;
		@include media(lx) {
			font-size: $fs_xl;
		}
	}//ttl

	> .list {
		@include margin(40px auto 80px);
		@include media(ln) {
			@include flex-between;
		}
		padding: 0 80px;
		border-bottom: $borderSol-publi1;
		font-size: 0;
		@include media(lx) {
			@include margin(0 auto 40px);
			padding: 0;
			border-bottom: none;
			> li {
				margin-top: 5px;
				display: inline-block;
				width: 50%;
				border-bottom: $borderSol-publi1;
				text-align: center;
			}
		}

		.btn-place {
			display: block;
			position: relative;
			padding: 10px 30px 10px 10px;
			@include ie-hack(gteIE11) {padding-top: 17px;}
			color: $borderColor;
			font-size: $fs_xl;
			pointer-events: none;
			@include media(lx) {
				font-size: $fs_l;
			}
		}//btn-place

		.btn-place.-active {
			color: $publi-baseColor;
			pointer-events: auto;
			&::before {
				@include arrow-down;
				transition: top .3s;
			}
			@include hover() {
				text-decoration: none;
				&::before {
					top: 68%;
					transform: translate(0,-68%);
				}
			}
		}//btn-place.-active
	}//list
}//seminarsearch-place

.seminar-areattl {
	@include margin(80px auto 35px);
	padding: 12px;
	border-bottom: $borderSol-publi3;
	color: $titleColor;
	font-size: $fs_xxl;
	@include bold;
	text-align: center;
	@include media(lx) {
		@include margin(40px auto 20px);
	}
}

.seminar-detail {
	@include media(ln) {
		@include flex-between;
		@include flex-center-tb;
	}
	@include box-shadow;
	@include margin (35px 0 0);
	padding: 30px 0;
	@include media(lx) {
		@include margin(20px 0 0);
		padding: 20px 0;
	}
	> .list {
		width: 750px;
		padding: 0 35px;
		border-right: $borderSol;
		@include media(lx) {
			width: $fullWidth;
			padding: 0 15px;
			border-right: none;
		}
		> li {
			@include flex;
			align-items: baseline;
			padding-bottom: 10px;
			&.place {border-top: $borderDot;padding-top: 10px;}
			&:last-of-type {padding-bottom: 0;}
			> .ttl {
				padding: 5px;
				@include ie-hack(gteIE11) {padding-top: 12px;}
				width: 100px;
				color: $white;
				font-size: $fs_m;
				text-align: center;
				background-color: $gray;
				&.-date {background-color: $publi-baseColor;}
				@include media(lx) {
					width: 92px;
					font-size: $fs_s;
				}
			}//ttl
			> .txt-area {
				padding-left: 22px;
				width: $fullWidth;
				font-size: $fs_m;
				line-height: $lh_m;
				@include media(lx) {
					padding-left: 10px;
					font-size: $fs_s;
				}
				a {
					color: $publi-baseColor;
				}//a

				> .date {
					font-size: $fs_xl;
					color: $publi-baseColor;
					@include bold;
					@include media(lx) {font-size: $fs_l;}
				}
				> .time {
					font-size: $fs_xl;
					@include media(lx) {font-size: $fs_l;}
				}
				> .note {margin-top: 5px;}
				> .name {
					font-size: $fs_xxl;
					@include media(lx) {font-size: $fs_xl;}
				}
				> .address {
					margin-top: 5px;
					font-size: $fs_l;
					line-height: $lh_l;
					@include media(lx) {font-size: $fs_m;line-height: $lh_m;}
					> .map {
						display: inline-block;
						font-size: $fs_m;
						> .icon-map {margin-left: 5px;font-size: $fs_l;}
					}//map
				}//address
				> .shop {
					display: inline-block;
					font-size: $fs_m;
				}//shop

			}//txt-area
		}//li
	}//list
	> .btn-box {
		width: 330px;
		@include media(lx) {
			margin-top: 20px;
			width: $fullWidth;
		}
		> .btn-publi {
			width: 260px;
			padding: 15px 50px;
			@include ie-hack(gteIE11) {padding-top: 22px;}
			@include media(lx) {padding: 10px 30px;}
		}
	}//btn-box
}//seminar-detail


/* ============================================================
	seminar-flow
============================================================ */
.-soudankai-flow,
.-setsumeikai-flow {
	background: url(/publishing/images/common/bg_flow.jpg) bottom center no-repeat;
	background-size: cover;
	@include media(lx) {
		> .h2ttl-icon {font-size: $fs_xxl;}
	}
	> .flow-area {
		@include margin(50px auto 0);
		@include flex-wrap;
		width: $base2Width;
		@include media(lx) {
				@include margin(25px auto 0);
			width: $fullWidth;
		}
	}//flow-area

	.step-area {
		background-color: $white;
		> li {
			@include flex;
			@include media(lx) {
				display: block;
			}

			> .ttl-box {
				position: relative;
				display: block;
				padding: 35px 10px;
				width: 200px;
				background: $publi-baseColor;
				@include media(ln) {border-bottom: $borderSol-white2;}
				text-align: center;
				color: $white;
				&:last-child {border-bottom: none;}
				span.eng {
					display: block;
					font-size: $fs_l;
					color: $gray-e;
					@include media(lx) {
						display: inline-block;
						font-size: $fs_m;
					}
				}
				span.ttl {
					display: block;
					margin-top: 5px;
					font-size: $fs_xxl;
					color: $white;
					@include media(lx) {
						display: inline-block;
						margin-top: 0;
						margin-left: 5px;
						font-size: $fs_xl;
					}
				}
				@include media(ln) {
					&::before {
						display: block;
						font-family: 'bungeisha' !important;
						content: $icon-arrow-chart;
						@include position-center-lr;
						bottom: -24px;
						font-size: 3.6rem;
						@include z-index(default);
					}
				}
				@include media(lx) {
					display: block;
					padding: 5px 10px;
					width: $fullWidth;
					text-align: left;
				}
			}//ttl-box
			> .txt-box {
				@include margin(0 50px);
				padding: 30px 0;
				width: 660px;
				height: auto;
				background: $white;
				font-size: $fs_l;
				line-height: $lh_l;
				@include media(ln) {border-bottom: $borderDot;}
				@include media(lx) {
					@include margin(0);
					padding: 10px 10px 20px 10px;
					width: $fullWidth;
					font-size: $fs_m;
				}
			}//txt-box

		}//li
		> li:last-child {
			> .ttl-box {
				border-bottom: none;
				&::before {display: none;}
			}
			> .txt-box{border-bottom: none;}
		}
	}//step-area

	.guide {
		position: relative;
		background-color: $white;
		> .ttl {
			padding: 10px;
			@include ie-hack(gteIE11) {padding-top: 17px;}
			width: $fullWidth;
			background-color: $darkgray;
			color: $white;
			font-size: $fs_xl;
			@include media(lx) {font-size: $fs_l;}
		}
		> .wrap {
			padding: 20px;
			width: $fullWidth;
			font-size: $fs_l;
			@include media(lx) {
				padding: 10px;
				font-size: $fs_m;
			}
		}

		&.-area01 {
			@include margin(10px 10px 0 0);
			width: 370px;
			@include media(lx) {
				@include margin(10px 0 0);
				width: $fullWidth;
			}
		}
		&.-area02 {
			@include margin(10px 0 0);
			width: 580px;
			@include media(lx) {width: $fullWidth;}
			@include media(ln) {
				> .wrap {padding-right: 180px;}
				> .photo {
					position: absolute;
					top: 45px;
					right: 30px;
					width: 120px;
					font-size: $fs_xs;
					text-align: center;
					img {margin-bottom: 3px;}
				}
			}
			@include media(lx) {
				> .photo {
					padding: 0 20px 20px 20px;
					font-size: $fs_s;
					text-align: center;
					img {margin-bottom: 5px;}
				}
			}
		}
	}//guide

	.photo-area {
	@include media(ln) {@include flex-between;}
		@include margin(10px 0 0);
		padding: 20px;
		width: $fullWidth;
		background-color: $white;
		> li {
			width: 300px;
			@include media(lx) {width: $fullWidth;}
			img {margin: 5px 0;}
		}
	}

}//flow


/* ============================================================
	soudankai/webmtg-box
============================================================ */
.webmtg-box {
	@include flex-between;
	@include flex-center-tb;
	margin-top: 30px;
	padding: 10px 20px;
	border: $borderSol;
	> .photo {width: 300px;height: auto;}
	> .detail {
		width: 720px;
		> .txt {margin-bottom: 20px;}
	}//detail
	@include media(lx) {
		display: block;
		padding: 10px;
		margin: 20px 0 10px;
		> .photo {
			margin: 10px 0;
			width: $fullWidth;
			text-align: center;
			img {max-width: 300px;}
		}
		> .detail {
			width: $fullWidth;
		}
	}//media(lx)
}//webmtg-box


/* ============================================================
	soudankai/othermtg-box
============================================================ */
.othermtg-box {
	@include flex-between;
	margin-top: 30px;

	> .detail {
		padding: 10px 20px;
		margin-right: 2%;
		width: 32%;
		border: $borderSol;
		&:last-child {margin-right: 0;}
		> .h4ttl-line > [class^="icon-"] {
			margin-right: 6px;
			font-size: 2.2rem;
		}
		> .h4ttl-line > .label-red {
			background-color: $publi-baseColor;
			color: $white;
			border-radius: 4px;
			padding: 5px 10px;
			font-size: $fs_l;
			margin-right: 1rem;
		}
		> .txt {
			margin-bottom: 20px;
			text-align: justify;
		}

		&.-long {
			width: 66%;
			@include media(lx) {width: $fullWidth;}
			> .webmtg {
				@include flex-between;
				@include flex-center-tb;
				> .photo {width: 300px;height: auto;}
				> .detail {
					width: 370px;
					> .txt {margin-bottom: 20px;}
				}//detail
				@include media(lx) {
					display: block;
					> .photo {
						margin: 10px 0;
						width: $fullWidth;
						text-align: center;
						img {max-width: 300px;}
					}
					> .detail {width: $fullWidth;}
				}//media(lx)
			}//webmtg-box
		}//-long

	}//detail

	@include media(lx) {
		display: block;
		margin: 20px 0 10px;
		> .detail {
			padding: 10px;
			margin: 0 0 20px 0;
			width: $fullWidth;
			&:last-child {margin-bottom: 0;}
		}
	}//media(lx)
}//othermtg-box


/* ============================================================
	soudankai/selectmtg-box
============================================================ */
.selectmtg-box {
	display: block;
	margin-top: 30px;
	@include media(lx) {
		margin: 20px 0 10px;
	}//media(lx)

	> .detail {
		margin: 0 0 10px 0;
		padding: 10px 20px;
		width: $fullWidth;
		border: $borderSol;
		&:last-child {margin-bottom: 0;}
		@include media(lx) {
			padding: 10px;
		}//media(lx)

		> .h4ttl-line > [class^="icon-"] {
			margin-right: 6px;
			font-size: 2.2rem;
		}
		> .h4ttl-line > .label-red {
			background-color: $publi-baseColor;
			color: $white;
			border-radius: 4px;
			padding: 5px 10px;
			font-size: $fs_l;
			margin-right: 1rem;
		}

		> .wrap {
			@include flex-wrap;
			margin-bottom: 5px;
			@include media(lx) {
				display: block;
			}
			> .txt {
				text-align: justify;
				flex: 1;
			}
			>.btn-box {
				@include media(ln) {
					margin-left: 20px;
					max-width: 272px;
					> .btn-publi:last-child {min-width: 272px;}
				}
				@include media(lx) {
					margin-top: 5px;
					> .btn-publi {min-width: 212px;}
					> .btn-publi.-blank::before {font-size: 1.2rem;}
				}
			}
		}//wrap
	}//detail
}//selectmtg-box