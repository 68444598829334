@charset "utf-8";

@include media(lx) {
/* ============================================================
	global-header
============================================================ */
.global-header {
	position: relative;
	@include margin;
	width: $fullWidth;
	height: 56px;
	background: $white;
	border-bottom: $borderSol;
	@include z-index(hamburger);

	> .logo {
		position: absolute;
		top: 12px;
		left: 5px;
		width: 124px;
		height: auto;
	}

	> .lead {display: none;}

	> .tel-box {
		position: absolute;
		top: 0;
		right: 120px;
		color: $textColor;
		font-size: $fs_s;
		@include bold;
		@include transition;

		.time, .time .label {display: none;}

		.tel {
			> a.size-sp {
				display: flex !important;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				width: 60px;
				height: 55px;
				border-left: $borderSol;
				font-size: 2.4rem;
				color: $baseColor;
				text-align: center;
				@include link-base;
				.jpn {
					display: block;
					margin-top: 5px;
					color: $textColor;
					font-size: $fs_xs;
					@include bold;
				}
			}//a.size-sp
		}//tel
	}//tel-box

	.global-searchbtn {
		@include flex;
		position: absolute;
		top: 0;
		right: 60px;
		@include transition;
		> .trigger {
			@include flex-center;
			width: 60px;
			height: 55px;
			border-left: $borderSol;
			text-align: center;
			@include link-black;
			font-size: 2.4rem;
			> .icon-search::before {color: $baseColor;}
			.jpn {
				display: block;
				margin-top: 5px;
				color: $textColor;
				font-size: $fs_xs;
				@include bold;
			}//jpn
		}//p
	}//global-searchbtn

	.global-searchsp {
		@include z-index(search-sp);
		position: fixed;
		top: 54px;
		right: 0;
		margin: 0;
		padding: 0 12px;
		width: $fullWidth;
		display: none;

		&::before {
			position: absolute;
			top: -8px;
			right: 75px;
			content: "";
			border-bottom: 10px solid $bkinfo-baseColor;
			border-left: 15px solid transparent;
			border-right: 15px solid transparent;
		}
		&::after {
			position: absolute;
			top: -7px;
			right: 76px;
			content: "";
			border-bottom: 9px solid $bkinfo-hoverColor;
			border-left: 14px solid transparent;
			border-right: 14px solid transparent;
			text-decoration: none;
		}
	}//global-searchsp
	.global-searchsp.-open {
		transform: translate(0);
	}

	.global-searchsp > .wrap {
		width: 100%;
		background-color: $bkinfo-hoverColor;
		padding: 12px;
		border-radius: 10px;
		border: 1px solid $bkinfo-baseColor;
		font-size: $fs-m;
		position: relative;

		> .ttl {
			margin-bottom: 10px;
			padding: 6px;
			background: $bkinfo-baseColor;
			@include radius(5);
			font-size: $fs-l;
			color: $white;
			@include bold;
		}//ttl
		> .sbttl {
			margin: 10px 0 5px;
			font-size: $fs-l;
			color: $bkinfo-baseColor;
			@include bold;
		}//sbttl

		.searchsp-box {
			@include flex-center;
			li:first-child {width: 100% !important;}

			input[type="text"] {
				margin: 0;
				width: 100% !important;
				height: 36px;
			}

			.btn-search {
				display: inline-block;
				position: relative;
				margin: 0 0 0 5px;
				padding: 9px 10px;
				@include ie-hack(gteIE11) {padding-top: 17px;}
				color: $white;
				text-align: center;
				line-height: 1;
				@include radius(4);
				@include transition;
				cursor: pointer;
				background-color: $bkinfo-baseColor;
				border: 1px solid $bkinfo-baseColor;
				white-space: nowrap;

				&:visited {color: $white;}

				@include hover() {
					opacity: 1;
					background-color: $white;
					color: $bkinfo-baseColor;
					text-decoration: none;
				}
			}//btn-search
		}//search-box

		.category-list {
			height: 230px;
			border: 1px solid #dcbf3c;
			background-color: #fdf3c7;
			overflow-y: scroll;

			> .switch {
				border-bottom: 1px solid #EEE0A1;
				position: relative;
				display: flex;
				align-items: center;
				padding: 10px;
				text-align: left;
				text-decoration: none;
				&:last-of-type {border-bottom: none;}
				&::after {@include plus;color: $bkinfo-baseColor;}
				&.-active::after {@include minus;}
			}//switch

			> .genre {
				display: none;
				border-bottom: 1px solid #EEE0A1;
				&:last-of-type {
					border-top: 1px solid #EEE0A1;
					border-bottom: none;
				}
				> a {
					background-color: #fefae8;
					border-bottom: 1px solid #EEE0A1;
					position: relative;
					display: flex;
					align-items: center;
					padding: 10px;
					text-align: left;
					text-decoration: none;
					&:last-child {border-bottom: none;}
					&::after {@include arrow-right;color: $bkinfo-baseColor;}
				}
			}//genre
		}//category-list

	}//wrap

}//global-header


/* ============================================================
	global-nav changeold

.global-nav {
	@include flex;
	position: absolute;
	top: 0;
	right: 60px;
	@include transition;

	> li {position: relative;}
	> li > .ctg {
		@include flex-center;
		width: 60px;
		height: 55px;
		border-left: $borderSol;
		text-align: center;
		@include link-black;

		.icon-publishing {margin-right: 0;}

		> .ttl {
			@include bold;
			font-size: 2.4rem;
			> .icon-home::before {color: $baseColor;}
			> .jpn {
				display: block;
				margin-top: 4px;
				font-size: $fs_xs;
			}//jpn
		}
		> .eng {display: none;}

	}//ctg
}//global-nav old
============================================================ */

/* ============================================================
	global-nav changenew
============================================================ */
.global-nav {display: none;}


/* ============================================================
	header-nav
============================================================ */
.header-nav {
	@include z-index(navsp);
	position: fixed;
	top: 0;
	right: 0;
	margin: 0;
	padding-top: 55px;
	width: $fullWidth;
	height: $fullWidth;
	overflow-y: scroll;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	background-color: rgba(255,255,255,0.95);
	@include transition;
	transform: translate($fullWidth);
}//header-nav
.header-nav.-open {
	transform: translate(0);
}


/* ============================================================
	menu-mgdr
============================================================ */
.menu-mgdr {
	display: block;
	position: relative;
	margin: 0;
	width: $fullWidth;

	.first {
		display: none;
		padding: 0;
		width: $fullWidth;
		@include z-index(first);
	}//first

	.first > .ctg {
		position: relative;
		@include flex;
		@include flex-center-tb;
		padding: 0 20px;
		text-align: left;
		@include link-black;
		border-bottom: $borderDot;
		&::after {@include arrow-right;}
		&[target="_blank"]::after {content: $icon-blank;}

		> .ttl {
			@include margin(0 0 0 5px);
			font-size: $fs_m;
			@include bold;
		}
		> .eng {display: none;}
	}//ctg
	.sp-drawer + .first.-contact > .ctg {display: none;}

	.first > .second {
		display: block;
		position: relative;
		padding: 0;
		width: $fullWidth;
		text-align: left;
		transition: all .2s ease;
		@include z-index(default);
		cursor: default !important;
	}

	.first > .second.-neo,
	.first > .second.-ebook,
	.first > .second.-library {display: none !important;}

	.first > .second a {
		display: inline-block;
		position: relative;
		margin: 0;
		padding: 12px 20px;
		width: $fullWidth;
		border-bottom: $borderDot;
		text-align: left;
		@include link-black;
		img {display: none;}

		> .ttl {
			margin: 0;
			font-size: $fs_s;
		}

		&::after {@include arrow-right;}
		&[target="_blank"]::after {content: $icon-blank;}
	}//second a

	.first {
		@include hover() {
			cursor: pointer;
			.ctg {
				background-color: $publi-hoverColor;
				> .ttl {color: $publi-iconColor;}
			}
			> .second {display: flex;}
		}
	}

	[class^="icon2-"] {display: inline-block;}
	.icon2-contest {
		top: 4px;
		width: 28px;
		height: 36px;
		&::after {font-size: 28px;}
		&::before {font-size: 28px;color: $publi-iconColor;}
	}
	.icon2-publi-work {
		top: 8px;
		left: 4px;
		width: 28px;
		height: 36px;
		&::after {font-size: 20px;}
		&::before {font-size: 20px;color: $publi-iconColor;}
	}
	.icon2-publi-self {
		top: 5px;
		width: 28px;
		height: 36px;
		&::after {font-size: 28px;}
		&::before {font-size: 28px;color: $publi-iconColor;}
	}
	.icon2-publi-guide {
		top: 4px;
		width: 28px;
		height: 36px;
		&::after {font-size: 28px;}
		&::before {font-size: 28px;color: $publi-iconColor;}
	}
	.icon2-contact {
		width: 29px;
		height: 29px;
		&::after {font-size: 29px;color: $white;}
		&::before {font-size: 29px;color: $white;}
	}

	.icon2-hot {
		top: 3px;
		width: 28px;
		height: 36px;
		&::after {font-size: 28px;}
		&::before {font-size: 28px;color: $bkinfo-iconColor;}
	}
	.icon2-neo {
		top: 7px;
		left: 2px;
		width: 22px;
		height: 36px;
		&::before {font-size: 22px;color: $bkinfo-iconColor;}
	}
	.icon2-picturebook {
		top: 7px;
		width: 24px;
		height: 36px;
		&::after {font-size: 24px;}
		&::before {font-size: 24px;color: $bkinfo-iconColor;}
	}
	.icon2-ebook {
		top: 7px;
		width: 24px;
		height: 36px;
		&::after {font-size: 24px;}
		&::before {font-size: 24px;color: $bkinfo-iconColor;}
	}
	.icon2-library {
		top: 6px;
		width: 24px;
		height: 36px;
		&::after {font-size: 24px;}
		&::before {font-size: 24px;color: $bkinfo-iconColor;}
	}

	.icon2-company {
		top: 6px;
		width: 24px;
		height: 36px;
		&::after {font-size: 24px;}
		&::before {font-size: 24px;color: $about-iconColor;}
	}
	.icon2-philosophy {
		top: 7px;
		width: 24px;
		height: 36px;
		&::after {font-size: 24px;}
		&::before {font-size: 24px;color: $about-iconColor;}
	}
	.icon2-commitment {
		top: 7px;
		width: 22px;
		height: 36px;
		&::before {font-size: 22px;color: $about-iconColor;}
	}

	.sp-drawer {display: block;}
	.sp-drawer > .gnav {
		position: relative;
		@include flex;
		@include flex-center-tb;
		padding: 10px;
		text-align: left;
		@include link-white;
		&::after {@include plus;color: $white;}

		&.-publi {background: $publi-drawrColor;}
		&.-bkinfo {background: $bkinfo-drawrColor;}
		&.-about {background: $about-drawrColor;}
		&.-news {
			background: $news-drawrColor;
			&::after {@include arrow-right;}
		}
		&.-contact {background: $contact-drawrColor;}

		[class^="icon-"] {
			display: inline-block;
			padding-top: 1px;
			width: 30px;
			height: 30px;
			&::before {font-size: 28px;color: $white;}
		}
		.icon-publishing {
			padding-top: 0;
			&::before {font-size: 30px;}
		}

		> .ttl {
			@include margin(0 0 0 5px);
			font-size: $fs_l;
			@include bold;
		}
		> .eng {display: none;}
	}//gnav
	.sp-drawer > .gnav.-active {
		&::after {@include minus;}
	}

	.first > .ctg.-toplink {
		display: inline-block;
		margin: 0;
		padding: 12px 20px;
		width: 100%;
		border-bottom: $borderDot;
		text-align: left;
		@include link-black;

		> .ttl {
			margin: 0;
			font-size: $fs_m;
			@include bold;
		}
	}//gnav

	.first:nth-child(6) > .second a:last-of-type {border-bottom: none;}
	.first.-bkinfo#bkinfo05 > .ctg {border-bottom: none;}
}//menu-mgdr


/* ===================================================================
	header-navsp
=================================================================== */
.header-navsp {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	width: 60px;
	height: 55px;

	> .trigger {
		border-left: $borderSol;
		position: absolute;
		width: 100%;
		background-color: transparent;
		cursor: pointer;
		height: 55px;
		line-height: 0;
		right: 0;
		top: 50%;
		bottom: auto;
		transform: translateY(-50%);

		.hamburger {
			display: inline-block;
			position: absolute;
			top: 50%;
			bottom: auto;
			right: 16px;
			left: auto;
			transform: translateX(0) translateY(-50%);
			width: 25px;
			height: 2px;
			background-color: $textColor;
			transition: background-color 0.3s;

			&::before, &::after {
				content: '';
				width: $fullWidth;
				height: $fullWidth;
				position: absolute;
				background-color: $textColor;
				right: 0;
				transition: transform .3s, top .3s, background-color 0s;
			}
			&::before {top: -8px;}
			&::after {top: 8px;}
			&.-clicked {
				background-color: rgba(255, 255, 255, 0);
				&::before, &::after {
					background-color: $textColor-light;
				}
				&::before {top: 0;transform: rotate(135deg);}
				&::after {top: 0;transform: rotate(225deg);}
			}

		}//hamburger
	}//trigger

}//header-navsp


/* ========================================================= */
}//media(lx)