@charset "utf-8";

/* ============================================================
	contest-list
============================================================ */
.contest-ttl {
	margin-bottom: 30px;
	text-align: center;
	> .ttl {
		@include margin;
		display: inline-block;
		padding-left: 55px;
		&::after,
		&::before {top: -6px;font-size: 50px;}
		&::before {color: $publi-iconColor;}
		@include media(lx) {
			padding-left: 45px;
			&::after,
			&::before {top: -8px;font-size: 40px;}
		}
		span {
			font-size: 3.4rem;
			@include jpn;
			@include bold;
			@include media(lx) {
				font-size: 2.6rem;
			}
		}
	}//ttl
}//contest-ttl

.contest-list {
	@include media(mn) {@include flex-wrap;}
	> .item-box {
		position: relative;
		margin: 16px 16px 0 0;
		width: 258px;
		border: $borderSol;
		&:nth-child(4n) {margin: 16px 0 0 0;}
		@include media(lx) {
			margin: 16px 0 0 0;
			&:nth-child(odd) {margin: 16px 16px 0 0;}
			width: 48.6%;
		}
		@include media(mx) {
			margin: 16px 0 0 0;
			width: $fullWidth;
		}
	}

	> .item-box a.item {
	display: block;
	padding: 14px;
	width: 258px;
	@include link-black;
	cursor: pointer;
	@include media(lx) {
		margin: 0;
		width: $fullWidth;
		height: auto;
	}

	> .ttl {
		@include flex-center;
		min-height: 44px;
		@include fs-16m;
		@include bold;
		text-align: center;
	}
	> .bnr {margin: 15px 0;}
	> .txt {min-height: 57px;@include fs-14m;text-align: justify;}
	> .info {
		margin: 15px 0;
		min-height: 38px;
		@include fs-14m;
	}
	.link {
		position: relative;
		margin: 20px 0 0 0;
		padding-right: 15px;
		color: $publi-baseColor;
		text-align: right;

		&::before {
			font-family: '#{$icomoon-font-family}' !important;
			content: $icon-arrow-right;
			position: absolute;
			top: 50%;
			right: 0;
			font-size: $fs_s;
			transform: translate(0,-50%);
			display: inline-block;
			transition: right .1s;
			@include media(lx) {
				font-size: $fs_xs;
			}
		}
	}
	> .status {
		position: absolute;
		top: 5px;
		left: 5px;
		z-index: 3;
		color: $white;
		@include bold;
	}
		@include hover() {
			.link {
				&::before {right: -5px;}
			}
		}
	}//a.item

	> .item-box a.item.-now {
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			border-bottom: 5em solid transparent;
			border-left: 5em solid #00a1e9;
			z-index: 2;
		}
	}//now
	> .item-box a.item.-shortly {
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			border-bottom: 5em solid transparent;
			border-left: 5em solid $publi-baseColor;
			z-index: 2;
		}
		> .info {
			color: $publi-baseColor;
			@include bold;
		}
	}//shortly
	> .item-box a.item.-finish {
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			border-bottom: 5em solid transparent;
			border-left: 5em solid $gray;
			z-index: 2;
		}
	}//finish
	> .item-box a.item.-result {
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			border-bottom: 5em solid transparent;
			border-left: 5em solid #c1ab05;
			z-index: 2;
		}
	}//result

}//contest-list

.past-wrap {
	text-align: center;
}
.past-btn {
	display: inline-block;
	margin-top: 30px;
}
.past-item {
	position: relative;
	overflow: hidden;
	height: 0;
	text-align: left;
	opacity: 0;
	transition: 0.8s;
}
.past-trigger {
	display: none;
}
.past-trigger:checked + .past-btn {
	display: none;
}
.past-trigger:checked ~ .past-item {
	height: auto;
	opacity: 1;
}


/* ============================================================
	contest-parts
============================================================ */
.contest-main {
	margin-bottom: 15px;
}

.contest-guide {
	border: $borderSol;
	@include media(ln) {width: 1080px;}
	table-layout: fixed;
	> tbody tr, > tbody th, > tbody td {border: $borderSol;}
	> tbody th, > tbody td {padding: 15px;line-height: $lh_l;}

	> tbody th {
		width: 140px;
		background: #ffe6d8;
		text-align: center;
		vertical-align: top;
	}
	> tbody td {
		width: 939px;
		text-align: left;
		> .list {
			@include media(ln) {
				@include flex-wrap;
				>.ttl {width: 90px;margin-bottom: 0;}
				>.txt {width: 810px;}
				>.txt:last-of-type {margin-bottom: 0;}
			}
		}//list
		.attention {
			margin-bottom: 20px;
			padding: 8px;
			background-color: #fbded9;
			border: 1px solid $red;
		}//attention
	}

	> tbody tr.finish th {background: #f5b1aa;}
	> tbody tr.finish td {background: #fff9f9;}
	> tbody tr.finish td p:first-of-type {margin-bottom: 10px;}

	.template-area {
		@include media(ln) {
			@include flex-between;
			@include flex-center-tb;
		}
		margin: 15px auto;
		padding: 15px 45px 15px 15px;
		background-color: $gray-e;

		.btnw-publi {
			@include media(ln) {padding: 10px 50px 10px 40px;}
			width: 260px;
			@include fs-14m;
			> span {font-size: $fs_s;}
		}
	}//template-area
	.btn-entry {
		display: block;
		margin: 0 auto;
		text-align: center;
		.btn-publi {
			@include media(ln) {padding: 10px 50px 10px 40px;}
			width: 500px;
			@include media(lx) {margin-bottom: 10px;width: $fullWidth;}
		}
	}

	@include media(lx) {
		> tbody th, > tbody td {
			display: block;
			padding: 5px;
			line-height: $lh_l;
			border: none;
			width: $fullWidth;
		}
		> tbody th {
			border-bottom: $borderSol;
			text-align: left;
		}
		.template-area {
			margin: 15px auto;
			padding: 15px;

			.btnw-publi {
				margin-top: 10px;
				width: $fullWidth;
			}
		}//template-area
	}//media(lx)

}//contest-guide


.contest-alert {
	margin-bottom: 20px;
	padding: 15px;
	width: 100%;
	background-color: $bg-alert;
	@include fs-16l;
	@include media(lx) {
		padding: 10px;
		@include fs-14m;
	}
}//contest-alert

.contest-entryinfo {
	margin-bottom: 20px;
	@include bold;
	> .txt {
		@include fs-16l;
		@include normal;
		@include media(lx) {
			@include fs-14l;
		}
	}
}

.contest-entrybox {
	position: relative;
	margin: 10px 0 0 0;
	padding: 20px 15px 20px 98px;
	border: 1px solid #ff0000;
	border-radius: 5px;
	overflow: hidden;
	line-height: $lh_l;
	@include media(lx) {
		padding: 40px 10px 10px 10px;
	}

	> .ttl {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 30px;
		line-height: 30px;
		font-size: 16px;
		color: #ffffff;
		font-weight: 800;
		text-align: center;
		background: #ff0000;
	}//ttl

	> .sbttl {
		margin-bottom: 10px;
		@include bold;
	}
	&.-post > .add {
		@include fs-18l;
		@include bold;
	}
	&.-mail > .add {
		margin: 20px 0;
		font-size: 2.8rem;
		@include gfont-Lato700;
		//@include media(lx) {font-size: 2.4rem;}
	}
	&.-fax {
		min-height: 100px;
		> .number {
			margin-top: 10px;
			font-size: 2.8rem;
			@include gfont-Lato700;
			//@include media(lx) {font-size: 2.4rem;}
		}
	}//-fax

	@include media(ln) {
		&.-post::before {
			content: " ";
			position: absolute;
			top: 0;
			left: 0;
			width: 83px;
			height: 100%;
			text-align: center;
			background: #ff0000 url(/contest/images/contest_entry_01.gif) 50% 50% no-repeat;
			border-radius: 4px 0 0 4px;
		}
		&.-mail::before {
			content: " ";
			position: absolute;
			top: 0;
			left: 0;
			width: 83px;
			height: 100%;
			text-align: center;
			background: #ff0000 url(/contest/images/contest_entry_02.gif) 50% 50% no-repeat;
			border-radius: 4px 0 0 4px;
		}
		&.-web::before {
			content: " ";
			position: absolute;
			top: 0;
			left: 0;
			width: 83px;
			height: 100%;
			text-align: center;
			background: #ff0000 url(/contest/images/contest_entry_03.gif) 50% 50% no-repeat;
			border-radius: 4px 0 0 4px;
		}
		&.-fax:before{
			content: " ";
			position: absolute;
			top: 0;
			left: 0;
			width: 83px;
			height: 100%;
			text-align: center;
			background: #ff0000 url(/contest/images/contest_entry_04.gif) 50% 50% no-repeat;
			border-radius: 4px 0 0 4px;
		}
	}//media(ln)

	.btn-area {
		@include media(ln) {@include flex-between;@include flex-center-tb;}
		margin: 15px auto 0;
		padding: 15px;
		background-color: $gray-e;
		@include fs-14l;

		.btnw-publi {
			@include media(ln) {padding: 10px 50px 10px 40px;}
			@include media(lx) {margin-top: 10px;width: $fullWidth;}
		}
		.btn-publi {
			@include media(ln) {padding: 10px 50px 10px 40px;}
			width: 344px;
			@include media(lx) {margin-top: 10px;width: $fullWidth;}
		}
	}//template-area

}//contest-entrybox


.contest-entrynote {
	margin: 15px auto;
	padding: 15px;
	background-color: $gray-e;
	@include fs-14l;
}

.contest-inquiry {
	margin: 0 auto;
	@include media(ln) {
		@include flex-between;
		@include flex-center-tb;
	}

	> li {
		display: inline-block;
		margin: 0;
		padding-left: 60px;
		color: #fc0d1b;
		font-size: 3.2rem;
		line-height: 1;
		@include bold;
	}

	@include media(ln) {
		> li.mail {
			@include flex;
			@include flex-center-tb;
			height: 43px;
			background: url(/contest/images/contest_inquiry_mail.png) left center no-repeat;
		}
		> li.tel-box {background: url(/contest/images/contest_inquiry_tel.png) left center no-repeat;}
		> li.fax {background: url(/contest/images/contest_inquiry_fax.png) left center no-repeat;}
		> li > p.ttl {display: none;}
	}
	> li.mail {@include gfont-Lato700;}
	> li > .tel {@include gfont-Lato700;}
	> li > .number {@include gfont-Lato700;}
	> li > .time {
		margin-top: 5px;
		color: $textColor;
		font-size: $fs_m;
		@include normal;
	}
	@include media(lx) {
		> li {
			display: block;
			font-size: 3rem;
			padding: 10px 0;
			border-bottom: 1px dotted #cccccc;
			&:first-of-type {padding-top: 0;}
		}
		> li > p {display: inline-block;}
		> li > p.ttl {
			margin-right: 5px;
			font-size: $fs_m;
			@include gfont-Lato700;
		}
		> li.tel-box a {color: #fc0d1b;}
		> li > .time {
			margin-top: 0;
			font-size: $fs_s;
		}
	}
}//contest-inquiry

/* ============================================================
	contest-extend
============================================================ */
.contest-extend {
	margin-bottom: 20px;
	padding: 8px;
	background-color: #fff2f2;
	border: 1px solid $red;
	line-height: 1.5;
}

/* ============================================================
	contest-report
============================================================ */
.contest-report {
	margin: 30px 0 0;
	> h2 {
		font-size: 2.0rem;
		margin-bottom: 15px;
		@include bold;
	}
	> .txt-box {
		display: inline-block;
		font-size: 1.4rem;
		line-height: 1.7;
	}
	> .txt-box .ph-box {
		float: right;
		margin: 5px 0 8px 10px;
		width: 260px;
		text-align: center;
		font-size: 1.2rem;
		line-height: 1;
		clear: both;
		img {margin-bottom: 5px;}
	}
	@include media(lx) {
		> .txt-box .ph-box {
			margin: 0 0 25px;
			width: 100%;
			img {width: 260px;}
		}
	}
}//contest-report


/* ============================================================
	contest-result
============================================================ */
.contest-result {
	margin: 30px 0 40px;
	padding: 30px 20px;
	box-sizing: border-box;
	border: 1px solid #1572b8;
	background-color: #f2f8fc;
	text-align: center;
	@include media(lx) {
		padding: 20px 10px;
		width: 100%;
	}

	> h2 {
		@include flex-center;
		margin-bottom: 40px;
		text-align: center;
		color: #36357e;
		font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
		font-weight: 700;
		@include txt-deco_bdw;
		transform: rotate(.03deg);
		@include media(lx) {margin-bottom: 30px;}
		span {
			font-size: 2rem;
			@include media(lx) {font-size: 1.6rem;}
		}
		span.ttl {
			margin: 0 20px;
			font-size: 3.6rem;
			@include media(lx) {
				margin: 0 10px;
				font-size: 2.8rem;
			}
		}
	}//h2

	> .genre01 {
		margin-bottom: 30px;
		font-size: 3rem;
		color: #1467b3;
		@include media(lx) {
			margin-bottom: 20px;
			font-size: 2.4rem;
		}
	}
	> .genre02 {
		margin: 60px 0 20px;
		font-size: 3rem;
		color: #257b8a;
		@include media(lx) {
			margin: 40px 0 20px;
			font-size: 2.4rem;
		}
	}

	> h5 {
		margin: 30px 0 10px;
		padding: 5px 10px;
		background: #1572b8;
		font-size: 1.6rem;
		line-height: 1.5;
		font-weight: 700;
		color: #ffffff;
		text-align: left;
		@include media(lx) {margin: 20px 0 10px;}
	}//h5

	> .txt {
		padding: 0 10px;
		text-align: justify;
	}

	.grand {
		color: #00548a;

		> h4 {
			margin: 0;
			font-size: 3.6rem;
			line-height: 1;
			font-weight: 700;
			color: #b38813;
			font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
			@include txt-deco_bdw;
			@include media(lx) {font-size: 2.8rem;}
		}
		> .prize {
			margin: 10px 0;
			font-size: 1.8rem;
			line-height: 1.4;
			font-weight: 700;
			color: #b38813;
			font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
			@include txt-deco_bdw;
			@include media(lx) {font-size: 1.6rem;}
		}
		> .title {
			margin: 20px 0 10px;
			font-size: 4.0rem;
			line-height: 1.4;
			font-weight: 700;
			text-align: center;
			@include media(lx) {margin: 15px 0 10px;font-size: 3rem;}
		}
		> .author {
			font-size: 2.8rem;
			line-height: 1;
			font-weight: 700;
			text-align: center;
			@include media(lx) {font-size: 2.2rem;}
			> span {
				font-size: 2.2rem;
				@include media(lx) {font-size: 1.8rem;}
			}
		}
	}//grand

	.selected {
		margin-top: 60px;
		color: #00548a;
		@include media(lx) {margin-top: 40px;}

		> h4 {
			margin: 0;
			font-size: 3rem;
			line-height: 1;
			font-weight: 700;
			color: #888;
			font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
			@include txt-deco_bdw;
			@include media(lx) {font-size: 2.6rem;}
		}
		> .prize {
			margin: 10px 0;
			font-size: 1.6rem;
			line-height: 1.4;
			font-weight: 700;
			color: #888;
			font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
			@include txt-deco_bdw;
			@include media(lx) {font-size: 1.4rem;line-height: 1.3;}
		}
		> .title {
			margin: 20px 0 10px;
			font-size: 3.4rem;
			line-height: 1.4;
			font-weight: 700;
			text-align: center;;
			@include media(lx) {margin: 15px 0 10px;font-size: 2.8rem;}
		}
		> .author {
			font-size: 2.6rem;
			line-height: 1;
			font-weight: 700;
			text-align: center;
			@include media(lx) {font-size: 2rem;}
			> span {
				font-size: 2rem;
				@include media(lx) {font-size: 1.6rem;}
			}
		}
	}//selected

	> ul.list {
		display: flex;
		flex-wrap: wrap;
		@include media(lx) {display: block;}
		> li {
			margin: 10px 5px 0;
			padding: 5px 10px;
			width: 336px;
			background: #ffffff;
			border: 1px solid #257b8a;
			font-size: 1.6rem;
			line-height: 1.5;
			font-weight: 700;
			text-align: left;
			@include media(lx) {width: auto;}

			span {text-align: right;font-weight: 500;font-size: 1.5rem;}
		}//li
	}//ul

}//contest-result


/* ============================================================
	contest-ehon
============================================================ */
.ehon-info {
	padding: 10px;
	@include ie-hack(gteIE11) {padding-top: 17px;}
	text-align: center;
	font-size: 2.2rem;
	line-height: $lh_m;
	color: #fff;
	font-weight: 500 !important;
	@include media(lx) {
		font-size: $fs_l;
	}
}

.ehon-memo {
	margin-bottom: 15px;
	padding: 15px;
	border: 1px solid #ffc99e;
	background: #fffde7;
	color: #8a745d;
	font-size: $fs_m;
	line-height: $lh_l;
	text-align: center;
	@include media(lx) {
		padding: 10px;
		font-size: $fs_s;
		line-height: $lh_m;
		text-align: left;
	}
}//ehon-memo

.ehon-report {
	margin: 30px 0 0;
	> h2 {
		font-size: 2.0rem;
		margin-bottom: 15px;
		@include bold;
	}
	> .txt-box {
		display: inline-block;
		font-size: 1.4rem;
		line-height: 1.7;
	}
	> .txt-box .ph-box {
		float: right;
		margin: 5px 0 8px 10px;
		width: 260px;
		text-align: center;
		font-size: 1.2rem;
		line-height: 1;
		clear: both;
		img {margin-bottom: 5px;}
	}
	@include media(lx) {
		> .txt-box .ph-box {
			margin: 0 0 25px;
			width: 100%;
			img {width: 260px;}
		}
	}
}//ehon-report

.ehon-result {
	margin: 30px 0 40px;
	padding: 30px 100px 60px;
	box-sizing: border-box;
	background-color: #ffffef;
	border: 1px solid #e8542d;
	@include media(lx) {
		margin: 20px 0 30px;
		padding: 20px 10px 10px;
	}

	> h2 {
		@include flex-center;
		margin-bottom: 30px;
		text-align: center;
		color: #e8542d;
		font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
		font-weight: 700;
		@include txt-deco_bdw;
		transform: rotate(.03deg);
		@include media(lx) {margin-bottom: 20px;}
		span {
			font-size: 2rem;
			@include media(lx) {font-size: 1.6rem;}
		}
		span.ttl {
			margin: 0 20px;
			font-size: 3.6rem;
			@include media(lx) {
				margin: 0 10px;
				font-size: 2.8rem;
			}
		}
	}//h2

	.in-box {
		text-align: center;
		border-radius: 12px;
		@include media(lx) {border-radius: 6px;}

		> h3 {
			margin: 30px 0;
			position: relative;
			text-align: center;
			font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
			@include txt-deco_bdw;
			@include media(lx) {margin: 20px 0;}

			span {
				position: relative;
				z-index: 2;
				display: inline-block;
				margin: 0 4em;
				padding: 0 1em;
				background-color: #efffff;
				text-align: left;
				color: #086eff;
				font-size: 3rem;
				@include media(lx) {
					position: relative;
					z-index: 2;
					display: inline-block;
					margin: 0 1em;
					padding: 0 .5em;
					font-size: 2.4rem;
				}
			}//span
			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				z-index: 1;
				width: 100%;
				height: 2px;
				background: #086eff;
			}
		}//h3

		&.-ehon {
			background-color: #efffff;
			border: 1px solid #086eff;
			color: #086eff;
		}
		&.-story {
			margin-top: 40px;
			background-color: #f0fff0;
			border: 1px solid #01a447;
			color: #01a447;
			@include media(lx) {margin-top: 20px;}
			> h3 span {background-color: #f0fff0;color: #01a447;}
			> h3::before {background: #01a447;}
		}

		.grand {
			> h4 {
				margin: 0;
				font-size: 3.6rem;
				line-height: 1;
				font-weight: 700;
				color: #b38813;
				font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
				@include txt-deco_bdw;
				@include media(lx) {font-size: 2.8rem;}
			}
			> .prize {
				margin: 10px 0;
				font-size: 2rem;
				line-height: 1;
				font-weight: 700;
				color: #b38813;
				font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
				@include txt-deco_bdw;
				@include media(lx) {font-size: 1.6rem;}
			}
			> .title {
				margin: 20px 0 10px;
				font-size: 4.0rem;
				line-height: 1.4;
				font-weight: 700;
				text-align: center;
				@include txt-deco_bdw_sdb;
				@include media(lx) {margin: 15px 0 10px;font-size: 3rem;}
			}
			> .author {
				font-size: 2.8rem;
				line-height: 1;
				font-weight: 700;
				text-align: center;
				@include txt-deco_bdw_sdb;
				@include media(lx) {font-size: 2.2rem;}
				> span {
					font-size: 2.2rem;
					@include media(lx) {font-size: 1.8rem;}
				}
			}
			> .txt {
				margin: 20px 0;
				padding: 0 40px;
				text-align: justify;
				font-size: 1.6rem;
				@include media(lx) {
					margin: 20px 0;
					padding: 0 10px;
					font-size: 1.4rem;
				}
			}
		}//grand

		.selected {
			margin: 50px 0;
			@include media(lx) {margin: 40px 0;}
			> h5 {
				margin: 0;
				font-size: 3rem;
				line-height: 1;
				font-weight: 700;
				color: #888;
				font-family: '游明朝', YuMincho, 'Yu Mincho', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
				@include txt-deco_bdw;
				@include media(lx) {font-size: 2.6rem;}
			}
			> .title {
				margin: 20px 0 10px;
				font-size: 3.4rem;
				line-height: 1.4;
				font-weight: 700;
				text-align: center;
				@include txt-deco_bdw_sdb;
				@include media(lx) {margin: 15px 0 10px;font-size: 2.8rem;}
			}
			> .author {
				font-size: 2.6rem;
				line-height: 1;
				font-weight: 700;
				text-align: center;
				@include txt-deco_bdw_sdb;
				@include media(lx) {font-size: 2rem;}
				> span {
					font-size: 2rem;
					@include media(lx) {font-size: 1.6rem;}
				}
			}
		}//selected
	}//in-box
}//ehon-result


/* ===================================================================
	ehon-awardlist
=================================================================== */
.ehon-awardlist {
	box-sizing: border-box;
	margin: 0;
	width: 1080px;
	padding: 25px 20px 20px;
	background: #fed001 url(/ehon/images/awardlist_bg.png) repeat;
	border: 1px solid #fd8900;
	border-radius: 5px;
	text-align: center;

	.award-set {
		box-sizing: border-box;
		margin: 30px auto 0;
		padding: 14px;
		background: rgba(255,255,255,0.3);
		border-radius: 5px;
		text-align: center;
	}
	> .award-set:first-child {
		margin-top: 0;
	}

	.award-set > .title {
		display: inline-block;
		position: relative;
		height: 45px;
		vertical-align: middle;
		text-align: center;
		box-sizing: border-box;
		margin: -30px 0 0;

		&::before {
			content: '';
			position: absolute;
			width: 10px;
			bottom: -10px;
			left: -35px;
			border: 20px solid #f7f7f7;
			border-left-color: transparent;
		}
		&::after {
			content: '';
			position: absolute;
			width: 10px;
			bottom: -10px;
			right: -35px;
			border: 20px solid #f7f7f7;
			border-right-color: transparent;
		}
	}//title

	.award-set > .title > h3 {
		display: inline-block;
		position: relative;
		margin: 0;
		padding: 0 20px;
		line-height: 45px;
		font-size: 1.8rem;
		font-weight: 700;
		color: #fe0000;
		background: #fff;
		z-index: 1;

		&::before {
			position: absolute;
			content: '';
			top: 100%;
			left: 0;
			border: none;
			border-bottom: solid 10px transparent;
			border-right: solid 15px #ccc;
		}
		&::after {
			position: absolute;
			content: '';
			top: 100%;
			right: 0;
			border: none;
			border-bottom: solid 10px transparent;
			border-left: solid 15px #ccc;
		}
	}//h3

	.award-set > .box {
		@include media(ln) {
			@include flex-between;
		}
		margin: 20px 0 0;
		> li {
			position: relative;
			width: 498px;
			min-height: 280px;
			border: 1px solid #ff3366;
			border-radius: 10px;
			padding-top: 31px;
			&.award01 {
				padding-top: 0;
				width: 100%;
			}
			&.award-single {
				width: 100%;
			}

			> h4 {
				position: absolute;
				top: 0;
				display: block;
				margin: 0;
				padding: 8px 0 7px 0;
				width: 100%;
				color: #fff;
				font-size: 1.6rem;
				background: #ff3366;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
			}
		}//li
	}//box

	.award-set >.note {
		margin-top: 10px;
		text-align: right;
}

	@include media(ln) {
		.award-set > .box dl {
			position: relative;
			top: 50%;
			left: 50%;
			transform : translate(-50%,-50%);

			@include flex-center;
			box-sizing: border-box;
			margin: 0;
		}
	}//media(ln)

	.award-set > .box dl dt {
		margin: 0;
		padding: 0 10px 0 0;
		text-align: center;

		> img {
			box-shadow: 3px 3px 10px rgba(0,0,0,0.5);
			width: auto;
		}
	}

	.award-set > .box dl dd {
		margin: 0;
		padding: 0;
		text-align: center;
		white-space: nowrap;
		min-width: 230px;

		> h5 {
			margin: 0 0 10px;
			color: #ff3366;
			font-size: 2.4rem;
			font-weight: 700;
			line-height: 1.2;
			@include txt-deco_bdw;
			&.long {letter-spacing: -.1em;}
		}

		> .name {
			margin: 0 0 15px;
			color: #ff3366;
			font-size: 1.8rem;
			font-weight: 700;
			line-height: 1.4;
			@include txt-deco_bdw;
		}

		> .detail {
			margin: 0 0 5px;
			font-size: 1.2rem;
			line-height: 1.4;
		}

		> a {
			position: relative;
			display: inline-block;
			font-size: 1.6rem;
			line-height: 24px;
			height: 36px;
			margin: 10px 0 0;
			padding: 6px 15px;
			text-decoration: none;
			color: #ffffff;
			font-weight: 700;
			border-radius: 5px;
			box-shadow: 3px 3px 10px rgba(0,0,0,0.8);
			border: 1px solid #fff;
			text-shadow: 0 0 5px rgba(51,102,144,1);
			background: linear-gradient(to bottom, #aeec65 0%,#55aa04 100%);
			backface-visibility: hidden;
			&:hover {opacity: .8;}
		}
	}//dd

	> .award-past > .past-btn {
		display: inline-block;
		margin: 20px 0 0;
		padding: 8px 8px 8px 27px;
		border-radius: 2px;
		background: #4169e1;
		color: #fff;
		font-size: 1.4rem;
		cursor: pointer;
		transition: .2s ease;
		box-shadow: 0 0 3px rgba(0,0,0,.3);
		position: relative;
		&:hover {
			background: #fff;
			color: #4169e1;
		}
		&::before {
			position: absolute;
			content: "";
			top: 9px;
			left: 10px;
			width: 10px;
			height: 10px;
			border-left: 2px solid #fff;
			border-bottom: 2px solid #fff;
			transform: rotate(-45deg);
			box-sizing: border-box;
		}
		&:hover::before {
			border-left: 2px solid #4169e1;
			border-bottom: 2px solid #4169e1;
		}
	}//past-btn

	> .award-past > .past-item {
		position: relative;
		overflow: hidden;
		height: 0;
		opacity: 0;
		transition: 0.8s;
	}
	> .award-past > .past-trigger {
		display: none;
	}
		> .award-past > .past-trigger:checked + .past-btn {
		display: none;
	}
	> .award-past > .past-trigger:checked ~ .past-item {
		height: auto;
		opacity: 1;
	}

}//ehon-awardlist


/* ===================================================================
	ehon-awardlist-sp
=================================================================== */
@include media(lx) {
	.ehon-awardlist {
		width: 100%;
		padding: 25px 10px 10px;

		.award-set {
			padding: 10px;
		}

		.award-set > .title {
			height: 35px;
			margin: -20px 0 0;

			&::before {
				bottom: -8px;
				left: -22px;
				border: 13px solid #f7f7f7;
				border-left-color: transparent;
			}
			&::after {
				bottom: -8px;
				right: -22px;
				border: 13px solid #f7f7f7;
				border-right-color: transparent;
			}
		}//title

		.award-set > .title > h3 {
			padding: 0 15px;
			line-height: 35px;
			font-size: 1.8rem;

			&::before {
				border: none;
				border-bottom: solid 8px transparent;
				border-right: solid 15px #ccc;
			}
			&::after {
				border: none;
				border-bottom: solid 8px transparent;
				border-left: solid 15px #ccc;
			}
		}//h3

		.award-set > .box {
			> li {
				margin: 20px 0 0;
				width: 100%;
				min-height: auto;
				padding-top: 0;

				> h4 {
					position: relative;
					top: auto;
				}
			}//li
		}//box

		.award-set > .box dl {
			display: block;
			margin: 0;
			padding: 15px 5px 15px 15px;
		}

		.award-set > .box dl dt {
			padding: 0 10px 20px 0;
		}

		.award-set > .box dl dd {
			padding: 0 10px 0 0;
			white-space: normal;
			min-width: auto;

			> h5 {
				font-size: 2rem;
				line-height: 1.2;
			}

			> .name {
				font-size: 1.6rem;
			}

		}//dd
	}//ehon-awardlist
}//media(lx)

@media only screen and (max-width: 345px) {
	.ehon-awardlist .award-set > .title > h3 {
		padding: 0 10px;
		font-size: 1.7rem;
	}
}


/* ============================================================
	contest-old
============================================================ */
.old-wrap {
	@include media(ln) {img {width: auto;}}
	h2, h3, h4, h5, h6 {font-weight: 700;}
	@include media(lx) {
		img.wauto {width: auto;}
	}
	@include media(xxsx) {
		img.wauto {width: auto;max-width: 280px;}
	}

	font-size: 1.4rem;
	line-height: 1.5;
	@include media(lx) {
		font-size: 1.2rem;
	}

	.result-box {
		margin: 40px auto;
		padding: 10px;
		width: 96% !important;
		border-radius: 3px;
		background: #f0b6b6;
		box-shadow: 0 0 10px 10px #f0b6b6;
		border: 1px solid #f0b6b6;

		@include media(lx) {
			padding: 10px 0 5px;
			width: 92% !important;
		}

		> h2 {text-align: center;}
		> h2.ttl {
			margin: 0;
			padding: 0;
			font-size: 3.6rem;
			color: #9B1041;
			text-shadow: -1px 1px 1px #fff, 0 0 5px #fff;
			> .deco {
				color: #f8a5c9;
				font-family: YuMincho, '游明朝', 'メイリオ', sans-serif;
			}
			@include media(lx) {font-size: 3.2rem;}
		}//ttl

		.memo {
			margin-top: 5px;
			font-size: 1.2rem;
			text-align: right;
			color: #808080;
		}
	}//result-box

	table.result-list {
		margin-top: 15px;
		width: 100%;
		border: #e18888 1px solid;
		border-collapse: collapse;
		background-color: #fff;
		text-align: left;

		tr {border: #e18888 1px solid;}
		td {border: #e18888 1px solid;padding: 3px 10px;vertical-align: top;}
		td.list1 {background: #fad6d6;width: 136px;min-width: 70px;white-space: nowrap;}
		td.list2 {background: #fad6d6;width: 294px}
		td.list3 {background: #fad6d6;width: 90px;min-width: 35px;white-space: nowrap;}

		@include media(lx) {
			td {padding: 3px 2px;}
		}
	}//result-list

	ul.prize-area {
		margin: 20px 4px 0;
		padding: 25px 5px;
		color: #36367E;
		border: 1px solid #ffffff;
		box-shadow: 0 0 0 4px #af96ad;
		background: #ffffff;
		@include media(lx) {padding: 20px 5px;}

		> li {
			text-align: center;
			line-height: 1.2;
			font-weight: 700;
			ruby > rt {font-size: 30%;}
			.prize {
				font-size: 1.6rem;
				@include media(lx) {font-size: 1.4rem;}
			}
			.author {margin-top: 10px;}
		}

		> li.gold {
			h3 {
				margin: 0;
				font-size: 3.6rem;
				color: #c19314;
				line-height: 1;
				text-shadow: -1px 1px 1px #ffffff, 0 0 5px #e7ce87;
			}
			.prize {margin: 10px 0 15px;color: #c19314;}
			.workttl {font-size: 3.6rem;}
			.author {
				font-size: 2.8rem;
				line-height: 1;
				span {font-size: 1.6rem;}
			}
			@include media(lx) {
				h3 {font-size: 3rem;}
				.workttl {font-size: 3rem;}
				.author {font-size: 2.2rem;}
			}
		}//gold
		> li.silver {
			margin-top: 30px;
			h3 {
				font-size: 3.2rem;
				color: #aaa;
				text-shadow: -1px 1px 1px #ffffff, 0 0 5px #c0c0c0;
			}
			.prize {margin: 5px 0 15px;color: #aaa;}
			.workttl {font-size: 3.2rem;}
			.author {
				font-size: 2.2rem;
				span {font-size: 1.6rem;}
			}
			@include media(lx) {
				h3 {font-size: 2.6rem;}
				.workttl {font-size: 2.6rem;}
				.author {font-size: 1.8rem;}
			}
		}//silver
		> li.bronze {
			margin-top: 30px;
			h3 {
				font-size: 3.2rem;
				color: #ac6b25;
				text-shadow: -1px 1px 1px #ffffff, 0 0 5px #ac6b25;
			}
			.prize {margin: 5px 0 15px;color: #ac6b25;}
			.workttl {font-size: 3.2rem;}
			.author {
				font-size: 2.2rem;
				span {font-size: 1.6rem;}
			}
			@include media(lx) {
				h3 {font-size: 2.6rem;}
				.workttl {font-size: 2.6rem;}
				.author {font-size: 1.8rem;}
			}
		}//bronze
	}//prize-area
}//old-wrap


/* ============================================================
	ehon_old
============================================================ */
.ehonold-report {
	margin: 30px 0 0;
	> h2 {
		margin-bottom: 15px;
		font-size: 2.0rem;
		font-weight: 700;
		@include media(lx) {font-size: 1.8rem;}
	}//h2
	> .txt-box {
		font-size: 1.4rem;
		line-height: 1.7;
		@include media(lx) {line-height: 1.5;}
		.ph-box {
			float: right;
			margin: 5px 0 8px 5px;
			clear: both;
			width: 224px;
			text-align: center;
			font-size: 1.2rem;
			line-height: 1;
			@include media(lx) {width: 180px;}
			img {margin-bottom: 5px;}
		}//ph-box
	}//txt-box
}//ehonold-report

.ehonold-result {
	margin: 30px 0 0;
	position: relative;

	@include media(ln) {
		img {vertical-align: top;}
		p {
			position: absolute;
			left: 40px;
			max-width: 500px;
			font-size: 1.4rem;
			line-height: 1.7;
			&.ehon {top: 320px;color: #0a6cff;}
			&.story {top: 1095px;color: #4fb000;}
		}//p
	}//min-width: 768px

	@include media(lx) {
		text-align: center;
		position: relative;
		z-index: 1;
		padding: 20px 10px;
		width: 100%;

		&::before {
			content: "";
			z-index: -1;
			-ms-filter: blur(4px);
			filter: blur(4px);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			margin: 0;
			padding: 0;
			background: #c9f6ff;
			background: linear-gradient(to bottom, #c9f6ff, #effbff);
		}
		h3 {
			margin: 5px 0 20px;
			font-size: 2.6rem;
			font-weight: 700;
			color: #9f8a00;
			text-shadow: -1px 1px 1px #ffffff, 0 0 5px #fff178;
			> span {
				font-size: 1.8rem;
				font-family: "游明朝体", YuMincho, "游明朝", "Yu Mincho", "メイリオ", sans-serif;
			}
		}
		h4 {
			margin-bottom:15px;
			font-size: 2.2rem;
			font-weight: 700;
			color: #884e00;
			text-shadow: -1px 1px 1px #ffffff, 0 0 5px #e7bd6f;
			> span {font-size: 1.4rem;}
		}
		h5 {
			margin: 30px 0 15px;
			font-size: 1.8rem;
			font-weight: 700;
			color: #884e00;
			text-shadow: -1px 1px 1px #ffffff, 0 0 5px #e7bd6f;
		}
		p {
			text-shadow:-1px -1px #fff,1px -1px #fff,-1px 1px #fff,1px 1px #fff;
			font-weight: 700;

			&.ttl-ehon {margin-bottom:15px;font-size: 2.2rem;color: #0a6cff;}
			&.ttl-story {margin-bottom:15px;font-size: 2.2rem;color: #4fb000;}
			&.-ehon {color: #0a6cff;}
			&.-story {color: #4fb000;}
			&.first-ttl {margin-bottom: 5px;font-size: 2.4rem;}
			&.first-name {margin-bottom: 15px;font-size: 1.8rem;}
			&.first-txt {font-size: 1.4rem;line-height: 1.5;text-align: left;}
			&.second-ttl {margin-bottom: 5px;font-size: 2rem;}
			&.second-name {margin-bottom: 15px;font-size: 1.6rem;}
			&.memo {font-size: 1.2rem;text-align: right;color: #808080;}
		}//p
	}//max-width: 767px
}//ehonold-result
