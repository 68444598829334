@charset "utf-8";

/* ============================================================
	base
============================================================ */
html {
	font-size: 62.5%;
	min-width: 320px;
}
body {
	position: relative;
	font-family: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif;

	font-weight: 500;
	font-size: $fs_m;
	color: $textColor;
}
body.is-fixed {
	overflow: hidden;
}

a {
	color: $textColor-link;
	text-decoration: none;
	@include transition;

	&:visited {color: $textColor-visited;}
	@include hover {
		@include opacity;
		color: $textColor-hover;
		text-decoration: underline;
	}
}//a

img {
	width: $fullWidth;
	height: auto;
}

img.no-resize {
	width: auto !important;
	height: auto;
}

.txt {
	@include txt;
}
.txt-p {
	margin-top: 15px;
	@include txt;
}

.for-print {
	display: none !important;
}

.txt-l {@include fs-16xl;}


/* ============================================================
	content
============================================================ */
.content-area {
	//changeold margin-top: 175px;
	//changenew
	margin-top: 204px;
	width: $fullWidth;
	@include media(xlx) {
		width: $baseWidthPlus;
	}
	@include media(lx) {
		margin-top: 56px;
		width: $fullWidth;
	}
	@include media(ln) {
		&.mgdr-none {
			margin-top: 105px;
		}
	}
}//content-area

.title-area {
	@include flex-center;
	width: $fullWidth;
	height: 265px;
	background-color: $pink-baseColor;
	@include media(lx) {
		height: 132px;
	}

	> .title {
		width: $fullWidth;
		color: $white;
		font-size: 3.4rem;
		text-align: center;
		@include bold;
		text-shadow: 0 0 5px $titleColor,
									0 0 6px $titleColor,
									0 0 7px $titleColor;
		.small {font-size: 2.4rem;}
		@include media(lx) {
			font-size: 2.4rem;
			.small {font-size: 1.4rem;}
		}

		> .eng {
			width: $fullWidth;
			margin-top: 16px;
			color: $baseColor;
			font-size: $fs_l;
			text-align: center;
			text-shadow: 0 0 5px $borderColor,
									0 0 6px $borderColor,
									0 0 7px $borderColor;
			@include eng;
			font-style: italic;
			@include media(lx) {
				margin-top: 8px;
			}
		}//eng
	}//title

	&.-news {background: url(/images/ttlbg/news.jpg) center no-repeat;background-size: cover;}
//publishing
	&.-contest {background: url(/publishing/images/ttlbg/contest.jpg) center no-repeat;background-size: cover;}
	&.-form {background: url(/publishing/images/ttlbg/form.jpg) center no-repeat;background-size: cover;}
	&.-webform {background: url(/publishing/images/ttlbg/webform.jpg) center no-repeat;background-size: cover;}
	&.-download {background: url(/publishing/images/ttlbg/download.jpg) center no-repeat;background-size: cover;}
	&.-soudankai {background: url(/publishing/images/ttlbg/soudankai.jpg) center no-repeat;background-size: cover;}
	&.-flow {background: url(/publishing/images/ttlbg/flow.jpg) center no-repeat;background-size: cover;}
	&.-support {background: url(/publishing/images/ttlbg/support.jpg) center no-repeat;background-size: cover;}
	&.-partner {background: url(/publishing/images/ttlbg/partner.jpg) center no-repeat;background-size: cover;}
	&.-storage {background: url(/publishing/images/ttlbg/storage.jpg) center no-repeat;background-size: cover;}
	&.-syuppantaiken {background: url(/publishing/images/ttlbg/syuppantaiken.jpg) center no-repeat;background-size: cover;}
	&.-setsumeikai {background: url(/publishing/images/ttlbg/setsumeikai.jpg) center no-repeat;background-size: cover;}
	&.-kakitai {background: url(/publishing/images/ttlbg/kakitai.jpg) center no-repeat;background-size: cover;}
	&.-hondas {background: url(/publishing/images/ttlbg/hondas.jpg) center no-repeat;background-size: cover;}
	&.-qa {background: url(/publishing/images/ttlbg/qa.jpg) center no-repeat;background-size: cover;}
	&.-pamphlet {background: url(/publishing/images/ttlbg/pamphlet.jpg) center no-repeat;background-size: cover;}
	&.-cashico {background: url(/publishing/images/ttlbg/cashico.jpg) center no-repeat;background-size: cover;}
	&.-webmeeting {background: url(/publishing/images/ttlbg/webmeeting.jpg) center no-repeat;background-size: cover;}
	&.-telephonemeeting {background: url(/publishing/images/ttlbg/telephonemeeting.jpg) center no-repeat;background-size: cover;}
//bookinfo
	&.-pickup {background: url(/bookinfo/images/ttlbg/pickup.jpg) center no-repeat;background-size: cover;}
	&.-reprint {background: url(/bookinfo/images/ttlbg/reprint.jpg) center no-repeat;background-size: cover;}
	&.-drama {background: url(/bookinfo/images/ttlbg/drama.jpg) center no-repeat;background-size: cover;}
//about
	&.-profile {background: url(/about/images/ttlbg/profile.jpg) center no-repeat;background-size: cover;}
	&.-map {background: url(/about/images/ttlbg/map.jpg) center no-repeat;background-size: cover;}
	&.-history {background: url(/about/images/ttlbg/history.jpg) center no-repeat;background-size: cover;}
	&.-recruit {background: url(/about/images/ttlbg/recruit.jpg) center no-repeat;background-size: cover;}
	&.-group {background: url(/about/images/ttlbg/group.jpg) center no-repeat;background-size: cover;}
	&.-message {background: url(/about/images/ttlbg/message.jpg) center no-repeat;background-size: cover;}
	&.-philosophy {background: url(/about/images/ttlbg/philosophy.jpg) center no-repeat;background-size: cover;}
	&.-anniversary {background: url(/about/images/ttlbg/20th.jpg) center no-repeat;background-size: cover;}
	&.-protection {background: url(/about/images/ttlbg/protection.jpg) center no-repeat;background-size: cover;}
	&.-principles {background: url(/about/images/ttlbg/principles.jpg) center no-repeat;background-size: cover;}

	&.-contact {background: url(/about/images/ttlbg/contact.jpg) center no-repeat;background-size: cover;}
}//title-area

.title-area.-contest,
.title-area.-form,
.title-area.-webform,
.title-area.-download,
.title-area.-soudankai,
.title-area.-flow,
.title-area.-support,
.title-area.-partner,
.title-area.-storage,
.title-area.-syuppantaiken,
.title-area.-setsumeikai,
.title-area.-kakitai,
.title-area.-hondas,
.title-area.-qa,
.title-area.-pamphlet,
.title-area.-cashico,
.title-area.-webmeeting,
.title-area.-telephonemeeting {
	> .title > .eng {color: $publi-baseColor;}
}
.title-area.-pickup,
.title-area.-reprint,
.title-area.-drama {
	> .title > .eng {
		color: $bkinfo-baseColor;
	}
}
.title-area.-pickup,
.title-area.-reprint {
	> .title > .eng {
		text-shadow: 0 0 5px #eee,
								0 0 6px #eee,
								0 0 7px #eee;
	}
}
.title-area.-simple {
	@include flex-center;
	width: $fullWidth;
	height: 100px;
	background-color: $pink-baseColor;
	@include media(lx) {
		height: 60px;
	}
	> .title {
		text-shadow: none;
	}
}
.title-area.-bkinfo {
	@include flex-center;
	width: $fullWidth;
	height: 90px;
	background-color: #fff;
	@include media(lx) {
		height: 100px;
	}
	> .title {
		color: $baseColor;
		text-shadow: none;
		> .eng {color: $bkinfo-baseColor;text-shadow: none;}
	}
}

.title-area.-small {
	@include flex-center;
	width: $fullWidth;
	height: auto;
	background-color: $white;
	@include media(lx) {
		margin-top: 10px;
		height: auto;
	}
	> .title {
		color: $titleColor;
		font-size: 3rem;
		text-shadow: none;
		.small {font-size: 2rem;}
		@include media(lx) {
			font-size: 2.4rem;
			.small {font-size: 1.6rem;}
		}
	}
}

.title-area.-about {
	@include flex-center;
	width: $fullWidth;
	height: 140px;
	background-color: $baseColor;
	@include media(lx) {
		height: 120px;
		line-height: $lh_s;
	}
	> .title {
		text-shadow: none;

		> .eng {
			margin-top: 16px;
			color: $white;
			text-align: center;
			text-shadow: none;
			@include media(lx) {
				margin-top: 8px;
			}
		}//eng
	}
}


/* ============================================================
	lead-area
============================================================ */
.lead-area {
	@include margin(0 auto 80px);
	padding: 40px 0;
	width: $baseWidth;
	border-bottom: $borderSol;
	.lead {@include fs-16xl;}
	@include media(lx) {
		@include margin-sp(0, 40px);
		padding: 20px 0;
		line-height: $lh_l;
		.lead {line-height: $lh_l;}
	}
}//lead-area

@include media(ln) {
	.lead-area > .column {
		@include flex-between;
		@include flex-center-tb;
		> .txt-area {width: 800px;}
		> .photo {
			width: 260px;
			height: 100%;
		}
	}//column

	.lead-area.-col {
		@include flex-between;
	}
	.lead-area.-col > .wrap {
		width: 860px;
	}
	.lead-area.-col > img {
		width: 190px;
		height: 100%;
	}
}//ln

@include media(lx) {
	.lead-area > .column {
		> .photo {
			margin-top: 15px;
			text-align: center;
			img {max-width: 300px;}
		}
	}//column

	.lead-area.-spcol {
		@include flex-between;
	}
	.lead-area.-spcol > .wrap {
		width: 79%;
	}
	.lead-area.-spcol > img {
		width: 20%;
	}
}//lx

.lead-ttl {
	margin-bottom: 20px;
	font-size: 2.4rem;
	@include bold;
	@include media(lx) {
		margin-bottom: 15px;
		font-size: $fs_xxl;
		line-height: $lh_s;
	}
}


/* ============================================================
	wrap
============================================================ */
.base-wrap {
	@include margin(140px auto);
	width: $baseWidth;
	text-align: left;
	@include media(lx) {
		@include margin-sp(60px, 60px);
	}
}
.full-wrap {
	@include margin(140px auto);
	padding: 60px 0;
	width: $fullWidth;
	background-color: $gray-e;
	text-align: left;
	@include media(lx) {
		@include margin(60px 0);
		@include padding-sp(30px);
	}
}
.old-wrap {
	@include margin(0 auto 140px);
	width: 580px;
	text-align: left;
	@include media(lx) {
		@include margin-sp(0, 60px);
	}
}

.bc-area + .base-wrap,
.lead-area + .base-wrap,
.lead-area + .full-wrap {
	margin-top: 0;
}
.title-area + .base-wrap {
	margin-top: 40px;
	@include media(lx) {
		margin-top: 20px;
	}
}
.old-wrap + .base-wrap {
	margin-top: -100px;
	@include media(lx) {
		margin-top: -30px;
	}
}
.base-wrap.-bkinfo {
	margin-bottom: 60px;
	@include media(lx) {
		@include margin-sp(0, 20px);
	}
}
.search-box + .base-wrap,
.search-word + .base-wrap {
	margin-top: 40px;
	@include media(lx) {
		margin-top: 20px;
	}
}

.social-box {
	@include flex-wrap;
	margin: 15px auto;
	padding: 5px 2px 10px 10px;
	border: $borderSol;
	> div {
		display: inline-block;
		margin-top: 5px;
		padding: 0 5px 0 0;
		height: 20px;
		line-height: 30px;
		&.facebook {
			margin-top: -5px;
			padding: 0;
			width: 91px;
			height: auto;
		}
	}
//> .facebook_like {padding: 0;width: 112px;}
}//social-box


/* ============================================================
	.content-area.-single
============================================================ */
.content-area.-single {
	margin: 10px 0 30px;
	@include media(lx) {margin: 0 0 20px;}
}

.header-simple + .content-area.-single {
	margin: 90px 0 30px;
	@include media(lx) {margin: 56px 0 20px;}
}


.content-area.-single > .title-area {
	@include margin(0 auto 20px);
	@include flex-center;
	width: $baseWidth;
	height: 100px;
	background-color: $pink-baseColor;
	@include media(lx) {
		width: $fullWidth;
		height: 80px;
	}

	> .title {
		width: $fullWidth;
		color: $white;
		font-size: 3.4rem;
		text-align: center;
		@include bold;
		text-shadow: none;
		.small {font-size: 2.4rem;}
		@include media(lx) {
			font-size: 2.4rem;
			.small {font-size: 1.4rem;}
		}
	}//title
}//title-area
.content-area.-single > .title-area + .base-wrap {@include margin;}


/* ============================================================
	section-box
============================================================ */
.sec-box {
	@include margin(100px auto);
	@include media(lx) {
		@include margin(60px auto);
	}
}
.sec-sbox {
	@include margin(60px auto);
	@include media(lx) {
		@include margin(30px auto);
	}
}
.sec-box:first-child,
.sec-sbox:first-child {margin-top: 0;}
.sec-box:last-child,
.sec-sbox:last-child {margin-bottom: 0;}




/* ============================================================
	title-icon
============================================================ */
//例:日程
.h2ttl-icon {
	@include flex-center;
	color: $titleColor;
	font-size: 2.6rem;
	@include bold;
	text-align: center;
	@include media(lx) {
		font-size: 2.2rem;
	}
	> [class^="icon-"] {
		margin-top: -4px;
		@include ie-hack(gteIE11) {margin-top: -11px;}
		margin-right:6px;
		font-size: 3.4rem;
		@include media(lx) {
			font-size: 3rem;
		}
	}
	> .icon-talk {
		margin-top: -12px;
		@include media(lx) {
			margin-top: -8px;
		}
	}
}//h2ttl-icon

//例:こちらのコンテンツもおすすめです
.h3ttl-icon {
	@include flex-center;
	color: $titleColor;
	font-size: $fs_xxl;
	@include bold;
	text-align: center;
	@include media(lx) {
		font-size: $fs_xl;
	}
	@include media(xxsx) {
		font-size: $fs_l;
	}
	> [class^="icon-"] {
		margin-right: 6px;
		font-size: 2.8rem;
		@include media(lx) {
			font-size: 2.6rem;
		}
	}
}//h3ttl-icon


/* ============================================================
	h2ttl-linebox
============================================================ */
.h2ttl-linebox {
	display: block;
	margin-bottom: 20px;
	padding: 10px 12px;
	border: $borderSol-baseC2;
	color: $baseColor;
	font-size: 2.6rem;
	line-height: $lh_s;
	@include bold;
	text-align: center;

	@include media(lx) {
		margin-bottom: 15px;
		padding: 6px 8px;
		font-size: 2.2rem;
	}

	&.-publi {
		border: $borderSol-publi2;
		color: $publi-baseColor;
	}
	&.-support {
		border: $borderSol-pink2;
		color: $pink-baseColor;
	}
	&.-bkinfo {
		border: $borderSol-bkinfo2;
		color: $bkinfo-baseColor;
	}

	> [class^="icon-"] {
		margin-right: 6px;
		font-size: 2.6rem;
		@include media(lx) {
			font-size: 2.2rem;
		}
	}
}//h2ttl-linebox


/* ============================================================
	h2ttl-bgbox
============================================================ */
.h2ttl-bgbox {
	display: block;
	margin-bottom: 20px;
	padding: 10px 12px;
	background-color: $baseColor;
	color: $white;
	font-size: 2.6rem;
	line-height: $lh_s;
	@include bold;
	text-align: center;

	@include media(lx) {
		margin-bottom: 15px;
		padding: 6px 8px;
		font-size: 2.2rem;
	}

	&.-publi {
		background-color: $publi-baseColor;
	}
	&.-support {
		background-color: $pink-baseColor;
	}
	&.-bkinfo {
		background-color: $bkinfo-baseColor;
	}

	> [class^="icon-"] {
		margin-right: 6px;
		font-size: 2.6rem;
		@include media(lx) {
			font-size: 2.2rem;
		}
	}
}//h2ttl-bgbox


/* ============================================================
	h2ttl-line
============================================================ */
.h2ttl-line {
	display: block;
	margin-bottom: 20px;
	padding: 6px 10px;
	border-bottom: $borderSol-baseC3;
	color: $baseColor;
	font-size: 2.6rem;
	line-height: $lh_m;
	@include bold;

	@include media(lx) {
		margin-bottom: 15px;
		padding: 4px 8px;
		font-size: 2.2rem;
	}

	&.-publi {
		border-bottom: $borderSol-publi2;
		color: $publi-baseColor;
	}
	&.-support {
		border-bottom: $borderSol-pink2;
		color: $pink-baseColor;
	}
	&.-bkinfo {
		border-bottom: $borderSol-bkinfo2;
		color: $bkinfo-baseColor;
	}
}//h2ttl-line


/* ============================================================
	h3ttl-line
============================================================ */
.h3ttl-line {
	display: block;
	margin-bottom: 20px;
	padding: 10px;
	border-bottom: $borderSol-baseC3;
	color: $titleColor;
	font-size: $fs_xxl;
	line-height: $lh_s;
	@include bold;
	text-align: center;

	@include media(lx) {
		padding: 10px 0;
		margin-bottom: 15px;
		font-size: $fs_xl;
		text-align: center;
	}

	&.-publi {
		border-bottom: $borderSol-publi3;
	}
	&.-support {
		border-bottom: $borderSol-pink3;
		@include media(lx) {text-align: left;}
	}
	&.-hondas {
		border-bottom: $borderSol-hondas3;
	}
	&.-bkinfo {
		border-bottom: $borderSol-bkinfo3;
	}
}

/* ============================================================
	h3ttl
============================================================ */
.h3ttl {
	display: block;
	margin-bottom: 15px;
	color: $titleColor;
	font-size: $fs_xxl;
	line-height: $lh_m;
	@include bold;
	text-align: left;

	@include media(lx) {
		font-size: $fs_xl;
	}
}


/* ============================================================
	h4ttl-line
============================================================ */
.h4ttl-line {
	display: block;
	margin-bottom: 20px;
	padding: 4px 8px;
	border-bottom: $borderSol-baseC2;
	color: $titleColor;
	font-size: $fs_xl;
	line-height: $lh_m;
	@include bold;
	text-align: left;

	@include media(lx) {
		margin-bottom: 15px;
		font-size: $fs_l;
	}

	&.-publi {
		border-bottom: $borderSol-publi2;
	}
	&.-support {
		border-bottom: $borderSol-pink2;
	}
	&.-bkinfo {
		border-bottom: $borderSol-bkinfo2;
	}
}

/* ============================================================
	h4ttl
============================================================ */
.h4ttl {
	display: block;
	margin-bottom: 15px;
	color: $titleColor;
	font-size: $fs_xl;
	line-height: $lh_m;
	@include bold;
	text-align: left;

	@include media(lx) {
		margin-bottom: 10px;
		font-size: $fs_l;
	}
}

/* ============================================================
	h5ttl-line
============================================================ */
.h5ttl-line {
	display: block;
	margin-bottom: 15px;
	padding: 3px 8px 5px 10px;
	border-left: 5px solid $baseColor;
	border-bottom: $borderSol-baseC1;
	color: $titleColor;
	font-size: $fs_l;
	line-height: $lh_m;
	@include bold;
	text-align: left;

	@include media(lx) {
		margin-bottom: 10px;
	}

	&.-publi {
		border-left: 5px solid $publi-baseColor;
		border-bottom: $borderSol-publi1;
	}
	&.-support {
		border-left: 5px solid $pink-baseColor;
		border-bottom: $borderSol-pink1;
	}
	&.-bkinfo {
		border-left: 5px solid $bkinfo-baseColor;
		border-bottom: $borderSol-bkinfo1;
	}
}

/* ============================================================
	h5ttl
============================================================ */
.h5ttl {
	display: block;
	margin-bottom: 10px;
	color: $titleColor;
	font-size: $fs_l;
	line-height: $lh_m;
	@include bold;
	text-align: left;
}


/* ============================================================
	lead
============================================================ */
//1.6rem-center
.lead-lcc {
	@include margin(20px 0 30px);
	@include fs-16l;
	text-align: center;
	@include media(lx) {
		@include margin(10px 0 20px);
	}
}
//1.4rem-center
.lead-mcc {
	@include margin(20px 0);
	@include fs-14xl;
	text-align: center;
	@include media(lx) {
		@include margin(10px 0 15px);
		line-height: $lh_l;
	}
}

//1.6rem-pc_center
.lead-lcl {
	@include margin(20px 0 30px);
	@include fs-16l;
	text-align: center;
	@include media(lx) {
		@include margin(10px 0 20px);
		text-align: left;
	}
}
//1.4rem-pc_center
.lead-mcl {
	@include margin(20px 0);
	@include fs-14xl;
	text-align: center;
	@include media(lx) {
		@include margin(10px 0 15px);
		text-align: left;
		line-height: $lh_l;
	}
}

//1.6rem-left
.lead-lll {
	@include margin(20px 0 30px);
	@include fs-16xl;
	text-align: left;
	@include media(lx) {
		@include margin(15px 0 20px);
		line-height: $lh_l;
	}
}
//1.4rem-left
.lead-mll {
	@include margin(20px 0);
	@include fs-14xl;
	text-align: left;
	@include media(lx) {
		@include margin(10px 0 15px);
		line-height: $lh_l;
	}
}


/* ============================================================
	bc_area
============================================================ */
.bc-area {
	@include margin;
	padding: 20px 0;
	overflow: auto;
	white-space: nowrap;
	width: $baseWidth;
	text-align: left;
	@include media(lx) {
		padding: 0;
		width: $fullWidth;
	}
	&::-webkit-scrollbar {
		height: 1px;
	}
	&::-webkit-scrollbar-track {
		background: $white;
	}
	&::-webkit-scrollbar-track {
		background: $white;
	}
}//bc-area

.bc-list {
	display: table;
	list-style: none;
	margin: 0;
	padding: 12px 5px;
	font-size: 0;
	> li {
		display: table-cell;
		font-size: $fs_s;
		white-space: nowrap;
		a {
			color: $textColor;
		}
		&:not(:last-child)::after {
			content: ">";
			padding: 0 10px;
		}
			@include media(lx) {
				font-size: $fs_xs;
			}
	}
}//bc-list


/* ============================================================
	btn
============================================================ */
.btn-box {
	display: block;
	@include margin;
	text-align: center;
}

.btn-back {
	@include btn-back;
}

.btn {
	@include btn;

	&.-narrow {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	&.-anker {
		&::before {
			@include anker;
			right: 15px;
			transition: top .3s;
			@include media(lx) {right: 12px;}
		}
		@include hover() {
			text-decoration: none;
			background-color: $white;
			&::before {
				right: 15px;
				top: 58%;
				transform: translate(0,-58%);
				@include media(lx) {right: 12px;}
			}
		}
	}//anker

	&.-blank {
		&::before {
			@include blank;
			right: 15px;
			@include media(lx) {right: 12px;}
		}
		@include hover() {
			background-color: $white;
			&::before {
				right: 15px;
				@include media(lx) {right: 12px;}
			}
		}
	}//blank

	&.-dl {
		@include media(lx) {padding-right: 40px;}
		&::before {
			@include dl;
			right: 15px;
			@include media(lx) {right: 12px;}
		}
		@include hover() {
			background-color: $white;
			&::before {
				right: 15px;
				@include media(lx) {right: 12px;}
			}
		}
	}//dl

}//btn

.btn-publi {
	@include btn-publi;

	&.-narrow {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	&.-anker {
		&::before {
			@include anker;
			right: 15px;
			transition: top .3s;
			@include media(lx) {right: 12px;}
		}
		@include hover() {
			text-decoration: none;
			background-color: $white;
			&::before {
				right: 15px;
				top: 58%;
				transform: translate(0,-58%);
				@include media(lx) {right: 12px;}
			}
		}
	}//anker

	&.-blank {
		&::before {
			@include blank;
			right: 15px;
			@include media(lx) {right: 12px;}
		}
		@include hover() {
			background-color: $white;
			&::before {
				right: 15px;
				@include media(lx) {right: 12px;}
			}
		}
	}//blank

	&.-dl {
		@include media(lx) {padding-right: 40px;}
		&::before {
			@include dl;
			right: 15px;
			@include media(lx) {right: 12px;}
		}
		@include hover() {
			background-color: $white;
			&::before {
				right: 15px;
				@include media(lx) {right: 12px;}
			}
		}
	}//dl

}//btn-publi

.btn-bkinfo {
	@include btn-bkinfo;

	&.-narrow {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	&.-anker {
		&::before {
			@include anker;
			right: 15px;
			transition: top .3s;
			@include media(lx) {right: 12px;}
		}
		@include hover() {
			text-decoration: none;
			background-color: $white;
			&::before {
				right: 15px;
				top: 58%;
				transform: translate(0,-58%);
				@include media(lx) {right: 12px;}
			}
		}
	}//anker

	&.-blank {
		&::before {
			@include blank;
			right: 15px;
			@include media(lx) {right: 12px;}
		}
		@include hover() {
			background-color: $white;
			&::before {
				right: 15px;
				@include media(lx) {right: 12px;}
			}
		}
	}//blank

	&.-dl {
		@include media(lx) {padding-right: 40px;}
		&::before {
			@include dl;
			right: 15px;
			@include media(lx) {right: 12px;}
		}
		@include hover() {
			background-color: $white;
			&::before {
				right: 15px;
				@include media(lx) {right: 12px;}
			}
		}
	}//dl

}//btn-bkinfo


/* ============================================================
	btnw
============================================================ */
.btnw {
	@include btnw;

	&.-narrow {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	&.-anker {
		&::before {
			@include anker;
			right: 15px;
			transition: top .3s;
			@include media(lx) {right: 12px;}
		}
		@include hover() {
			&::before {
				right: 15px;
				top: 58%;
				transform: translate(0,-58%);
				@include media(lx) {right: 12px;}
			}
		}
	}//anker

	&.-blank {
		&::before {
			@include blank;
			right: 15px;
			@include media(lx) {right: 12px;}
		}
		@include hover() {
			&::before {
				right: 15px;
				@include media(lx) {right: 12px;}
			}
		}
	}//blank

}//btnw

.btnw-publi {
	@include btnw-publi;

	&.-anker {
		&::before {
			@include anker;
			right: 15px;
			transition: top .3s;
			@include media(lx) {right: 12px;}
		}
		@include hover() {
			text-decoration: none;
			background-color: $publi-baseColor;
			&::before {
				right: 15px;
				top: 58%;
				transform: translate(0,-58%);
				@include media(lx) {right: 12px;}
			}
		}
	}//anker

	&.-blank {
		&::before {
			@include blank;
			right: 15px;
			@include media(lx) {right: 12px;}
		}
		@include hover() {
			background-color: $publi-baseColor;
			&::before {
				right: 15px;
				@include media(lx) {right: 12px;}
			}
		}
	}//blank

	&.-dl {
		@include media(lx) {padding-right: 40px;}
		&::before {
			@include dl;
			right: 15px;
			@include media(lx) {right: 12px;}
		}
		@include hover() {
			background-color: $publi-baseColor;
			&::before {
				right: 15px;
				@include media(lx) {right: 12px;}
			}
		}
	}//dl

}//btnw-publi

.btnw-bkinfo {
	@include btnw-bkinfo;
}


/* ============================================================
	link
============================================================ */
.link-box {
	display: block;
	margin-top: 20px;
}
.link-box + .link-box {
	margin-top: 15px;
}

.txtlink {
	@include link-text;
	text-indent: 0;
}

.link-arrow {
	display: inline-block;
	position: relative;
	margin-right: 5px;
	padding-right: 15px;
	//font-size: $fs_m;
	line-height: $lh_s;
	text-decoration: none !important;

	&::before {
		@include position-center-tb;
		@include icon-bfaf;
		content: $icon-arrow-right;
		right: 0;
		font-size: $fs_xs;
		transition: right .1s;
	}

	@include hover() {
		text-decoration: underline !important;
		&::before {
			right: -5px;
		}
	}

	&.-publi {
		color: $publi-baseColor;
	}
	&.-bkinfo {
		color: $bkinfo-baseColor;
	}
}


/* ============================================================
	indent
============================================================ */
//1文字インデント（中点や米印など）
.indent {
	@include indent;
	line-height: $lh_l;
}
.indent + .indent {
	margin-top: 5px;
}

//1文字インデント リスト用（中点や丸数字など）
.indent-list {
	> li {
		@include indent;
		margin-top: 5px;
		&:first-of-type {
			margin-top: 0;
		}
		line-height: $lh_l;
	}
}//indent_list

//複数文字インデント（括弧数字など）
.indent-multilist {
	> li {
		position: relative;
		@include margin(5px 0 0 0);
		display: table;
		line-height: $lh_l;
		text-align: left;
		> span {
			display: table-cell;
			&:first-child {white-space: nowrap;}
		}
		> div {
			display: table-cell;
			&:first-child {white-space: nowrap;}
		}
	}//li
}//indent-multi_list
//「1.」〜「10.」用
.indent-multilist.-decimal > li > span:first-child {
	width: 2.3rem;
	text-align: right;
	padding-right: 0.4rem;
}

//補足文用 1文字（米印）インデント
.note {
	@include indent;
	margin-top: 15px;
	font-size: $fs_s !important;
	line-height: $lh_l;
	@include media(lx) {
		font-size: $fs_xs !important;
	line-height: $lh_m;
	}
}
.note + .note {
	margin-top: 5px;
}

//補足文用 複数文字インデント （米印＆数字など。1文字も可）
.note-list {
	margin-top: 15px;
	> li {
		position: relative;
		@include margin(5px 0 0 0);
		display: table;
		@include fs-12m;
		text-align: left;
		@include media(lx) {font-size: $fs_xs;}
		> span {
			display: table-cell;
			&:first-child {white-space: nowrap;}
		}
	}//li
}//note_list


/* ============================================================
	list
============================================================ */
.decimal-list {
	list-style-type: decimal;
	padding-left: 40px;
	> li {
		margin-top: 5px;
		&:first-of-type {
			margin-top: 0;
		}
		line-height: $lh_l;
	}
}

/* ============================================================
	column
============================================================ */
.column2-wrap {
	@include flex-between;
	> .item {width: 48%;}
	@include media(lx) {
		display: block;
		> .item {width: $fullWidth;}
		> .item:first-of-type {margin-bottom: 20px;}
	}
}

.pcflexbw-wrap {
	@include pcflexbw-wrap;
}


/* ============================================================
	pc-sp change
============================================================ */
/* PC時表示 */
.size-pc {
	display: inline-block;
	@include media(lx) {
		display: none !important;
	}
}
/* 480px以上表示 */
.size-pcsx {
	display: inline-block;
	@include media(sx) {
		display: none !important;
	}
}

/* SP時表示 */
.size-sp {
	display: none;
	@include media(lx) {
		display: inline-block !important;
	}
}
/* 700px以下表示 */
.size-spmx {
	display: none;
	@include media(mx) {
		display: inline-block !important;
	}
}
/* 479px以下表示 */
.size-spsx {
	display: none;
	@include media(sx) {
		display: inline-block !important;
	}
}
/* 375px以下表示 */
.size-spxsx {
	display: none;
	@include media(xsx) {
		display: inline-block !important;
	}
}
/* 360px以下表示 */
.size-spaxsx {
	display: none;
	@include media(axsx) {
		display: inline-block !important;
	}
}
/* 320px以下表示 */
.size-spxxsx {
	display: none;
	@include media(xxsx) {
		display: inline-block !important;
	}
}


/* ============================================================
	marker
============================================================ */
.marker-red {
	background: linear-gradient(transparent 90%, #ff7f7f 90%);
}
.marker-blue {
	background: linear-gradient(transparent 90%, #7fbfff 90%);
}
.marker-yellow {
	background: linear-gradient(transparent 90%, #ffdc00 90%);
}
.marker-purple {
	background: linear-gradient(transparent 90%, #ce9eff 90%);
}


/* ============================================================
	bgbox
============================================================ */
.box-base {
	@include margin(30px 0);
	padding: 16px;
	width: $fullWidth;
	@include media(lx) {
		padding: 8px;
	}
	&.-bgred {background-color: #fbded9;}
}


/* ============================================================
	icon addition
============================================================ */
[class^="icon-"], [class*=" icon-"] {
	&::before {
		display: inline-block;
		@include hover {text-decoration: none !important;}
	}
}
.icon-blank {
	margin: 0 3px;
	&::before {
		font-size: $fs_s;
	}
}